import axios from 'axios';
// API URLs
import { API_BASE_URL } from "config";
import { getWithToken } from 'service/common/tokenService';
const LOGS = '/logs';
const SETTINGS = '/settings';

const GET = '/get';
const ACTIVITIES_LIST = '/activities-list';

/**
 * Log listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getLogs = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${SETTINGS}${LOGS}${GET}`);
    return response.data;
  } catch (error) {
    console.error('Support listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Modulleri ve izinleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getActivitiesList = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${SETTINGS}${LOGS}${ACTIVITIES_LIST}`);
    return response.data;
  } catch (error) {
    console.error('Moduller ve izinler listelenirken bir hata oluştu:', error);
    throw error;
  }
};
