import {
  SET_FILTERED_ROLES,
} from './actionTypes';

// Action to set fetched companies
export const setFilteredRoles = (data) => {
  return {
    type: SET_FILTERED_ROLES,
    payload: data,
  };
};
