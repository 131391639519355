// reducer.js
import {
  SET_UNITS,
  CLEAR_UNITS
} from './actionTypes';

const initialState = {
  units: [],
};

const unitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNITS:
      return {
        ...state,
        units: action.payload,  // Payload doğrudan companies array'i olacak
      };

    case CLEAR_UNITS:
      return {
        ...state,
        units: [],  // Payload doğrudan companies array'i olacak
      };

    default:
      return state;
  }
};

export default unitsReducer;
