import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { FaCheck, FaEye } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'styles/custom/custom-tooltip.css';

const CheckButton = ({ onClick = ()=>{}, disabled }) => {
  const { t } = useTranslation();
  const [checkTooltipOpen, setCheckTooltipOpen] = useState(false);

  const toggleCheckTooltip = () => setCheckTooltipOpen(!checkTooltipOpen);

  return (
    <div id="checkButtonTooltipWrapper">
      <Button color="link" onClick={() => onClick()} disabled={disabled} className='py-0'>
        <FaCheck color={disabled ? "gray" : "#27B0FF"} size={18} />
      </Button>
      <Tooltip
        isOpen={checkTooltipOpen}
        target="checkButtonTooltipWrapper"
        toggle={toggleCheckTooltip}
        placement="top"
        className='custom-tooltip'
      >
        {disabled ? t("No authority") : t("Confirm")}
      </Tooltip>
    </div>
  );
};

CheckButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

CheckButton.defaultProps = {
  disabled: false
};

export default CheckButton;
