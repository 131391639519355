// reducer.js
import {
  UPDATE_CITY_SUCCESS,
  DELETE_CITY_SUCCESS,
  SET_CITY,
} from './actionTypes';

const initialState = {
  cities: [],
  error: null,
};

const citiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CITY_SUCCESS:
      const updatedCities = state.cities.map((city) =>
      city.id === action.payload.id ? action.payload.data : city
      );
      return {
        ...state,
        cities: updatedCities,
      };

    case DELETE_CITY_SUCCESS:
      const filteredCities = state.cities.filter((city) => city.id !== action.payload);
      return {
        ...state,
        cities: filteredCities,
      };

    case SET_CITY:
      return {
        ...state,
        cities: action.payload,  // Payload doğrudan companies array'i olacak
      };

    default:
      return state;
  }
};

export default citiesReducer;
