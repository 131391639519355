import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { deleteInslug, getDeletedInslugs, getInslugs, restoreInslug } from 'service/depot/inslugService';
import { FaEdit, FaEye, FaRecycle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import { Button, Input } from 'reactstrap';
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { hasDeletePermission, hasExcelPermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import DetailView from 'components/Common/detailView';
import { useNavigate } from 'react-router-dom';
import RestoreButton from 'components/Common/Button/restoreButton';
import { closeModal, openModal } from 'store/modal/actions';
import { useDispatch } from 'react-redux';
import AcceptModal from 'components/Common/Modals/acceptModal';

const TrashedInslugs = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [inslugData, setInslugData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchInslugData = async () => {
      try {
        const inslugList = await getDeletedInslugs();
        setInslugData(inslugList);
        setFilteredData(inslugList);
      } catch (error) {
        // toast.error("toast-error-fetch-data", { position: "top-right" });
      }
    };
    fetchInslugData();
  }, []);

  // Arama işlemi için useEffect
  useEffect(() => {
    const filtered = inslugData.filter((item) => {
      return (
        (item.client_no && item.client_no.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.company_name && item.company_name.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.booking_date && new Date(item.booking_date).toLocaleDateString().includes(searchText)) ||
        (item.eta && new Date(item.eta).toLocaleDateString().includes(searchText)) ||
        (item.closing_out && new Date(item.closing_out).toLocaleDateString().includes(searchText)) ||
        (item.closing_in && new Date(item.closing_in).toLocaleDateString().includes(searchText))
      );
    });
    setFilteredData(filtered);
  }, [searchText, inslugData]);

  const createJson = (data) => {
    const jsonObject = {
      [props.t("Company No")]: data.client_no,
      [props.t("Company Name")]: data.company_name,
      [props.t("Transport Company No")]: data.transport_company_no,
      [props.t("Supplier Company No")]: data.supplier_company_no,
      [props.t("Agency Reference")]: data.agency_reference,
      [props.t("Booking Date")]: data.booking_date,
      [props.t("ETA")]: data.client_no,
      [props.t("Closing Out")]: data.closing_out,
      [props.t("Closing In")]: data.closing_in,
      [props.t("Document No")]: data.document_no,
      [props.t("Ext Document No")]: data.ext_document_no,
      [props.t("Description")]: data.description,
      [props.t("Customs Status")]: data.customs_status_id,
      [props.t("Carrier Type")]: data.carrier_type_id,
      [props.t("Loading Status")]: data.loading_status_id,
      [props.t("Process Status")]: data.process_status_id,
    };
    return jsonObject;
  };

  const CloseButton = ({ closeToast }) => (
    <FaTimes style={{ cursor: 'pointer', color: 'purple', fontSize: '16px' }} onClick={closeToast} />
  );

  const handleViewDetails = (inslug) => {
    const data = createJson(inslug);
    toast(
      <DetailView val={data} />,
      {
        position: "bottom-left",
        autoClose: false,
        closeOnClick: false,
        draggable: true,
        pauseOnHover: true,
        className: "shadow shadow-lg custom-toast-info",
        style: { width: "600px" },
        closeButton: <CloseButton />
      }
    );
  };

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          try {
            await restoreInslug(id); // Delete company
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });

            const inslugList = await getDeletedInslugs();
            setInslugData(inslugList);
            setFilteredData(inslugList);
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-restore-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const restoreIcon = (row) => <RestoreButton onClick={() => handleRestore(row.id)} />

  const columns = [
    { name: props.t("Company No"), selector: row => row.client_no || props.t("missing"), sortable: true },
    { name: props.t("Company Name"), selector: row => row.company_name || props.t("missing"), sortable: true },
    { name: props.t("Booking Date"), selector: row => row.booking_date ? new Date(row.booking_date).toLocaleDateString() : props.t("missing"), sortable: true },
    { name: props.t("ETA"), selector: row => row.eta ? new Date(row.eta).toLocaleDateString() : props.t("missing"), sortable: true },
    { name: props.t("Closing Out"), selector: row => row.closing_out ? new Date(row.closing_out).toLocaleDateString() : props.t("missing"), sortable: true },
    { name: props.t("Closing In"), selector: row => row.closing_in ? new Date(row.closing_in).toLocaleDateString() : props.t("missing"), sortable: true },
    {
      name: props.t("Actions"),
      cell: row => (
        <>
          <Button color="link" onClick={() => handleViewDetails(row)}>
            <FaEye size={18} color="#CB80AB" style={{ cursor: 'pointer' }} />
          </Button>
          {hasDeletePermission(permissions, "DE_01") && restoreIcon(row)}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const exportToExcel = () => {
    const columnNames = {
      client_no: props.t("Company No"),
      company_name: props.t("Company Name"),
      booking_date: props.t("Booking Date"),
      eta: props.t("ETA"),
      closing_in: props.t("Closing In"),
      closing_out: props.t("Closing Out"),
    };

    const selectedColumns = inslugData.map(item => ({
      [columnNames.client_no]: item.client_no,
      [columnNames.company_name]: item.company_name,
      [columnNames.booking_date]: new Date(item.booking_date).toLocaleDateString(),
      [columnNames.eta]: new Date(item.eta).toLocaleDateString(),
      [columnNames.closing_in]: new Date(item.closing_in).toLocaleDateString(),
      [columnNames.closing_out]: new Date(item.closing_out).toLocaleDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Inslug List"));
    XLSX.writeFile(workbook, props.t("Inslug List") + ".xlsx");
  };

  const excelIcon = (
    <Button className="me-2 p-0" color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>
  );

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary">{props.t("Inslug List")}</h5>
      <DataTable
        subHeader
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")}
        paginationComponentOptions={customPaginationOptions}
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_03") && filteredData.length > 0 ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))}
              className="form-control w-50"
              maxLength={32}
            />
          </div>
        }
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10]}
        columns={columns}
        data={filteredData}
        pagination
        className='p-3'
        highlightOnHover
      />
      <AcceptModal />
    </div>
  );
};

TrashedInslugs.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(TrashedInslugs);
