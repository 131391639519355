import { SET_CREATE_DEPOT_FORM_DATA, CLEAR_CREATE_DEPOT_FORM_DATA } from './actionTypes';

export const setCreateDepotFormData = (formData) => ({
  type: SET_CREATE_DEPOT_FORM_DATA,
  payload: formData,
});

export const clearCreateDepotFormData = () => ({
  type: CLEAR_CREATE_DEPOT_FORM_DATA,
});
