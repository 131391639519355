// src/store/selectedCountry/selectedCountryReducer.js
import { SET_SELECTED_IMAGE, CLEAR_SELECTED_IMAGE } from './actionTypes';

const initialState = {
  selectedImage: null, // Başlangıçta seçilen ülke boş
};

const selectedImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_IMAGE:
      return {
        ...state,
        selectedImage: action.payload,
      };

    case CLEAR_SELECTED_IMAGE:
      return {
        ...state,
        selectedImage: null,
      };

    default:
      return state;
  }
};

export default selectedImageReducer;
