// reducer.js
import { OPEN_MODAL, CLOSE_MODAL } from './actionTypes';

const initialState = {
  isOpen: false,
  content: {
    title: '',
    body: '',
    confirmLabel: '',
    cancelLabel: '',
    onConfirm: null,
  },
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        content: action.payload, // Set dynamic modal content
      };

    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        content: initialState.content, // Reset modal content
      };

    default:
      return state;
  }
};

export default modalReducer;
