import {
    SET_DEPOTS,
    CLEAR_DEPOTS
} from './actionTypes';

// Action to set fetched companies
export const setDepots = (data) => {
    return {
        type: SET_DEPOTS,
        payload: data,
    };
};


// Action to clear companies
export const clearDepots = (data) => {
    return {
        type: CLEAR_DEPOTS,
        payload: data,
    };
};
