// src/store/form/action.js
import { SET_UPDATE_CLIENT_PRODUCT_FORM_DATA, CLEAR_UPDATE_CLIENT_PRODUCT_FORM_DATA } from './actionTypes';

// Form verilerini set etmek
export const setClientProductFormData = (formData) => ({
  type: SET_UPDATE_CLIENT_PRODUCT_FORM_DATA,
  payload: formData,
});

// Form verilerini temizlemek
export const clearClientProductFormData = () => ({
  type: CLEAR_UPDATE_CLIENT_PRODUCT_FORM_DATA,
});
