// src/store/cityUpdate/cityUpdateActions.js
import { SET_SUPPORT_DATA, CLEAR_SUPPORT_DATA } from './actionTypes';

export const setSupportFormData = (formData) => ({
  type: SET_SUPPORT_DATA,
  payload: formData,
});

export const clearSupportFormData = () => ({
  type: CLEAR_SUPPORT_DATA,
});
