// reducer.js
import { OPEN_FAST_CREATE_MODAL, CLOSE_FAST_CREATE_MODAL } from './actionTypes';


const initialState = {
  isOpen: false,
  content: {
    title: 'Fast Save',
    body: 'Are you sure you want to save the data?',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    onConfirm: () => {},
  },
};

const fastCreateModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_FAST_CREATE_MODAL:
      return {
        ...state,
        isOpen: true,
        content: action.payload, // Set dynamic modal content
      };

    case CLOSE_FAST_CREATE_MODAL:
      return {
        ...state,
        isOpen: false,
        content: initialState.content, // Reset modal content
      };

    default:
      return state;
  }
};

export default fastCreateModalReducer;
