// reducer.js
import {
  UPDATE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_SUCCESS,
  SET_COUNTRY,
} from './actionTypes';

const initialState = {
  countries: [],
  error: null,
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COUNTRY_SUCCESS:
      const updatedCountries = state.countries.map((country) =>
      country.id === action.payload.id ? action.payload.data : country
      );
      return {
        ...state,
        countries: updatedCountries,
      };

    case DELETE_COUNTRY_SUCCESS:
      const filteredCountires = state.countries.filter((country) => country.id !== action.payload);
      return {
        ...state,
        countries: filteredCountires,
      };

    case SET_COUNTRY:
      return {
        ...state,
        countries: action.payload,  // Payload doğrudan companies array'i olacak
      };

    default:
      return state;
  }
};

export default countriesReducer;
