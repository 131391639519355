import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const CON_CIT = '/country-city';
const CITY = '/city';
const CREATE = '/create';
const GET = '/get';
const GET_DELETED = '/get-deleted';
const UPDATE = '/update';
const DELETE = '/delete';

/**
 * Yeni şehir oluşturma fonksiyonu
 * @param {Object} data - Şehir oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createCity = async (data) => {
  console.log("servis iç");
  console.log(data);
  try {
    const response = await postWithToken(`${API_BASE_URL}${CON_CIT}${CITY}${CREATE}`, data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şehir eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şehirleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getCities = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${CON_CIT}${CITY}${GET}`)
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Şehir listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şehirleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedCities = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${CON_CIT}${CITY}${GET_DELETED}`)
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şehir listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şehir güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şehir bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateCity = async (data) => {
  try {
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${CON_CIT}${CITY}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şehir güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şehir silme fonksiyonu
 * @param {string} id - Silinmek istenen şehir ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteCity = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${CON_CIT}${CITY}${DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şehir silinirken bir hata oluştu:', error);
    throw error;
  }
};