// src/store/cityUpdate/cityUpdateActions.js
import { SET_PERMISSIONS, CLEAR_PERMISSIONS } from './actionTypes';

export const setPermissions = (permissions) => ({
  type: SET_PERMISSIONS,
  payload: permissions,
});

export const clearCityFormData = () => ({
  type: CLEAR_PERMISSIONS,
});
