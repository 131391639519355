import axios from 'axios';
// API URLs
import { API_BASE_URL, API_DEPOT } from "config";
import { getWithToken, postWithToken } from '../common/tokenService';
const API_CLIENT_PRODUCT = '/client-product';
const API_CLIENT_PRODUCT_CREATE = '/create';
const API_CLIENT_PRODUCT_GET = '/get';
const API_CLIENT_PRODUCT_DETAILS = '/get-details';
const API_CLIENT_PRODUCT_GET_DELETED = '/get-deleted';
const API_CLIENT_PRODUCT_RESTORE = '/restore';
const API_CLIENT_PRODUCT_UPDATE = '/update';
const API_CLIENT_PRODUCT_DELETE = '/delete';
const API_CLIENT_PRODUCT_BY_COMPANY_GET = '/get-by-company';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} companyData - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createClientProduct = async (productData) => {
  console.log("servis iç");
  console.log(productData);
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_CLIENT_PRODUCT}${API_CLIENT_PRODUCT_CREATE}`, productData);
    console.log("clientProductList");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getClientProducts = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_CLIENT_PRODUCT}${API_CLIENT_PRODUCT_GET}`);
      console.log("burası getCompanies service");
      console.log(response.data);
      console.log("*******");
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getClientProductsByCompany = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_CLIENT_PRODUCT}${API_CLIENT_PRODUCT_BY_COMPANY_GET}`);
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getClientProductDetails = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_CLIENT_PRODUCT}${API_CLIENT_PRODUCT_DETAILS}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedClientProducts = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_CLIENT_PRODUCT}${API_CLIENT_PRODUCT_GET_DELETED}`);
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateClientProduct = async (data) => {
  try {
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_CLIENT_PRODUCT}${API_CLIENT_PRODUCT_UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteClientProduct = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_CLIENT_PRODUCT}${API_CLIENT_PRODUCT_DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreClientProduct = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_CLIENT_PRODUCT}${API_CLIENT_PRODUCT_RESTORE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};