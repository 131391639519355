import { SET_UPDATE_UNIT_FORM_DATA, CLEAR_UPDATE_UNIT_FORM_DATA } from './actionTypes';

export const setUpdateUnitFormData = (formData) => ({
  type: SET_UPDATE_UNIT_FORM_DATA,
  payload: formData,
});

export const clearUpdateUnitFormData = () => ({
  type: CLEAR_UPDATE_UNIT_FORM_DATA,
});
