// inslug/actions.js

import { SET_INSLUG_DATA, SUBMIT_INSLUG_FORM, FETCH_INSLUG_SUCCESS, RESET_INSLUG_FORM } from './actionTypes';

// Action to set form data
export const setInslugData = (data) => {
  return {
    type: SET_INSLUG_DATA,
    payload: data,
  };
};

// Action to submit form data
export const submitInslugForm = (inslugData) => {
  return {
    type: SUBMIT_INSLUG_FORM,
    payload: inslugData,
  };
};

// Action to fetch inslug data (you could use this to pre-fill form or other purposes)
export const fetchInslugSuccess = (data) => {
  return {
    type: FETCH_INSLUG_SUCCESS,
    payload: data,
  };
};

// Async action to fetch inslug (you can simulate an API call here)
export const fetchInslug = () => {
  return (dispatch) => {
    // Simulate API call
    const inslugData = {}; // Replace with real data
    dispatch(fetchInslugSuccess(inslugData));
  };
};

export const resetInslugForm = () => ({
  type: RESET_INSLUG_FORM,
});

