import { SET_SELECTED_CLIENT_PRODUCT, RESET_SELECTED_CLIENT_PRODUCTS } from './actionTypes';

const initialState = {
  selectedClientProducts: [],
};

const selectedClientProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CLIENT_PRODUCT:
      return {
        ...state,
        selectedClientProducts: action.payload,
      };

    case RESET_SELECTED_CLIENT_PRODUCTS:
      return initialState; 

    default:
      return state;
  }
};

export default selectedClientProductsReducer;
