// src/store/selectedCountry/selectedCountryReducer.js
import { SET_SELECTED_CITY, CLEAR_SELECTED_CITY } from './actionTypes';

const initialState = {
  selectedCity: null, // Başlangıçta seçilen ülke boş
};

const selectedCityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CITY:
      return {
        ...state,
        selectedCity: action.payload,
      };

    case CLEAR_SELECTED_CITY:
      return {
        ...state,
        selectedCity: null,
      };

    default:
      return state;
  }
};

export default selectedCityReducer;
