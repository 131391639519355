import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { FaRecycle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal'; // AcceptModal import
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasDeletePermission, hasExcelPermission, hasUpdatePermission } from 'service/common/permissionService';
import * as XLSX from 'xlsx'; // Excel export için
import excel from "assets/icons/excel-white.png";
import { deleteUser, getUsers } from 'service/userService';
import { standartDateTime } from 'components/Common/dateConverter';
import RestoreButton from 'components/Common/Button/restoreButton';

const TrashedUsers = (props) => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState(''); // State for search text
  const [filteredUsers, setFilteredUsers] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const userList = await getUsers(); // Fetch countries from API
        setUserList(userList.users);  // Dispatch to Redux store
        setFilteredUsers(userList.users);
      } catch (error) {
        console.error('Error fetching countries:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    console.log("filter user");
    console.log(userList);
    const filteredData = userList?.filter((user) =>
      user.name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.surname?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.phone?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.email?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.role_name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.createdAt?.includes(searchText)
    );
    setFilteredUsers(filteredData);
  }, [searchText, userList]);

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            await deleteUser(id); // Delete country
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });
            const usersData = await getUsers(); // Fetch updated countries
            setUserList(usersData.users); // Update Redux state
            setFilteredUsers(usersData.users); // Update filtered countries
          } catch (error) {
            console.error('Error deleting country:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        },
      })
    );
  };

  const exportToExcel = () => {

    const column1Name = props.t("id");
    const column2Name = props.t("Name");
    const column3Name = props.t("Phone");
    const column4Name = props.t("Email");
    const column5Name = props.t("Role");
    const column6Name = props.t("Status");
    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredUsers.map(user => ({
      [column1Name]: user.id,
      [column2Name]: user.name + " " + user.surname,
      [column3Name]: user.phone,
      [column4Name]: user.email,
      [column5Name]: user.role_name,
      [column6Name]: user.is_passive === true ? props.t("Passive") : props.t("Active"),
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Users"));
    XLSX.writeFile(workbook, props.t("Users") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const restoreIcon = (row) => <RestoreButton onClick={() => handleRestore(row.id)} />

  const columns = [
    {
      name: props.t("#"),
      selector: (row) => row.id,
      sortable: true,
      wrap: true,
      width: "60px"
    },
    {
      name: props.t("Name"),
      selector: (row) => row.name + " " + row.surname,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Phone"),
      selector: (row) => row.phone,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Email"),
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Role"),
      selector: (row) => row.role_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Created At"),
      selector: (row) => standartDateTime(row.createdAt),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row) => (
        <>
          {hasDeletePermission(permissions, "MM_01") ? restoreIcon(row) : null}
        </>
      ),
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary">{props.t("Trashed Users")}</h5>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredUsers}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_11") && filteredUsers.length > 0 ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
              maxLength={32}
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

TrashedUsers.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(TrashedUsers);
