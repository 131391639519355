import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { getProductsByCategory } from '../../../service/depot/productService'; // Product ve Kategori servisi
import { createClientProduct, getClientProducts } from '../../../service/depot/clientProductService'; // Product ve Kategori servisi
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { openModal, closeModal } from '../../../store/modal/actions'; // Modal actions
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { createCompany, getCompanies } from 'service/depot/companyService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { setClientProducts } from 'store/depot/clientProduct/action';
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { createCountry, getCountries } from 'service/Definitions/countryService';
import { hasCreatePermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import { createDefinition, getDefinitions, getMultiDefinitions } from 'service/Definitions/definitionsService';
import { createProductCategory, getProductCategories } from 'service/depot/productCategoryService';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import { customValidationError } from 'components/Common/customValidationError';
import { clearCreateClientProductFormData, setCreateClientProductFormData } from 'store/depot/createClientProductForm/action';

const ClientProductForm = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const formData = useSelector((state) => state.createClientProductFormData?.formData || {});


  const [products, setProducts] = useState([]); // Kategorileri tutacak state
  const [companies, setCompanies] = useState([]); // Kategorileri tutacak state
  // const [selectedCompany, setSelectedCompany] = useState(null); // Seçilen kategoriyi tutacak state
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [units, setUnits] = useState([]);
  const [prices, setPrices] = useState([]);

  const md = 6;
  const xl = 4;

  const formattedCompanyData = (dataList) => {
    const formattedList = dataList.map(data => ({
      value: data.client_no,
      label: data.name
    }));
    return formattedList;
  }

  const getAllCountries = async () => {
    try {
      const countryList = await getCountries();
      const formattedCountries = countryList.map(data => ({
        value: data.id,
        label: data.name
      }));
      setCountries(formattedCountries);
    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryList = await getProductCategories();
        const companyList = await getCompanies();
        const defData = await getMultiDefinitions([18, 19]);
        await getAllCountries();
        console.log("defData");
        console.log(defData);
        const formattedUnitList = defData.find((element) => element.id === 18).sub.map(data => ({
          value: data.id,
          label: data.name
        }));
        console.log("formattedUnit");
        console.log(formattedUnitList);
        const formattedPriceList = defData.find((element) => element.id === 19).sub.map(data => ({
          value: data.id,
          label: data.name
        }));

        setUnits(formattedUnitList);
        setPrices(formattedPriceList);

        const formattedCategories = categoryList.map(data => ({
          value: data.product_category_no,
          label: data.name
        }));
        const compData = formattedCompanyData(companyList);
        setCompanies(compData);
        setCategories(formattedCategories);
      } catch (error) {

      }
    }

    fetchData();
  }, [dispatch]);

  const handleChange = (e) => {
    const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    dispatch(setCreateClientProductFormData({
      ...formData,
      [e.target.name]: inputData,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(openModal(
      {
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          handleConfirm();
        }
      }
    )); // Modalı açıyoruz
  };

  const handleSelectChange = (field, selected) => {
    if (selected) {
      dispatch(setCreateClientProductFormData({ ...formData, [field]: selected.value }));
    } else {
      dispatch(setCreateClientProductFormData({ ...formData, [field]: null }));
    }
  };

  const handleCategorySelectChange = async (field, selected) => {
    if (selected) {
      try {
        const product_category_no = selected.value;
        dispatch(setCreateClientProductFormData({ ...formData, [field]: selected.value }));
        const productList = await getProductsByCategory(product_category_no);
        const formattedProducts = productList.map(data => ({
          value: data.product_no,
          label: data.name
        }));
        setProducts(formattedProducts || []);
      } catch (error) {
        console.log("hata oluştu");
      }
    } else {
      setProducts([]);
      dispatch(setCreateClientProductFormData({ ...formData, [field]: null }));
    }
  };

  const handleSelectCompanyChange = (field, selected) => {
    console.log("field111");
    console.log(field);
    console.log("selected111");
    console.log(selected);
    //setSelectedCompany(selected)
    dispatch(setCreateClientProductFormData({ ...formData, [field]: selected?.value }));
  };

  const handleSelectCountryChange = async (field, selected) => {
    console.log(selected);
    setSelectedCountry(selected)
    dispatch(setCreateClientProductFormData({ ...formData, [field]: selected ? selected.value : null }));
    await getAllCountries();
  };

  const handleCreateCompany = (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_01")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          const newCompany = {
            name: inputValue,
          };

          await createCompany(newCompany)
            .then(async (addedCompany) => {
              const newCompanyList = await getCompanies();
              const compData = formattedCompanyData(newCompanyList)
              setCompanies(compData);
              //setSelectedCompany({ value: addedCompany.client_no, label: addedCompany.name });
              dispatch(setCreateClientProductFormData({ ...formData, ["client_no"]: addedCompany.client_no }));
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error, props);
            });
        }
      }));
  }

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  const handleConfirm = async () => {
    const { product_category_no, ...finalData } = formData;
    console.log("finalData");
    console.log(finalData);
    await createClientProduct(finalData)
      .then(async () => {
        toast.success(props.t("toast-success-save-data"), { position: "top-right" });
        const updatedProducts = await getClientProducts();
        dispatch(setClientProducts(updatedProducts));
        //setSelectedCompany(null);
        setSelectedCountry(null);
        dispatch(clearCreateClientProductFormData());
      })
      .catch((error) => {
        console.error("Müşteri ürünü eklenemedi:", error);
        //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
        customValidationError(error, props);
      });
    dispatch(closeModal()); // Modalı kapatır
  };

  const handleCreateCountry = (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_10")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          const newData = {
            name: inputValue,  // Kullanıcının girdiği değer
          };
          await createCountry(newData)
            .then(async (addedCountry) => {
              console.log("id geldii");
              console.log(addedCountry);
              console.log("-----------------------------");
              const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
              console.log("ülke listesiiiiiiii");
              console.log(newCountryList);
              const formattedCountries = newCountryList.map(country => ({
                value: country.id,
                label: country.name
              }));
              const newCountry = newCountryList.find(data => data.id === addedCountry.id);
              console.log(newCountry);
              // Yeni şirketi listeye ekle
              console.log("newCountry");
              setCountries(formattedCountries);
              setSelectedCountry({ value: newCountry.id, label: newCountry.name });
              dispatch(setCreateClientProductFormData({ ...formData, ["country_id"]: newCountry.id }));
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              console.log("error oluştuuuu");
              console.log(error);
              //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error, props);
            });
        }
      }));
  }

  const handleCreateUnit = (inputValue) => {
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          try {
            const data = { name: inputValue, up_module_id: 18 }
            await createDefinition(data).then(async (newData) => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });

              const updatedDatas = await getDefinitions(18);
              const formattedData = updatedDatas.map(data => ({
                value: data.id,
                label: data.name
              }));
              setUnits(formattedData);
              dispatch(setCreateClientProductFormData({ ...formData, unit_id: newData.id }));
            })
          } catch (error) {
            console.log(error);
            customValidationError(error, props);
          }

        }
      }));
  }

  const handleCreatePrice = (inputValue) => {
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          try {
            const data = { name: inputValue, up_module_id: 19 }
            await createDefinition(data).then(async (newData) => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });

              const updatedDatas = await getDefinitions(19);
              const formattedData = updatedDatas.map(data => ({
                value: data.id,
                label: data.name
              }));
              setPrices(formattedData);
              dispatch(setCreateClientProductFormData({ ...formData, price_id: newData.id }));
            })
          } catch (error) {
            console.log(error);
            customValidationError(error, props);
          }

        }
      }));
  }

  const handleCreateProductGroup = async (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_07")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }

    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          //const newProductCategoryNo = Math.random().toString(36).substr(2, 8).toUpperCase()
          const data = { name: inputValue };
          await createProductCategory(data)
            .then(async (addedCategory) => {
              console.log("adddedddd");
              console.log(addedCategory);
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
              const updatedProducts = await getProductCategories();
              console.log("updatedddd");
              console.log(updatedProducts);
              const formattedCategories = updatedProducts.map(data => ({
                value: data.product_category_no,
                label: data.name
              }));
              setCategories(formattedCategories);
              setProducts([]);
              console.log("product_Category_no");
              console.log(addedCategory.product_category_no);
              dispatch(setCreateClientProductFormData({ ...formData, product_category_no: addedCategory.product_category_no }));
            })
            .catch((error) => {
              console.error("Ürün grubu eklenemedi:", error);
              //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error, props);
            });
        }
      }));// Modalı açıyoruz
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Add Company Product")}</h5>
          <Button className='text-white' color="success" type='submit'>
            {props.t("Save")}
          </Button>
        </div>
        <Row className='mb-4' form>
          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="client_no">{props.t("Company")}</Label>
              <DynamicCreatableSelect
                options={companies}
                value={companies.find(data => data.value === formData.client_no)}
                onChange={(selected) => handleSelectCompanyChange("client_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateCompany(inputValue) }}
                placeholder={props.t("Select a company")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const companyNameMatch = option?.label?.toLowerCase().includes(inputValue.toLowerCase());

                  return companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="product_category_no">{props.t("Product Category")}</Label>
              <DynamicSelect
                options={categories}
                value={categories.find(data => data.value === formData.product_category_no)}
                onChange={(selected) => handleCategorySelectChange("product_category_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateProductGroup(inputValue) }}
                placeholder={props.t("Select product category")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="product_no">{props.t("Product")}</Label>
              <DynamicSelect
                options={products}
                value={products?.find(data => data.value === formData.product_no)}
                onChange={(selected) => handleSelectChange("product_no", selected)} // Kategori seçimi
                placeholder={props.t("Select product")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const nameMatch = option?.label?.toLowerCase().includes(inputValue.toLowerCase());

                  return nameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="extern_no">{props.t("Extern No")}</Label>
              <DynamicInput
                type="text"
                name="extern_no"
                id="extern_no"
                value={formData.extern_no}
                onChange={handleChange}
                placeholder={props.t("Enter extern no")}
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="origin_country">{props.t("Origin Country")}</Label>
              <DynamicCreatableSelect
                options={countries}
                value={selectedCountry}
                onChange={(selected) => handleSelectCountryChange("country_id", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                placeholder={props.t("Enter country")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const clientNo = option.data.label || "";
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                  return companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="production_date">{props.t("Production Date")}</Label>
              <DynamicInput
                type="date"
                id="production_date"
                name="production_date"
                value={formData.production_date}
                onChange={handleChange}
                onClick={(e) => e.target.showPicker()} // Tıklanınca tarih seçiciyi aç
                placeholderText={props.t("Enter production date")}
              />
            </FormGroup>
          </Col>
          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="unit_id">{props.t("Unit")}</Label>
              <DynamicCreatableSelect
                options={units}
                value={units.find(data => data.value === formData.unit_id)}
                onChange={(selected) => handleSelectChange("unit_id", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateUnit(inputValue.toLocaleUpperCase("tr")) }}
                placeholder={props.t("Select unit")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue.toLocaleUpperCase("tr")}`} // Yeni seçenek ekleme mesajı
                filterOption={(option, inputValue) => {
                  const nameMatch = option?.label?.toLowerCase().includes(inputValue.toLowerCase());

                  return nameMatch;
                }}
              />
            </FormGroup>
          </Col>
          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="price_id">{props.t("Price")}</Label>
              <DynamicCreatableSelect
                options={prices}
                value={prices.find(data => data.value === formData.price_id)}
                onChange={(selected) => handleSelectChange("price_id", selected)} // Kategori seçimi
                onInputChange={(inputData) => {
                  // Sadece rakamlar ve '.' karakteri kalsın
                  let filteredValue = inputData?.replace(/[^0-9.]/g, "");

                  // Eğer ilk karakter '.' ise '0.' yap
                  if (filteredValue.startsWith(".")) {
                    filteredValue = "0.";
                  }

                  // Birden fazla '.' varsa yalnızca ilkini tut
                  const dotIndex = filteredValue.indexOf(".");
                  if (dotIndex !== -1) {
                    filteredValue =
                      filteredValue.substring(0, dotIndex + 1) + // İlk '.' ve öncesini al
                      filteredValue
                        .substring(dotIndex + 1)
                        .replace(/\./g, ""); // Sonraki tüm '.' karakterlerini kaldır
                  }

                  // .dan sonra maksimum 2 karakter sınırını uygula
                  if (dotIndex !== -1 && filteredValue.length > dotIndex + 3) {
                    filteredValue = filteredValue.substring(0, dotIndex + 3);
                  }

                  // Maksimum 12 karakter sınırını uygula
                  if (filteredValue.length > 12) {
                    filteredValue = filteredValue.slice(0, 12);
                  }

                  return filteredValue;
                }}
                onCreateOption={async (inputValue) => { handleCreatePrice(inputValue.toLocaleUpperCase("tr")) }}
                placeholder={props.t("Select price")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                //onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue.toLocaleUpperCase("tr")}`} // Yeni seçenek ekleme mesajı
                filterOption={(option, inputValue) => {
                  const nameMatch = option?.label?.toLowerCase().includes(inputValue.toLowerCase());

                  return nameMatch;
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <AcceptModal />
      <FastCreateModal />
    </div>
  );
};

ClientProductForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ClientProductForm)