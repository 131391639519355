// src/store/form/action.js
import { SET_COUNTRY_FORM_DATA, CLEAR_COUNTRY_FORM_DATA } from './actionTypes';

// Form verilerini set etmek
export const setCountryFormData = (formData) => ({
  type: SET_COUNTRY_FORM_DATA,
  payload: formData,
});

// Form verilerini temizlemek
export const clearCountryFormData = () => ({
  type: CLEAR_COUNTRY_FORM_DATA,
});
