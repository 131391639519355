// src/store/selectedCountry/selectedCountryReducer.js
import { SET_STOCK_ITEM_LIST, CLEAR_STOCK_ITEM_LIST } from './actionTypes';

const initialState = {
  stockItemList: [], // Başlangıçta seçilen ülke boş
};

const stockItemListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STOCK_ITEM_LIST:
      return {
        ...state,
        stockItemList: action.payload,
      };

    case CLEAR_STOCK_ITEM_LIST:
      return {
        ...state,
        stockItemList: [],
      };

    default:
      return state;
  }
};

export default stockItemListReducer;
