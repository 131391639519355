// src/store/selectedCountry/selectedCountryActions.js
import { SET_LANGUAGES, CLEAR_LANGUAGES } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setLanguages = (data) => ({
  type: SET_LANGUAGES,
  payload: data,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedLanguages = () => ({
  type: CLEAR_LANGUAGES,
});
