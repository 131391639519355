import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { FaEye } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'styles/custom/custom-tooltip.css';

const DetailsButton = ({ onClick = ()=>{}, disabled }) => {
  const { t } = useTranslation();
  const [detailTooltipOpen, setDetailTooltipOpen] = useState(false);

  const toggleDetailTooltip = () => setDetailTooltipOpen(!detailTooltipOpen);

  return (
    <div id="detailsButtonTooltipWrapper">
      <Button color="link" onClick={() => onClick()} disabled={disabled} className='py-0'>
        <FaEye color={disabled ? "gray" : "#CB80AB"} size={18} />
      </Button>
      
      <Tooltip
        isOpen={detailTooltipOpen}
        target="detailsButtonTooltipWrapper"
        toggle={toggleDetailTooltip}
        placement="top"
        className='custom-tooltip'
      >
        {disabled ? t("No authority") : t("View")}
      </Tooltip>
    </div>
  );
};

DetailsButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

DetailsButton.defaultProps = {
  disabled: false
};

export default DetailsButton;
