// reducer.js
import {
  UPDATE_PRODUCT_GROUP_SUCCESS,
  DELETE_PRODUCT_GROUP_SUCCESS,
  SET_PRODUCT_GROUP,
} from './actionTypes';

const initialState = {
  productGroups: [],
  error: null,
};

const productGroupFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_GROUP_SUCCESS:
      const updatedProductGroups = state.products.map((product) =>
        product.id === action.payload.id ? action.payload.productData : product
      );
      return {
        ...state,
        productGroups: updatedProductGroups,
      };

    case DELETE_PRODUCT_GROUP_SUCCESS:
      const filteredProductGroups = state.products.filter((product) => product.id !== action.payload);
      return {
        ...state,
        productGroups: filteredProductGroups,
      };

    case SET_PRODUCT_GROUP:
      return {
        ...state,
        productGroups: action.payload,  // Payload doğrudan companies array'i olacak
      };

    default:
      return state;
  }
};

export default productGroupFormReducer;
