import React, { useState, useEffect } from 'react';
import { Card } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import SupportForm from './supportForm';

const Support = (props) => {
  const [refresh, setRefresh] = useState(false); // Şirket listesi yenileme durumu

  const handleRefresh = () => {
    setRefresh(!refresh); // Form başarıyla tamamlandığında listeyi yeniden yüklemek için state değiştirme
  };

  const { t } = useTranslation();
  document.title = t("Support");
  const breadcrumbItems = [];
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)

  return (
    <Card className='shadow p-4 rounded-lg'>
      <SupportForm onSuccess={handleRefresh} />
    </Card>
  );
};

export default connect(null, { setBreadcrumbItems })(Support);