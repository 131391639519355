import React, { useEffect, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { createExternCompany, getCompanies, getExternCompanies } from 'service/depot/companyService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { openModal, closeModal } from 'store/modal/actions';
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { hasCreatePermission } from 'service/common/permissionService';
import DynamicButton from 'components/Common/Button/dynamicButton';
import { getUitslugAllData, getUitslugsByCompany, updateUitslug } from 'service/depot/uitslugService';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import { clearUitslugItemList, setUitslugItemList } from 'store/depot/uitslugStore/uitslugList/action';
import { getMultiDefinitions } from 'service/Definitions/definitionsService';
import UitslugItemList from '../makeUitslug/uitslugItemList';
import { setUpdateUitslugFormData } from 'store/depot/uitslugStore/updateUitslugForm/action';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import { customValidationError } from 'components/Common/customValidationError';

const UpdateUitslugForm = props => {
  const dispatch = useDispatch();
  const { client_no, id } = useParams();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const updateUitslugFormData = useSelector((state) => state.updateUitslugFormData?.formData);
  const uitslugItemList = useSelector((state) => state.uitslugItemList?.uitslugItemList || []);
  const [externCompanies, setExternCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [customsStatuses, setCustomStatuses] = useState([]);
  const [loadingStatuses, setLoadingStatuses] = useState([]);
  const [carrierTypes, setCarrierTypes] = useState([]);

  const md = 6;
  const lg = 6;
  const xl = 4;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dateFormatter = (date) => {
          return date.split('T')[0];
        }

        const dataByCompany = await getUitslugsByCompany(client_no);
        console.log("dataByCompany");
        console.log(dataByCompany);
        console.log("-----");
        console.log(client_no);
        const uitslugData = await getUitslugAllData(id);
        console.log("uiittsluugggggDatttaaaaaa");
        console.log(uitslugData);
        const companies = await getCompanies();
        const externCompanies = await getExternCompanies();
        const defData = await getMultiDefinitions([1, 5, 8])
        const itemsList = handleOutputs(dataByCompany, uitslugData.items)
        dispatch(setUitslugItemList(itemsList));
        const { booking_date, ...rest } = uitslugData.uitslug;
        const newData = { ...rest, booking_date: booking_date ? dateFormatter(booking_date) : null }
        dispatch(setUpdateUitslugFormData(newData));
        //dispatch(setUpdateUitslugFormData({...updateUitslugFormData, booking_date: uitslugData.uitslug.booking_date ? dateFormatter(uitslugData.uitslug.booking_date) : null}));

        setCompanies(companies);
        setExternCompanies(externCompanies);
        setCustomStatuses(defData.find((element) => element.id === 5).sub);
        setLoadingStatuses(defData.find((element) => element.id === 8).sub);
        setCarrierTypes(defData.find((element) => element.id === 1).sub);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setUpdateUitslugFormData({ ...updateUitslugFormData, [name]: value != "" ? value : null }));
  };

  useEffect(() => {
    console.log("uitslugFormData");
    console.log(updateUitslugFormData);
  }, [updateUitslugFormData]);

  const handleSelectChange = (field, selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    console.log("value : " + value);
    dispatch(setUpdateUitslugFormData({ ...updateUitslugFormData, [field]: value }));
  };

  const handleChangeMainCompany = async (field, selectedOption) => {
    if (selectedOption) {
      try {
        const value = selectedOption.value;
        dispatch(setUpdateUitslugFormData({ ...updateUitslugFormData, [field]: value }));
        const dataByCompany = await getUitslugsByCompany(value);
        console.log("uitslug listesi");
        console.log(dataByCompany);
        dispatch(setUitslugItemList(dataByCompany));
      } catch (error) {
        console.log(error)
      }
    } else {
      dispatch(setUpdateUitslugFormData({ ...updateUitslugFormData, [field]: null }));
      dispatch(clearUitslugItemList());

    }
  };

  const handleCreateExternCompany = (inputValue, isTransport) => {
    if (!hasCreatePermission(permissions, "DE_02")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          const newCompany = {
            name: inputValue,
          };
          await createExternCompany(newCompany)
            .then(async (addedCompany) => {
              const externCompanies = await getExternCompanies();
              setExternCompanies(externCompanies);
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });

              if (isTransport === 0) {
                dispatch(setUpdateUitslugFormData({ ...updateUitslugFormData, transport_company_no: addedCompany.client_no }));
              } else {
                dispatch(setUpdateUitslugFormData({ ...updateUitslugFormData, delivered_company_no: addedCompany.client_no }));
              }
            })
            .catch((error) => {
              //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error, props);
            });
        }
      }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleConfirm();
  }

  function handleItems(inputArray) {
    if (!Array.isArray(inputArray)) {
      console.log("Input is not an array");
      return [];
    }

    // Yeni dizi oluştur
    const newArray = inputArray
      .filter(item => item.output_count)
      .map(item => ({
        inslug_item_id: item.id,
        inslug_id: item.inslug_id,
        output_count: parseInt(item.output_count, 10),
      }));

    return newArray;
  }


  const handleConfirm = async (id) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after failure
          try {
            const items = handleItems(uitslugItemList)
            const { is_deleted, delivery_company, updatedAt, qr, createdAt, ...rest } = updateUitslugFormData;
            const finalData = { uitslug: rest, items: items }
            console.log("finalData");
            console.log(finalData);
            await updateUitslug(finalData);
            navigate(`/uitslugs`);
          } catch (error) {
            toast.error(error);
          }
        }
      })
    );
  }

  /* const handleOutputs = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      console.error("Both inputs must be arrays.");
      return arr1;
    }
  
    // arr2'de output_count değeri olanları filtrele
    const filteredArr2 = arr2.filter(item => item.output_count !== null && item.output_count !== undefined);
  
    // arr1'e output_count değerlerini ekle
    const updatedArr1 = arr1.map(item => {
      const matchingItem = filteredArr2.find(arr2Item => arr2Item.id === item.id);
      if (matchingItem) {
        return { ...item, output_count: matchingItem.output_count };
      }
      return item; // eşleşme yoksa olduğu gibi bırak
    });
  
    return updatedArr1;
  } */

  const sortByOutputCount = (arr) => {
    return arr.sort((a, b) => {
      const aHasValue = a.output_count !== null && a.output_count !== undefined && a.output_count !== '' && a.output_count !== 0;
      const bHasValue = b.output_count !== null && b.output_count !== undefined && b.output_count !== '' && b.output_count !== 0;

      // a dolu ve b boşsa a önce gelir, b dolu ve a boşsa b önce gelir
      return aHasValue === bHasValue ? 0 : aHasValue ? -1 : 1;
    });
  };

  const handleOutputs = (arr1, arr2) => {
    console.log("**--**---**--**---**--*--*-*-*-*-*-");
    console.log(arr1);
    console.log(arr2);
    console.log("**--**---**--**---**--*--*-*-*-*-*-");

    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      console.error("Both inputs must be arrays.");
      return arr1;
    }

    // arr2'de output_count değeri olanları filtrele
    const filteredArr2 = arr2.filter(item => item.output_count !== null && item.output_count !== undefined && item.output_count !== '' && item.output_count !== 0);

    // arr1'e output_count değerlerini ekle
    const updatedArr1 = arr1.map(item => {
      const matchingItem = filteredArr2.find(arr2Item => arr2Item.inslug_item_id === item.id);
      console.log("eşleşmeeeeeeeeeeeeeeeeeeeeee");
      console.log(matchingItem);
      console.log("biitttiiiiiiiiii");
      if (matchingItem) {
        return { ...item, output_count: matchingItem.output_count };
      }
      return item; // eşleşme yoksa olduğu gibi bırak
    });

    console.log("updatedArr111111111111111");
    console.log(updatedArr1);
    const lastData = sortByOutputCount(updatedArr1);
    return sortByOutputCount(updatedArr1);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className='p-4 rounded-lg'>
          <div className='d-flex justify-content-between mb-4'>
            <h5 className="text-primary">{props.t("Update Uitslug")}</h5>
            <DynamicButton
              text={props.t("Update")}
              type="submit"
              buttonType={1}
            />
          </div>

          <Row form>
            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="client_no">{props.t("Company No / Company Name")}</Label>
                <DynamicSelect
                  options={companies.map((company) => ({
                    value: company.client_no,
                    label: company.name,
                  }))}
                  value={
                    companies.find(
                      (company) => company.client_no === updateUitslugFormData.client_no
                    )
                      ? {
                        value: companies.find(
                          (company) => company.client_no === updateUitslugFormData.client_no
                        ).client_no,
                        label: companies.find(
                          (company) => company.client_no === updateUitslugFormData.client_no
                        ).name,
                      }
                      : null
                  }
                  onChange={(selected) => {
                    handleChangeMainCompany('client_no', selected);
                  }}
                  placeholder={props.t("Select a company")}
                  isClearable
                  noOptionsMessage={() => props.t("no-option-message")}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  filterOption={(option, inputValue) => {
                    const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                    const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                    return clientNoMatch || companyNameMatch;
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="delivered_company_no">{props.t("Delivered Company")}</Label>
                <DynamicCreatableSelect
                  options={externCompanies.map((company) => ({
                    value: company.client_no,
                    label: company.name,
                  }))}
                  value={
                    externCompanies.find(
                      (company) => company.client_no === updateUitslugFormData.delivered_company_no
                    )
                      ? {
                        value: externCompanies.find(
                          (company) => company.client_no === updateUitslugFormData.delivered_company_no
                        ).client_no,
                        label: externCompanies.find(
                          (company) => company.client_no === updateUitslugFormData.delivered_company_no
                        ).name,
                      }
                      : null
                  }
                  onChange={(selected) => {
                    handleSelectChange('delivered_company_no', selected);
                  }}
                  onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue) }}
                  placeholder={props.t("Select delivered company")}
                  isClearable
                  noOptionsMessage={() => props.t("no-option-message")}
                  formatCreateLabel={(inputValue) => `${props.t("Add a new")}: ${inputValue}`}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  filterOption={(option, inputValue) => {
                    const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                    const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                    return clientNoMatch || companyNameMatch;
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="transport_company_no">{props.t("Transport Company")}</Label>
                <DynamicCreatableSelect
                  options={externCompanies.map((company) => ({
                    value: company.client_no,
                    label: company.name,
                  }))}
                  noOptionsMessage={() => props.t("no-option-message")}
                  value={
                    externCompanies.find(
                      (company) => company.client_no === updateUitslugFormData.transport_company_no
                    )
                      ? {
                        value: externCompanies.find(
                          (company) => company.client_no === updateUitslugFormData.transport_company_no
                        ).client_no,
                        label: externCompanies.find(
                          (company) => company.client_no === updateUitslugFormData.transport_company_no
                        ).name,
                      }
                      : null
                  }
                  onChange={(selected) => {
                    handleSelectChange('transport_company_no', selected);
                  }}
                  onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue, 0) }}
                  placeholder={props.t("Select transport company")}
                  isClearable
                  formatCreateLabel={(inputValue) => `Yeni ekle: ${inputValue}`}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  filterOption={(option, inputValue) => {
                    const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                    const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                    return clientNoMatch || companyNameMatch;
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="carrier_type_id">{props.t("Carrier Type")}</Label>
                <DynamicSelect
                  options={carrierTypes.map((carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                  }))}
                  value={
                    carrierTypes.find(
                      (status) => status.id === updateUitslugFormData.carrier_type_id // Eşleşen durumu bul
                    )
                      ? {
                        value: carrierTypes.find(
                          (status) => status.id === updateUitslugFormData.carrier_type_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: carrierTypes.find(
                          (status) => status.id === updateUitslugFormData.carrier_type_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) =>
                    handleSelectChange('carrier_type_id', selected)
                  }
                  placeholder={props.t("Select carrier type")}
                  isClearable
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="license_plate">{props.t("License Plate")}</Label>
                <DynamicInput
                  type="text"
                  id="license_plate"
                  name="license_plate"
                  value={updateUitslugFormData.license_plate || ""}
                  placeholder={props.t("Enter license plate")}
                  onChange={handleChange}
                  minLength={3}
                  maxLength={24}
                />
              </FormGroup>
            </Col>
            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="driver_name">{props.t("Driver Name")}</Label>
                <DynamicInput
                  type="text"
                  id="driver_name"
                  name="driver_name"
                  value={updateUitslugFormData.driver_name || ""}
                  placeholder={props.t("Enter driver name")}
                  onChange={handleChange}
                  minLength={3}
                  maxLength={24}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="booking_date">{props.t("Booking Date")}</Label>
                <DynamicInput
                  type="date"
                  id="booking_date"
                  name="booking_date"
                  value={updateUitslugFormData.booking_date}
                  onChange={handleChange}
                  onClick={(e) => e.target.showPicker()} // Tıklanınca tarih seçiciyi aç

                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="description">{props.t("Description")}</Label>
                <DynamicInput
                  type="text"
                  id="description"
                  name="description"
                  value={updateUitslugFormData.description || ""}
                  placeholder={props.t("Enter description")}
                  onChange={handleChange}
                  minLength={3}
                  maxLength={255}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="customs_status_id">{props.t("Customs Status")}</Label>
                <DynamicSelect
                  options={customsStatuses.map((status) => ({
                    value: status.id,  // value, id değerini içerir
                    label: status.name, // label, name değerini içerir
                  }))}
                  value={
                    customsStatuses.find(
                      (status) => status.id === updateUitslugFormData.customs_status_id // Eşleşen durumu bul
                    )
                      ? {
                        value: customsStatuses.find(
                          (status) => status.id === updateUitslugFormData.customs_status_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: customsStatuses.find(
                          (status) => status.id === updateUitslugFormData.customs_status_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) => {
                    console.log("onChange çalıştı");
                    console.log(selected);

                    handleSelectChange("customs_status_id", selected); // Seçilen id'yi işliyoruz
                  }}
                  placeholder={props.t("Select customs status")}
                  isClearable
                />

              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="loading_status_id">{props.t("Loading Status")}</Label>
                <DynamicSelect
                  options={loadingStatuses.map((status) => ({
                    value: status.id,
                    label: status.name,
                  }))}
                  value={
                    loadingStatuses.find(
                      (status) => status.id === updateUitslugFormData.loading_status_id // Eşleşen durumu bul
                    )
                      ? {
                        value: loadingStatuses.find(
                          (status) => status.id === updateUitslugFormData.loading_status_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: loadingStatuses.find(
                          (status) => status.id === updateUitslugFormData.loading_status_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) =>
                    handleSelectChange('loading_status_id', selected)
                  }
                  placeholder={props.t("Select loading status")}
                  isClearable
                />
              </FormGroup>
            </Col>

          </Row>
          <AcceptModal />
          <FastCreateModal />
        </Card>


        <UitslugItemList />
      </Form>

    </>
  );
};

UpdateUitslugForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateUitslugForm)