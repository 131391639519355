import { SET_USER_LIST_UPDATE_DATA, CLEAR_USER_LIST_UPDATE_DATA } from './actionTypes';

const initialState = {
  data: [],
};

const userListFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LIST_UPDATE_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case CLEAR_USER_LIST_UPDATE_DATA:
      return {
        ...state,
        data: {},
      };

    default:
      return state;
  }
};

export default userListFormReducer;
