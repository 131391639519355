import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

class MonthlyEarnings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                colors: ['#28bbe3', '#F0F1F4'],
                chart: {
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    bar: {
                        columnWidth: '70%',
                    },
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },

                xaxis: {
                    categories: [this.props.t("January"), this.props.t("February"), this.props.t("March"), this.props.t("April"), this.props.t("May"), this.props.t("June"), this.props.t("July"), this.props.t("August"), this.props.t("September"), this.props.t("October"), this.props.t("November"), this.props.t("December")],
                    labels: {
                        formatter: function (val) {
                            return val
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                fill: {
                    opacity: 1
                },

                legend: {
                    show: false,
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            },
            series: [{
                name: props.t("Completed"),
                data: [45, 75, 100, 75, 100, 75, 50, 75, 50, 75, 100, 80]
            }, {
                name: props.t("Upcoming Payments"),
                data: [180, 65, 90, 65, 90, 65, 40, 65, 40, 65, 90, 65]
            }],
        }
    }
    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">{this.props.t("Total Profit")}</h4>

                        <Row className="text-center mt-4">
                            <Col xs="6">
                                <h5 className="font-size-20">$ 2548</h5>
                                <p className="text-muted">{this.props.t("Completed")}</p>
                            </Col>
                            <Col xs="6">
                                <h5 className="font-size-20">$ 6985</h5>
                                <p className="text-muted">{this.props.t("Upcoming Payments")}</p>
                            </Col>
                        </Row>

                        <div id="morris-bar-stacked" className="morris-charts morris-charts-height" dir="ltr">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="290" />
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

MonthlyEarnings.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(MonthlyEarnings)