import { SET_UITSLUG_FORM_DATA, CLEAR_UITSLUG_FORM_DATA } from './actionTypes';

export const setUitslugFormData = (formData) => ({
  type: SET_UITSLUG_FORM_DATA,
  payload: formData,
});

export const clearUitslugFormData = () => ({
  type: CLEAR_UITSLUG_FORM_DATA,
});
