import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { deleteProduct, getDeletedProducts, getProducts, restoreProduct, } from 'service/depot/productService'; // API functions
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasDeletePermission, hasExcelPermission, hasRestorePermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import RestoreButton from 'components/Common/Button/restoreButton';

const TrashedProducts = props => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState(''); // State for search text
  const [trashedProducts, setTrashedProducts] = useState([]);
  const [filteredTrashedProducts, setFilteredTrashedProducts] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const productList = await getDeletedProducts(); // Fetch companies from API
        setTrashedProducts(productList);  // Dispatch to Redux store
        setFilteredTrashedProducts(productList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);

  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    console.log("filteredelecek company");
    console.log(trashedProducts);
    const filteredData = trashedProducts.filter((product) =>
      product.product_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.product_category_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.gtip_code?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.document_attribute_id?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.additional_attribute?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.description?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredTrashedProducts(filteredData);
  }, [searchText, trashedProducts]);

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          try {
            await restoreProduct(id); // Delete company
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });
            /* const newProducts = await getProducts(); // Fetch updated companies
            dispatch(setProducts(newProducts)); */
            const newDeletedProducts = await getDeletedProducts(); // Fetch updated companies
            setTrashedProducts(newDeletedProducts); // Update Redux state
            setFilteredTrashedProducts(newDeletedProducts); // Update filtered companies
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-restore-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const exportToExcel = () => {
    const column1Name = props.t("Product No");
    const column2Name = props.t("Product Name");
    const column3Name = props.t("Product Category No");
    const column4Name = props.t("Description");
    const column5Name = props.t("GTIP Code");
    const column6Name = props.t("Document Reference ID");
    const column7Name = props.t("Additional Attribute");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredTrashedProducts.map(itemProducts => ({
      [column1Name]: itemProducts.product_no, // 1. sütun verisi
      [column2Name]: itemProducts.name, // 2. sütun verisi
      [column3Name]: itemProducts.product_category_no, // 2. sütun verisi
      [column4Name]: itemProducts.description, // 2. sütun verisi
      [column5Name]: itemProducts.gtip_code, // 2. sütun verisi
      [column6Name]: itemProducts.document_attribute_id, // 2. sütun verisi
      [column7Name]: itemProducts.additional_attribute, // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Products"));
    XLSX.writeFile(workbook, props.t("Products") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const columns = [
    {
      name: props.t("Product No"),
      selector: (row, rowIndex) => row.product_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Name"),
      selector: (row, rowIndex) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Category No"),
      selector: (row, rowIndex) => row.product_category_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Description"),
      selector: (row, rowIndex) => row.description,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("GTIP Code"),
      selector: (row, rowIndex) => row.gtip_code,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Document Reference ID"),
      selector: (row, index) => row.document_attribute_id,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Additional Attribute"),
      selector: (row, index) => row.additional_attribute,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        <>
          {hasRestorePermission(permissions, "DE_08") ? <RestoreButton onClick={() => handleRestore(row.id)} /> : <RestoreButton disabled={true} />}
        </>
    },
  ];
  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary mb-4">{props.t("Trashed Product List")}</h5>

      <DataTable
        columns={columns}
        data={filteredTrashedProducts}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        pagination
        highlightOnHover
        subHeader
        className='p-3'
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_08") && filteredTrashedProducts.length > 0 ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
              maxLength={32}
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

export default withTranslation()(TrashedProducts)