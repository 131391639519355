// reducer.js
import {
  UPDATE_LOADING_STATUS_SUCCESS,
  DELETE_LOADING_STATUS_SUCCESS,
  SET_LOADING_STATUS,
} from './actionTypes';

const initialState = {
  loadingStatus: [],
  error: null,
};

const loadingStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOADING_STATUS_SUCCESS:
      const updateLoadingStatus = state.customsStatus.map((country) =>
      country.id === action.payload.id ? action.payload.data : country
      );
      return {
        ...state,
        loadingStatus: updateLoadingStatus,
      };

    case DELETE_LOADING_STATUS_SUCCESS:
      const filteredLoadingStatus = state.customsStatus.filter((country) => country.id !== action.payload);
      return {
        ...state,
        loadingStatus: filteredLoadingStatus,
      };

    case SET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.payload, 
      };

    default:
      return state;
  }
};

export default loadingStatusReducer;
