import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const customValidationError = (error, props) => {
    console.log("Error Response Status:", error.response.status);
    if (error.response.status === 410) {
        console.log("Triggering toast for exists error");
        toast.error(props.t("This data is already exists"), { position: "top-right" });
    } 
    else {
        console.log("Triggering general error toast");
        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
    }
};
