import {
    UPDATE_CLIENT_PRODUCT_SUCCESS,
    DELETE_CLIENT_PRODUCT_SUCCESS,
    SET_CLIENT_PRODUCT,
} from './actionTypes';

// Action to set fetched companies
export const setClientProducts = (data) => {
    return {
      type: SET_CLIENT_PRODUCT,
      payload: data,
    };
  };

// Action to update a company
export const updateClientProductAction = (id, productData) => {
    return {
        type: UPDATE_CLIENT_PRODUCT_SUCCESS,
        payload: { id, productData },
    };
};

// Action to delete a company
export const deleteClientProductAction = (id) => {
    return {
        type: DELETE_CLIENT_PRODUCT_SUCCESS,
        payload: id,
    };
};
