import { SET_SELECTED_UPDATE_CLIENT_PRODUCT, RESET_SELECTED_UPDATE_CLIENT_PRODUCTS } from './actionTypes';

// Action to set selected Inslug data
export const setSelectedUpdateClientProducts = (product) => {
  return {
    type: SET_SELECTED_UPDATE_CLIENT_PRODUCT,
    payload: product,
  };
};

export const resetSelectedUpdateClientProducts = () => ({
  type: RESET_SELECTED_UPDATE_CLIENT_PRODUCTS,
});