// src/store/selectedCountry/selectedCountryReducer.js
import { SET_SELECTED_DEFINITION, CLEAR_SELECTED_DEFINITION } from './actionTypes';

const initialState = {
  selectedDefinition: null, // Başlangıçta seçilen ülke boş
};

const selectedDefinitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_DEFINITION:
      return {
        ...state,
        selectedDefinition: action.payload,
      };

    case CLEAR_SELECTED_DEFINITION:
      return {
        ...state,
        selectedDefinition: null,
      };

    default:
      return state;
  }
};

export default selectedDefinitionReducer;
