// src/store/cityUpdate/cityUpdateActions.js
import { SET_USER_UPDATE_DATA, CLEAR_USER_UPDATE_DATA } from './actionTypes';

export const setUserFormData = (formData) => ({
  type: SET_USER_UPDATE_DATA,
  payload: formData,
});

export const clearUserFormData = () => ({
  type: CLEAR_USER_UPDATE_DATA,
});
