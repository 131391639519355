import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Row, Col, Button } from 'reactstrap';
import { getProductCategory, getProducts, updateProduct } from 'service/depot/productService'; // Product ve Kategori servisi
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setProducts } from '../../../store/depot/product/action'; // Redux actions
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import DynamicInput from 'components/Common/Form/DynamicInput';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { createProductCategory, getProductCategories } from 'service/depot/productCategoryService';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { clearProductFormData, setProductFormData } from 'store/updates/productForm/action';
import { useSelector } from 'react-redux';
import { clearSelectedProductGroup, setSelectedProductGroup } from 'store/depot/selectedProductGroup/action';
import { closeUpdateModal } from 'store/updateModal/actions';
import { hasCreatePermission, hasViewPermission } from 'service/common/permissionService';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import { customValidationError } from 'components/Common/customValidationError';

const UpdateProductForm = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);

  // Form state, JSON yapısına göre düzenlendi
  const formData = useSelector((state) => state.productFormData?.formData || {});

  const [productGroups, setProductGroups] = useState([]); // Kategorileri tutacak state
  //const [selectedProductGroup, setSelectedProductGroup] = useState(null); // Seçilen kategoriyi tutacak state
  const selectedProductGroup = useSelector((state) => state.selectedProductGroup?.selectedProductGroup || {});

  // Kategori verilerini çekmek için useEffect
  useEffect(() => {
    console.log("selectedProductGroup");
    console.log(selectedProductGroup);
    const fetchCategories = async () => {
      try {
        const productGroupList = await getProductCategory(); // Kategorileri çekiyoruz
        const formattedProductCategory = productGroupList.map((category) => ({
          value: category.product_category_no,  // value'da client_no kullanıyoruz
          label: category.name,  // label'da company.name kullanıyoruz
        }));
        setProductGroups(formattedProductCategory); // Kategorileri state'e set ediyoruz
      } catch (error) {
        //toast.error("Kategoriler getirilirken bir hata oluştu.", { position: "top-right" });
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    dispatch(setProductFormData({
      ...formData,
      [e.target.name]: inputData,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(openModal(
      {
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          handleConfirm();
        }
      }
    )); // Modalı açıyoruz
  };

  const handleSelectChange = (field, selected) => {
    if (selected) {
      dispatch(setProductFormData({ ...formData, [field]: selected.value }));
    }
    else {
      dispatch(setProductFormData({ ...formData, [field]: null }));
    }
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  useEffect(() => {
    console.log("selectedCategory güncellendi");
    console.log(selectedProductGroup);
  }, [selectedProductGroup])

  const handleConfirm = async () => {
    dispatch(closeModal()); // Modalı kapatır
    console.log("product update formData");
    console.log(formData);
    const { createdAt, updatedAt, cost, product_no, is_deleted, product_category_name,  ...rest } = formData;

    await updateProduct(rest)
      .then(async () => {
        toast.success(props.t("toast-error-save-data"), { position: "top-right" });
        const updatedProducts = await getProducts(); // Ürünleri güncelle
        dispatch(setProducts(updatedProducts)); // Redux state güncelleme
        dispatch(clearSelectedProductGroup());
        dispatch(clearProductFormData());
        dispatch(closeUpdateModal());
      })
      .catch((error) => {
        console.error("Ürün eklenemedi:", error);
        //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
        customValidationError(error,props);
      });
  };

  const handleCreateProductGroup = async (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_07")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          //const newProductCategoryNo = Math.random().toString(36).substr(2, 8).toUpperCase()
          const data = { name: inputValue };
          await createProductCategory(data)
            .then(async (addedProductGroup) => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
              const updatedProducts = await getProductCategories(); // Ürünleri güncelle
              const formattedProductCategory = updatedProducts.map((category) => ({
                value: category.product_category_no,  // value'da client_no kullanıyoruz
                label: category.name,  // label'da company.name kullanıyoruz
              }));
              setProductGroups(formattedProductCategory);
              const newData = { value: addedProductGroup.product_category_no, label: addedProductGroup.name };
              handleSelectChange("product_category_no", newData);
              /*               setSelectedProductGroup({
                              value: data.product_category_no,
                              label: data.name,
                            }); */
            })
            .catch((error) => {
              console.error("Ürün grubu eklenemedi:", error);
              //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error,props);
            });
        }
      }));// Modalı açıyoruz
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Update Product")}</h5>
          <Button color="warning" type="submit">
            {props.t("Update")}
          </Button>
        </div>
        <Row className='mb-4' form>
          <Col md={4}>
            <FormGroup>
              <Label for="product_category_no">{props.t("Product Category")}</Label>
              {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_07")} props={props} />
              {hasViewPermission(permissions, "DE_07") ?  */}
              <DynamicCreatableSelect
                options={productGroups}
                value={productGroups?.find(data => data.value === formData.product_category_no)}
                onChange={(selected) => handleSelectChange("product_category_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateProductGroup(inputValue) }}
                placeholder={props.t("Select product category")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return clientNoMatch || companyNameMatch;
                }}
              />
              {/* :
              <NoAuthUpdateInput data={selectedProductGroup.label} />
            } */}
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="name">{props.t("Product Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter product name")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="description">{props.t("Description")}</Label>
              <DynamicInput
                type="text"
                name="description"
                id="description"
                value={formData.description}
                onChange={handleChange}
                placeholder={props.t("Enter product description")}
                minLength={3}
                maxLength={255}
              />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="gtip_code">{props.t("GTIP Code")}</Label>
              <DynamicInput
                type="text"
                name="gtip_code"
                id="gtip_code"
                value={formData.gtip_code}
                onChange={handleChange}
                placeholder={props.t("Enter GTIP code")}
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="document_attribute_id">{props.t("Document Reference ID")}</Label>
              <DynamicInput
                type="text"
                name="document_attribute_id"
                id="document_attribute_id"
                value={formData.document_attribute_id}
                onChange={handleChange}
                placeholder={props.t("Enter document reference no")}
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="additional_attribute">{props.t("Additional Attribute")}</Label>
              <DynamicInput
                type="text"
                name="additional_attribute"
                id="additional_attribute"
                value={formData.additional_attribute}
                onChange={handleChange}
                placeholder={props.t("Enter additional attribute")}
                minLength={3}
                maxLength={255}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

UpdateProductForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateProductForm)