import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { API_BASE_URL } from "config";

// Backend API URL'sini ayarlayın

// API'ye kullanıcı giriş isteği
const postLogin = async (user) => {
  const response = await axios.post(`${API_BASE_URL}/auth/login`, user);
  return response.data;
};

// API'ye kullanıcı sosyal giriş isteği
const postSocialLogin = async (type) => {
  const response = await axios.post(`${API_BASE_URL}/social-login`, { type });
  return response.data;
};

// API'den kullanıcı çıkış isteği
const postLogout = async () => {
  // Eğer çıkış yapmak için backend'e bir istek gerekiyorsa, buraya ekleyin.
  localStorage.removeItem("authUser");
  localStorage.removeItem("token");
  return true; // Basit bir yanıt döndürün
};

function* loginUser({ payload: { user, history, errorMessage } }) {
  console.log("message");
  console.log(errorMessage);
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    });
    console.log("loginRepsonseeeeeeeeeeeeeeeee");
    console.log(response);
    localStorage.setItem("authUser", JSON.stringify(response.user));
    localStorage.setItem("permissions", JSON.stringify(response.permissions));
    localStorage.setItem("token", response.token);

    yield put(loginSuccess(response));
    history('/dashboard');
  } catch (error) {
    yield put(apiError(errorMessage));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    yield call(postLogout);
    yield put(logoutUserSuccess());
    history('/login');
  } catch (error) {
    yield put(apiError(error.response?.data?.message || error.message));
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    const response = yield call(postSocialLogin, type);
    localStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginSuccess(response));
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error.response?.data?.message || error.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;




















/* import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, type);
      if (response) {
        history("/dashboard");
      } else {
        history("/login");
      }
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    const response = yield call(fireBaseBackend.socialLoginUser, type);
    if(response)
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
 */