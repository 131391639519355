import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setProductGroups } from 'store/depot/productGroup/action'; // Redux actions
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import { deleteProductCategory, getProductCategories, updateProductCategory } from 'service/depot/productCategoryService';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { setProductGroupFormData } from 'store/updates/productGroupForm/action';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateProductGroupForm from './updateProductCategoryForm';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasDeletePermission, hasExcelPermission, hasUpdatePermission, hasViewPermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import DetailsButton from 'components/Common/Button/detailsButton';
import { useNavigate } from 'react-router-dom';

const ProductGroupList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [formData, setFormData] = useState({}); // State for form data
  const [searchText, setSearchText] = useState(''); // State for search text

  const productGroups = useSelector((state) => state.productGroups?.productGroups || []);

  const [filteredProductGroups, setFilteredProductGroups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productGroupList = await getProductCategories(); // Fetch companies from API
        console.log("product liiistttt");
        console.log(productGroupList);
        console.log("Fetched Companies:", productGroupList); // Doğru veri alınıyor mu kontrol edin
        dispatch(setProductGroups(productGroupList));  // Dispatch to Redux store
        setFilteredProductGroups(productGroupList);
        console.log("******");
        console.log(productGroups);
        console.log(productGroupList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);


  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    console.log("filteredelecek company");
    console.log(productGroups);
    const filteredData = productGroups.filter((productGroup) =>
      productGroup.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      productGroup.product_category_no?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredProductGroups(filteredData);
  }, [searchText, productGroups]);

  // Handle editing row and open modal
  const handleEdit = (id) => {
    const selectedData = filteredProductGroups.find((data) => data.id === id);
    dispatch(setProductGroupFormData(selectedData));
    dispatch(
      openUpdateModal({
        body: (
          <UpdateProductGroupForm />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteProductCategory(id); // Delete company
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const updatedCompanies = await getProductCategories(); // Fetch updated companies
            dispatch(setProductGroups(updatedCompanies)); // Update Redux state
            setFilteredProductGroups(updatedCompanies); // Update filtered companies
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('There was a problem deleting the product group.', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleViewDetails = (id) => {
    navigate(`/product-category/${id}`);
  }

  const exportToExcel = () => {
    const column1Name = props.t("Product Category No");
    const column2Name = props.t("Product Category Name");
    const column3Name = props.t("Product Count");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredProductGroups.map(itemProductCategories => ({
      [column1Name]: itemProductCategories.product_category_no, // 1. sütun verisi
      [column2Name]: itemProductCategories.name, // 2. sütun verisi
      [column3Name]: itemProductCategories.product_count, // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Product Categories"));
    XLSX.writeFile(workbook, props.t("Product Categories") + ".xlsx");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const columns = [
    {
      name: props.t("Product Category No"),
      selector: (row, rowIndex) =>
        row.product_category_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Name"),
      selector: (row, rowIndex) =>
        row.name,
      sortable: true,
      wrap: true,

    },
    {
      name: props.t("product-count"),
      selector: (row, rowIndex) => row.product_count,
      sortable: true,
      wrap: true,

    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        <>
          {hasViewPermission(permissions, "DE_07") ? <DetailsButton disabled={false} onClick={() => handleViewDetails(row.id)} /> : <DetailsButton disabled={true} />}
          {hasUpdatePermission(permissions, "DE_07") ? <EditButton disabled={false} onClick={() => handleEdit(row.id)} /> : <EditButton disabled={true} />}
          {hasDeletePermission(permissions, "DE_07") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} />}
        </>
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary">{props.t("Product Category List")}</h5>
      <DataTable
        columns={columns}
        data={filteredProductGroups}
        customStyles={customStyles}
        pagination
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_07") && filteredProductGroups.length > 0 ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
              maxLength={32}
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

ProductGroupList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ProductGroupList)