import {
    SET_PARTS,
    CLEAR_PARTS
} from './actionTypes';

// Action to set fetched companies
export const setParts = (data) => {
    return {
        type: SET_PARTS,
        payload: data,
    };
};


// Action to clear companies
export const clearParts = (data) => {
    return {
        type: CLEAR_PARTS,
        payload: data,
    };
};
