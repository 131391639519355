// src/store/form/formReducer.js
import { SET_HELEVEN_UPDATE_FORM_DATA, CLEAR_HELEVEN_UPDATE_FORM_DATA } from './actionTypes';

const initialState = {
  formData: {},
};

const updateHelevenFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HELEVEN_UPDATE_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };

    case CLEAR_HELEVEN_UPDATE_FORM_DATA:
      return {
        ...state,
        formData: {},
      };

    default:
      return state;
  }
};

export default updateHelevenFormReducer;
