import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col, Input, CardBody, Card, Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from '../../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { closeModal, openModal } from 'store/modal/actions';
import DataTable from 'react-data-table-component';
import customStyles from 'styles/customDatatableStyle';
import AcceptModal from 'components/Common/Modals/acceptModal';
import UpdateModal from 'components/Common/Modals/updateModal';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import { openUpdateModal } from 'store/updateModal/actions';
import * as XLSX from 'xlsx';
import excel from "assets/icons/excel-white.png";
import { hasCreatePermission, hasDeletePermission, hasExcelPermission, hasUpdatePermission } from 'service/common/permissionService';
import { createDefinition, deleteDefinition, getDefinitionsWithDeleted, getModuleInfo } from 'service/Definitions/definitionsService';
import DefinitionUpdate from './definitionUpdate';
import DetailsButton from 'components/Common/Button/detailsButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { setDefinitionItems } from 'store/depot/Definitions/definitionItems/action';
import { customValidationError } from 'components/Common/customValidationError';

const DefinitionItemPage = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const [formData, setFormData] = useState({});
    const definitionItems = useSelector((state) => state.definitionItems?.definitionItems || []);
    const selectedDefinition = useSelector((state) => state.selectedDefinition?.selectedDefinition || {});
    const [filteredDefinitionDatas, setFilteredDefinitionDatas] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [moduleInfo, setModuleInfo] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataList = await getDefinitionsWithDeleted(id);
                console.log("dataLiiissstttttstt");
                console.log(dataList);
                dispatch(setDefinitionItems(dataList));
                setFilteredDefinitionDatas(dataList);
                console.log("iddd");
                console.log(id);
                const moduleInfoData = await getModuleInfo(id);
                console.log("module Info");
                console.log(moduleInfoData);
                setModuleInfo(moduleInfoData);
            } catch (error) {
                console.error('Error fetching loading statuses:', error);
            }
        };
        fetchData();
    }, [dispatch, id]);

    useEffect(() => {
        const filteredData = definitionItems?.filter((data) =>
            data.name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr"))
        );
        setFilteredDefinitionDatas(filteredData);
        console.log("filteredData");
        console.log(filteredData);
    }, [searchText, definitionItems]);

    const handleChange = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        if (id == 19) {
            let filteredValue = inputData ? inputData.replace(/[^0-9.]/g, "") : "";

            // Eğer ilk karakter '.' ise '0.' yap
            if (filteredValue.startsWith(".")) {
                filteredValue = "0.";
            }

            // Eğer ilk karakter '0' ise ve ikinci karakter '.' değilse kaldır
            if (filteredValue.startsWith("0") && filteredValue[1] !== ".") {
                filteredValue = filteredValue.substring(1);
            }

            // Birden fazla '.' varsa yalnızca ilkini tut
            const dotIndex = filteredValue.indexOf(".");
            if (dotIndex !== -1) {
                filteredValue =
                    filteredValue.substring(0, dotIndex + 1) + // İlk '.' ve öncesini al
                    filteredValue.substring(dotIndex + 1).replace(/\./g, ""); // Sonraki tüm '.' karakterlerini kaldır
            }

            // .dan sonra maksimum 2 karakter sınırını uygula
            if (dotIndex !== -1 && filteredValue.length > dotIndex + 3) {
                filteredValue = filteredValue.substring(0, dotIndex + 3);
            }

            setFormData({
                ...formData,
                [e.target.name]: filteredValue,
            });


        } else {
            setFormData({
                ...formData,
                [e.target.name]: inputData,
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleConfirm = () => {
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    dispatch(closeModal());
                    try {
                        await createDefinition({ ...formData, up_module_id: parseInt(id) });
                        toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                        const updatedStatuses = await getDefinitionsWithDeleted(id);
                        dispatch(setDefinitionItems(updatedStatuses));
                        setFilteredDefinitionDatas(updatedStatuses);
                        setFormData({});
                    } catch (error) {
                        //toast.error(props.t(error), { position: "top-right" });
                        console.log("statusss");
                        console.log(error.status);
                        customValidationError(error, props);
                    }
                },
            })
        );
    };

    const handleDelete = (dataId) => {
        dispatch(
            openModal({
                title: props.t("modal-delete-title"),
                body: props.t("modal-delete-body"),
                onConfirm: async () => {
                    dispatch(closeModal());
                    try {
                        await deleteDefinition(dataId);
                        toast.success(props.t("toast-success-delete-data"), { position: "top-right" });
                        const dataList = await getDefinitionsWithDeleted(id);
                        dispatch(setDefinitionItems(dataList));
                        setFilteredDefinitionDatas(dataList);
                    } catch (error) {
                        console.error('Error deleting loading status:', error);
                        toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
                    }
                },
            })
        );
    };

    const handleSubMenu = (row) => {
        navigate(`/depot/definition-item/${row.id}`);
    }

    const findLastIndex = (data) => {
        return data[data.length - 1];
    };

    const exportToExcel = () => {
        const selectedColumns = filteredDefinitionDatas.map(status => ({
            /* [props.t("id")]: status?.id, */
            [props.t("Name")]: status?.name,
        }));
        const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
        const workbook = XLSX.utils.book_new();
        const lastIndex = findLastIndex(moduleInfo);
        XLSX.utils.book_append_sheet(workbook, worksheet, lastIndex ? props.t(`${lastIndex.name}`): props.t("Definitions"));
        XLSX.writeFile(workbook, lastIndex ? props.t(`${lastIndex.name}`) : props.t("Definitions") + ".xlsx");
    };

    const columns = [
        {
            name: props.t("#"),
            selector: (row, index) => index + 1,
            sortable: true,
            wrap: true,
        },
        {
            name: props.t("Definition"),
            selector: (row) => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: props.t("Status"),
            selector: (row) => row.is_passive, // Sıralama için ham veriyi kullanır
            sortable: true, // Sıralamayı aktif hale getirir  
            cell: (row) =>
                row.is_passive === true ?
                    <Badge>{props.t("Passive")}</Badge> :
                    <Badge color='success'>{props.t("Active")}</Badge>,
            sortFunction: (rowA, rowB) => {
                // "is_passive" değerine göre sıralama yapar: true (Pasif) veya false (Aktif)
                return rowA.is_passive === rowB.is_passive ? 0 : rowA.is_passive ? 1 : -1;
            },
            wrap: true,
        },
        {
            name: props.t("Actions"),
            cell: (row) => (
                row.is_passive === true ?
                    restoreIcon(row)
                    :
                    <>
                        <DetailsButton disabled={false} onClick={() => handleSubMenu(row)} />
                        {hasUpdatePermission(permissions, "DE_10") && (
                            <EditButton disabled={false} onClick={() => handleEdit(row)} />
                        )}
                        {hasDeletePermission(permissions, "DE_10") && id != -1 ? (
                            <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} />
                        ) : null}
                    </>
            ),
        },
    ];

    const restoreIcon = (row) =>
        <Button size='sm' color="success" onClick={() => handleDelete(row.id)}>
            {props.t("Activate")}
        </Button>;

    const handleEdit = (row) => {
        dispatch(
            openUpdateModal({
                body: <DefinitionUpdate row={row} />,
                onConfirm: () => { },
            })
        );
    };

    const customPaginationOptions = {
        rowsPerPageText: props.t("Rows count"),
        rangeSeparatorText: '/',
    };

    return (
        <div>
            {hasCreatePermission(permissions, "DE_10") && id != -1 ?
                <Card>
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <Row className='mb-4' form>
                                <Col>
                                    <FormGroup>
                                        <h5 className="text-primary mb-3">{props.t('Add Definition')}</h5>
                                        {id == 19 ?
                                            <DynamicInput
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder={props.t("Enter definition")}

                                                required
                                                minLength={1}
                                                maxLength={12}
                                            />
                                            :
                                            <DynamicInput
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder={props.t("Enter definition")}
                                                required
                                                minLength={1}
                                                maxLength={32}
                                            />}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <div className='d-flex justify-content-end mb-4'>
                                        <Button className='text-white' color="success" type='submit'>
                                            {props.t("Save")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>

                : null}

            <Card>
                <CardBody>
                    <h5 className="text-primary"></h5>
                    <div className="d-flex justify-content-between mb-4">
                        <h5 className="text-primary">{props.t('Definitions')}</h5>
                        {moduleInfo && moduleInfo.length > 0 ? (
                            <div className="d-flex flex-wrap align-items-center gap-0.5">
                                {moduleInfo.map((module, index) => {
                                    // Mod değerine göre renk belirleme
                                    const color =
                                        index % 4 === 0
                                            ? "success" // Red (Danger)
                                            : index % 4 === 1
                                                ? "warning" // Yellow (Warning)
                                                : index % 4 === 2
                                                    ? "info" // Green (Success)
                                                    : "primary"; // Blue (Info)

                                    return (
                                        <Link to={module.id === null ? `/depot/definition-item/-1` : `/depot/definition-item/${module.id}`}>
                                            <Badge
                                                key={index}
                                                className='p-2 m-1'
                                                color={color}
                                            >
                                                {props.t(module.name ? module.name : "Definitions")}
                                            </Badge>
                                        </Link>
                                    );
                                })}
                            </div>
                        ) : (
                            null
                        )}
                    </div>

                    <DataTable
                        className='p-3'
                        columns={columns}
                        data={filteredDefinitionDatas}
                        customStyles={customStyles}
                        paginationComponentOptions={customPaginationOptions}
                        noDataComponent={props.t("table-empty-message")}
                        pagination
                        highlightOnHover
                        paginationLa
                        subHeader
                        subHeaderComponent={
                            <div className='d-flex justify-content-end w-50'>
                                {hasExcelPermission(permissions, "DE_10") && filteredDefinitionDatas.length > 0 ? (
                                    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
                                        <img src={excel} width={'24'} alt="" />
                                    </Button>
                                ) : null}
                                <Input
                                    type="text"
                                    placeholder={props.t("Search")}
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase('tr'))}
                                    style={{ width: '250px' }}
                                    maxLength={32}
                                />
                            </div>
                        }
                    />
                </CardBody>
            </Card>

            <AcceptModal />
            <UpdateModal />
        </div>
    );
};

DefinitionItemPage.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(DefinitionItemPage);
