import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getCompany, getCompanyDetails } from 'service/depot/companyService'; // API functions
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasCreatePermission, hasExcelPermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import 'styles/custom/datatable.css'
import { useNavigate, useParams } from 'react-router-dom';
import SplitDataTable from 'components/Common/SplitDataTable/splitDataTable';
import { setCreateClientProductFormData } from 'store/depot/createClientProductForm/action';

const CompanyList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchTextInfo, setSearchTextInfo] = useState(''); // State for search text
  const [searchTextData, setSearchTextData] = useState(''); // State for search text
  const [dataList, setDataList] = useState([]);
  const [infoList, setInfoList] = useState([]);

  const [filteredDataList, setFilteredDataList] = useState([]);
  const [filteredInfoList, setFilteredInfoList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { info, details } = await getCompanyDetails(id);
        const infoArray = [
          { key: "Company No", value: info.client_no },
          { key: "Company Name", value: info.name },
          { key: "Mail", value: info.mail },
          { key: "Phone", value: info.phone },
          { key: "Country", value: info.country_name },
          { key: "City", value: info.city_name },
          { key: "Address", value: info.address },
/*           { key: "IBAN", value: info.iban },
          { key: "Tax Number", value: info.tax_no },
          { key: "Tax Office", value: info.tax_office }, */
          /* { key: "Created At", value: info.createdAt }, */
        ];

        setDataList(details);
        setInfoList(infoArray);

      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("dataList");
    console.log(dataList);
    setFilteredDataList(dataList);
  }, [dataList]);

  useEffect(() => {
    setFilteredInfoList(infoList);
  }, [infoList]);

  useEffect(() => {
    setFilteredInfoList(
      infoList.filter(row =>
        row.key.toLowerCase().includes(searchTextInfo.toLowerCase()) ||
        row.value?.toString().toLowerCase().includes(searchTextInfo.toLowerCase())
      )
    );
  }, [searchTextInfo, infoList]);

  useEffect(() => {
    setFilteredDataList(
      dataList?.filter(row =>
        row.product_name?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.product_category_name?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.extern_no?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.unit_name?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.price_name?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.count?.toString().toLowerCase().includes(searchTextData.toLowerCase())
      )
    );
  }, [searchTextData, dataList]);

  const exportToExcel = () => {
    const column1Name = props.t("Product Name");
    const column2Name = props.t("Category Name");
    const column3Name = props.t("Stock Status");


    const selectedColumns = filteredDataList.map(itemCompanies => ({
      [column1Name]: itemCompanies.name,
      [column2Name]: itemCompanies.product_category_name,
      [column3Name]: itemCompanies.count,
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Company products"));
    XLSX.writeFile(workbook, props.t("Company Product") + ".xlsx");
  };

  const columns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];

  const detailColumns = [
    {
      name: props.t("Product Name"),
      selector: row => row.product_name,
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      name: props.t("Category Name"),
      selector: row => row.product_category_name,
      sortable: false,
      wrap: true,
    },
    {
      name: props.t("Extern No"),
      selector: row => row.extern_no,
      sortable: false,
      wrap: true,
    },
    {
      name: props.t("Unit"),
      selector: row => row.unit_name,
      sortable: false,
      wrap: true,
    },
    {
      name: props.t("Price"),
      selector: row => row.price_name,
      sortable: false,
      wrap: true,
    },
  ];

  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  const handleAddProduct = () => {
    dispatch(setCreateClientProductFormData({client_no: infoList[0].value}))
    navigate('/client-product');
  }

  return (
    <div  >
      <Card>
        <CardBody>
          <h5 className="text-primary">{props.t("Company Informations")}</h5>
          <SplitDataTable
            data={infoList}
            columns={columns}
            t={props.t} />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <div className='d-flex justify-content-between mb-2'>
            <h5 className="text-primary mb-4">{props.t("Company Product List")}</h5>
            <div>
              {hasCreatePermission(permissions, "DE_09") ?
                <Button className="btn btn-md mx-2" color="success" onClick={handleAddProduct}>{props.t("Add Company Product")}</Button>
                : <Button className="btn btn-md mx-2" disabled color="secondary">{props.t("Add Company Product")}</Button>}
            </div>
          </div>
          <DataTable
            style={{
              border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
            }}
            className='data-table p-3'
            columns={detailColumns}
            data={filteredDataList}
            customStyles={customStyles}
            pagination
            noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
            paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
            highlightOnHover
            paginationLa
            subHeader
            subHeaderComponent={
              <div className='d-flex align-items-right mb-2'>
                <div className='d-flex'>
                  {hasExcelPermission(permissions, "DE_01") && filteredDataList.length > 0 ? excelButton : null}
                  <Input
                    type="text"
                    placeholder={props.t("Search")}
                    value={searchTextData}
                    onChange={(e) => setSearchTextData(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                    style={{ width: '250px' }} // Smaller input field
                    maxLength={32}
                  />
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

CompanyList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(CompanyList)