/* const customStyles = {
    rows: {
        style: {
            borderBottom: '2px solid #EED3D9 !important',  // Satırların arasındaki çizgi rengi kırmızı olacak
        },
    },
    headRow: {
        style: {
            borderBottom: '2px solid #EED3D9 !important',  // Satırların arasındaki çizgi rengi kırmızı olacak
        },
    },
}; */
const customStyles = {
  rows: {
    style: {
      minHeight: '36px', // Satır yüksekliğini düşürdük
      borderBottom: '2px solid #EED3D9 !important',  // Satırların arasındaki çizgi rengi
      padding: '4px', // Satır içi boşlukları küçülttük

    },
  },
  headRow: {
    style: {
      padding: '4px !important',
      borderBottom: '2px solid #EED3D9 !important',  // Başlık altındaki çizgi rengi
    },
  },
  tableWrapper: {
    style: {
      borderBottom: 'none',  // Tablo altındaki çizgiyi kaldırıyoruz
    },
  },
  pagination: {
    style: {
      borderTop: 'none',  // Eğer pagination'da da çizgi varsa onu kaldırıyoruz
    },
  },

  header: {
    style: {
      fontSize: '32px',
      fontWeight: 'bold',
      whiteSpace: 'normal !important', // Başlık metninin taşmasını engeller
      wordWrap: 'break-word !important', // Uzun metinler alt satıra geçer
      textAlign: 'center', // Başlık metinlerini ortalar
    },
  },
  headCells: {
    style: {
      fontSize: '11px',
      fontWeight: 'bold',
      padding: '0px  !important',
      whiteSpace: 'normal !important', // Hücre içeriğini sarar
      wordWrap: 'break-word !important', // Uzun metinleri alt satıra taşır
      textAlign: 'left', // Başlık metinlerini ortalar
    },
  },
  cells: {
    style: {
      fontSize: '11px',
      padding: '4px !important',
      whiteSpace: 'normal !important', // Hücre içeriğini sarar
      wordWrap: 'break-word !important', // Uzun metinleri alt satıra taşır
      textAlign: 'left', // Hücre içeriğini ortalar
    },
  },
};

export default customStyles;
