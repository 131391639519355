// reducer.js
import {
  SET_FILTERED_ROLES,
} from './actionTypes';

const initialState = {
  roles: [],
  error: null,
};

const filteredRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERED_ROLES:
      return {
        ...state,
        roles: action.payload,  // Payload doğrudan companies array'i olacak
      };

    default:
      return state;
  }
};

export default filteredRolesReducer;
