// src/store/selectedCountry/selectedCountryActions.js
import { SET_INCOME_LIST, CLEAR_INCOME_LIST, UPDATE_UITSLUG_ITEM } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setIncomeList = (data) => ({
  type: SET_INCOME_LIST,
  payload: data,
});

// Seçilen ülkeyi temizlemek
export const clearIncomeList = () => ({
  type: CLEAR_INCOME_LIST,
});


