import { SET_IS_CREATE, CLEAR_IS_CREATE } from './actionTypes';

const initialState = {
  isCreate: false, 
};

const isCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_CREATE:
      return {
        ...state,
        isCreate: action.payload,
      };

    case CLEAR_IS_CREATE:
      return {
        ...state,
        isCreate: false,
      };

    default:
      return state;
  }
};

export default isCreateReducer;
