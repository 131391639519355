// src/store/selectedCountry/selectedCountryActions.js
import { SET_SELECTED_IMAGE, CLEAR_SELECTED_IMAGE } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setSelectedImage = (data) => ({
  type: SET_SELECTED_IMAGE,
  payload: data,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedImage = () => ({
  type: CLEAR_SELECTED_IMAGE,
});
