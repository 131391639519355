import axios from 'axios';
// API URLs
import { API_BASE_URL, API_SETTINGS } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const API_LANGUAGE = '/languages';
const API_LANGUAGE_CREATE = '/create';
const API_LANGUAGE_GET = '/all-languages';
const API_LANGUAGE_UPDATE = '/update';
const API_LANGUAGE_DELETE = '/passive';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} LANGUAGEData - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createLanguage = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${API_LANGUAGE}${API_LANGUAGE_CREATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getLanguages = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${API_SETTINGS}${API_LANGUAGE}${API_LANGUAGE_GET}`);
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateLanguage = async (data) => {
  try {

    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${API_LANGUAGE}${API_LANGUAGE_UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} LANGUAGEId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const changePassiveStatus = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${API_LANGUAGE}${API_LANGUAGE_DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};
