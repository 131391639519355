import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Row, Col, Form } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import AcceptModal from 'components/Common/Modals/acceptModal';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import { createCountry, getCountries } from 'service/Definitions/countryService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { getCities } from 'service/Definitions/cityService';
import UpdateCityForm from 'pages/Depot/Definitions/City/cityUpdateForm';
import { openUpdateModal } from 'store/updateModal/actions';
import { clearCityFormData, setCityFormData } from 'store/updates/cityForm/action';
import { setIsCreate } from 'store/isUpdate/action';
import { clearSelectedCity, setSelectedCity } from 'store/depot/selectedCity/action';
import { setCountries } from 'store/depot/Definitions/country/action';
import { hasCreatePermission, hasViewPermission } from 'service/common/permissionService';
import DynamicButton from 'components/Common/Button/dynamicButton';
import { setCities } from 'store/depot/Definitions/city/action';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import { customValidationError } from 'components/Common/customValidationError';
import { createCurrent, getCurrents } from 'service/accounting/currentService';
import { setCurrentFormData } from 'store/accounting/currentForm/action';
import { setCurrents } from 'store/accounting/currentList/action';

const CurrentForm = props => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const formData = useSelector((state) => state.currentFormData?.formData || null);
    const countries = useSelector((state) => state.countries?.countries || []);
    const cities = useSelector((state) => state.cities?.cities || []);
    const md = 6;
    const lg = 6;
    const xl = 4;


    const getCountriesAndCities =async () => {
        try {
            const countryList = await getCountries();
            const cityList = await getCities();
            const formattedCities = cityList.map(city => ({
                value: city.id,
                label: city.name,
                country_id: city.country_id
            }));
            const formattedCountries = countryList.map(country => ({
                value: country.id,
                label: country.name
            }));
            console.log("**********");
            console.log(formattedCities);
            console.log(formattedCountries);
            dispatch(setCountries(formattedCountries));
            dispatch(setCities(formattedCities));
        } catch (error) {
            console.log("error");
        }
    };

    useEffect(() => {
        getCountriesAndCities();
    }, [dispatch]);

    const handleChange = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        dispatch(setCurrentFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    const handleChangeLowerCase = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value;
        dispatch(setCurrentFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    const handlePhone = (value) => {
        console.log("value");
        console.log(value);
        dispatch(setCurrentFormData({
            ...formData,
            ["phone"]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleSelectCountryChange = async (field, selected) => {
        dispatch(clearSelectedCity());
        dispatch(setCurrentFormData({ ...formData, country_id: null, city_id: null }));
        console.log(selected);
        if (selected) {
            dispatch(setCurrentFormData({ ...formData, [field]: selected.value, city_id: null }));
            await getCountriesAndCities();
        } else {
            dispatch(setCurrentFormData({ ...formData, [field]: null, city_id: null }));
            await getCountriesAndCities();
        }
    };

    const handleSelectCityChange = (field, selected) => {
        dispatch(setSelectedCity(selected));
        dispatch(setCurrentFormData({ ...formData, [field]: selected?.value }));
    };

    const handleCreateCountry = (inputValue) => {
        if (!hasCreatePermission(permissions, "DE_10")) {
            toast.error(props.t("No authority"), { position: "top-right" });
            return;
        }
        dispatch(
            openFastCreateModal({
                title: inputValue,
                body: <span>{props.t("modal-save-body")}</span>,
                onConfirm: async () => {
                    dispatch(closeFastCreateModal());
                    const newCountry = {
                        name: inputValue, 
                    };
                    await createCountry(newCountry) 
                        .then(async (addedCountry) => {
                            console.log("idddssssss");
                            console.log(addedCountry);
                            const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
                            const newSelected = newCountryList.find(data => data.id === addedCountry.id);
                            const formattedCompanies = newCountryList.map(country => ({
                                value: country.id,
                                label: country.name
                            }));
                            dispatch(setCountries(formattedCompanies));
                            dispatch(clearSelectedCity());
                            dispatch(setCurrentFormData({ ...formData, ["country_id"]: newSelected.id, ["city_id"]: null }));
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                        })
                        .catch((error) => {
                            //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                            customValidationError(error,props);

                        });
                }
            }));
    }

    const handleCreateCity = (inputValue) => {
        if (!hasCreatePermission(permissions, "DE_10")) {
            toast.error(props.t("No authority"), { position: "top-right" });
            return;
        }
        handleEdit(inputValue);
        dispatch(
            openUpdateModal({
                body: <UpdateCityForm page={"company"} />,
                onConfirm: async () => { }
            })
        );
    }

    // Handle editing row and open modal
    const handleEdit = async (inputValue) => {
        dispatch(setIsCreate(true));
        dispatch(setCityFormData({ country_id: formData.country_id, name: inputValue }));
    };

    const handleConfirm = async () => {
        console.log(formData);
        if (!(formData.name) || formData.name === '') {
            toast.error(props.t("Please enter designation"));
            return;
        }
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after failure
                    await createCurrent(formData)
                        .then(async () => {
                            dispatch(closeModal()); // Close the modal after failure
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                            console.log(formData);
                            const updatedCompanies = await getCurrents(); // Fetch updated companies
                            dispatch(setCurrents(updatedCompanies)); // Update Redux state
                            dispatch(clearCityFormData());
                            dispatch(clearSelectedCity());
                            setSelectedCity(null);
                            dispatch(setCurrentFormData({}));
                        })
                        .catch((error) => {
                            console.error("Şirket ekelme işlemi başarısız oldu:", error);
                            //toast.error(props.t(error), { position: "top-right" });
                            customValidationError(error,props);
                        });
                }
            })
        );


    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Add Current")}</h5>
                    <DynamicButton
                        text={props.t("Save")}
                        type="submit"
                        buttonType={0}
                    />
                </div>
                <Row className='mb-4' form>
                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="name">{props.t("Designation")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter designation")}
                                required
                                maxLength={64}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="email">{props.t("Mail")}</Label>
                            <DynamicInput
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChangeLowerCase}
                                placeholder={props.t("Enter mail address")}
                                minLength={3}
                                maxLength={64}

                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="phone">{props.t("Phone")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder={props.t("Enter phone number")}
                                value={formData.phone}
                                minLength={6}
                                maxLength={24}
                                onChange={value => handlePhone(value)} />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="address">{props.t("Address")}</Label>
                            <DynamicInput
                                type="text"
                                name="address"
                                id="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder={props.t("Enter address")}
                                maxLength={255}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="country">{props.t("Country")}</Label>
                            <DynamicCreatableSelect
                                options={countries}
                                value={countries.find(data => data.value === formData.country_id || null)}
                                onChange={(selected) => handleSelectCountryChange("country_id", selected)} 
                                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                                placeholder={props.t("Enter country")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} 
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    const clientNo = option.data.label || "";
                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="city">{props.t("City")}</Label>
                            <DynamicCreatableSelect
                                options={cities.filter(data => data.country_id === formData.country_id || null)}
                                value={cities.find(data => data.value === formData.city_id)}
                                onChange={(selected) => handleSelectCityChange("city_id", selected)}
                                onCreateOption={async (inputValue) => { handleCreateCity(inputValue) }}
                                placeholder={props.t("Enter city")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    const clientNo = option.data.label || "";
                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="iban">{props.t("IBAN")}</Label>
                            <DynamicInput
                                type="text"
                                name="iban"
                                id="iban"
                                value={formData.iban}
                                onChange={handleChange}
                                placeholder={props.t("Enter IBAN")}
                                maxLength={48}
                                minLength={6}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="tax_no">{props.t("Tax Number")}</Label>
                            <DynamicInput
                                type="text"
                                name="tax_no"
                                id="tax_no"
                                value={formData.tax_no}
                                onChange={handleChange}
                                placeholder={props.t("Enter tax number")}
                                maxLength={32}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="tax_office">{props.t("Tax Office")}</Label>
                            <DynamicInput
                                type="text"
                                name="tax_office"
                                id="tax_office"
                                value={formData.tax_office}
                                onChange={handleChange}
                                placeholder={props.t("Enter tax office")}
                                maxLength={64}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <AcceptModal />
            <FastCreateModal />
        </div>
    );
};

CurrentForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(CurrentForm)