import { SET_USER_UPDATE_DATA, CLEAR_USER_UPDATE_DATA } from './actionTypes';

const initialState = {
  formData: {},
};

const userUpdateFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_UPDATE_DATA:
      return {
        ...state,
        formData: action.payload,
      };

    case CLEAR_USER_UPDATE_DATA:
      return {
        ...state,
        formData: {},
      };

    default:
      return state;
  }
};

export default userUpdateFormReducer;
