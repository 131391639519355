import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setProducts } from 'store/depot/product/action'; // Redux actions
import { deleteProduct, getProductCategory, getProducts, updateProduct, } from 'service/depot/productService'; // API functions
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { setProductFormData } from 'store/updates/productForm/action';
import UpdateProductForm from './updateProductForm';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateModal from 'components/Common/Modals/updateModal';
import { setSelectedProductGroup } from 'store/depot/selectedProductGroup/action';
import { hasDeletePermission, hasExcelPermission, hasUpdatePermission, hasViewPermission } from 'service/common/permissionService';
import { nameMounthWithTime, standartDateTime } from 'components/Common/dateConverter';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import DetailsButton from 'components/Common/Button/detailsButton';
import { useNavigate } from 'react-router-dom';

const ProductList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState(''); // State for search text

  const products = useSelector((state) => state.products?.products || []);

  const [filteredProducts, setFilteredCompanies] = useState([]);
  const productFormData = useSelector((state) => state.productFormData?.formData || {});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productList = await getProducts(); // Fetch companies from API
        dispatch(setProducts(productList));  // Dispatch to Redux store
        setFilteredCompanies(productList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    console.log("product form data güncellendi");
    console.log(productFormData);
  }, [productFormData]);


  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    console.log("filteredelecek company");
    console.log(products);
    const filteredData = products.filter((product) =>
      product.product_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.product_category_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.description?.toLowerCase().includes(searchText.toLowerCase())

    );
    setFilteredCompanies(filteredData);
  }, [searchText, products]);

  // Handle editing row and open modal
  const handleEdit = async (id) => {
    console.log("iddddddd");
    console.log(id);
    const selectedData = filteredProducts.find((data) => data.id === id);
    console.log("selected producttttttttttt");
    console.log(selectedData);
    dispatch(setProductFormData(selectedData));
    try {
      const productGroupList = await getProductCategory(); // Kategorileri çekiyoruz
      console.log("****************************");
      console.log(productGroupList);
      const selectedproductGroupData = productGroupList.find(data => data.product_category_no === selectedData.product_category_no);
      const newData = { value: selectedproductGroupData.product_category_no, label: selectedproductGroupData.name }
      dispatch(setSelectedProductGroup(newData));
    } catch (error) {
      //toast.error("Kategoriler getirilirken bir hata oluştu.", { position: "top-right" });
    }
    console.log("verinin güncellenmiş olması lazım");
    console.log(productFormData);
    dispatch(
      openUpdateModal({
        body: (
          <UpdateProductForm />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteProduct(id); // Delete company
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const updatedProducts = await getProducts(); // Fetch updated companies
            dispatch(setProducts(updatedProducts)); // Update Redux state
            setFilteredCompanies(updatedProducts); // Update filtered companies
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleViewDetails = (id) => {
    navigate(`/product/${id}`);
  }

  const exportToExcel = () => {
    const column1Name = props.t("Product No");
    const column2Name = props.t("Product Name");
    const column3Name = props.t("Product Category No");
    const column4Name = props.t("Description");
    const column5Name = props.t("GTIP Code");
    const column6Name = props.t("Document Reference ID");
    const column7Name = props.t("Additional Attribute");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredProducts.map(itemProducts => ({
      [column1Name]: itemProducts.product_no, // 1. sütun verisi
      [column2Name]: itemProducts.name, // 2. sütun verisi
      [column3Name]: itemProducts.product_category_no, // 2. sütun verisi
      [column4Name]: itemProducts.description, // 2. sütun verisi
      [column5Name]: itemProducts.gtip_code, // 2. sütun verisi
      [column6Name]: itemProducts.document_attribute_id, // 2. sütun verisi
      [column7Name]: itemProducts.additional_attribute, // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Products"));
    XLSX.writeFile(workbook, props.t("Products") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const columns = [
    {
      name: props.t("Product No"),
      selector: (row, rowIndex) =>
        row.product_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Name"),
      selector: (row, rowIndex) =>
        row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Category"),
      selector: (row, rowIndex) =>
        row.product_category_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("GTIP Code"),
      selector: (row, rowIndex) =>
        row.gtip_code,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        <>
          {hasViewPermission(permissions, "DE_08") ? <DetailsButton disabled={false} onClick={() => handleViewDetails(row.id)} /> : <DetailsButton disabled={true} />}
          {hasUpdatePermission(permissions, "DE_08") ? <EditButton disabled={false} onClick={() => handleEdit(row.id)} /> : <EditButton disabled={true} />}
          {hasDeletePermission(permissions, "DE_08") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} />}
        </>
    },
  ];
  
  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div className="my-4">
      <h5 className="text-primary mb-4">{props.t("Product List")}</h5>

      <DataTable
        columns={columns}
        data={filteredProducts}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        pagination
        highlightOnHover
        subHeader
        className='p-3'
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_08") && filteredProducts.length > 0 ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
              maxLength={32}
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

ProductList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ProductList)