import React, { useEffect } from "react";
import { connect } from "react-redux";
import StockFilter from "./stockFilter";
import { setBreadcrumbItems } from "store/actions";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'; 
import StockItemList from "./stockItemList";
import { useSelector } from "react-redux";

const StockPage = (props) => {
    const { t } = useTranslation();
    document.title = t("Stock");
    const breadcrumbItems = [];
    const stockItemList = useSelector((state) => state.stockItemList?.stockItemList || []);
    const stockFilterForms = useSelector((state) => state.stockFilterFormData?.formData || []);

    console.log(stockItemList);
    useEffect(() => {
        props.setBreadcrumbItems("", breadcrumbItems);
    }, []);


    return (
        <React.Fragment>
            <div>
                <StockFilter />
                 <StockItemList />
            </div>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(StockPage);
