// src/store/selectedCountry/selectedCountryReducer.js
import { SET_LANGUAGES, CLEAR_LANGUAGES } from './actionTypes';

const initialState = {
  languages: null, // Başlangıçta seçilen ülke boş
};

const languagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };

    case CLEAR_LANGUAGES:
      return {
        ...state,
        languages: null,
      };

    default:
      return state;
  }
};

export default languagesReducer;
