import React from "react";
import { Card, CardBody, Row, CardTitle } from "reactstrap";
import DonutChart from '../AllCharts/DonutChart';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

const MonthlyEarnings = props => {
    const data = {
        columns: [
            ["+", 32],
            ["-", 30],
            /* ['Beklenen Gelir', 20] */
        ],
        type: "donut",
    };

    const donut = {
        title: "",
        width: 32,
        label: { show: !1 }
    };

    const color = {
        pattern: ['#5cb85c', '#d9534f'/* , '#5cb85c' */]
    };

    const size = {
        height: 300
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">{props.t("Monthly Income Expense")}</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-4">
                            <h5 className="font-size-20 text-muted">$56241</h5>
                            <p className="text-muted">{props.t("Income")}</p>
                        </div>
                        <div className="col-4">
                            <h5 className="font-size-20 text-muted">$56241</h5>
                            <p className="text-muted">{props.t("Expense")}</p>
                        </div>
                        <div className="col-4">
                            <h5 className="font-size-20 text-success">$23651</h5>
                            <p className="text-success">{props.t("Profit")}</p>
                        </div>
                    </Row>
                    
                    <div dir="ltr">
                        {/* DonutChart'a gerekli parametreleri gönderiyoruz */}
                        <DonutChart 
                            data={data} 
                            donut={donut} 
                            color={color} 
                            size={size} 
                            dir="ltr" 
                        />
                    </div>

                </CardBody>
            </Card>
        </React.Fragment>
    );
};

MonthlyEarnings.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(MonthlyEarnings)