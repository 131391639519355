// src/store/selectedCountry/selectedCountryActions.js
import { SET_SELECTED_UPDATE_COUNTRY, CLEAR_SELECTED_UPDATE_COUNTRY } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setSelectedUpdateCountry = (country) => ({
  type: SET_SELECTED_UPDATE_COUNTRY,
  payload: country,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedUpdateCountry = () => ({
  type: CLEAR_SELECTED_UPDATE_COUNTRY,
});
