// reducer.js
import {
  UPDATE_CLIENT_PRODUCT_SUCCESS,
  DELETE_CLIENT_PRODUCT_SUCCESS,
  SET_CLIENT_PRODUCT,
} from './actionTypes';

const initialState = {
  clientProducts: [],
  error: null,
};

const clientProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_PRODUCT_SUCCESS:
      const updatedProducts = state.clientProducts.map((product) =>
        product.id === action.payload.id ? action.payload.productData : product
      );
      return {
        ...state,
        clientProducts: updatedProducts,
      };

    case DELETE_CLIENT_PRODUCT_SUCCESS:
      const filteredProducts = state.clientProducts.filter((product) => product.id !== action.payload);
      return {
        ...state,
        clientProducts: filteredProducts,
      };

    case SET_CLIENT_PRODUCT:
      return {
        ...state,
        clientProducts: action.payload,  // Payload doğrudan companies array'i olacak
      };

    default:
      return state;
  }
};

export default clientProductReducer;
