import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setCountries } from 'store/depot/Definitions/country/action';
import { getMuhasebe, updateMuhasebe } from 'service/muhasebeService';
import DynamicInput from 'components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { closeModal, openModal } from 'store/modal/actions';
import { useSelector } from 'react-redux';
import { clearCountryFormData, setCountryFormData } from 'store/updates/countryForm/action';
import { closeUpdateModal, openUpdateModal } from 'store/updateModal/actions';

const UpdateCountriesForm = props => {
    const dispatch = useDispatch();

    // Form state, JSON yapısına göre düzenlendi
    //const [formData, setFormData] = useState({});
    const formData = useSelector((state) => state.countryFormData?.formData || {});

    const handleChange = (e) => {
        const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        dispatch(setCountryFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
        console.log("update handleChane formData");
        console.log(formData);
    };

    useEffect(() => {
        console.log("Formdata güncellendi");
        console.log(formData);
    }, [formData])

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleConfirm = async () => {
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    const { createdAt, updatedAt, ...rest } = formData;
                    console.log("reeesssttt");
                    console.log(rest);
                    try {
                        await updateMuhasebe(rest)
                            .then(async () => {
                                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                                const countries = await getMuhasebe(); // Ürünleri güncelle
                                dispatch(setCountries(countries)); // Redux state güncelleme
                                dispatch(clearCountryFormData());
                            })
                        dispatch(closeModal()); // Close the modal after successful update
                        dispatch(closeUpdateModal());

                    } catch (error) {
                        console.error("Ürün grubu eklenemedi:", error);
                        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                        dispatch(closeModal()); // Close the modal after successful update
                    }
                }
            })
        );

    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Update Country")}</h5>
                    <Button onClick={handleConfirm} color="warning">
                        {props.t("Update")}
                    </Button>
                </div>
                <Row className='mb-4' form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="name">{props.t("Country Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter country name")}
                                required
                                minLength={3}
                                maxLength={32}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

UpdateCountriesForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(UpdateCountriesForm)