const standartDateTime = (dateData) => {
  const date = new Date(dateData);
  return date.toLocaleString("tr-TR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

const nameMounthWithTime = (dateData) => {
  const date = new Date(dateData);
  return date.toLocaleString("tr-TR", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

module.exports = {
  standartDateTime,
  nameMounthWithTime
}