import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasExcelPermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import 'styles/custom/datatable.css'
import { useParams } from 'react-router-dom';
import { getClientProductDetails } from 'service/depot/clientProductService';
import { getUserDetails, getUsers } from 'service/userService';

const UserDetails = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchTextInfo, setSearchTextInfo] = useState(''); // State for search text
  const [searchTextData, setSearchTextData] = useState(''); // State for search text
  const [dataList, setDataList] = useState([]);
  const [infoList, setInfoList] = useState([]);

  const [filteredDataList, setFilteredDataList] = useState([]); // State for form data
  const [filteredInfoList, setFilteredInfoList] = useState([]); // State for form data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { info, details } = await getUserDetails(id);
        console.log(info);
        console.log(details);
        const infoArray = [
          { key: "Name", value: info.name },
          { key: "Surname", value: info.surname },
          { key: "Phone", value: info.phone },
          { key: "Mail", value: info.email },
          { key: "Role", value: info.role_name },
          { key: "Created At", value: info.createdAt ? new Date(info.createdAt).toLocaleDateString() : "" },
        ];

        setDataList(details);
        setInfoList(infoArray);

      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredDataList(dataList);
  }, [dataList]);

  useEffect(() => {
    setFilteredInfoList(infoList);
  }, [infoList]);

  useEffect(() => {
    setFilteredInfoList(
      infoList.filter(row =>
        row.key.toLowerCase().includes(searchTextInfo.toLowerCase()) ||
        row.value?.toString().toLowerCase().includes(searchTextInfo.toLowerCase())
      )
    );
  }, [searchTextInfo, infoList]);

  useEffect(() => {
    setFilteredDataList(
      dataList.filter(row =>
        row.key.toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.value?.toString().toLowerCase().includes(searchTextData.toLowerCase())
      )
    );
  }, [searchTextData, dataList]);

  const exportToExcel = () => {
    const column1Name = props.t("Company No");
    const column2Name = props.t("Name");
    const column3Name = props.t("Mail");
    const column4Name = props.t("Phone");
    const column5Name = props.t("Address");
    const column6Name = props.t("Country");
    const column7Name = props.t("City");
    const column8Name = props.t("IBAN");
    const column9Name = props.t("Tax No");
    const column10Name = props.t("Tax Office");


    const selectedColumns = filteredDataList.map(itemCompanies => ({
      [column1Name]: itemCompanies.client_no,
      [column2Name]: itemCompanies.name,
      [column3Name]: itemCompanies.email,
      [column4Name]: itemCompanies.phone,
      [column5Name]: itemCompanies.address,
      [column6Name]: itemCompanies.country_name,
      [column7Name]: itemCompanies.city_name,
      [column8Name]: itemCompanies.iban,
      [column9Name]: itemCompanies.tax_no,
      [column10Name]: itemCompanies.tax_office,
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Companies"));
    XLSX.writeFile(workbook, props.t("Companies") + ".xlsx");
  };

  const columns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];

  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div  >
      <Card>
        <CardBody>
          <h5 className="text-primary">{props.t("User Informations")}</h5>

          <DataTable
            style={{
              border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
            }}
            className='data-table px-3'
            columns={columns}
            data={filteredInfoList}
            customStyles={customStyles}
            noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
            highlightOnHover
          />
        </CardBody>
      </Card>

      {
        dataList.length > 0 ?
          <Card>
            <CardBody>
              <h5 className="text-primary mb-4">{props.t("")}</h5>

              <DataTable
                style={{
                  border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
                }}
                className='data-table p-3'
                columns={columns}
                data={filteredDataList}
                customStyles={customStyles}
                pagination
                noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
                paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
                highlightOnHover
                paginationLa
                subHeader
                subHeaderComponent={
                  <div className='d-flex align-items-right mb-2'>
                    <div className='d-flex'>
                      {hasExcelPermission(permissions, "DE_01") && filteredDataList.length > 0 ? excelButton  : null}
                      <Input
                        type="text"
                        placeholder={props.t("Search")}
                        value={searchTextInfo}
                        onChange={(e) => setSearchTextData(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                        style={{ width: '250px' }} // Smaller input field
                        maxLength={32}
                      />
                    </div>
                  </div>
                }
              />
            </CardBody>
          </Card>
          : null
      }

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

UserDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UserDetails)