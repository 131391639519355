import { SET_UPDATE_CELL_FORM_DATA, CLEAR_UPDATE_CELL_FORM_DATA } from './actionTypes';

export const setUpdateCellFormData = (formData) => ({
  type: SET_UPDATE_CELL_FORM_DATA,
  payload: formData,
});

export const clearUpdateCellFormData = () => ({
  type: CLEAR_UPDATE_CELL_FORM_DATA,
});
