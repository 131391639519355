import React , {useEffect} from "react"

import { connect } from "react-redux";
import {
  Row,
  Col,
} from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings";
import EmailSent from "./email-sent";
import MonthlyEarnings2 from "./montly-earnings2";
import Inbox from "./inbox";
import RecentActivity from "./recent-activity";
import WidgetUser from "./widget-user";
import YearlySales from "./yearly-sales";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";
import { useTranslation } from 'react-i18next'; 

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { setPermissions } from "store/permissions/action";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);

  const { t } = useTranslation();
  document.title = t("Main Menu");

  useEffect(() => {
    const fetchPermissions = async () => {
      const permissionData = localStorage.getItem("permissions");
      const permArray = JSON.parse(permissionData)
      dispatch(setPermissions(permArray));
    }
    fetchPermissions();
  }, []);

  useEffect(() => {
  }, [permissions]);


  const breadcrumbItems = [];

  useEffect(() => {
    props.setBreadcrumbItems('' , breadcrumbItems)
  },)

  return (
    <React.Fragment>

      {/*mimi widgets */}
      <Miniwidget />

      <Row>
        <Col xl="3">
          {/* Monthly Earnings */}
          <MonthlyEarnings />
        </Col>

        <Col xl="6">
          {/* Email sent */}
          <EmailSent />
        </Col>

        <Col xl="3">
          <MonthlyEarnings2 />
        </Col>

      </Row>
      <Row>

        <Col xl="4" lg="6">
          {/* inbox */}
          <Inbox />
        </Col>
        <Col xl="4" lg="6">
          {/* recent activity */}
          <RecentActivity />

        </Col>
        <Col xl="4">
          {/* widget user */}
          <WidgetUser />

          {/* yearly sales */}
          <YearlySales />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);