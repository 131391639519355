import {
    UPDATE_COUNTRY_SUCCESS,
    DELETE_COUNTRY_SUCCESS,
    SET_COUNTRY,
} from './actionTypes';

// Action to set fetched companies
export const setCountries = (data) => {
    return {
      type: SET_COUNTRY,
      payload: data,
    };
  };

// Action to update a company
export const updateCountry = (id, data) => {
    return {
        type: UPDATE_COUNTRY_SUCCESS,
        payload: { id, data: data },
    };
};

// Action to delete a company
export const deleteCountry = (id) => {
    return {
        type: DELETE_COUNTRY_SUCCESS,
        payload: id,
    };
};
