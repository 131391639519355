import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setRoles } from '../../../store/settings/roles/action'; 
import { openModal, closeModal } from '../../../store/modal/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from '../../../components/Common/Modals/acceptModal'; 
import { deleteRole, getRoles } from '../../../service/settings/rolesService';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import customStyles from 'styles/customDatatableStyle';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasDeletePermission, hasExcelPermission, hasUpdatePermission } from 'service/common/permissionService';
import * as XLSX from 'xlsx';
import excel from "../../../assets/icons/excel-white.png";
import UpdateRoles from './updateRoles';
import { standartDateTime } from 'components/Common/dateConverter';

const RolesList = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState('');
  const [filteredRoles, setFilteredRoles] = useState([]);
  const roles = useSelector((state) => state.roles?.roles);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesList = await getRoles();
        dispatch(setRoles(rolesList));
        setFilteredRoles(rolesList);
      } catch (error) {
        console.error('Error fetching countries:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    console.log("filteredilecek liste");
    console.log(roles);
    const filteredData = roles?.filter((item) =>
      item.name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr"))
    );
    setFilteredRoles(filteredData);
  }, [searchText, roles]);

  const handleEdit = (id) => {
    dispatch(
      openUpdateModal({
        body: (
          <UpdateRoles selectedId={id} />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleDelete = (id) => {
    console.log(id);
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteRole(id); 
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });
            const updatedRoles = await getRoles(); 
            dispatch(setRoles(updatedRoles)); 
            setFilteredRoles(updatedRoles); 
            dispatch(closeModal()); 
          } catch (error) {
            console.error('Error deleting country:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); 
          }
        },
      })
    );
  };

  const exportToExcel = () => {

    const column1Name = props.t("Name");

    const selectedColumns = filteredRoles.map(item => ({
      [column1Name]: item.name,
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Roles"));
    XLSX.writeFile(workbook, props.t("Roles") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const editIcon = (row) =>
    <Button color="link" onClick={() => handleEdit(row.role_id)}>
      <FaEdit color="green" size={18} />
    </Button>;

  const deleteIcon = (row) =>
    <Button color="link" onClick={() => handleDelete(row.role_id)}>
      <FaTrash color="red" size={18} />
    </Button>;

  const columns = [
    {
      name: props.t("Role Name"),
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row) => (
        <>
          {hasUpdatePermission(permissions, "MM_02") ? editIcon(row) : null}
          {hasDeletePermission(permissions, "MM_02") ? deleteIcon(row) : null}
        </>
      ),
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary">{props.t("Role List")}</h5>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredRoles}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")}
        paginationComponentOptions={customPaginationOptions}
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "MM_02") && filteredRoles > 0 ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))}
              style={{ width: '250px' }}
              maxLength={32}
            />
          </div>
        }
      />

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

RolesList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(RolesList);
