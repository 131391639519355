import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import { Button, Card, CardBody, Input } from 'reactstrap';
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { useSelector, useDispatch } from 'react-redux';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { hasExcelPermission } from 'service/common/permissionService';
import { useReactToPrint } from 'react-to-print';
import qr from "assets/icons/qr-colored.png";
import logoSrc from "assets/images/act-logo.webp";
import { QRCodeCanvas } from 'qrcode.react';
import DynamicPrint from 'components/Common/Print/dynamicPrint';
import { getCompanies } from 'service/depot/companyService';

const StockItemList = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const stockItemList = useSelector((state) => state.stockItemList?.stockItemList || []);
  const stockFilterFormData = useSelector((state) => state.stockFilterFormData?.formData);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const contentRefItem = useRef(null);
  const [companies, setCompanies] = useState([]);
  const handlePrintItem = useReactToPrint({ contentRef: contentRefItem });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companies = await getCompanies();
        setCompanies(companies);
      } catch (error) {
        console.log("error");
      }
    }
    fetchData();
  }, []);

  const exportToExcel = () => {
    const selectedColumns = stockItemList.map((item) => {
      const row = {};
      columns.forEach((col) => {
        row[col.name] = col.selector(item); // Kolonun adını ve değerini al
      });
      return row;
    });

    // Excel çalışma sayfasını ve dosyasını oluşturma
    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Stock List"));
    XLSX.writeFile(workbook, props.t("Stock List") + ".xlsx");
  };


  // DataTable sütunları
  const columns = [
    /* { name: props.t("Document No"), selector: (row) => row.document_no || props.t(""), sortable: true }, */
    { name: props.t("Product"), selector: (row) => row.product_name || props.t(""), finder: "product_name", sortable: true },
    { name: props.t("Container No"), selector: (row) => row.container_no || props.t(""), finder: "container_no", sortable: true },
    { name: props.t("Extern Party No"), selector: (row) => row.extern_party_no || props.t(""), finder: "extern_party_no", sortable: true },
/*     { name: props.t("Created At"), selector: (row) => (row.createdAt ? new Date(row.createdAt).toLocaleDateString() : props.t("")), finder: "createdAt", sortable: true },
 */    { name: props.t("Product Count"), selector: (row) => row.count || props.t(""), finder: "count", sortable: true },
  ];

  useEffect(() => {
    const filtered = stockItemList.filter((item) => {
      return columns.some((column) => {
        const cellValue = column.selector(item); // Her sütunun verisini al
        if (typeof cellValue === "string" || typeof cellValue === "number") {
          return cellValue.toString().toLowerCase().includes(searchText.toLowerCase()); // Arama metni eşleşirse
        }
        return false;
      });
    });
    setFilteredData(filtered); // Filtrelenen veriyi state'e ata
  }, [searchText, stockItemList]);


  const excelIcon = (
    <Button className="me-2 p-0" color="success p-2" onClick={exportToExcel}>
      <img src={excel} width="24" alt="" />
    </Button>
  );

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: "/",
  };

  return (
    <Card>
      <CardBody>
        <div>
          <div className="d-flex align-items-center justify-content-between mb-3 p-2">
            <h5 className="text-primary">{props.t("Items")}</h5>
          </div>
          <DataTable
            subHeader
            customStyles={customStyles}
            noDataComponent={props.t("table-empty-message")}
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10]}
            paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
            subHeaderComponent={
              <div className='d-flex justify-content-end w-50'>
                <Button className="m-0 p-0 px-3" color="red" onClick={handlePrintItem}>
                  <img src={qr} width={'30'} alt="" />
                </Button>
                {hasExcelPermission(permissions, "DE_03") && filteredData.length > 0 ? excelIcon : null}
                <Input
                  type="text"
                  placeholder={props.t("Search")}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))}
                  className="form-control w-50"
                  maxLength={32}
                />
              </div>
            }
          />
          <DynamicPrint
            t={props.t}
            contentRef={contentRefItem}
            itemDatas={stockItemList}
            itemColumns={columns}
            actionTitle={companies?.find(data => data.client_no == stockFilterFormData.client_no)?.name}
            actionTitleBgColor="#329ED4"
          />

          {/* <div className="print-only">
            <div ref={contentRefItem} style={{ margin: '40px', fontFamily: 'Arial, sans-serif' }}>
              <div style={{ textAlign: 'right', marginBottom: '24px' }}>
                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Date: {new Date().toLocaleDateString()}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '32px' }}>
                <div>
                  <img src={logoSrc} alt="Company Logo" style={{ width: '240px', height: 'auto', marginBottom: '10px' }} />
                  <div style={{ paddingLeft: '12px', fontSize: '14px', fontWeight: 'bold' }}>Antwerp Container Transport</div>
                </div>
              </div>
              <table
                style={{
                  marginTop: '30px',
                  width: "100%",
                  borderCollapse: "collapse",
                  fontFamily: "Arial, sans-serif",
                  tableLayout: "fixed", // Hücre genişliklerini eşit yapar
                }}
              >
                <thead>
                  <tr>
                    {columns.map((col, index) => (
                      <th
                        key={index}
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#f9f9f9",
                          textAlign: "left",
                          wordWrap: "break-word", // Uzun başlıkları alt satıra taşır
                        }}
                      >
                        {col.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {columns.map((col, colIndex) => (
                        <td
                          key={colIndex}
                          style={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            wordWrap: "break-word", // Uzun metinler alt satıra taşınır
                          }}
                        >
                          {col.selector(row)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div> */}


        </div>
        <AcceptModal />
      </CardBody>
    </Card>
  );
};

StockItemList.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(StockItemList);
