import { SET_UPDATE_PRODUCT_FORM_DATA, CLEAR_UPDATE_PRODUCT_FORM_DATA } from './actionTypes';

const initialState = {
  formData: {},
};

const productFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UPDATE_PRODUCT_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };

    case CLEAR_UPDATE_PRODUCT_FORM_DATA:
      return {
        ...state,
        formData: {},
      };

    default:
      return state;
  }
};

export default productFormReducer;
