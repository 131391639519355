// src/store/form/formReducer.js
import { SET_CARRIER_TYPE_FORM_DATA, CLEAR_CARRIER_TYPE_FORM_DATA } from './actionTypes';

const initialState = {
  formData: {}, // Başlangıçta boş bir form data
};

const carrierTypeFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CARRIER_TYPE_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };

    case CLEAR_CARRIER_TYPE_FORM_DATA:
      return {
        ...state,
        formData: {},
      };

    default:
      return state;
  }
};

export default carrierTypeFormReducer;
