import customStyles from 'styles/customDatatableStyle';
import DataTable from 'react-data-table-component';

const SplitDataTable = ({ data, columns, t }) => {
    // Verileri ikiye böl
    const midIndex = Math.ceil(data.length / 2);
    const firstHalf = data.slice(0, midIndex);
    const secondHalf = data.slice(midIndex);

    return (
      <div style={{ display: "flex", gap: "32px" }}>
        <DataTable
          style={{ flex: 1 }}
          columns={columns}
          data={firstHalf}
          customStyles={customStyles}
          highlightOnHover
          noDataComponent={""}
        />
        <DataTable
          style={{ flex: 1 }}
          columns={columns}
          data={secondHalf}
          customStyles={customStyles}
          highlightOnHover
          noDataComponent={""}
        />
      </div>
    );
  };

  export default SplitDataTable;
