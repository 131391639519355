// src/store/cityUpdate/cityUpdateActions.js
import { SET_CITY_UPDATE_DATA, CLEAR_CITY_UPDATE_DATA } from './actionTypes';

export const setCityFormData = (formData) => ({
  type: SET_CITY_UPDATE_DATA,
  payload: formData,
});

export const clearCityFormData = () => ({
  type: CLEAR_CITY_UPDATE_DATA,
});
