const { default: DynamicInput } = require("components/Common/Form/DynamicInput");

const NoAuthInput = ({ placeholder }) => {

    return <DynamicInput
        type="text"
        name="noAuth"
        id="noAuth"
        value={''}
        placeholder={placeholder}
        disabled={true}
        
    />
};

export default NoAuthInput;