// reducer.js
import {
  FETCH_EXTERN_COMPANIES_SUCCESS,
  FETCH_EXTERN_COMPANIES_FAILURE,
  UPDATE_EXTERN_COMPANY_SUCCESS,
  DELETE_EXTERN_COMPANY_SUCCESS,
  SET_EXTERN_COMPANY,
} from './actionTypes';

const initialState = {
  externCompanies: [],
  error: null,
};

const externCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXTERN_COMPANIES_SUCCESS:
      return {
        ...state,
        externCompanies: action.payload,
        error: null,
      };

    case FETCH_EXTERN_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EXTERN_COMPANY_SUCCESS:
      const updatedExternCompanies = state.externCompanies.map((externCompany) =>
        externCompany.id === action.payload.id ? action.payload.externCompanyData : externCompany
      );
      return {
        ...state,
        externCompanies: updatedExternCompanies,
      };

    case DELETE_EXTERN_COMPANY_SUCCESS:
      const filteredExternCompanies = state.externCompanies.filter((externCompany) => externCompany.id !== action.payload);
      return {
        ...state,
        externCompanies: filteredExternCompanies,
      };

    case SET_EXTERN_COMPANY:
      return {
        ...state,
        externCompanies: action.payload,  // Payload doğrudan companies array'i olacak
      };

    default:
      return state;
  }
};

export default externCompanyReducer;
