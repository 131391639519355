import React, { useEffect } from "react";
import { connect } from "react-redux";
import UpdateInslug from "./updateInslug";
import UpdateInslugItem from "./updateInslugItem";
import { setBreadcrumbItems } from "../../../../store/actions";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'; 

const UpdateInslugFormPage = (props) => {
    const { t } = useTranslation();
    document.title = t("Update Inslug");
    const breadcrumbItems = [];

    useEffect(() => {
        props.setBreadcrumbItems("", breadcrumbItems);
    }, []);


    return (
        <React.Fragment>
            <div>
                <UpdateInslug />
                <UpdateInslugItem />
                {/* <UpdateInslugActions /> */}
            </div>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(UpdateInslugFormPage);
