import { SET_SELECTED_PRODUCT, RESET_SELECTED_PRODUCTS } from './actionTypes';

const initialState = {
  selectedProducts: [],
};

const selectedProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProducts: action.payload,
      };

    case RESET_SELECTED_PRODUCTS:
      return initialState; 

    default:
      return state;
  }
};

export default selectedProductsReducer;
