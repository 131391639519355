import { API_BASE_URL, API_SETTINGS } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const ROLES = '/roles';
const LIST = '/list';
const GET_DELETED = '/get-deleted';
const LIST_MODULES = '/list-modules';
const CREATE = '/create';
const UPDATE = '/update';
const GET_ROLE_PERMISSION = '/get-role-permission';
const DELETE = '/delete';

/**
 * Rolleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getRoles = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_SETTINGS}${ROLES}${LIST}`);

    console.log("xxxxxxxxxxxxxxxxxxx");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Roller listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Rolleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedRoles = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_SETTINGS}${ROLES}${GET_DELETED}`);

    console.log("xxxxxxxxxxxxxxxxxxx");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Roller listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Modulleri ve izinleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getModulsAndPermission = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_SETTINGS}${ROLES}${LIST_MODULES}`);

    console.log("xxxxxxxxxxxxxxxxxxx");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Moduller ve izinler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Modulleri ve izinleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getRolePermission = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${ROLES}${GET_ROLE_PERMISSION}`,{id});

    console.log("xxxxxxxxxxxxxxxxxxx");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Moduller ve izinler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Create new role function
 * @returns {Promise} - API cevabı
 */
export const createNewRole = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${ROLES}${CREATE}`,data);

    console.log("xxxxxxxxxxxxxxxxxxx");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Yeni rol tanımlanırken:', error);
    throw error;
  }
};

/**
 * Create new role function
 * @returns {Promise} - API cevabı
 */
export const updateRole = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${ROLES}${UPDATE}`,data);
    console.log("xxxxxxxxxxxxxxxxxxx");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Rol güncelleme hatası:', error);
    throw error;
  }
};


export const deleteRole = async (id) => {
  console.log(id);
  try {
    
    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${ROLES}${DELETE}`,{id});

    console.log("xxxxxxxxxxxxxxxxxxx");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Yeni rol tanımlanırken:', error);
    throw error;
  }
};