// src/store/selectedCountry/selectedCountryActions.js
import { SET_UITSLUG_ITEM_LIST, CLEAR_UITSLUG_ITEM_LIST, UPDATE_UITSLUG_ITEM } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setUitslugItemList = (city) => ({
  type: SET_UITSLUG_ITEM_LIST,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearUitslugItemList = () => ({
  type: CLEAR_UITSLUG_ITEM_LIST,
});

export const updateUitslugItem = (id, outputCount) => ({
  type: UPDATE_UITSLUG_ITEM,
  payload: { id, outputCount },
});

