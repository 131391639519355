import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { FaRecycle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import 'styles/custom/custom-tooltip.css';
import { useTranslation } from 'react-i18next';

const RestoreButton = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <div id="restoreButtonTooltipWrapper">
      <Button color="link" onClick={onClick} disabled={disabled}>
        <FaRecycle color={disabled ? "gray" : "green"} size={18} />
      </Button>

      <Tooltip
        isOpen={tooltipOpen}
        target="restoreButtonTooltipWrapper"
        toggle={toggleTooltip}
        placement="top"
        className="custom-tooltip"
      >
        {disabled ? t("No authority") : t("Restore")}
      </Tooltip>
    </div>
  );
};

RestoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

RestoreButton.defaultProps = {
  disabled: false
};

export default RestoreButton;
