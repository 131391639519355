// reducer.js
import { SET_TIMER } from './actionTypes';

const initialState = {
  timer: true,
};

const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMER:
      return {
        timer: action.payload
      };
    default:
      return state;
  }
};

export default timerReducer;
