import {
    FETCH_EXTERN_COMPANIES_SUCCESS,
    FETCH_EXTERN_COMPANIES_FAILURE,
    UPDATE_EXTERN_COMPANY_SUCCESS,
    DELETE_EXTERN_COMPANY_SUCCESS,
    SET_EXTERN_COMPANY,
} from './actionTypes';

// Action to set fetched companies
export const setExternCompanies = (data) => {
    return {
      type: SET_EXTERN_COMPANY,
      payload: data,
    };
  };

// Action for fetch error
export const fetchExternCompaniesFailure = (error) => {
    return {
        type: FETCH_EXTERN_COMPANIES_FAILURE,
        payload: error,
    };
};

// Action to update a company
export const updateExternCompanyAction = (id, companyData) => {
    return {
        type: UPDATE_EXTERN_COMPANY_SUCCESS,
        payload: { id, companyData },
    };
};

// Action to delete a company
export const deleteExternCompanyAction = (id) => {
    return {
        type: DELETE_EXTERN_COMPANY_SUCCESS,
        payload: id,
    };
};
