import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../../store/actions";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import UitslugDetails from "./uitslugDetails";

const UitslugDetailsPage = (props) => {
    const { t } = useTranslation();
    document.title = t("Uitslug Detail");
    const breadcrumbItems = [

    ];
    useEffect(() => {
        props.setBreadcrumbItems("", breadcrumbItems);
    }, []);

    return (
        <React.Fragment>
            <UitslugDetails />
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(UitslugDetailsPage);
