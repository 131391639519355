import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Card, CardBody, Form, Input, Table } from 'reactstrap';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { changePassiveStatus, getLanguages, updateLanguage } from 'service/depot/languageService';
import { setLanguages } from 'store/languages/action';
import { openModal, closeModal } from 'store/modal/actions';
import CreateNewLanguage from './createNewLanguage';
import { closeUpdateModal, openUpdateModal } from 'store/updateModal/actions';
import customStyles from 'styles/customDatatableStyle';
import AcceptModal from 'components/Common/Modals/acceptModal';
import UpdateModal from 'components/Common/Modals/updateModal';
import UpdateLanguage from './updateLanguage';
import { FaBan, FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import i18n from "i18n";
import { hasCreatePermission, hasDeletePermission, hasUpdatePermission, hasViewPermission } from 'service/common/permissionService';
import ToastMessage from 'components/Common/Toasts/dynamicToast';
import DetailsButton from 'components/Common/Button/detailsButton';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import { clearSelectedImage } from 'store/depot/selectedImage/action';

const LanguageForm = props => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const languageFormData = useSelector((state) => state.languages?.languages || []);
    const [selectedLanguageFormData, setSelectedLanguagesFormData] = useState(null);
    const [englishContent, setEnglishContent] = useState({});
    const [selectedOriginalData, setSelectedOriginalData] = useState({});
    const [searchText, setSearchText] = useState("");
    const [showDetailTable, setShowDetailTable] = useState(false); // Yeni state eklendi
    const selectedImage = useSelector((state) => state.selectedImage?.selectedImage || []); // Use Redux state for selected image

    useEffect(() => {
        const fetchData = async () => {
            try {
                const languageJSON = await getLanguages();
                console.log("1290895132");
                console.log(languageJSON);
                dispatch(setLanguages(languageJSON));

                // Set English as the default content
                const englishLanguage = languageJSON.find(lang => lang.name.toLowerCase() === "english");
                if (englishLanguage) {
                    setEnglishContent(englishLanguage.content);
                }
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleInputChange = (key, value) => {
        setSelectedLanguagesFormData((prevData) => ({
            ...prevData,
            content: {
                ...prevData.content,
                [key]: value
            }
        }));
    };

    const handleOpenUpdateModal = (selectedLanguage) => {
        console.log("SELECTEDIMAGEEEEE");
        console.log(selectedImage);
        dispatch(openUpdateModal({
            body: <UpdateLanguage language={selectedLanguage} />
        }));
    };

    const changeLanguageToDefault = async () => {
        try {
            const getNewLanguagesData = await getLanguages();
            const newLang = getNewLanguagesData.find(data => data.is_passive === false);
            console.log("newLaaaanngggggg");
            console.log(newLang);
            await i18n.changeLanguage(newLang.isocode);
            localStorage.setItem("I18N_LANGUAGE", newLang.isocode);
        } catch (error) {
            console.error("Language switch error:", error);
        }
    };

    const handleChangePassiveStatusLanguage = (id, isDelete) => {

        if (isDelete === true) {
            const activeLangs = languageFormData.filter(data => data.is_passive === false);
            if (activeLangs.length === 1) {
                toast.error(props.t("Cannot delete the last remaining language"))
                return;
            }
            dispatch(
                openModal({
                    title: props.t("modal-delete-title"),
                    body: props.t("modal-delete-body"),
                    onConfirm: async () => {
                        try {
                            await changePassiveStatus(id).then(async () => {
                                toast.success(props.t("toast-success-delete-data"), { position: "top-right" });
                                dispatch(closeModal()); // Close the modal after failure
                                await changeLanguageToDefault();
                                window.location.reload();
                            })
                        } catch (error) {
                            console.error('Şirket silinirken bir hata oluştu:', error);
                            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
                            dispatch(closeModal()); // Close the modal after failure
                        }
                    }
                })
            );
        } else {
            dispatch(
                openModal({
                    title: props.t("modal-update-title"),
                    body: props.t("modal-update-body"),
                    onConfirm: async () => {
                        try {
                            await changePassiveStatus(id); // Delete company
                            toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                            dispatch(closeModal()); // Close the modal after failure
                            window.location.reload();

                        } catch (error) {
                            console.error('Şirket silinirken bir hata oluştu:', error);
                            toast.error(props.t("toast-error-update-data"), { position: "top-right" });
                            dispatch(closeModal()); // Close the modal after failure
                        }
                    }
                })
            );
        }
    };

    const handleViewDetails = (selectedLanguage) => {
        setSelectedLanguagesFormData(selectedLanguage);
        setSelectedOriginalData(selectedLanguage.content);
        setShowDetailTable(true); // Detay tablosunu göster
    };

    useEffect(() => {
        console.log("selectedOriginalData");
        console.log(selectedOriginalData);
    }, [selectedOriginalData]);

    const handleInputFocus = async (key) => {
        toast.dismiss();
        const wordTranslations = languageFormData.map(language => ({
            language: language.name,
            translation: language.content[key] || "N/A"
        }));

        const tableContent = (
            <Table bordered>
                <thead>
                    <tr>
                        <th>{props.t("Language")}</th>
                        <th>{props.t("Translation")}</th>
                    </tr>
                </thead>
                <tbody>
                    {wordTranslations.map((item, index) => (
                        <tr key={index}>
                            <td>{item.language}</td>
                            <td>{item.translation}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );

        const tableTitle = <h5 className='text-center text-primary'>{key}</h5>;

        // ToastMessage bileşenini çağırıyoruz
        ToastMessage({
            title: tableTitle,
            content: tableContent,  // Tabloyu `content` prop’u olarak geçiyoruz
        });

        // Custom toast with table content
       /*  toast(
            <div>
                <h5 className='text-center text-primary'>{key}</h5>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>{props.t("Language")}</th>
                            <th>{props.t("Translation")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {wordTranslations.map((item, index) => (
                            <tr key={index}>
                                <td>{item.language}</td>
                                <td>{item.translation}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>,
            {
                position: "top-left",
                autoClose: false, // Do not auto close
                closeOnClick: true,
                draggable: true,
                pauseOnHover: true,
                style: { backgroundColor: '#FEFAF6' },
                className: "shadow shadow-lg custom-toast-info",
                closeButton: <CloseButton />
            }
        ); */
    };

    const handleValidation = () => {
        const emptyKeys = [];
        Object.keys(selectedLanguageFormData.content).forEach(key => {
            const value = selectedLanguageFormData.content[key];
            if (value === "" || value === null || value === undefined) {
                emptyKeys.push(key);
            }
        });

        return emptyKeys;
    };

    const handleSubmit = (e) => {
        const validateResult = handleValidation();
        if (validateResult.length > 0) {
            toast.error(`${props.t("Empty filed detected")} - ${validateResult[0]}`);
            return;
        }
        e.preventDefault();
        dispatch(openModal(
            {
                title: props.t("modal-update-title"),
                body: props.t("modal-update-body"),
                onConfirm: async () => {
                    await handleConfirm();
                }
            }
        ));
    };

    const handleConfirm = async () => {
        const { is_passive, ...rest } = selectedLanguageFormData;
        try {
            await updateLanguage(rest);
            dispatch(closeModal());
            window.location.reload();
            toast.success(props.t("toast-success-update-data"), { position: "top-right" });
            const updatedLanguages = await getLanguages();
            dispatch(setLanguages(updatedLanguages));
        } catch (error) {
            console.error("Failed to update language data:", error);
            toast.error(props.t("toast-error-update-data"), { position: "top-right" });
            dispatch(closeModal());
        }
    };

    const createNewLanguage = () => {
        dispatch(clearSelectedImage());
        dispatch(
            openUpdateModal({
                body: <CreateNewLanguage />,
                onConfirm: async () => { },
            })
        );
    };

    const languageColumns = [
        {
            name: props.t("Language"),
            selector: row => row.name,
            sortable: true,
        },
        {
            name: props.t("Flag"),
            cell: row => <img src={row.flag} alt="Language Flag" width={36} height={36} />,
        },
        {
            name: props.t("Actions"),
            cell: row => (
                row.is_passive === false ?
                    <>
                        {hasViewPermission(permissions, "MM_05") ?
                            <DetailsButton onClick={() => handleViewDetails(row)} />
                            : null}
                        {hasUpdatePermission(permissions, "MM_05") ?
                            <EditButton onClick={() => handleOpenUpdateModal(row)} />
                            : null}
                        {hasDeletePermission(permissions, "MM_05") ?
                            <DeleteButton onClick={() => handleChangePassiveStatusLanguage(row.id, true)} />
                            : null}
                    </>
                    :
                    <>
                        <Button color="link">
                            <FaBan color="grey" size={16} />
                        </Button>

                        <Button onClick={() => handleChangePassiveStatusLanguage(row.id, false)} size='sm' color='success'>
                            <span className='text-white'>
                                {props.t("Activate")}
                            </span>
                        </Button>
                    </>
            ),
        },
    ];

    const contentColumns = selectedLanguageFormData ? [
        {
            name: props.t("Default (English)"),
            selector: row => row.default,
        },
        /*         {
                    name: props.t("Language Value"),  // Yeni sütun eklendi
                    selector: row => row.originalValue,       // `row.value` değeri düz metin olarak gösterilecek
                }, */
        {
            name: props.t("Translated Value"),
            cell: row => (
                <Input
                    type="text"
                    name={row.key}
                    value={row.value}
                    required
                    min={1}
                    max={128}
                    onFocus={() => handleInputFocus(row.key)} // Show custom toast on focus
                    onChange={(e) => handleInputChange(row.key, e.target.value)}
                    placeholder={`Enter value`}
                />
            ),
        }
    ] : [];

    const contentData = selectedLanguageFormData ?
        Object.entries(selectedLanguageFormData.content)
            .map(([key, value]) => ({
                key,
                value,
                default: englishContent[key] || "", // Show default English value
                originalValue: selectedOriginalData[key],  // Orijinal değer, Language Value için sabit kalacak
            }))
            .filter(dataItem =>
                dataItem.originalValue.toLowerCase().includes(searchText.toLowerCase()) // Arama Translated Value'ya göre yapılıyor
            ) : [];

    const customPaginationOptions = {
        rowsPerPageText: props.t("Rows count"),
        rangeSeparatorText: '/',
    };

    return (
        <div>
            <Card>
                <CardBody>
                    <div className='d-flex justify-content-between mb-4'>
                        <h5 className="text-primary">{props.t("Language List")}</h5>
                        <div>
                            {hasCreatePermission(permissions, "MM_05") ?
                                <Button className="btn btn-md mx-2" color="success" onClick={createNewLanguage}>+</Button>
                                : null}
                        </div>
                    </div>
                    <DataTable
                        columns={languageColumns}
                        data={languageFormData}
                        customStyles={customStyles}
                        noDataComponent={props.t("table-empty-message")}
                        paginationComponentOptions={customPaginationOptions}
                        selectableRows={false} // Bu satır seçim kutucuklarını kaldırır
                        pagination
                        highlightOnHover
                    />
                </CardBody>
            </Card>
            {selectedLanguageFormData && showDetailTable && (
                <Card>
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <div className='d-flex justify-content-between mb-4'>
                                <div className='d-flex align-items-center'>
                                    <h5 className="text-primary me-3 my-0">{selectedLanguageFormData.name}</h5>
                                    <img width={32} height={32} src={`${selectedLanguageFormData.flag}`} />
                                </div>
                                <div>
                                    <Button color="success" type='submit'>{props.t("Save")}</Button>
                                </div>
                            </div>
                            <DataTable
                                columns={contentColumns}
                                data={contentData}
                                customStyles={customStyles}
                                pagination
                                noDataComponent={props.t("table-empty-message")}
                                paginationComponentOptions={customPaginationOptions}
                                paginationPerPage={500}
                                highlightOnHover
                                subHeader
                                subHeaderComponent={
                                    <div className='d-flex justify-content-end w-25'>
                                        <Input
                                            type="text"
                                            placeholder={props.t("Search")}
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            maxLength={32}
                                        />
                                    </div>
                                }
                            />
                        </Form>
                    </CardBody>
                </Card>
            )}

            <AcceptModal />
            <UpdateModal />
        </div>
    );
};

LanguageForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(LanguageForm);
