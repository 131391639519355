// src/store/selectedCountry/selectedCountryReducer.js
import { SET_CURRENTS, CLEAR_CURRENTS } from './actionTypes';

const initialState = {
  list: [], // Başlangıçta seçilen ülke boş
};

const currentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENTS:
      return {
        ...state,
        list: action.payload,
      };

    case CLEAR_CURRENTS:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
};

export default currentsReducer;
