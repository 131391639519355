import React, { useState, useEffect } from 'react';
import { Input, Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setProductGroups } from 'store/depot/productGroup/action'; // Redux actions
import DynamicInput from 'components/Common/Form/DynamicInput';
import { createProductCategory, getProductCategories, updateProductCategory } from 'service/depot/productCategoryService';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { closeModal, openModal } from 'store/modal/actions';
import { clearProductGroupFormData, setProductGroupFormData } from 'store/updates/productGroupForm/action';
import { useSelector } from 'react-redux';
import { closeUpdateModal } from 'store/updateModal/actions';
import { customValidationError } from 'components/Common/customValidationError';

const UpdateProductGroupForm = props => {
  const dispatch = useDispatch();

  // Form state, JSON yapısına göre düzenlendi
  const formData = useSelector((state) => state.updateProductGroupFormData?.formData);
  console.log("Form data gproduct grouppp");

  console.log(formData);
  const handleChange = (e) => {
    const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    dispatch(setProductGroupFormData({
      ...formData,
      [e.target.name]: inputData,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  const handleConfirm = async () => {
    console.log("Update product Group Form Data");
    console.log(formData);
    const {createdAt, updatedAt, product_count, product_category_no, ...rest} = formData;
    console.log("rest çalışacak mııı");
    console.log(rest);
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          
          try {
            await updateProductCategory(rest)
              .then(async () => {
                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                const updatedProducts = await getProductCategories(); // Ürünleri güncelle
                dispatch(setProductGroups(updatedProducts)); // Redux state güncelleme
                dispatch(clearProductGroupFormData());
              })
            dispatch(closeModal()); // Close the modal after successful update
            dispatch(closeUpdateModal());
          } catch (error) {
            console.error("Ürün grubu eklenemedi:", error);
            //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            customValidationError(error,props);
          }
        }
      })
    );

  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Update Product Category")}</h5>
          <Button color="warning" type="submit">
            {props.t("Update")}
          </Button>
        </div>
        <Row className='mb-4' form>
          <Col md={6}>
            <FormGroup>
              <Label for="name">{props.t("Product Category Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter product group name")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

UpdateProductGroupForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateProductGroupForm)