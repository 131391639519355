import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { clearSupportFormData, setSupportFormData } from '../../store/support/action';
import DynamicInput from '../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { closeModal, openModal } from 'store/modal/actions';
import { useSelector } from 'react-redux';
import { createSupport, getSupports } from 'service/supportService';
import AcceptModal from 'components/Common/Modals/acceptModal';

const SupportForm = props => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.support?.formData || []);

  const handleChange = (e) => {
    const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value;
    dispatch(setSupportFormData({
      ...formData,
      [e.target.name]: inputData,
    }));
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  }

  const handleConfirm = async () => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          dispatch(closeModal());
          try {
            await createSupport(formData)
              .then(async () => {
                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                //const countries = await getSupports(); 
                dispatch(clearSupportFormData());
              })
          } catch (error) {
            console.error("Support eklenemedi:", error);
            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Support Request")}</h5>
          <Button className='text-white' color="success" type='submit'>
            {props.t("Save")}
          </Button>
        </div>
        <Row className='mb-4' form>
          <FormGroup>
            <Label for="subject">{props.t("Subject")}</Label>
            <DynamicInput
              type="text"
              name="subject"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder={props.t("Enter subject")}
              minLength={6}
              maxLength={64}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="content">{props.t("Content")}</Label>
            <DynamicInput
              type="textarea"
              name="content"
              id="content"
              value={formData.content}
              onChange={handleChange}
              placeholder={props.t("Enter content")}
              minLength={6}
              maxLength={255}
              required
            />
          </FormGroup>
        </Row>
      </Form>
      <AcceptModal />
    </div>
  );
};

SupportForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(SupportForm)