import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import MonthlyEarnings from 'pages/Dashboard/montly-earnings';
import MonthlyEarnings2 from 'pages/Dashboard/montly-earnings2';
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import DepotDashboardActions from './depotDashboardActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const DepotDashboard = (props) => {
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  //const [permissions, setPermissions] = useState([]);

  const { t } = useTranslation();
  document.title = t("Dashboard");
  const breadcrumbItems = [];



  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)

  return (
    <React.Fragment>
      <div className="text-center">
        <div>
          <DepotDashboardActions />
        </div>
        <div>
          <Row>
            {/* {hasViewPermission(permissions, "DE_03") ? inslugListComp : null} */}
            <Col lg={6}>
              <MonthlyEarnings />
            </Col>
            <Col lg={6}>
              <MonthlyEarnings2 />
            </Col>
          </Row>
        </div>
        {/* <div className="my-4">
          <Row className="justify-content-center mb-4 mx-0 px-0">
            <Col md={12} lg={6}>
              <MonthlyEarnings />
            </Col>
            <Col md={12} lg={6} >
              <MonthlyEarnings2 />
            </Col>
          </Row>
        </div> */}
      </div>
    </React.Fragment>
  );
};
export default connect(null, { setBreadcrumbItems })(DepotDashboard);