import React, { useEffect } from "react";
import { connect } from "react-redux";
import UitslugForm from "./uitslugForm";
import { setBreadcrumbItems } from "store/actions";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'; 
import UitslugItemList from "./uitslugItemList";
import { useSelector } from "react-redux";

const UitslugPage = (props) => {
    const { t } = useTranslation();
    document.title = t("Uitslug");
    const breadcrumbItems = [];
    const uitItems = useSelector((state) => state.uitslugItemList?.uitslugItemList || []);
    console.log(uitItems);
    useEffect(() => {
        props.setBreadcrumbItems("", breadcrumbItems);
    }, []);


    return (
        <React.Fragment>
            <div>
                <UitslugForm />
            </div>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(UitslugPage);
