import { SET_SELECTED_UPDATE_PRODUCT, RESET_SELECTED_UPDATE_PRODUCTS } from './actionTypes';

const initialState = {
  selectedUpdateProducts: [],
};

const selectedUpdateProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_UPDATE_PRODUCT:
      return {
        ...state,
        selectedUpdateProducts: action.payload,
      };

    case RESET_SELECTED_UPDATE_PRODUCTS:
      return initialState; 

    default:
      return state;
  }
};

export default selectedUpdateProductsReducer;
