// store/auth/forgetpwd/saga.js
import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from 'axios';
import { API_BASE_URL } from "config";

// Redux State Types
import { FORGET_PASSWORD, CHANGE_PASSWORD_REQUEST } from "./actionTypes";
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  changePasswordSuccess,
  changePasswordError,
  setIsLoading
} from "./actions";

// Backend API URL

// Kullanıcı şifre sıfırlama isteği gönderme
const postForgetPassword = async (email) => {
  const response = await axios.post(`${API_BASE_URL}/auth/reset-password`, { email });
  return response.data;
};

function* forgetUser({ payload: { user, history, successMessage, errorMessage } }) {
  yield put(setIsLoading(true));
  try {
    const response = yield call(postForgetPassword, user.email);
    if (response) {
      yield put(userForgetPasswordSuccess(successMessage));
      yield put(setIsLoading(false));

    }
  } catch (error) {
    yield put(userForgetPasswordError(errorMessage));
    yield put(setIsLoading(false));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

// Kullanıcı şifre değiştirme isteği gönderme
const postChangePassword = async ({ newPassword, token }) => {
  const response = await axios.post(`${API_BASE_URL}/auth/change-password`, { newPassword, token });
  return response.data;
};

function* changePasswordSaga({ payload: { newPassword, token, navigate } }) {
  try {
    const response = yield call(postChangePassword, { newPassword, token });
    if (response.success) {
      yield put(changePasswordSuccess(response.message)); // Doğru action çağrısı
      navigate('/login'); // Şifre başarıyla değiştirildiyse login sayfasına yönlendir
    } else {
      yield put(changePasswordError(response.message));
    }
  } catch (error) {
    yield put(changePasswordError(error.response?.data?.message || 'Şifre değiştirme işlemi sırasında bir hata oluştu.'));
  }
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordSaga);
}

// Saga Root
function* rootSaga() {
  yield all([
    fork(watchUserPasswordForget),
    fork(watchChangePassword)
  ]);
}

export default rootSaga;
