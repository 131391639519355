import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const API_DEPOT = '/depot';
const API_PART = '/part';
const CREATE = '/create';
const GET = '/get';
const GET_UNIT_PARTS = '/get-unit-parts';
const GET_DELETED = '/get-deleted';
const UPDATE = '/update';
const DELETE = '/passive';
const RESTORE = '/restore';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} data - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createPart = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_PART}${CREATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getParts = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_PART}${GET}`);
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getUnitParts = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_PART}${GET_UNIT_PARTS}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedParts = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_PART}${GET_DELETED}`);
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updatePart = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_PART}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deletePart = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_PART}${DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restorePart= async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_PART}${RESTORE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};
