import React from "react";
import { QRCode } from "react-qrcode-logo";
import logo from "assets/images/act-word-big.png";

const DynamicQr = ({
  value = "https://example.com", // Default QR kod değeri
  fgColor = "#0e5b8c", // Varsayılan ön plan rengi
  bgColor = "#ffffff", // Varsayılan arka plan rengi
  quietZone = 0, // Varsayılan boş alan
  size = 120, // Varsayılan QR kod boyutu
  logoImage = null, // Varsayılan logo
  logoWidth = size/4, // Varsayılan logo genişliği
  logoHeight = size/4, // Varsayılan logo yüksekliği
  logoOpacity = 1, // Varsayılan logo opaklığı
  logoPadding = size/30, // Varsayılan logo etrafı boşluk
  logoPaddingStyle = "circle", // Varsayılan logo boşluk stili
  qrStyle = "squares", // Varsayılan QR kod stili
  eyeRadius = { // Varsayılan köşe gözü şekillendirme
    outer: 5, // Dış göz yarıçapı
    inner: 5, // İç göz yarıçapı
  },
  eyeColor = "#0e5b8c", // Varsayılan köşe göz rengi
}) => {
  return (
    <QRCode
      value={value}
      fgColor={fgColor}
      bgColor={bgColor}
      quietZone={quietZone}
      size={size}
      logoImage={logoImage || logo}
      logoWidth={logoWidth}
      logoHeight={logoHeight}
      logoOpacity={logoOpacity}
      //logoPadding={logoPadding}
      logoPaddingStyle={logoPaddingStyle}
      qrStyle={qrStyle}
      eyeRadius={eyeRadius}
      eyeColor={eyeColor}
    />
  );
};

export default DynamicQr;
