import React, { useState, useEffect, useRef } from 'react';
import { Badge, Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasDeletePermission, hasExcelPermission, hasRestorePermission, hasUpdatePermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import UnitUpdateForm from './unitUpdateForm';
import { deleteUnit, getDepotUnits } from 'service/settings/unitService';
import { setUnits } from 'store/settings/units/actions';
import { setUpdateUnitFormData } from 'store/settings/updateUnitForm/action';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DetailsButton from 'components/Common/Button/detailsButton';
import RestoreButton from 'components/Common/Button/restoreButton';
import { QRCodeCanvas } from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import qr from "assets/icons/qr-colored.png";

const UnitList = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const contentRef = useRef(null);

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState('');
  const units = useSelector((state) => state.units?.units || []);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [info, setInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await getDepotUnits(id);
        const { info, data } = list;
        setInfo(info);
        dispatch(setUnits(data || []));
      } catch (error) {
        console.error('Error fetching units:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, []);

  // Seçime göre filteredCompanies'i güncelle
  useEffect(() => {
    setFilteredUnits(units);
  }, [units]);

  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    const dataToFilter = units;
    const filteredData = dataToFilter.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.depot_id.toString().toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredUnits(filteredData);
  }, [searchText, units]);

  // Handle editing row and open modal
  const handleEdit = async (id) => {
    const selectedData = filteredUnits.find((data) => data.id === id);
    dispatch(setUpdateUnitFormData(selectedData));

    // Open modal for editing
    dispatch(
      openUpdateModal({
        body: (
          <UnitUpdateForm />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleDelete = (dataId) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          dispatch(closeModal());
          try {
            await deleteUnit(dataId);
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const list = await getDepotUnits(id);
            const { info, data } = list;
            setInfo(info);
            dispatch(setUnits(data || []));
            setFilteredUnits(data || []);
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  const handleRestore = (dataId) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after failure
          try {
            await deleteUnit(dataId); // Delete company
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });
            const list = await getDepotUnits(id);
            const { info, data } = list;
            setInfo(info);
            dispatch(setUnits(data || []));
            setFilteredUnits(data || []);
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  const handleViewDetails = (id) => {
    navigate(`/depots/units/${id}`);
  };

  const handlePrint = useReactToPrint({ contentRef });


  const exportToExcel = () => {
    const column2Name = props.t("Unit Name");


    const selectedColumns = filteredUnits.map(itemCompanies => ({
      [column2Name]: itemCompanies.name,
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t(info?.depot_name));
    XLSX.writeFile(workbook, props.t(info?.depot_name) + ".xlsx");
  };

  const columns = [
    {
      name: props.t("#"),
      selector: (row, rowIndex) =>
        rowIndex + 1,
      sortable: true,
      wrap: true,
    },
    /*     {
          name: props.t("QR Code"),
          selector: (row, rowIndex) => <QRCodeCanvas value={row.qr} size={20} />,
          sortable: true,
          wrap: true,
        }, */
    {
      name: props.t("Unit Name"),
      selector: (row, rowIndex) =>
        row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Status"),
      selector: (row) => row.is_passive, // Sıralama için ham veriyi kullanır
      sortable: true, // Sıralamayı aktif hale getirir  
      cell: (row) =>
        row.is_passive === true ?
          <Badge>{props.t("Passive")}</Badge> :
          <Badge color='success'>{props.t("Active")}</Badge>,
      sortFunction: (rowA, rowB) => {
        // "is_passive" değerine göre sıralama yapar: true (Pasif) veya false (Aktif)
        return rowA.is_passive === rowB.is_passive ? 0 : rowA.is_passive ? 1 : -1;
      },
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        row.is_passive ?
          hasRestorePermission(permissions, "DE_11") ? <RestoreButton onClick={() => handleRestore(row.id)} /> : <RestoreButton disabled={true} />
          :
          <>
            <DetailsButton disabled={false} onClick={() => handleViewDetails(row.id)} />
            {hasUpdatePermission(permissions, "DE_11") ? <EditButton disabled={false} onClick={() => handleEdit(row.id)} /> : <EditButton disabled={true} />}
            {hasDeletePermission(permissions, "DE_11") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} />}
          </>
    },
  ];

  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  return (
    <div  >
      <div className='d-flex justify-content-between'>
        <h5 className="text-primary">{props.t("Unit List")}</h5>
        <div className="d-flex align-items-center mb-4">
          <Link to={`/depots/${info?.depot_id}`}>
            <Badge className='p-2 m-1' color='success'>{props.t(info?.depot_name)}</Badge>
          </Link>
        </div>
      </div>

      <DataTable
        style={{
          border: "1px solid #ff6347"
        }}
        className='p-3'
        columns={columns}
        data={filteredUnits}
        customStyles={customStyles}
        pagination
        noDataComponent={props.t("table-empty-message")}
        paginationComponentOptions={customPaginationOptions}
        highlightOnHover
        paginationLa
        subHeader
        subHeaderComponent={
          <div className='d-flex align-items-right mb-2'>
            <div className='d-flex'>
              {/* <Button className="m-0 p-0 px-3" color="red" onClick={handlePrint}>
                <img src={qr} width={'30'} alt="" />
              </Button> */}
              {hasExcelPermission(permissions, "DE_11") && filteredUnits.length > 0 ? excelButton : null}
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))}
                style={{ width: '250px' }}
                maxLength={32}
              />
            </div>
          </div>
        }
      />

      <AcceptModal />
      <UpdateModal />
      <div ref={contentRef} className="print-only">
        <div className="container mt-5">
          <div className="row">
            {units.map((unit, index) => (
              <div key={index} className="col-6 text-center mb-4">
                <QRCodeCanvas value={unit.qr} size={120} />
                <div><strong>{info.depot_name}</strong></div>
                <div><strong>{unit.name}</strong></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

UnitList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UnitList)