import { SET_PERMISSIONS, CLEAR_PERMISSIONS } from './actionTypes';

const initialState = {
  permissions: [],
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };

    case CLEAR_PERMISSIONS:
      return {
        ...state,
        permissions: initialState,
      };

    default:
      return state;
  }
};

export default permissionsReducer;
