import React, { useEffect } from 'react';
import { Button, Table, FormGroup, Card, Badge } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { addUpdateInslugItemRow, setUpdateInslugItemData, removeUpdateLastInslugItemRow, setUpdateInslugItemsAll, removeUpdateInslugItemRow } from 'store/depot/updateInslugItem/actions';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { inslugItemInfo } from 'service/depot/inslugItemService';
import { useNavigate, useParams } from 'react-router-dom';
import { getInslug, updateInslug } from 'service/depot/inslugService';
import 'react-toastify/dist/ReactToastify.css';
import DynamicInput from 'components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { setSelectedUpdateClientProducts } from 'store/depot/selectedUpdateClientProducts/actions';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import { closeModal, openModal } from 'store/modal/actions';
import { toast } from 'react-toastify';

const UpdateInslugItem = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const updateInslugData = useSelector((state) => state.updateInslug);
  const updateInslugItemsData = useSelector((state) => state.updateInslugItems?.updateInslugItems || []);
  const selectedUpdateProducts = useSelector((state) => state.selectedUpdateProducts?.selectedUpdateProducts || []);
  const clientUpdateProducts = useSelector((state) => state.selectedUpdateClientProducts?.selectedUpdateClientProducts || []);


  useEffect(() => {
    const updateSelectedProducts = (inslugItems, clientProducts) => {
      inslugItems.forEach((inslugItem, index) => {
        const product = clientProducts.find(product => product.value === inslugItem.client_product_id);
        dispatch(setUpdateInslugItemData(index, "id", inslugItem.id));
        if (product) {
          //dispatch(setUpdateInslugItemData(index, "client_no", product ? product.value : null));
          dispatch(setUpdateInslugItemData(index, "client_product_id", product ? product.value : null));

          /*        dispatch(setUpdateInslugItemData(index, "client_no", selectedOption ? selectedOption.value : null));
                 dispatch(setUpdateInslugItemData(index, "client_product_id", selectedOption ? selectedOption.value : null)); */

        }
      });
    };

    const setDatas = (inslugItem, clientProductData) => {
      dispatch(setUpdateInslugItemsAll(inslugItem));
      updateSelectedProducts(inslugItem, clientProductData);
    };

    const customLabel = (product) => {
      return <div className="d-flex align-items-center">
        {/* <span className='shadow rounded bg-info px-1 text-white mx-2' color="info" pill>{`${product.client_no}`}</span>
        <span className=''>{product.company_name}</span>
        <span className='mx-2'>|</span> */}
        {/* <span className='rounded bg-warning px-1 text-white mx-2' color="info" pill>{`${product.product_no}`}</span> */}
        <span>{product.product_name}</span>
      </div>;
    }

    const fetchData = async () => {
      try {
        const inslugData = await getInslug(id);
        console.log("inslugDataa");
        console.log(inslugData);
        const clientProductData = await inslugItemInfo();
        const formattedClientProducts = clientProductData.map(product => ({
          value: product.client_product_id,
          clientNo: product.client_no,
          companyName: product.company_name,
          productNo: product.product_no,
          productCategoryName: product.product_name,
          originCountry: product.origin_country,
          label: customLabel(product)
        }));
        dispatch(setSelectedUpdateClientProducts(formattedClientProducts));
        setDatas(inslugData.inslugItems, formattedClientProducts);
      } catch (error) {
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };

    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    console.log("CLİENT PRODUCTSSS");
    console.log(clientUpdateProducts);
  }, [clientUpdateProducts]);

  useEffect(() => {
    console.log("updateInslugItemsDataaaaaaaaaaaaaaaaa");
    console.log(updateInslugItemsData);
  }, [updateInslugItemsData]);

  const handleCountChange = (index, e) => {
    console.log("eeeee");
    console.log(e);
    if (e?.value <= 100) {
      dispatch(setUpdateInslugItemData(index, "count", e?.value));
    } else {
      dispatch(setUpdateInslugItemData(index, "count", null));
    }
  }

  const handleSelectChange = (index, field, selectedOption) => {
    //dispatch(setUpdateInslugItemData(index, "client_no", selectedOption ? selectedOption.value : null));
    dispatch(setUpdateInslugItemData(index, "client_product_id", selectedOption ? selectedOption.value : null));
  };

  const handleCreateOption = (inputValue, index) => {
    const newOption = {
      value: inputValue,
      label: (
        <div className="d-flex align-items-center">
          <Badge color="info" pill>{inputValue}</Badge>
        </div>
      )
    };
    dispatch(setSelectedUpdateClientProducts([...clientUpdateProducts, newOption]));

    /* let updatedSelectedProducts = [...selectedUpdateProducts];
    updatedSelectedProducts[index] = newOption; */
    //dispatch(setSelectedUpdateProducts(updatedSelectedProducts));
    //dispatch(setUpdateInslugItemData(index, 'client_no', newOption.value));
  };

  const handleInputChange = (index, e) => {
    dispatch(setUpdateInslugItemData(index, e.target.name, e.target.value.toLocaleUpperCase("tr")));
  };

  const addNewRow = () => {
    dispatch(addUpdateInslugItemRow());
    //dispatch(setSelectedUpdateProducts([...selectedProducts, null]));
  };

  const removeLastRow = () => {
    if (updateInslugItemsData.length > 0) {
      dispatch(removeUpdateLastInslugItemRow());
    }
  };

  const removeIndexRow = (index) => {
    if (updateInslugItemsData.length > 0) {
      dispatch(
        openModal({
          title: props.t("modal-delete-title"),
          body: <span>{props.t("modal-delete-body")}</span>,
          onConfirm: async () => {
            dispatch(closeModal());
            dispatch(removeUpdateInslugItemRow(index));
          }
        })
      );
    }
  };

  const validation = () => {
    if (!updateInslugData.client_no) {
      toast.error(props.t("error-company-empty"), { position: "top-right" });
      return false;
    }
    if (!updateInslugData.document_no) {
      toast.error(props.t("error-document-empty"), { position: "top-right" });
      return false;
    }
    return true;
  }

  const handleSubmitData = async () => {
    console.log("inslugIteemmm");
    console.log(updateInslugItemsData);
    const validationResult = validation();
    if (validationResult === false) {
      return;
    }
    dispatch(
      openModal({
        title: props.t("modal-update-title"),
        body: props.t("modal-update-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            const inslugData = createInslugJson(updateInslugData);
            const inslugItemData = extractSelectedFields(updateInslugItemsData);
            const data = { inslug: inslugData, inslugItems: inslugItemData };
            console.log("hepsilkdsjhfgdsfjksdfhgsdjkjdsfhgdsjkjdshfg");
            console.log(data);
            const response = await updateInslug(data)
              .then(() => {
                toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                navigate('/inslugs');
              })
          } catch (error) {
            console.error("Hata:", error);
            toast.error(props.t("toast-error-update-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  const createInslugJson = (data) => {
    const inslugData = {
      id: data.id,
      client_no: data.client_no,
      transport_company_no: data.transport_company_no,
      booking_date: data.booking_date,
      document_no: data.document_no,
      extern_document_no: data.extern_document_no,
      agency_reference: data.agency_reference,
      description: data.description,
      eta: data.eta,
      closing_out: data.closing_out,
      closing_in: data.closing_in,
      customs_status_id: data.customs_status_id,
      supplier_company_no: data.supplier_company_no,
      carrier_type_id: data.carrier_type_id,
      loading_status_id: data.loading_status_id,
      process_status_id: data.process_status_id,
    };
    return inslugData;
  };

  function extractSelectedFields(data) {
    console.log("result öncesi");
    console.log(data);
    const result = data.map(item => {
      return {
        id: item.id,
        client_product_id: item.client_product_id || null,
        container_no: item.container_no || null,
        leverage_charge: item.leverage_charge || null,
        extern_party_no: item.extern_party_no || null,
        description: item.description || null,
        count: parseInt(item.count, 10) || null,
      };
    });
    console.log("result sonrası");
    console.log(result);
    return result;
  }

  const renderProductLabel = (option) => {
    return (
      <div className="d-flex align-items-center">
        <Badge color="info" pill>{option.clientNo}</Badge>
        <Badge color="warning" pill className="mx-2">{option.productNo}</Badge>
        <Badge color="success" pill>{option.originCountry}</Badge>
      </div>
    );
  };

  return (
    <Card className="p-4 rounded-lg">
      <div className="d-flex mb-3 justify-content-between">
        <h5 className='d-flex text-primary align-items-center'>{props.t("Product Information")}</h5>
        <div>
          <Button className="btn btn-md mx-2" color="success" onClick={addNewRow}>+</Button>
        </div>
      </div>
      <FormGroup>
        <Table>
          <thead>
            <tr>
              <th>
                {props.t("Company Product")}
                {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_09")} props={props} /> */}
              </th>
              <th>{props.t("Container No")}</th>
              <th>{props.t("Leverage Charge")}</th>
              <th>{props.t("Extern Party No")}</th>
              <th>{props.t("Description")}</th>
              <th>{props.t("Product Count")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {updateInslugItemsData.map((item, index) => (
              <tr key={index}>
                <td>
                  {/* {hasViewPermission(permissions, "DE_09") ? */}
                  <DynamicCreatableSelect
                    name="client_product_id"
                    value={clientUpdateProducts.find(option => option.value === item.client_product_id)}
                    options={clientUpdateProducts.filter(product => product.clientNo === updateInslugData.client_no)} // Filtreleme işlemi
                    onChange={(selectedOption) => handleSelectChange(index, 'client_product_id', selectedOption)}
                    onCreateOption={(inputValue) => handleCreateOption(inputValue, index)}
                    placeholder={props.t("Select company product")}
                    noOptionsMessage={() => props.t("no-option-message")}
                    minWidth={220}
                    getOptionLabel={renderProductLabel} // Görsel olarak etiketi şekillendir
                    onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                    filterOption={(option, inputValue) => {
                      const productNo = option.data.productNo || "";
                      const productName = option.data.productName || "";
                      const productNoMatch = productNo.toLowerCase().includes(inputValue.toLowerCase());
                      const productNameMatch = productName.toLowerCase().includes(inputValue.toLowerCase());

                      return productNoMatch || productNameMatch;
                    }}
                  />
                  {/* :
                  <NoAuthUpdateInput data={clientUpdateProducts.find(option => option.value === item.client_product_id)?.productCategoryName} />
                } */}
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="container_no"
                    value={item.container_no}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter container no")}
                    required
                    minLength={3}
                    maxLength={32}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="leverage_charge"
                    value={item.leverage_charge}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter leverage charge information")}
                    minLength={3}
                    maxLength={32}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="extern_party_no"
                    value={item.extern_party_no}
                    placeholder={props.t("Enter extern party no")}
                    onChange={(e) => handleInputChange(index, e)}
                    minLength={3}
                    maxLength={32}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="description"
                    value={item.description}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter description")}
                    minLength={3}
                    maxLength={255}
                  />
                </td>
                <td className='px-1 m-0'>
                  <DynamicSelect
                    id="count"
                    name="count"
                    value={item.count ? { value: item.count, label: item.count } : null}
                    placeholder={props.t("Quantity")}
                    minWidth={135}
                    isSearchable={true} // Arama işlevini aktif eder
                    options={Array.from({ length: 100 }, (_, i) => ({
                      value: i + 1,
                      label: i + 1,
                    }))}
                    filterOption={(option, inputValue) =>
                      option.label.toString().includes(inputValue)
                    }
                    onChange={(e) => handleCountChange(index, e)}
                  />
                </td>
                <td>
                  <div className='d-flex justify-content-center align-items-center'>
                    <Button className="btn btn-md my-2" color="danger" onClick={() => removeIndexRow(index)}>-</Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </FormGroup>
      <div className="text-end">
        <Button onClick={() => handleSubmitData()} className="btn btn-md" color="warning">
          {props.t("Update")}
        </Button>
      </div>
    </Card>
  );
};

UpdateInslugItem.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateInslugItem)