// src/store/cityUpdate/cityUpdateActions.js
import { SET_USER_LIST_UPDATE_DATA, CLEAR_USER_LIST_UPDATE_DATA } from './actionTypes';

export const setUserListFormData = (formData) => ({
  type: SET_USER_LIST_UPDATE_DATA,
  payload: formData,
});

export const clearUserListFormData = () => ({
  type: CLEAR_USER_LIST_UPDATE_DATA,
});
