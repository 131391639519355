// src/store/form/action.js
import { SET_PRODUCT_GROUP_FORM_DATA, CLEAR_PRODUCT_GROUP_FORM_DATA } from './actionTypes';

// Form verilerini set etmek
export const setProductGroupFormData = (formData) => ({
  type: SET_PRODUCT_GROUP_FORM_DATA,
  payload: formData,
});

// Form verilerini temizlemek
export const clearProductGroupFormData = () => ({
  type: CLEAR_PRODUCT_GROUP_FORM_DATA,
});
