// src/store/selectedCountry/selectedCountryActions.js
import { SET_BUTTON_LOADING, CLEAR_BUTTON_LOADING } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setButtonLoading = () => ({
  type: SET_BUTTON_LOADING,
});

// Seçilen ülkeyi temizlemek
export const clearButtonLoading = () => ({
  type: CLEAR_BUTTON_LOADING,
});
