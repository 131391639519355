import { SET_CURRENT_FORM_DATA, CLEAR_CURRENT_FORM_DATA } from './actionTypes';

export const setCurrentFormData = (formData) => ({
  type: SET_CURRENT_FORM_DATA,
  payload: formData,
});

export const clearCurrentFormData = () => ({
  type: CLEAR_CURRENT_FORM_DATA,
});
