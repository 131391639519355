import React, { useEffect, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { getCompanies } from 'service/depot/companyService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { openModal, closeModal } from 'store/modal/actions';
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import DynamicButton from 'components/Common/Button/dynamicButton';
import { createUitslug, getUitslugsByCompany } from 'service/depot/uitslugService';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import { getMultiDefinitions } from 'service/Definitions/definitionsService';
import { setStockFilterFormData } from 'store/depot/stockFilterForm/action';
import { clearStockItemList, setStockItemList } from 'store/depot/stockItemList/action';
import { getFilteredStocks } from 'service/depot/stockService';
import { createProduct, getProducts } from 'service/depot/productService';

const StockFilter = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const stockFilterFormData = useSelector((state) => state.stockFilterFormData?.formData);
  const [companies, setCompanies] = useState([]);
  const [products, setProducts] = useState([]); // Kategorileri tutacak state
  const [defaultDate, setDefaultDate] = useState("");

  const md = 6;
  const lg = 6;
  const xl = 6;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(clearStockItemList());
      try {
        const productList = await getProducts(); // Kategorileri çekiyoruz
        setProducts(productList);
        const companies = await getCompanies();

         /*  const clients = [
            { client_no: "101", name: "John Doe" },
            { client_no: "102", name: "Jane Smith" },
            { client_no: "103", name: "Alice Johnson" },
            { client_no: "104", name: "Bob Brown" },
            { client_no: "105", name: "John Doe 2" },
            { client_no: "106", name: "Jane Smith 3" },
            { client_no: "107", name: "Alice Johnson 4" },
            { client_no: "108", name: "Bob Brown 5" },
            { client_no: "109", name: "John Doe" },
            { client_no: "1110", name: "Jane Smith" },
            { client_no: "113", name: "Alice Johnson" },
            { client_no: "114", name: "Bob Brown" },
            { client_no: "115", name: "John Doe 2" },
            { client_no: "116", name: "Jane Smith 3" },
            { client_no: "117", name: "Alice Johnson 4" },
            { client_no: "118", name: "Bob Brown 5" },
          ]; */
          
        setCompanies(companies);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setStockFilterFormData({ ...stockFilterFormData, [name]: value != "" ? value.toLocaleUpperCase("tr") : null }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataByCompany = await getFilteredStocks({ client_no: stockFilterFormData.client_no });
        console.log("uitslug listesi");
        console.log(dataByCompany);
        dispatch(setStockItemList(dataByCompany));
      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
    console.log("uitslugFormData");
    console.log(stockFilterFormData);
  }, [stockFilterFormData]);

  const handleSelectChange = (field, selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    console.log("value : " + value);
    dispatch(setStockFilterFormData({ ...stockFilterFormData, [field]: value }));
  };

  const handleChangeMainCompany = async (field, selectedOption) => {
    if (selectedOption) {
      const value = selectedOption.value;
      dispatch(setStockFilterFormData({ [field]: value }));
    } else {
      dispatch(setStockFilterFormData({ [field]: null }));
      dispatch(clearStockItemList());

    }
  };

  const handleCreateProduct = (inputValue) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: <span> {props.t("modal-save-body")}</span>,
        onConfirm: async () => {
          const newProduct = {
            product_no: Math.random().toString(36).substr(2, 8).toUpperCase(),
            name: inputValue,
          };

          await createProduct(newProduct)
            .then(async (addedProduct) => {
              const newProductList = await getProducts();

              setProducts(newProductList);
              dispatch(setStockFilterFormData({ ...stockFilterFormData, product_no: addedProduct.product_no }));
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
        }
      }));
  }

  return (
    <Card className='p-4 rounded-lg'>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h5 className="text-primary">{props.t("Filter")}</h5>
      </div>
      <Row form>
        <Col md={md} lg={lg} xl={xl}>
          <FormGroup>
            <Label for="client_no">{props.t("Company No / Company Name")}</Label>
            <DynamicSelect
              options={companies.map((company) => ({
                value: company.client_no,
                label: company.name,
              }))}
              value={
                companies.find(
                  (company) => company.client_no === stockFilterFormData.client_no
                )
                  ? {
                    value: companies.find(
                      (company) => company.client_no === stockFilterFormData.client_no
                    ).client_no,
                    label: companies.find(
                      (company) => company.client_no === stockFilterFormData.client_no
                    ).name,
                  }
                  : null
              }
              onChange={(selected) => {
                handleChangeMainCompany('client_no', selected);
              }}
              placeholder={props.t("Select a company")}
              isClearable
              required
              noOptionsMessage={() => props.t("no-option-message")}
              onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
              filterOption={(option, inputValue) => {
                const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                return clientNoMatch || companyNameMatch;
              }}
            />
          </FormGroup>
        </Col>

        {/* <Col md={md} xl={xl}>
          <FormGroup>
            <Label for="product_no">{props.t("Product Information")}</Label>
            <DynamicSelect
              options={products.map((product) => ({
                value: product.product_no,
                label: product.name,  
              }))}
              value={
                products.find(
                  (company) => company.product_no === stockFilterFormData.product_no
                )
                  ? {
                    value: products.find(
                      (company) => company.product_no === stockFilterFormData.product_no
                    ).product_no,
                    label: products.find(
                      (company) => company.product_no === stockFilterFormData.product_no
                    ).name,
                  }
                  : null
              }
              onChange={(selected) => handleSelectChange("product_no", selected)} // Kategori seçimi
              onCreateOption={async (inputValue) => { handleCreateProduct(inputValue.toLocaleUpperCase("tr")) }}
              placeholder={props.t("Select product")}
              isClearable
              required
              noOptionsMessage={() => props.t("no-option-message")}
              onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
              formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue.toLocaleUpperCase("tr")}`} // Yeni seçenek ekleme mesajı
              filterOption={(option, inputValue) => {
                const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                return clientNoMatch || companyNameMatch;
              }}
            />
          </FormGroup>
        </Col> */}

        {/* <Col md={md} lg={lg} xl={xl}>
          <FormGroup>
            <Label for="carrier_type_id">{props.t("Carrier Type")}</Label>
            <DynamicSelect
              options={carrierTypes.map((carrier) => ({
                value: carrier.id,
                label: carrier.name,
              }))}
              value={
                carrierTypes.find(
                  (status) => status.id === stockFilterFormData.carrier_type_id // Eşleşen durumu bul
                )
                  ? {
                    value: carrierTypes.find(
                      (status) => status.id === stockFilterFormData.carrier_type_id
                    ).id, // value'da id değerini kullanıyoruz
                    label: carrierTypes.find(
                      (status) => status.id === stockFilterFormData.carrier_type_id
                    ).name, // label'da name değerini kullanıyoruz
                  }
                  : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
              }
              noOptionsMessage={() => props.t("no-option-message")}
              onChange={(selected) =>
                handleSelectChange('carrier_type_id', selected)
              }
              placeholder={props.t("Select carrier type")}
              isClearable
            />
          </FormGroup>
        </Col>

        <Col md={md} lg={lg} xl={xl}>
          <FormGroup>
            <Label for="license_plate">{props.t("License Plate")}</Label>
            <DynamicInput
              type="text"
              id="license_plate"
              name="license_plate"
              value={stockFilterFormData.license_plate}
              placeholder={props.t("Enter license plate")}
              onChange={handleInputChange}
              minLength={3}
              maxLength={24}
            />
          </FormGroup>
        </Col>
        <Col md={md} lg={lg} xl={xl}>
          <FormGroup>
            <Label for="driver_name">{props.t("Driver Name")}</Label>
            <DynamicInput
              type="text"
              id="driver_name"
              name="driver_name"
              value={stockFilterFormData.driver_name}
              placeholder={props.t("Enter driver name")}
              onChange={handleInputChange}
              minLength={3}
              maxLength={24}
            />
          </FormGroup>
        </Col>

        <Col md={md} lg={lg} xl={xl}>
          <FormGroup>
            <Label for="booking_date">{props.t("Booking Date")}</Label>
            <DynamicInput
              type="date"
              id="booking_date"
              name="booking_date"
              value={stockFilterFormData.booking_date || defaultDate} // Eğer Redux'ta başka bir tarih yoksa defaultDate kullan
              onChange={handleInputChange}
              onClick={(e) => e.target.showPicker()} // Tıklanınca tarih seçiciyi aç

            />
          </FormGroup>
        </Col>

        <Col md={md} lg={lg} xl={xl}>
          <FormGroup>
            <Label for="description">{props.t("Description")}</Label>
            <DynamicInput
              type="text"
              id="description"
              name="description"
              value={stockFilterFormData.description}
              placeholder={props.t("Enter description")}
              onChange={handleInputChange}
              minLength={3}
              maxLength={255}
            />
          </FormGroup>
        </Col>

        <Col md={md} lg={lg} xl={xl}>
          <FormGroup>
            <Label for="customs_status_id">{props.t("Customs Status")}</Label>
            <DynamicSelect
              options={customsStatuses.map((status) => ({
                value: status.id,  // value, id değerini içerir
                label: status.name, // label, name değerini içerir
              }))}
              value={
                customsStatuses.find(
                  (status) => status.id === stockFilterFormData.customs_status_id // Eşleşen durumu bul
                )
                  ? {
                    value: customsStatuses.find(
                      (status) => status.id === stockFilterFormData.customs_status_id
                    ).id, // value'da id değerini kullanıyoruz
                    label: customsStatuses.find(
                      (status) => status.id === stockFilterFormData.customs_status_id
                    ).name, // label'da name değerini kullanıyoruz
                  }
                  : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
              }
              noOptionsMessage={() => props.t("no-option-message")}
              onChange={(selected) => {
                console.log("onChange çalıştı");
                console.log(selected);

                handleSelectChange("customs_status_id", selected); // Seçilen id'yi işliyoruz
              }}
              placeholder={props.t("Select customs status")}
              isClearable
            />

          </FormGroup>
        </Col>

        <Col md={md} lg={lg} xl={xl}>
          <FormGroup>
            <Label for="loading_status_id">{props.t("Loading Status")}</Label>
            <DynamicSelect
              options={loadingStatuses.map((status) => ({
                value: status.id,
                label: status.name,
              }))}
              value={
                loadingStatuses.find(
                  (status) => status.id === stockFilterFormData.loading_status_id // Eşleşen durumu bul
                )
                  ? {
                    value: loadingStatuses.find(
                      (status) => status.id === stockFilterFormData.loading_status_id
                    ).id, // value'da id değerini kullanıyoruz
                    label: loadingStatuses.find(
                      (status) => status.id === stockFilterFormData.loading_status_id
                    ).name, // label'da name değerini kullanıyoruz
                  }
                  : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
              }
              noOptionsMessage={() => props.t("no-option-message")}
              onChange={(selected) =>
                handleSelectChange('loading_status_id', selected)
              }
              placeholder={props.t("Select loading status")}
              isClearable
            />
          </FormGroup>
        </Col> */}

      </Row>
      <AcceptModal />
    </Card>
  );
};

StockFilter.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(StockFilter)