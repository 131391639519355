import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";

// Videoları dinamik olarak yükle ve tekrarları kaldır
const importAllVideos = (r) => {
  const videoSet = new Set();
  return r.keys()
    .map((fileName) => ({
      name: fileName.replace("./", "").split(".")[0], // Video adı (dosya adı)
      path: r(fileName), // Video yolu
    }))
    .filter((video) => {
      if (videoSet.has(video.path)) {
        return false; // Eğer yol zaten varsa dahil etme
      }
      videoSet.add(video.path);
      return true;
    });
};



const videos = importAllVideos(require.context("../../assets/videos", false, /\.(mp4|webm|ogg)$/));

const Guide = (props) => {
  const [videoDurations, setVideoDurations] = useState({}); // Videoların sürelerini tutar
  const [selectedVideo, setSelectedVideo] = useState(null); // Sağ tarafta oynatılacak videoyu tutar

  useEffect(() => {
    // Video süresi hesaplandığında state güncellenir
    videos.forEach((video) => {
      const videoElement = document.createElement("video");
      videoElement.src = video.path;
      videoElement.onloadedmetadata = () => {
        setVideoDurations((prevDurations) => ({
          ...prevDurations,
          [video.name]: formatDuration(videoElement.duration),
        }));
      };
    });
  }, []);

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  // Süreyi dakikalar ve saniyeler formatına dönüştürme
  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  // Tablo için sütun tanımları
  const columns = [
    /*     {
          name: props.t("ID"), // ID sütunu
          selector: (row, index) => index + 1, // Satır sırasına göre numaralandırma (1'den başlayarak)
          sortable: false, // Sıralama yapılmaz
          left: true, // Sola hizalama
        }, */
    {
      name: props.t("ID"), // ID sütunu
      selector: (row) => {
        const nameParts = row.name.split(" "); // Boşluklara göre parçala
        const rawId = nameParts[0]; // İlk kısmı al
        const cleanedId = rawId.replace(/^0+/, ""); // Başındaki sıfırları kaldır
        return cleanedId;
      },
      sortable: false, // Sıralama yapılmaz
      left: true, // Sola hizalama
      minWidth: "60px", // Minimum genişlik
      maxWidth: "60px", // Maksimum genişlik
      wrap: true, // Metni sarma
    },


    {
      name: props.t("Name"),
      selector: (row) => {
        const nameParts = row.name.split(" "); // Boşluklara göre parçala
        nameParts.shift(); // İlk kısmı çıkar
        return props.t(nameParts.join(" ")); // Geriye kalanları birleştir ve döndür
      },
      sortable: true,
      left: true, // Sola hizalama
    },

    {
      name: props.t("Duration"),
      sortable: true,

      selector: (row) => videoDurations[row.name] || props.t("Loading..."),
    },
    {
      name: props.t("Watch"),

      cell: (row) => (
        <button
          className={`btn btn-sm ${selectedVideo === row.path ? "btn-success" : "btn-primary"
            }`}
          onClick={() => setSelectedVideo(row.path)}
        >
          {props.t("Watch")}
        </button>
      ),
    },
  ];

  return (

    <div>
      <h5 className="text-primary">{props.t("Guide Video List")}</h5>
      <div className="container-fluid">
        <div className="row">
          {/* Sol Bölüm */}
          <div className="col-md-5" style={{ textAlign: "left" }}>

            <DataTable
              style={{
                border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
              }}
              columns={columns}
              data={videos}
              pagination
              highlightOnHover
              noDataComponent={props.t("No data available")}
              paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
              paginationPerPage={20}
            />
          </div>

          {/* Sağ Bölüm */}
          <div className="col-md-7">
            {selectedVideo ? (
              <video
                key={selectedVideo} // Her seçimde yeni bir <video> oluşturulur
                width="100%"
                height="auto"
                controls
              >
                <source src={selectedVideo} type="video/mp4" />
                {props.t("Your browser does not support the video tag.")}
              </video>
            ) : (
              <p>{props.t("Select a video to watch")}</p>
            )}
          </div>
        </div>
      </div>
    </div>


  );
};

Guide.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(Guide);
