import React from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap"
import Dropzone from "react-dropzone"

import { Link } from "react-router-dom"
import { clearSelectedImage, setSelectedImage } from "store/depot/selectedImage/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"


const FormUploadItem = props => {
    const dispatch = useDispatch();
    const selectedImage = useSelector((state) => state.selectedImage?.selectedImage || []);

    function handleAcceptedFiles(files) {
        console.log(files);
        files = files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        dispatch(setSelectedImage(files));
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const handleRemoveFile = () => {
        dispatch(clearSelectedImage());
    };

    return (
        <Row>
            <Col className="col-12">
                <Card>
                    <CardBody>
                        <Dropzone
                            accept={{ "image/png": [".png"] }}
                            multiple={false}
                            onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone dz-clickable">
                                    <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                            <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                                        </div>
                                        <h4>{props.t("Drop Files")}</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedImage.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                    >
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                </Col>
                                                <Col className="col-auto">
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        onClick={handleRemoveFile}
                                                    >
                                                        -
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                )
                            })}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

FormUploadItem.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(FormUploadItem)