import { SET_HELEVEN_FORM_DATA, CLEAR_HELEVEN_FORM_DATA } from './actionTypes';

export const setHelevenFormData = (formData) => ({
  type: SET_HELEVEN_FORM_DATA,
  payload: formData,
});

export const clearHelevenFormData = () => ({
  type: CLEAR_HELEVEN_FORM_DATA,
});
