// src/store/selectedCountry/selectedCountryReducer.js
import { SET_SELECTED_COUNTRY, CLEAR_SELECTED_COUNTRY } from './actionTypes';

const initialState = {
  selectedCountry: null, // Başlangıçta seçilen ülke boş
};

const selectedCountryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
      };

    case CLEAR_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: null,
      };

    default:
      return state;
  }
};

export default selectedCountryReducer;
