import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { deleteClientProduct, getClientProducts, updateClientProduct, } from 'service/depot/clientProductService'; // API functions
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import { setClientProducts } from 'store/depot/clientProduct/action';
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { setClientProductFormData } from 'store/updates/clientProductForm/action';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateclientProductForm from './updateclientProductForm';
import UpdateModal from 'components/Common/Modals/updateModal';
import { getProducts } from 'service/depot/productService';
import { getCompanies } from 'service/depot/companyService';
import { clearSelectedProduct, setSelectedProduct } from 'store/depot/selectedProduct/action';
import { clearSelectedCompany, setSelectedCompany } from 'store/depot/selectedCompany/action';
import { getCountries } from 'service/Definitions/countryService';
import { clearSelectedCountry, setSelectedCountry } from 'store/depot/selectedCountry/action';
import { hasDeletePermission, hasExcelPermission, hasUpdatePermission, hasViewPermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import DetailsButton from 'components/Common/Button/detailsButton';
import { useNavigate } from 'react-router-dom';

const ClientProductList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState(''); // State for search text
  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  const clientProducts = useSelector((state) => state.clientProducts?.clientProducts || []);
  const [filteredClientProducts, setFilteredClientProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientProductList = await getClientProducts(); // Fetch companies from API
        console.log("112748121");
        console.log(clientProductList);
        dispatch(setClientProducts(clientProductList));  // Dispatch to Redux store
        setFilteredClientProducts(clientProductList);
        console.log("filtered Client Product List");
        console.log(clientProductList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    const fetchCategories = async () => {
      try {
        const productList = await getProducts(); // Kategorileri çekiyoruz
        setProducts(productList); // Kategorileri state'e set ediyoruz
        const companyList = await getCompanies(); // Kategorileri çekiyoruz
        setCompanies(companyList); // Kategorileri state'e set ediyoruz
        const countryList = await getCountries(); // Kategorileri çekiyoruz
        setCountries(countryList);
      } catch (error) {
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
    fetchCategories();
  }, [dispatch]);


  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    console.log("filteredelecek company");
    console.log(clientProducts);
    const filteredData = clientProducts.filter((product) =>
      product.product_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.product_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.client_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.company_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.extern_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.origin_country?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.production_date?.toLowerCase().includes(searchText.toLowerCase())

    );
    setFilteredClientProducts(filteredData);
  }, [searchText, clientProducts]);


  const formatDateForInput = (isoDate) => {
    if (!isoDate) {
      return null;
    }
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Handle editing row and open modal
  const handleEdit = async (id) => {
    const selectedData = filteredClientProducts.find((data) => data.id === id);
    const selectedProduct = products.find(data => data.product_no === selectedData.product_no);
    const selectedCompany = companies.find(data => data.client_no === selectedData.client_no);
    const selectedCountry = countries.find(data => data.id === selectedData.country_id);
    const finalSelectedData = { production_date: formatDateForInput, ...selectedData };
    dispatch(setClientProductFormData(finalSelectedData));
    selectedProduct ? dispatch(setSelectedProduct({ value: selectedProduct.product_no, label: selectedProduct.name }))
      : dispatch(clearSelectedProduct());
    selectedCompany ? dispatch(setSelectedCompany({ value: selectedCompany.client_no, label: selectedCompany.name }))
      : dispatch(clearSelectedCompany());
    selectedCountry ? dispatch(setSelectedCountry({ value: selectedCountry.id, label: selectedCountry.name }))
      : dispatch(clearSelectedCountry());
    dispatch(
      openUpdateModal({
        body: (
          <UpdateclientProductForm />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteClientProduct(id); // Delete company
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const updatedProducts = await getClientProducts(); // Fetch updated companies
            dispatch(setClientProducts(updatedProducts)); // Update Redux state
            setFilteredClientProducts(updatedProducts); // Update filtered companies
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const exportToExcel = () => {
    const column1Name = props.t("Company No");
    const companyName = props.t("Company Name");
    const column2Name = props.t("Product No");
    const productName = props.t("Product Name");
    const column3Name = props.t("Extern No");
    const column5Name = props.t("Production Date");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredClientProducts.map(itemClientProduct => ({
      [column1Name]: itemClientProduct.client_no,
      [companyName]: itemClientProduct.company_name,
      [column2Name]: itemClientProduct.product_no,
      [productName]: itemClientProduct.product_name,
      [column3Name]: itemClientProduct.extern_no,
      [column5Name]: new Date(itemClientProduct.production_date).toLocaleDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Company Products"));
    XLSX.writeFile(workbook, props.t("Company Products") + ".xlsx");
  };

  const handleViewDetails = (id) => {
    navigate(`/client-product/${id}`);
  }

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const columns = [
    {
      name: props.t("Company Name"),
      selector: (row, rowIndex) => row.company_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Name"),
      selector: (row, rowIndex) => row.product_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Extern No"),
      selector: (row, rowIndex) =>
        row.extern_no,
      sortable: true,
      wrap: true,
    },
/*     {
      name: props.t("Origin Country"),
      selector: (row, rowIndex) =>
        row.country_name,
      sortable: true,
      wrap: true,
    }, */
    {
      name: props.t("Production Date"),
      selector: (row, rowIndex) =>
        row.production_date ? new Date(row.production_date).toLocaleDateString() : "",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        <>
          {hasViewPermission(permissions, "DE_09") ? <DetailsButton disabled={false} onClick={() => handleViewDetails(row.id)} /> : <DetailsButton disabled={true} />}
          {hasUpdatePermission(permissions, "DE_09") ? <EditButton disabled={false} onClick={() => handleEdit(row.id)} /> : <EditButton disabled={true} />}
          {hasDeletePermission(permissions, "DE_09") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} />}
        </>
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };
  return (
    <div className="my-4">
      <h5 className="text-primary mb-4">{props.t("Company Product List")}</h5>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredClientProducts}
        customStyles={customStyles}
        pagination
        highlightOnHover
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_09") && filteredClientProducts.length > 0 ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
              maxLength={32}
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

ClientProductList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ClientProductList)