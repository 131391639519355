// src/store/selectedCountry/selectedCountryActions.js
import { SET_SELECTED_CITY, CLEAR_SELECTED_CITY } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setSelectedCity = (city) => ({
  type: SET_SELECTED_CITY,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedCity = () => ({
  type: CLEAR_SELECTED_CITY,
});
