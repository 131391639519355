import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import { Button, CardBody, Input } from 'reactstrap';
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { hasCreatePermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { getInslugQrs } from 'service/depot/inslugService';
import { FaPrint } from 'react-icons/fa';

const InlsugQrList = props => {
  const { id } = useParams();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [inslugQrData, setInslugQrData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInslugData = async () => {
      try {
        const inslugQrList = await getInslugQrs(id);
        console.log("inslugList");
        console.log(inslugQrList);
        setInslugQrData(inslugQrList);
        setFilteredData(inslugQrList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchInslugData();
  }, []);

  useEffect(() => {
    const filtered = inslugQrData.filter((item) => {
      const matchesSearchText = (
        (item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.count && item.count.toLowerCase().includes(searchText.toLowerCase()))
      );
      return matchesSearchText;
    });
    setFilteredData(filtered);
  }, [searchText, inslugQrData]);

  const columns = [
    { name: props.t("Product Name"), selector: row => row.name || props.t("-"), sortable: true },
    {
      name: props.t("Total Product Count"),
      cell: row => (
        <>
          <span>{row.count}</span>
          <Button color="link" onClick={() => { }} className='py-0'>
            <FaPrint color={"#3C7542"} size={18} />
          </Button>
        </>
      )
    },
    {
      name: props.t("Not Printed Product Count"),
      cell: row => (
        <>
          <span>{(row.qr.filter(data => data.is_printed === false)).length}</span>
          <Button color="link" onClick={() => { }} className='py-0'>
            <FaPrint color={"#F2A60D"} size={18} />
          </Button>
        </>
      )
    },
  ];

  const exportToExcel = () => {
    const columnNames = {
      client_no: props.t("Company No"),
      company_name: props.t("Company Name"),
      booking_date: props.t("Booking Date"),
      eta: props.t("ETA"),
      closing_in: props.t("Closing In"),
      closing_out: props.t("Closing Out"),
    };

    const selectedColumns = inslugQrData.map(item => ({
      [columnNames.client_no]: item.client_no,
      [columnNames.company_name]: item.company_name,
      [columnNames.booking_date]: new Date(item.booking_date).toLocaleDateString(),
      [columnNames.eta]: new Date(item.eta).toLocaleDateString(),
      [columnNames.closing_in]: new Date(item.closing_in).toLocaleDateString(),
      [columnNames.closing_out]: new Date(item.closing_out).toLocaleDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Inslug QR Code List"));
    XLSX.writeFile(workbook, props.t("Inslug QR Code List") + ".xlsx");
  };

  const excelIcon = (
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>
  );

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  return (
    <CardBody>
      <div>
        <div className='d-flex align-items-center justify-content-between mb-3 p-2'>
          <h5 className="text-primary">{props.t("Inslug QR Code List")}</h5>
          {hasCreatePermission(permissions, "DE_03") ?
            <Link to={"/inslug/create"}>
              <Button className="btn btn-md mx-2" color="success">+</Button>
            </Link>
            : <Button className="btn btn-md mx-2" disabled color="secondary">+</Button>}
        </div>
        <DataTable
          subHeader
          customStyles={customStyles}
          noDataComponent={props.t("table-empty-message")}
          paginationComponentOptions={customPaginationOptions}
          subHeaderComponent={
            <div className='d-flex justify-content-end w-75'>
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))}
                className="form-control w-50"
                maxLength={32}
              />
            </div>
          }
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          columns={columns}
          data={filteredData}
          pagination
          className='p-3'
          highlightOnHover
        />
      </div>
      <AcceptModal />
    </CardBody>
  );
};

InlsugQrList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(InlsugQrList);
