// reducer.js
import {
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  SET_COMPANY,
} from './actionTypes';

const initialState = {
  companies: [],
  error: null,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        error: null,
      };

    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_COMPANY_SUCCESS:
      const updatedCompanies = state.companies.map((company) =>
        company.id === action.payload.id ? action.payload.companyData : company
      );
      return {
        ...state,
        companies: updatedCompanies,
      };

    case DELETE_COMPANY_SUCCESS:
      const filteredCompanies = state.companies.filter((company) => company.id !== action.payload);
      return {
        ...state,
        companies: filteredCompanies,
      };
      
      case SET_COMPANY:
        return {
          ...state,
          companies: action.payload,  // Payload doğrudan companies array'i olacak
        };

    default:
      return state;
  }
};

export default companyReducer;
