// src/store/form/action.js
import { SET_UPDATE_COMPANY_FORM_DATA, CLEAR_UPDATE_COMPANY_FORM_DATA } from './actionTypes';

// Form verilerini set etmek
export const setCompanyFormData = (formData) => ({
  type: SET_UPDATE_COMPANY_FORM_DATA,
  payload: formData,
});

// Form verilerini temizlemek
export const clearCompanyFormData = () => ({
  type: CLEAR_UPDATE_COMPANY_FORM_DATA,
});
