// reducer.js
import {
  UPDATE_CARRIER_TYPE_SUCCESS,
  DELETE_CARRIER_TYPE_SUCCESS,
  SET_CARRIER_TYPE,
} from './actionTypes';

const initialState = {
  carrierTypes: [],
  error: null,
};

const carrierTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CARRIER_TYPE_SUCCESS:
      const updatedCustomsStatus = state.customsStatus.map((country) =>
      country.id === action.payload.id ? action.payload.data : country
      );
      return {
        ...state,
        carrierTypes: updatedCustomsStatus,
      };

    case DELETE_CARRIER_TYPE_SUCCESS:
      const filteredCustomsStatus = state.customsStatus.filter((country) => country.id !== action.payload);
      return {
        ...state,
        carrierTypes: filteredCustomsStatus,
      };

    case SET_CARRIER_TYPE:
      return {
        ...state,
        carrierTypes: action.payload, 
      };

    default:
      return state;
  }
};

export default carrierTypesReducer;
