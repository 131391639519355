import { SET_SELECTED_PRODUCT, RESET_SELECTED_PRODUCTS } from './actionTypes';

// Action to set selected Inslug data
export const setSelectedProducts = (product) => {
  return {
    type: SET_SELECTED_PRODUCT,
    payload: product,
  };
};

export const resetSelectedProducts = () => ({
  type: RESET_SELECTED_PRODUCTS,
});