import { SET_CREATE_CLIENT_PRODUCT_FORM_DATA, CLEAR_CREATE_PRODUCT_FORM_DATA } from './actionTypes';

export const setCreateClientProductFormData = (formData) => ({
  type: SET_CREATE_CLIENT_PRODUCT_FORM_DATA,
  payload: formData,
});

export const clearCreateClientProductFormData = () => ({
  type: CLEAR_CREATE_PRODUCT_FORM_DATA,
});
