// src/store/selectedCountry/selectedCountryActions.js
import { SET_SELECTED_EXTERN_CITY, CLEAR_SELECTED_EXTERN_CITY } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setSelectedExternCity = (city) => ({
  type: SET_SELECTED_EXTERN_CITY,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedExternCity = () => ({
  type: CLEAR_SELECTED_EXTERN_CITY,
});
