// reducer.js
import {
  SET_FILTERED_COMPANIES,
  CLEAR_FILTERED_COMPANIES
} from './actionTypes';

const initialState = {
  filteredCompanies: [],
};

const filteredCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERED_COMPANIES:
      return {
        ...state,
        filteredCompanies: action.payload,
      };

    case CLEAR_FILTERED_COMPANIES:
      return {
        ...state,
        filteredCompanies: null,
      };

    default:
      return state;
  }
};

export default filteredCompanyReducer;
