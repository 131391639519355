import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { getStatistics } from 'service/depot/mainMenuService';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { hasViewPermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
const Miniwidget = props => {
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [reports, setReports] = useState(); // Row being edited

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statistics = await getStatistics(); // Fetch companies from API
        console.log("statistics");
        console.log(statistics);
        const reportsData = [
          { title: "Inslug", iconClass: "cube-outline", total: statistics.inslug, average: "+11%", badgecolor: "success", link: "/inslugs", permission:"DE_00" },
          { title: "Company", iconClass: "buffer", total: statistics.company, average: "-29%", badgecolor: "danger", link: "/company", permission:"DE_01" },
          { title: "Product", iconClass: "tag-text-outline", total: statistics.product, average: "0%", badgecolor: "warning", link: "/product", permission:"DE_06" },
          { title: "Product Category", iconClass: "briefcase-check", total: statistics.productCategory, average: "+89%", badgecolor: "info", link: "/product-group", permission:"DE_07" },
        ]
        setReports(reportsData);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };
    fetchData();
  }, []);


  return (
    <React.Fragment>
      <Row>
        {reports?.map((report, key) => (
          <Col xl={3} sm={6} key={key}>
            {hasViewPermission(permissions, report.permission) ?  
            <Link to={report.link}>
              <Card className="mini-stat bg-primary" style={{ maxHeight: "130px" }}>
                <CardBody className="card-body mini-stat-img">
                  <div className="mini-stat-icon">
                    <i className={"float-end mdi mdi-" + report.iconClass}></i>
                  </div>
                  <div className="text-white">
                    <h6 className="mb-3 font-size-16 text-white">{props.t(report.title).toLocaleUpperCase("tr")}</h6>
                    <h2 className="mb-4 text-white">{report.total}</h2>
                    {/*                   <span className={"badge bg-" + report.badgecolor}> {report.average} </span> <span className="ms-2">Geçen ay</span>
 */}                </div>
                </CardBody>
              </Card>
            </Link>
            : 
            <Card className="mini-stat bg-primary">
                <CardBody className="card-body mini-stat-img">
                  <div className="mini-stat-icon">
                    <i className={"float-end mdi mdi-" + report.iconClass}></i>
                  </div>
                  <div className="text-white">
                    <h6 className="mb-3 font-size-16 text-white">{props.t(report.title).toLocaleUpperCase("tr")}</h6>
                    <h2 className="mb-4 text-white">{report.total}</h2>
                    {/*                   <span className={"badge bg-" + report.badgecolor}> {report.average} </span> <span className="ms-2">Geçen ay</span>
 */}                </div>
                </CardBody>
              </Card>}
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )

}

Miniwidget.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(Miniwidget)