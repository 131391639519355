import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getDeletedClientProducts, restoreClientProduct, } from 'service/depot/clientProductService'; // API functions
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasDeletePermission, hasExcelPermission, hasRestorePermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import RestoreButton from 'components/Common/Button/restoreButton';

const TrashedClientProducts = props => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState(''); // State for search text
  const [trashedClientProducts, setTrashedClientProducts] = useState([]);
  const [filteredTrashedClientProducts, setFilteredTrashedClientProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientProductList = await getDeletedClientProducts(); // Fetch companies from API
        console.log("112748121");
        console.log(clientProductList);
        setTrashedClientProducts(clientProductList);  // Dispatch to Redux store
        setFilteredTrashedClientProducts(clientProductList);
        console.log("filtered Client Product List");
        console.log(clientProductList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);

  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    console.log("filteredelecek company");
    console.log(trashedClientProducts);
    const filteredData = trashedClientProducts.filter((product) =>
      product.product_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.product_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.client_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.company_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.extern_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.country_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      (new Date(product.production_date).toLocaleDateString().includes(searchText)) // Tarih formatında arama
    );
    setFilteredTrashedClientProducts(filteredData);
  }, [searchText, trashedClientProducts]);


    const formatDateForInput = (isoDate) => {
      if(!isoDate) {
        return null;
      }
      const date = new Date(isoDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          try {
            await restoreClientProduct(id); // Delete company
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });

            const newDeletedClientProducts = await getDeletedClientProducts(); // Fetch updated companies
            setTrashedClientProducts(newDeletedClientProducts); // Update Redux state
            setFilteredTrashedClientProducts(newDeletedClientProducts); // Update filtered companies
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const exportToExcel = () => {
    const column1Name = props.t("Company No");
    const companyName = props.t("Company Name");
    const column2Name = props.t("Product No");
    const productName = props.t("Product Name");
    const column3Name = props.t("Extern No");
    const column5Name = props.t("Production Date");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredTrashedClientProducts.map(itemClientProduct => ({
      [column1Name]: itemClientProduct.client_no, 
      [companyName]: itemClientProduct.company_name, 
      [column2Name]: itemClientProduct.product_no, 
      [productName]: itemClientProduct.product_name, 
      [column3Name]: itemClientProduct.extern_no, 
      [column5Name]: new Date(itemClientProduct.production_date).toLocaleDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Company Products"));
    XLSX.writeFile(workbook, props.t("Company Products") + ".xlsx");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const columns = [
/*     {
      name: props.t("Company No"),
      selector: (row, rowIndex) => row.client_no,
      sortable: true,
      wrap: true,
    }, */
    {
      name: props.t("Company Name"),
      selector: (row, rowIndex) => row.company_name,  
      sortable: true,
      wrap: true,
    },
/*     {
      name: props.t("Product No"),
      selector: (row, rowIndex) => row.product_no,
      sortable: true,
      wrap: true,
    }, */
    {
      name: props.t("Product Name"),
      selector: (row, rowIndex) => row.product_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Extern No"),
      selector: (row, rowIndex) => row.extern_no,
      sortable: true,
      wrap: true,
    },
/*     {
      name: props.t("Origin Country"),
      selector: (row, rowIndex) => row.country_name,
      sortable: true,
      wrap: true,
    }, */
    {
      name: props.t("Production Date"),
      selector: (row, rowIndex) => row.production_date ? new Date(row.production_date).toLocaleDateString() : "",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
          <>
          {hasRestorePermission(permissions, "DE_09") ? <RestoreButton onClick={() => handleRestore(row.id)} /> : <RestoreButton disabled={true} />}
          </>
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };
  return (
    <div className="my-4">
      <h5 className="text-primary mb-4">{props.t("Trashed Company Product List")}</h5>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredTrashedClientProducts}
        customStyles={customStyles}
        pagination
        highlightOnHover
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_09") && filteredTrashedClientProducts.length > 0 ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
              maxLength={32}
            />
          </div>
        }
      />

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

TrashedClientProducts.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(TrashedClientProducts)