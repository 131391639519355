// src/store/selectedCountry/selectedCountryReducer.js
import { SET_SELECTED_UITSLUG_ITEM_LIST, CLEAR_SELECTED_UITSLUG_ITEM_LIST, UPDATE_SELECTED_UITSLUG_ITEM } from './actionTypes';

const initialState = {
  selectedUitslugItemList: [], // Başlangıçta seçilen ülke boş
};

const selectedUitslugItemListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_UITSLUG_ITEM_LIST:
      return {
        ...state,
        selectedUitslugItemList: action.payload,
      };

    case CLEAR_SELECTED_UITSLUG_ITEM_LIST:
      return {
        ...state,
        selectedUitslugItemList: [],
      };
    case UPDATE_SELECTED_UITSLUG_ITEM:
      const { rowId, outputCount } = action.payload;
      return {
        ...state,
        selectedUitslugItemList: {
          ...state.selectedUitslugItemList,
          [rowId]: {
            ...state.selectedUitslugItemList[rowId],
            output_count: outputCount,
          },
        },
      };
    default:
      return state;
  }
};

export default selectedUitslugItemListReducer;
