// src/store/selectedCountry/selectedCountryActions.js
import { SET_SELECTED_COUNTRY, CLEAR_SELECTED_COUNTRY } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setSelectedCountry = (country) => ({
  type: SET_SELECTED_COUNTRY,
  payload: country,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedCountry = () => ({
  type: CLEAR_SELECTED_COUNTRY,
});
