// reducer.js
import {
  SET_DEPOTS,
  CLEAR_DEPOTS
} from './actionTypes';

const initialState = {
  depots: [],
};

const depotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPOTS:
      return {
        ...state,
        depots: action.payload,  // Payload doğrudan companies array'i olacak
      };

    case CLEAR_DEPOTS:
      return {
        ...state,
        depots: [],  // Payload doğrudan companies array'i olacak
      };

    default:
      return state;
  }
};

export default depotsReducer;
