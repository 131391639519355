import {
    UPDATE_PRODUCT_GROUP_SUCCESS,
    DELETE_PRODUCT_GROUP_SUCCESS,
    SET_PRODUCT_GROUP,
} from './actionTypes';

// Action to set fetched companies
export const setProductGroups = (data) => {
    return {
      type: SET_PRODUCT_GROUP,
      payload: data,
    };
  };

// Action to update a company
export const updateProductGroup = (id, productGroupData) => {
    return {
        type: UPDATE_PRODUCT_GROUP_SUCCESS,
        payload: { id, productData: productGroupData },
    };
};

// Action to delete a company
export const deleteProductGroup = (id) => {
    return {
        type: DELETE_PRODUCT_GROUP_SUCCESS,
        payload: id,
    };
};
