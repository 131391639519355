import {
    UPDATE_CITY_SUCCESS,
    DELETE_CITY_SUCCESS,
    SET_CITY,
} from './actionTypes';

// Action to set fetched companies
export const setCities = (data) => {
    return {
      type: SET_CITY,
      payload: data,
    };
  };

// Action to update a company
export const updateCity = (id, data) => {
    return {
        type: UPDATE_CITY_SUCCESS,
        payload: { id, data: data },
    };
};

// Action to delete a company
export const deleteCity = (id) => {
    return {
        type: DELETE_CITY_SUCCESS,
        payload: id,
    };
};
