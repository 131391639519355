import {
  SET_ROLES,
} from './actionTypes';

// Action to set fetched companies
export const setRoles = (data) => {
  return {
    type: SET_ROLES,
    payload: data,
  };
};
