// reducer.js
import {
  SET_PROCESS_STATUS,
  CLEAR_PROCESS_STATUS
} from './actionTypes';

const initialState = {
  processStatus: [],
};

const processStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROCESS_STATUS:
      return {
        ...state,
        processStatus: action.payload,
      };

    case CLEAR_PROCESS_STATUS:
      return {
        ...state,
        processStatus: initialState.processStatus,
      };
    default:
      return state;
  }
};

export default processStatusReducer;
