import {
    SET_DEFINITION_ITEMS,
    CLEAR_DEFINITION_ITEMS,
} from './actionTypes';

export const setDefinitionItems = (data) => {
    return {
        type: SET_DEFINITION_ITEMS,
        payload: data,
    };
};

export const clearDefinitionItems = (data) => {
    return {
        type: CLEAR_DEFINITION_ITEMS,
        payload: data,
    };
};
