import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const API_DEPOT = '/depot';
const WAREHOUSE = '/warehouse';
const CREATE = '/create';
const GET = '/get';
const GET_DELETED = '/get-deleted';
const UPDATE = '/update';
const DELETE = '/passive';
const RESTORE = '/restore';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} data - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createDepot = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${WAREHOUSE}${CREATE}`, data);
    console.log("createCompany");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDepots = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${WAREHOUSE}${GET}`);
      console.log("burası getCompanies service");
      console.log(response.data);
      console.log("*******");
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedDepots = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${WAREHOUSE}${API_DEPOT}${GET_DELETED}`);
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateDepot = async (data) => {
  try {
    console.log ("comapnyData");
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${WAREHOUSE}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteDepot = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${WAREHOUSE}${DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreDepot = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${RESTORE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};
