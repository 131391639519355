import { SET_UPDATE_PART_FORM_DATA, CLEAR_UPDATE_PART_FORM_DATA } from './actionTypes';

export const setUpdatePartFormData = (formData) => ({
  type: SET_UPDATE_PART_FORM_DATA,
  payload: formData,
});

export const clearUpdatePartFormData = () => ({
  type: CLEAR_UPDATE_PART_FORM_DATA,
});
