import { SET_CREATE_PART_FORM_DATA, CLEAR_CREATE_PART_FORM_DATA } from './actionTypes';

export const setCreatePartFormData = (formData) => ({
  type: SET_CREATE_PART_FORM_DATA,
  payload: formData,
});

export const clearCreatePartFormData = () => ({
  type: CLEAR_CREATE_PART_FORM_DATA,
});
