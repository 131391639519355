// reducer.js
import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SAGA,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  SET_IS_LOADING
} from "./actionTypes";

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  changePasswordSuccessMsg: null, // Şifre değiştirme başarılı mesajı
  changePasswordError: null, // Şifre değiştirme hata mesajı
  isLoading: false
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetSuccessMsg: action.payload,
      };
    case FORGET_PASSWORD_ERROR:
      return {
        ...state,
        forgetError: action.payload,
      };
    case CHANGE_PASSWORD_SAGA:
      return {
        ...state,
        changePasswordSuccessMsg: null,
        changePasswordError: null,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordSuccessMsg: null,
        changePasswordError: null,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordSuccessMsg: action.payload,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordError: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default forgetPassword;
