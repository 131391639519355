// src/store/selectedCountry/selectedCountryReducer.js
import { SET_SELECTED_COMPANY, CLEAR_SELECTED_COMPANY } from './actionTypes';

const initialState = {
  selectedCompany: null, // Başlangıçta seçilen ülke boş
};

const selectedCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };

    case CLEAR_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: null,
      };

    default:
      return state;
  }
};

export default selectedCompanyReducer;
