import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Card, Input } from 'reactstrap';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import customStyles from 'styles/customDatatableStyle';
import { useNavigate, useParams } from 'react-router-dom';
import qr from "assets/icons/qr-colored.png";
import QRCode from 'qrcode';
import { QRCodeCanvas } from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import 'styles/custom/custom-print-style.css'
import { getInslugItemDataWithQr } from 'service/depot/inslugItemService';
import { FaCheck, FaPrint, FaTimes } from 'react-icons/fa';
import { closeUpdateModal, openUpdateModal } from 'store/updateModal/actions';
import UpdateModal from 'components/Common/Modals/updateModal';
import 'styles/custom/custom-print-style.css'
import DynamicButton from 'components/Common/Button/dynamicButton';
import SplitDataTable from 'components/Common/SplitDataTable/splitDataTable';
import DynamicQr from 'components/Common/Qr/dynamicQr';

const InslugItemQrList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [itemData, setItemData] = useState([]);
  const [itemDataObject, setItemDataObject] = useState({});
  const [qrData, setQrData] = useState([]);
  const [itemSearch, setItemSearch] = useState('');
  const [qrSearch, setQrSearch] = useState('');
  const [filteredItemData, setFilteredItemData] = useState([]);
  const [filteredQrData, setFilteredQrData] = useState([]);
  const contentRefOne = useRef(null);
  const contentRefItem = useRef(null);
  const contentRefItemNotPrinted = useRef(null);

  // Tarih olup olmadığını kontrol eden ve uygun formatta döndüren fonksiyon
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString; // Geçerli bir tarih değilse olduğu gibi döndür
    }

    // Gün ay yıl saat dakika formatında döndür
    return date.toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  useEffect(() => {
    const fetchInslugData = async () => {
      try {
        const inslugInfo = await getInslugItemDataWithQr(id);
        console.log("inslugInfo");
        console.log(inslugInfo);
        const { itemData, stocks } = inslugInfo;
        setItemDataObject(itemData);
        const itemArray = [
          { key: "Company Name", value: itemData.company_name },
          { key: "Product Name", value: itemData.product_name },
          { key: "Product Count", value: itemData.count },
          { key: "Container No", value: itemData.container_no },
          { key: "Extern Party No", value: itemData.extern_party_no },
          { key: "Leverage Charge", value: itemData.leverage_charge },
          { key: "Created At", value: itemData.createdAt ? new Date(itemData.createdAt).toLocaleDateString() : "" },
          { key: "Description", value: itemData.description },
        ];

        setItemData(itemArray);
        setQrData(stocks);
        setFilteredQrData(stocks);
        console.log("*02312*3");
        console.log(stocks);
        setFilteredItemData(itemArray);
      } catch (error) {
        console.error("Error fetching inslug data:", error);
      }
    };
    fetchInslugData();
  }, [dispatch, id]);

  useEffect(() => {
    setFilteredItemData(
      itemData.filter(row =>
        row.key.toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.value?.toString().toLowerCase().includes(itemSearch.toLowerCase())
      )
    );
  }, [itemSearch, itemData]);

  useEffect(() => {
    setFilteredQrData(
      qrData.filter(row =>
        row.qr?.toString().toLowerCase().includes(qrSearch.toLowerCase())
      )
    );
  }, [qrSearch, qrData]);

  const handleQrCode = (qr) => {
    console.log("la veri mi yok acep");
    console.log(itemData);
    dispatch(
      openUpdateModal({
        title: (
          <Button color='link' onClick={handlePrintOne}>
            <FaPrint color='green' size={24} />
          </Button>
        ),
        body: (
          <div className="row justify-content-center align-items-center">
            <div
              ref={contentRefOne}
              style={{
                width: '250px',
                height: '200px',
                margin: '32px',
                border: '1px solid #ddd',
                padding: '10px',
                boxSizing: 'border-box',
              }}
            >
              <p className="mb-1"><strong>{itemDataObject.company_name}</strong></p>
              <div className="d-flex align-items-center justify-content-between">
                <div style={{ flex: 1, fontSize: '9px' }}>
                  <p className="mb-1"><strong>{itemDataObject.product_name}</strong></p>
                  <p className="mb-1"><strong>{itemDataObject.container_no}</strong></p>
                  <p className="mb-1"><strong>{itemDataObject.extern_party_no}</strong></p>
                  <p className="mb-1"><strong>{itemDataObject.leverage_charge}</strong></p>
                  <p className="mb-1"><strong>{itemDataObject.count}</strong></p>
                  <p className="mb-1"><strong>{qr}</strong></p>

                </div>
                <div style={{ flexShrink: 0 }}>
                <DynamicQr size={120} value={qr || "N/A"} />
                </div>
              </div>
            </div>
          </div>
        ),
        onConfirm: async () => {
        },
      })
    );
  }



  const itemColumns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];

  const qrColumns = [
    {
      name: props.t("Scan Status"),
      selector: row => row.is_printed_inslug === true ?
        <Badge className='p-2' color='success'>{"Scanned"}</Badge>
        :
        <Badge className='p-2' color='danger'>{"Not Scanned"}</Badge>,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Scan Date"),
      selector: row =>
        row.is_printed_inslug === true ?
          <>
            <span className='me-2'>{row.updatedAt ? new Date(row.updatedAt).toLocaleDateString() : ""}</span>
            <span>{row.updatedAt ? new Date(row.updatedAt).toLocaleTimeString() : ""}</span>
          </>

          :
          <Badge className='p-2' color='danger'>{"Not Scanned"}</Badge>,

      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Depot"),
      selector: row =>
        row.is_printed_inslug === true ?
          <div>
            {row?.depot_name ? <Badge className='p-2 m-1' color='primary'>{row?.depot_name}</Badge> : null}
            {row?.unit_name ? <Badge className='p-2 m-1' color='success'>{row?.unit_name}</Badge> : null}
            {row?.part_name ? <Badge className='p-2 m-1' color='warning'>{row?.part_name}</Badge> : null}
            {row?.cell_name ? <Badge className='p-2 m-1' color='info'>{row?.cell_name}</Badge> : null}
          </div>
          :
          <Badge className='p-2' color='danger'>{"Not Scanned"}</Badge>,

      sortable: true,
      wrap: true,
    },
    {
      name: props.t("QR Code"),
      selector: row =>
        <Button color="tr" onClick={() => handleQrCode(row.qr)}>
          {/* <QRCodeCanvas className='mb-1 mt-1' value={row.qr} size={50} /> */}
          <DynamicQr size={50} value={row.qr || "N/A"} />
          <div className='mb-1'>{row.qr}</div>
        </Button>,
      sortable: true,
      wrap: true,
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  const handlePrintOne = useReactToPrint({ contentRef: contentRefOne });
  const handlePrintItem = useReactToPrint({ contentRef: contentRefItem });
  const handlePrintItemNotPrinted = useReactToPrint({ contentRef: contentRefItemNotPrinted });

  const handlePrintType = () => {
    dispatch(
      openUpdateModal({
        body: (
          <div className="d-flex justify-content-center align-items-center">
            <div className='m-3'>
              <Button color='success' onClick={() => {
                handlePrintItem();
                dispatch(closeUpdateModal());
              }}
              >{props.t("All")}</Button>
            </div>
            <Button color='warning' onClick={() => {
              handlePrintItemNotPrinted();
              dispatch(closeUpdateModal());
            }}
            >{props.t("Not Scanned")}</Button>
          </div>
        ),
        onConfirm: async () => {
        },
      })
    );
  }

  return (
    <div>
      <Card className="p-4 rounded-lg mb-4">
        <h5 className="text-primary">{props.t("Item Information")}</h5>
        <SplitDataTable 
        data={itemData}
        columns={itemColumns}
        t={props.t}
        />
      </Card>

      <Card className="p-4 rounded-lg">
        <h5 className="text-primary">{props.t("Stock Information")}</h5>
        <DataTable
          columns={qrColumns}
          data={filteredQrData}
          customStyles={customStyles}
          highlightOnHover
          pagination
          noDataComponent={props.t("table-empty-message")}
          paginationComponentOptions={customPaginationOptions}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          subHeader
          subHeaderComponent={
            <div className='d-flex justify-content-end w-50'>
              <Button className="m-0 p-0 px-3" color="red" onClick={handlePrintType}>
                <img src={qr} width={'30'} alt="" />
              </Button>
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={qrSearch}
                onChange={(e) => setQrSearch(e.target.value.toLocaleUpperCase("tr"))}
                className="form-control w-50"
                maxLength={32}
              />
            </div>
          }
        />
      </Card>

      <div ref={contentRefItem} className="print-only">
        <div className="container">
          <div className="row">
            {qrData?.map((data, index) => (
              <div
                key={index}
                className="col-6 mb-4 mt-5" // Her satıra 3 sütun için col-4 kullanıyoruz
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "260px",
                    height: "260px",
                    border: "1px solid #ddd",
                    padding: "10px",
                    boxSizing: "border-box",
                  }}
                >
                  <p className="mb-1">
                    <strong>{itemDataObject.company_name}</strong>
                  </p>
                  <div className="d-flex justify-content-between">
                    <div style={{ paddingTop: 20, flex: 1, fontSize: "9px" }}>
                      <p className="mb-1">
                        <strong style={{ fontSize: "9px" }}>{itemDataObject.product_name}</strong>
                      </p>
                      <p className="mb-1">
                        <strong style={{ fontSize: "9px" }}>{itemDataObject.container_no}</strong>
                      </p>
                      <p className="mb-1">
                        <strong style={{ fontSize: "9px" }}>{itemDataObject.extern_party_no}</strong>
                      </p>
                      <p className="mb-1">
                        <strong style={{ fontSize: "9px" }}>{itemDataObject.leverage_charge}</strong>
                      </p>
                      <p className="mb-1">
                        <strong style={{ fontSize: "9px" }}>{itemDataObject.count}</strong>
                      </p>
                      <p className="mb-1">
                        <strong style={{ fontSize: "9px" }}>{data.qr}</strong>
                      </p>
                    </div>
                    <div style={{ flexShrink: 0 }}>
                      {/* <QRCodeCanvas value={data.qr} size={120} /> */}
                      <DynamicQr size={120} value={data.qr || "N/A"} />

                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div ref={contentRefItemNotPrinted} className="print-only">
        <div className="container">
          <div className="row">
            {qrData.filter(item => item.is_printed_inslug === false).map((data, index) => (
              <div
                key={index}
                className="col-6 mb-4 mt-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "250px",
                    height: "200px",
                    border: "1px solid #ddd",
                    padding: "10px",
                    boxSizing: "border-box",
                  }}
                >
                  <p className="mb-1">
                    <strong>{itemDataObject.company_name}</strong>
                  </p>
                  <div className="d-flex justify-content-between">
                    <div style={{ paddingTop: 20, flex: 1, fontSize: "9px" }}>
                      <p className="mb-1">
                        <strong>{itemDataObject.product_name}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{itemDataObject.container_no}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{itemDataObject.extern_party_no}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{itemDataObject.leverage_charge}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{itemDataObject.count}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.qr}</strong>
                      </p>
                    </div>
                    <div style={{ flexShrink: 0 }}>
                      {/* <QRCodeCanvas value={data.qr} size={120} /> */}
                      <DynamicQr size={120} value={data.qr || "N/A"} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <UpdateModal />
    </div>
  );
};

InslugItemQrList.propTypes = {
  match: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(InslugItemQrList);
