import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AddInslug from "./addInslug";
import AddInslugItem from "./addInslugItem";
import { setBreadcrumbItems } from "../../../../store/actions";
import 'react-toastify/dist/ReactToastify.css';
import InslugActions from "./inslugActions";
import { useTranslation } from 'react-i18next'; 

const InslugFormPage = (props) => {
    const { t } = useTranslation();
    document.title = t("New Inslug");
    const breadcrumbItems = [

    ];
    useEffect(() => {
        props.setBreadcrumbItems("", breadcrumbItems);
    }, []);

    return (
        <React.Fragment>
            <div>
                <AddInslug />
                <AddInslugItem />
                {/* <InslugActions /> */}
            </div>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(InslugFormPage);
