import {
    SET_UNITS,
    CLEAR_UNITS
} from './actionTypes';

// Action to set fetched companies
export const setUnits = (data) => {
    return {
        type: SET_UNITS,
        payload: data,
    };
};


// Action to clear companies
export const clearUnits = (data) => {
    return {
        type: CLEAR_UNITS,
        payload: data,
    };
};
