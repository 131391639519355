import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import DynamicInput from '../../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { closeModal, openModal } from 'store/modal/actions';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { getDefinitions, updateDefinitionItemData } from 'service/Definitions/definitionsService';
import { setDefinitionItems } from 'store/depot/Definitions/definitionItems/action';
import { closeUpdateModal } from 'store/updateModal/actions';
import { customValidationError } from 'components/Common/customValidationError';

const DefinitionItemUpdate = props => {
    const dispatch = useDispatch();
    const [updateFormData, setUpdateFormData] = useState({});

    useEffect(() => {
        setUpdateFormData({ id: props.row.id, name: props.row.name });
    }, [dispatch])

    const handleChange = (e) => {
        const inputData = e.target.value === "" || e.target.value === ""
            ? null
            : e.target.value.toLocaleUpperCase("tr");

        if (props.row.up_module_id === 19) {
            // inputData null ise işleme devam etme
            if (inputData === null) {
                setUpdateFormData({
                    ...updateFormData,
                    [e.target.name]: "",
                });
                return;
            }

            let filteredValue = inputData.replace(/[^0-9.]/g, "");

            // Eğer ilk karakter '.' ise '0.' yap
            if (filteredValue.startsWith(".")) {
                filteredValue = "0.";
            }

            // Eğer ilk karakter '0' ise ve ikinci karakter '.' değilse kaldır
            if (filteredValue.startsWith("0") && filteredValue[1] !== ".") {
                filteredValue = filteredValue.substring(1);
            }

            // Birden fazla '.' varsa yalnızca ilkini tut
            const dotIndex = filteredValue.indexOf(".");
            if (dotIndex !== -1) {
                filteredValue =
                    filteredValue.substring(0, dotIndex + 1) +
                    filteredValue.substring(dotIndex + 1).replace(/\./g, "");
            }

            // .dan sonra maksimum 2 karakter sınırını uygula
            if (dotIndex !== -1 && filteredValue.length > dotIndex + 3) {
                filteredValue = filteredValue.substring(0, dotIndex + 3);
            }

            setUpdateFormData({
                ...updateFormData,
                [e.target.name]: filteredValue,
            });

        } else {
            setUpdateFormData({
                ...updateFormData,
                [e.target.name]: inputData,
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleConfirm = () => {
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after successful update
                    try {
                        await updateDefinitionItemData(updateFormData)
                            .then(async () => {
                                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                                const newData = await getDefinitions(props.row.up_module_id || -1);
                                dispatch(setDefinitionItems(newData));
                            })
                        dispatch(closeUpdateModal());
                    } catch (error) {
                        console.error("Ürün grubu eklenemedi:", error);
                        //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                        customValidationError(error, props);
                    }
                }
            })
        );
    }


    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Update Definition")} {props.name}</h5>
                    <Button color="warning" type='submit'>
                        {props.t("Update")}
                    </Button>
                </div>
                <Row className='mb-4' form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="name">{props.t("Definition")}</Label>
                            {
                                props.row.up_module_id == 19 ?
                                    <DynamicInput
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={updateFormData.name}
                                        onChange={handleChange}
                                        placeholder={props.t("Enter definition")}
                                        required
                                        minLength={1}
                                        maxLength={12}
                                    />
                                    :
                                    <DynamicInput
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={updateFormData.name}
                                        onChange={handleChange}
                                        placeholder={props.t("Enter definition")}
                                        required
                                        minLength={1}
                                        maxLength={32}
                                    />
                            }
                        </FormGroup>
                    </Col>
                </Row>
            </Form>

            <AcceptModal />
        </div>
    );
};

DefinitionItemUpdate.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(DefinitionItemUpdate);
