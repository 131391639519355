import { SET_SUPPORT_DATA, CLEAR_SUPPORT_DATA } from './actionTypes';

const initialState = {
  formData: {},
};

const supportFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUPPORT_DATA:
      return {
        ...state,
        formData: action.payload,
      };

    case CLEAR_SUPPORT_DATA:
      return {
        ...state,
        formData: {},
      };

    default:
      return state;
  }
};

export default supportFormReducer;
