import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getCompanies, getDeletedCompanies, restoreCompany } from 'service/depot/companyService'; // API functions
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasExcelPermission, hasRestorePermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import RestoreButton from 'components/Common/Button/restoreButton';

const TrashedCompanies = props => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState(''); // State for search text
  const [trashedCompanies, setTrashedCompanies] = useState([]);
  const [filteredTrashedCompanies, setTrashedFilteredCompanies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyList = await getDeletedCompanies();
        console.log("compoani listesiiiiiiiiiiiiii");
        console.log(companyList);
        setTrashedCompanies(companyList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);

  // Seçime göre filteredCompanies'i güncelle
  useEffect(() => {
    setTrashedFilteredCompanies(trashedCompanies);
  }, [trashedCompanies]);

  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    const dataToFilter = trashedCompanies;
    const filteredData = dataToFilter.filter((company) =>
      company.name.toLowerCase().includes(searchText.toLowerCase()) ||
      company.client_no.toLowerCase().includes(searchText.toLowerCase()) ||
      company.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
      /* company.address?.toLowerCase().includes(searchText.toLowerCase()) || */
      /* company.country?.toLowerCase().includes(searchText.toLowerCase()) || */
      /* company.city?.toLowerCase().includes(searchText.toLowerCase()) || */
      company.iban?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.tax_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.tax_office?.toLowerCase().includes(searchText.toLowerCase())
    );
    setTrashedFilteredCompanies(filteredData);
  }, [searchText, trashedCompanies]);

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          try {
            console.log("ididididididid");
            console.log(id);
            await restoreCompany(id).then(async () => {
              toast.success(props.t("toast-success-restore-data"), { position: "top-right" });
              const newDeletedCompanies = await getDeletedCompanies();
              console.log("newDeletedCompanies");
              console.log(newDeletedCompanies);
              setTrashedCompanies(newDeletedCompanies); 
              setTrashedFilteredCompanies(newDeletedCompanies); 
              dispatch(closeModal()); 
            });

          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-restore-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const exportToExcel = () => {
    const column1Name = props.t("Company No");
    const column2Name = props.t("Company Name");
    const column3Name = props.t("Mail");
    const column4Name = props.t("Phone");
    const column5Name = props.t("Address");
    const column6Name = props.t("Country");
    const column7Name = props.t("City");
    const column8Name = props.t("IBAN");
    const column9Name = props.t("Tax No");
    const column10Name = props.t("Tax Office");


    const selectedColumns = filteredTrashedCompanies.map(itemCompanies => ({
      [column1Name]: itemCompanies.client_no,
      [column2Name]: itemCompanies.name,
      [column3Name]: itemCompanies.email,
      [column4Name]: itemCompanies.phone,
      [column5Name]: itemCompanies.address,
      [column6Name]: itemCompanies.country_name,
      [column7Name]: itemCompanies.city_name,
      [column8Name]: itemCompanies.iban,
      [column9Name]: itemCompanies.tax_no,
      [column10Name]: itemCompanies.tax_office,
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Companies"));
    XLSX.writeFile(workbook, props.t("Companies") + ".xlsx");
  };

  const columns = [
    {
      name: props.t("Company No"),
      selector: (row, rowIndex) => row.client_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Company Name"),
      selector: (row, rowIndex) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Mail"),
      selector: (row, rowIndex) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Phone"),
      selector: (row, rowIndex) => row.phone,
      sortable: true,
      wrap: true,
    },
    /* {
      name: props.t("Country"),
      selector: (row, rowIndex) => row.country_name,
      sortable: true,
      wrap: true,
    }, */
    {
      name: props.t("IBAN"),
      selector: (row, rowIndex) => row.iban,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Tax Number"),
      selector: (row, rowIndex) => row.tax_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Tax Office"),
      selector: (row, rowIndex) => row.tax_office,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        <>
          {hasRestorePermission(permissions, "DE_01") ? <RestoreButton onClick={() => handleRestore(row.id)} /> : <RestoreButton disabled={true} />}
        </>
    },
  ];

  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary mb-4">{props.t("Trashed Company List")}</h5>

      <DataTable
        style={{
          border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
        }}
        className='p-3'
        columns={columns}
        data={filteredTrashedCompanies}
        customStyles={customStyles}
        pagination
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        highlightOnHover
        paginationLa
        subHeader
        subHeaderComponent={
          <div className='d-flex'>
            {hasExcelPermission(permissions, "DE_01") && filteredTrashedCompanies.length > 0 ? excelButton : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
              maxLength={32}
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

TrashedCompanies.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(TrashedCompanies)