import React, { useEffect, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { createExternCompany, getCompanies, getExternCompanies } from 'service/depot/companyService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { openModal, closeModal } from 'store/modal/actions';
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { hasCreatePermission } from 'service/common/permissionService';
import { setUitslugFormData } from 'store/depot/uitslugStore/uitslugForm/action';
import DynamicButton from 'components/Common/Button/dynamicButton';
import { createUitslug, getUitslugsByCompany } from 'service/depot/uitslugService';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import { clearUitslugItemList, setUitslugItemList } from 'store/depot/uitslugStore/uitslugList/action';
import { getAllDefinitions, getDefinitions, getMultiDefinitions } from 'service/Definitions/definitionsService';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import UitslugItemList from './uitslugItemList';
import { customValidationError } from 'components/Common/customValidationError';

const UitslugForm = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const uitslugFormData = useSelector((state) => state.uitslugFormData?.formData);
  const uitslugItemList = useSelector((state) => state.uitslugItemList?.uitslugItemList || []);
  const selectedUitslugItemList = useSelector((state) => state.selectedUitslugItemList?.selectedUitslugItemList || []);
  const [externCompanies, setExternCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [customsStatuses, setCustomStatuses] = useState([]);
  const [loadingStatuses, setLoadingStatuses] = useState([]);
  const [carrierTypes, setCarrierTypes] = useState([]);

  const md = 6;
  const lg = 6;
  const xl = 4;

  useEffect(() => {
    dispatch(clearUitslugItemList());
    dispatch(setUitslugFormData({ ...uitslugFormData, client_no: null }));
    const fetchData = async () => {
      try {
        //const uitslug = await getUitslug(id);
        const companies = await getCompanies();
        const externCompanies = await getExternCompanies();
        const defData = await getMultiDefinitions([1, 5, 8])
        setCompanies(companies);
        setExternCompanies(externCompanies);
        setCustomStatuses(defData.find((element) => element.id === 5).sub);
        setLoadingStatuses(defData.find((element) => element.id === 8).sub);
        setCarrierTypes(defData.find((element) => element.id === 1).sub);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setUitslugFormData({ ...uitslugFormData, [name]: value != "" ? value.toLocaleUpperCase("tr") : null }));
  };

  useEffect(() => {
    console.log("uitslugFormData");
    console.log(uitslugFormData);
  }, [uitslugFormData]);

  const handleSelectChange = (field, selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    console.log("value : " + value);
    dispatch(setUitslugFormData({ ...uitslugFormData, [field]: value }));
  };

  const handleChangeMainCompany = async (field, selectedOption) => {
    if (selectedOption) {
      try {
        const value = selectedOption.value;
        dispatch(setUitslugFormData({ ...uitslugFormData, [field]: value }));
        const dataByCompany = await getUitslugsByCompany(value);
        console.log("uitslug listesi");
        console.log(dataByCompany);
        dispatch(setUitslugItemList(dataByCompany));
      } catch (error) {
        console.log(error)
      }
    } else {
      dispatch(setUitslugFormData({ ...uitslugFormData, [field]: null }));
      dispatch(clearUitslugItemList());
    }
  };

  const handleCreateExternCompany = (inputValue, isTransport) => {
    if (!hasCreatePermission(permissions, "DE_02")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          const newCompany = {
            name: inputValue,
          };
          await createExternCompany(newCompany)
            .then(async (addedCompany) => {
              const externCompanies = await getExternCompanies();
              setExternCompanies(externCompanies);
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });

              if (isTransport === 0) {
                dispatch(setUitslugFormData({ ...uitslugFormData, transport_company_no: addedCompany.client_no }));
              } else {
                dispatch(setUitslugFormData({ ...uitslugFormData, delivered_company_no: addedCompany.client_no }));
              }
            })
            .catch((error) => {
              //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error,props);
            });
        }
      }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleConfirm();
  }

  function handleItems(inputArray) {
    console.log("liste gelmesi lazım");
    console.log(inputArray);
    if (!Array.isArray(inputArray)) {
      console.log("Input is not an array");
      return [];
    }

    // Yeni dizi oluştur
    const newArray = inputArray
      .filter(item => item.output_count)
      .map(item => ({
        id: item.id,
        inslug_id: item.inslug_id,
        output_count: parseInt(item.output_count, 10),
      }));
    console.log("-----");
    console.log(newArray);

    return newArray;
  }


  const handleConfirm = async (id) => {
    const items = handleItems(uitslugItemList);
    const finalData = { uitslug: uitslugFormData, items: items };
    console.log("finalData");
    console.log(finalData);
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after failure
          await createUitslug(finalData).then(() => {
            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            navigate(`/uitslugs`);
          })
            .catch((error) => {
              console.error("ekelme işlemi başarısız oldu:", error);
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            });
        }
      })
    );
  }

  return (
    <Form onSubmit={handleSubmit}>

      <Card className='p-4 rounded-lg'>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("New Uitslug")}</h5>
          <DynamicButton
            text={props.t("Save")}
            type="submit"
            buttonType={0}
          />
        </div>

        <Row form>
          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="client_no">{props.t("Company No / Company Name")}</Label>
              <DynamicSelect
                options={companies.map((company) => ({
                  value: company.client_no,
                  label: company.name,
                }))}
                value={
                  companies.find(
                    (company) => company.client_no === uitslugFormData.client_no
                  )
                    ? {
                      value: companies.find(
                        (company) => company.client_no === uitslugFormData.client_no
                      ).client_no,
                      label: companies.find(
                        (company) => company.client_no === uitslugFormData.client_no
                      ).name,
                    }
                    : null
                }
                onChange={(selected) => {
                  handleChangeMainCompany('client_no', selected);
                }}
                placeholder={props.t("Select a company")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                  return clientNoMatch || companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="delivered_company_no">{props.t("Delivered Company")}</Label>
              <DynamicCreatableSelect
                options={externCompanies.map((company) => ({
                  value: company.client_no,
                  label: company.name,
                }))}
                value={
                  externCompanies.find(
                    (company) => company.client_no === uitslugFormData.delivered_company_no
                  )
                    ? {
                      value: externCompanies.find(
                        (company) => company.client_no === uitslugFormData.delivered_company_no
                      ).client_no,
                      label: externCompanies.find(
                        (company) => company.client_no === uitslugFormData.delivered_company_no
                      ).name,
                    }
                    : null
                }
                onChange={(selected) => {
                  handleSelectChange('delivered_company_no', selected);
                }}
                onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue) }}
                placeholder={props.t("Select delivered company")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add a new")}: ${inputValue}`}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                  return clientNoMatch || companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="transport_company_no">{props.t("Transport Company")}</Label>
              <DynamicCreatableSelect
                options={externCompanies.map((company) => ({
                  value: company.client_no,
                  label: company.name,
                }))}
                noOptionsMessage={() => props.t("no-option-message")}
                value={
                  externCompanies.find(
                    (company) => company.client_no === uitslugFormData.transport_company_no
                  )
                    ? {
                      value: externCompanies.find(
                        (company) => company.client_no === uitslugFormData.transport_company_no
                      ).client_no,
                      label: externCompanies.find(
                        (company) => company.client_no === uitslugFormData.transport_company_no
                      ).name,
                    }
                    : null
                }
                onChange={(selected) => {
                  handleSelectChange('transport_company_no', selected);
                }}
                onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue, 0) }}
                placeholder={props.t("Select transport company")}
                isClearable
                formatCreateLabel={(inputValue) => `Yeni ekle: ${inputValue}`}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return clientNoMatch || companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="carrier_type_id">{props.t("Carrier Type")}</Label>
              <DynamicSelect
                options={carrierTypes.map((carrier) => ({
                  value: carrier.id,
                  label: carrier.name,
                }))}
                value={
                  carrierTypes.find(
                    (status) => status.id === uitslugFormData.carrier_type_id // Eşleşen durumu bul
                  )
                    ? {
                      value: carrierTypes.find(
                        (status) => status.id === uitslugFormData.carrier_type_id
                      ).id, // value'da id değerini kullanıyoruz
                      label: carrierTypes.find(
                        (status) => status.id === uitslugFormData.carrier_type_id
                      ).name, // label'da name değerini kullanıyoruz
                    }
                    : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                }
                noOptionsMessage={() => props.t("no-option-message")}
                onChange={(selected) =>
                  handleSelectChange('carrier_type_id', selected)
                }
                placeholder={props.t("Select carrier type")}
                isClearable
              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="license_plate">{props.t("License Plate")}</Label>
              <DynamicInput
                type="text"
                id="license_plate"
                name="license_plate"
                value={uitslugFormData.license_plate}
                placeholder={props.t("Enter license plate")}
                onChange={handleInputChange}
                minLength={3}
                maxLength={24}
              />
            </FormGroup>
          </Col>
          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="driver_name">{props.t("Driver Name")}</Label>
              <DynamicInput
                type="text"
                id="driver_name"
                name="driver_name"
                value={uitslugFormData.driver_name}
                placeholder={props.t("Enter driver name")}
                onChange={handleInputChange}
                minLength={3}
                maxLength={24}
              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="booking_date">{props.t("Booking Date")}</Label>
              <DynamicInput
                type="date"
                id="booking_date"
                name="booking_date"
                value={uitslugFormData.booking_date}
                onChange={handleInputChange}
                onClick={(e) => e.target.showPicker()}

              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="description">{props.t("Description")}</Label>
              <DynamicInput
                type="text"
                id="description"
                name="description"
                value={uitslugFormData.description}
                placeholder={props.t("Enter description")}
                onChange={handleInputChange}
                minLength={3}
                maxLength={255}
              />
            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="customs_status_id">{props.t("Customs Status")}</Label>
              <DynamicSelect
                options={customsStatuses.map((status) => ({
                  value: status.id,  // value, id değerini içerir
                  label: status.name, // label, name değerini içerir
                }))}
                value={
                  customsStatuses.find(
                    (status) => status.id === uitslugFormData.customs_status_id // Eşleşen durumu bul
                  )
                    ? {
                      value: customsStatuses.find(
                        (status) => status.id === uitslugFormData.customs_status_id
                      ).id, // value'da id değerini kullanıyoruz
                      label: customsStatuses.find(
                        (status) => status.id === uitslugFormData.customs_status_id
                      ).name, // label'da name değerini kullanıyoruz
                    }
                    : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                }
                noOptionsMessage={() => props.t("no-option-message")}
                onChange={(selected) => {
                  console.log("onChange çalıştı");
                  console.log(selected);

                  handleSelectChange("customs_status_id", selected); // Seçilen id'yi işliyoruz
                }}
                placeholder={props.t("Select customs status")}
                isClearable
              />

            </FormGroup>
          </Col>

          <Col md={md} lg={lg} xl={xl}>
            <FormGroup>
              <Label for="loading_status_id">{props.t("Loading Status")}</Label>
              <DynamicSelect
                options={loadingStatuses.map((status) => ({
                  value: status.id,
                  label: status.name,
                }))}
                value={
                  loadingStatuses.find(
                    (status) => status.id === uitslugFormData.loading_status_id // Eşleşen durumu bul
                  )
                    ? {
                      value: loadingStatuses.find(
                        (status) => status.id === uitslugFormData.loading_status_id
                      ).id, // value'da id değerini kullanıyoruz
                      label: loadingStatuses.find(
                        (status) => status.id === uitslugFormData.loading_status_id
                      ).name, // label'da name değerini kullanıyoruz
                    }
                    : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                }
                noOptionsMessage={() => props.t("no-option-message")}
                onChange={(selected) =>
                  handleSelectChange('loading_status_id', selected)
                }
                placeholder={props.t("Select loading status")}
                isClearable
              />
            </FormGroup>
          </Col>

        </Row>

        <AcceptModal />
        <FastCreateModal />
      </Card>
      <UitslugItemList />
    </Form >
  );
};

UitslugForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UitslugForm)