import {
    SET_CELLS,
    CLEAR_CELLS
} from './actionTypes';

// Action to set fetched companies
export const setCells = (data) => {
    return {
        type: SET_CELLS,
        payload: data,
    };
};


// Action to clear companies
export const clearCells = (data) => {
    return {
        type: CLEAR_CELLS,
        payload: data,
    };
};
