import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { createProduct, getProducts, getProductsByCategory } from 'service/depot/productService'; // Product ve Kategori servisi
import { getClientProducts, updateClientProduct } from 'service/depot/clientProductService'; // Product ve Kategori servisi

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { createCompany, getCompanies } from 'service/depot/companyService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { setClientProducts } from 'store/depot/clientProduct/action';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { createCountry, getCountries } from 'service/Definitions/countryService';
import { clearClientProductFormData, setClientProductFormData } from 'store/updates/clientProductForm/action';
import { useSelector } from 'react-redux';
import { clearSelectedCompany, setSelectedCompany } from 'store/depot/selectedCompany/action';
import { clearSelectedProduct, setSelectedProduct } from 'store/depot/selectedProduct/action';
import { clearSelectedCountry, setSelectedCountry } from 'store/depot/selectedCountry/action';
import { closeUpdateModal } from 'store/updateModal/actions';
import { hasCreatePermission } from 'service/common/permissionService';
import { createDefinition, getDefinitions, getMultiDefinitions } from 'service/Definitions/definitionsService';
import { createProductCategory, getProductCategories } from 'service/depot/productCategoryService';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import { customValidationError } from 'components/Common/customValidationError';

const UpdateClientProductForm = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const formData = useSelector((state) => state.clientProductFormData?.formData || {});
  console.log("updateClientProductFormDataaaaaaaaaaa");
  console.log(formData);
  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const selectedCompany = useSelector((state) => state.selectedCompany?.selectedCompany);
  const selectedProduct = useSelector((state) => state.selectedProduct?.selectedProduct);
  const selectedCountry = useSelector((state) => state.selectedCountry?.selectedCountry);
  const [units, setUnits] = useState([]);
  const [prices, setPrices] = useState([]);

  const getAllCountries = async () => {
    try {
      const countryList = await getCountries();
      const formattedCountries = countryList.map(data => ({
        value: data.id,
        label: data.name
      }));
      setCountries(formattedCountries);
    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const defData = await getMultiDefinitions([18, 19]);
        const categoryList = await getProductCategories();
        await getAllCountries();
        const formattedUnitList = defData.find((element) => element.id === 18).sub.map(data => ({
          value: data.id,
          label: data.name
        }));
        const formattedPriceList = defData.find((element) => element.id === 19).sub.map(data => ({
          value: data.id,
          label: data.name
        }));
        const formattedCategories = categoryList.map(data => ({
          value: data.product_category_no,
          label: data.name
        }));
        setUnits(formattedUnitList);
        setPrices(formattedPriceList);
        setCategories(formattedCategories);
      } catch (error) {
        console.log("hata");
      }
    }

    fetchData();

  }, [dispatch]);

  // Kategori verilerini çekmek için useEffect
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const productList = await getProductsByCategory(formData.product_category_no); // Kategorileri çekiyoruz
        const formattedProducts = productList.map(data => ({
          value: data.product_no,
          label: data.name
        }));
        setProducts(formattedProducts); // Kategorileri state'e set ediyoruz
        const companyList = await getCompanies(); // Kategorileri çekiyoruz
        setCompanies(companyList); // Kategorileri state'e set ediyoruz
      } catch (error) {
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    dispatch(setClientProductFormData({
      ...formData,
      [e.target.name]: inputData,
    }));
  };

  const handleSelectChange = (field, selected) => {
    if (selected) {
      dispatch(setClientProductFormData({ ...formData, [field]: selected.value }));
    } else {
      dispatch(setClientProductFormData({ ...formData, [field]: null }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  const handleSelectProductChange = (field, selected) => {
    dispatch(setSelectedProduct(selected));
    if (selected) {
      dispatch(setClientProductFormData({ ...formData, [field]: selected.value }));
    } else {
      dispatch(setClientProductFormData({ ...formData, [field]: null }));
    }
  };

  const handleSelectCompanyChange = (field, selected) => {
    console.log("field111");
    console.log(field);
    console.log("selected111");
    console.log(selected);
    dispatch(setSelectedCompany(selected));
    dispatch(setClientProductFormData({ ...formData, [field]: selected?.value }));
  };

  const handleSelectCountryChange = async (field, selected) => {
    console.log(selected);
    dispatch(setSelectedCountry(selected));
    dispatch(setClientProductFormData({ ...formData, [field]: selected ? selected.value : null }));
    await getAllCountries();
  };

  const handleCreateCompany = (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_01")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          const newCompany = {
            name: inputValue,  // Kullanıcının girdiği değer
          };
          await createCompany(newCompany)
            .then(async (addedCompany) => {
              const newCompanyList = await getCompanies();// Yeni şirketleri yeniden getir
              const formattedCompanies = newCompanyList.map(company => ({
                value: company.client_no,
                label: company.name
              }));
              setCompanies(formattedCompanies);
              dispatch(setSelectedCompany({ value: addedCompany.client_no, label: addedCompany.name }));
              dispatch(setClientProductFormData({ ...formData, ["client_no"]: addedCompany.client_no }));
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error, props);
            });
        }
      }));// Modalı açıyoruz
  }

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  useEffect(() => {
    console.log("selectedCategory güncellendi");
    console.log(selectedProduct);
  }, [selectedProduct])

  const handleConfirm = async () => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Modalı kapatır
          const { createdAt, updatedAt, is_deleted, country_name, company_name, product_name, product_category_no, product_category_name, origin_country_name, ...rest } = formData;

          await updateClientProduct(rest)
            .then(async () => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
              const updatedProducts = await getClientProducts();
              dispatch(setClientProducts(updatedProducts));
              dispatch(clearSelectedCompany());
              dispatch(clearSelectedCountry());
              dispatch(clearSelectedProduct());
              dispatch(clearClientProductFormData());
              dispatch(closeUpdateModal());
            })
            .catch((error) => {
              console.error("Müşteri ürünü eklenemedi:", error);
              customValidationError(error, props);
            });
        }
      })
    );
  };

  const handleCreateCountry = (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_10")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          const newCountry = {
            name: inputValue,
          };
          await createCountry(newCountry)
            .then(async (addedCountry) => {
              const newCountryList = await getCountries();
              console.log("addedCountry");
              console.log(addedCountry);

              const formattedCountries = newCountryList.map(country => ({
                value: country.id,
                label: country.name
              }));
              dispatch(setSelectedCountry({ value: addedCountry.id, label: addedCountry.name }));
              dispatch(setClientProductFormData({ ...formData, ["country_id"]: addedCountry.id }));
              setCountries(formattedCountries);
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              console.log(error);
              //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error, props);
            });
        }
      }));// Modalı açıyoruz
  }

  const handleCreateProduct = (inputValue) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: <span> {props.t("modal-save-body")}</span>,
        onConfirm: async () => {
          // Yeni bir şirket oluşturmak için:
          const newProduct = {
            name: inputValue,  // Kullanıcının girdiği değer
          };

          await createProduct(newProduct)
            .then(async () => {
              const newProductList = await getProducts();// Yeni şirketleri yeniden getir
              const formattedProducts = newProductList.map(data => ({
                value: data.product_no,
                label: data.name
              }));

              setProducts(formattedProducts);
              dispatch(setSelectedProduct({ value: newProduct.product_no, label: newProduct.name }));
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  }

  const formatDateForInput = (isoDate) => {
    if (!isoDate) {
      return null;
    }
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleCreateUnit = (inputValue) => {
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          try {
            const data = { name: inputValue, up_module_id: 18 }
            await createDefinition(data).then(async (newData) => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });

              const updatedDatas = await getDefinitions(18);
              const formattedData = updatedDatas.map(data => ({
                value: data.id,
                label: data.name
              }));
              setUnits(formattedData);
              dispatch(setClientProductFormData({ ...formData, unit_id: newData.id }));
            })
          } catch (error) {
            console.log(error);
            customValidationError(error, props);
          }

        }
      }));
  }

  const handleCreatePrice = (inputValue) => {
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          try {
            const data = { name: inputValue, up_module_id: 19 }
            await createDefinition(data).then(async (newData) => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });

              const updatedDatas = await getDefinitions(19);
              const formattedData = updatedDatas.map(data => ({
                value: data.id,
                label: data.name
              }));
              setPrices(formattedData);
              dispatch(setClientProductFormData({ ...formData, price_id: newData.id }));
            })
          } catch (error) {
            console.log(error);
            customValidationError(error, props);
          }

        }
      }));
  }

  const handleCategorySelectChange = async (field, selected) => {
    if (selected) {
      try {
        const product_category_no = selected.value;
        dispatch(setClientProductFormData({ ...formData, [field]: selected.value }));
        const productList = await getProductsByCategory(product_category_no);
        const formattedProducts = productList.map(data => ({
          value: data.product_no,
          label: data.name
        }));
        setProducts(formattedProducts || []);
      } catch (error) {
        console.log("hata oluştu");
      }
    } else {
      setProducts([]);
      dispatch(setClientProductFormData({ ...formData, [field]: null }));
    }
  };

  const handleCreateProductGroup = async (name) => {
    if (!hasCreatePermission(permissions, "DE_07")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }

    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: <span>{props.t("modal-save-body")}</span>,
        onConfirm: async () => {
          //const newProductCategoryNo = Math.random().toString(36).substr(2, 8).toUpperCase()
          const data = { name: name };
          await createProductCategory(data)
            .then(async (addedCategory) => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
              const updatedProducts = await getProductCategories();
              const formattedCategories = updatedProducts.map(data => ({
                value: data.product_category_no,
                label: data.name
              }));
              console.log("categoryListtttttt");
              console.log(updatedProducts);
              setCategories(formattedCategories);
              setProducts([]);
              dispatch(setClientProductFormData({ ...formData, product_category_no: addedCategory.product_category_no }));
            })
            .catch((error) => {
              console.error("Ürün grubu eklenemedi:", error);
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            });
          dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Update Company Product")}</h5>
          <Button color="warning" type='submit'>
            {props.t("Update")}
          </Button>
        </div>
        <Row className='mb-4' form>
          <Col md={4}>
            <FormGroup>
              <Label for="client_no">{props.t("Company")}</Label>
              <DynamicCreatableSelect
                options={companies.map((company) => ({
                  value: company.client_no,
                  label: company.name,
                }))}
                value={selectedCompany}
                onChange={(selected) => handleSelectCompanyChange("client_no", selected)}
                onCreateOption={async (inputValue) => { handleCreateCompany(inputValue) }}
                placeholder={props.t("Select a company")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                  return clientNoMatch || companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="product_category_no">{props.t("Product Category")}</Label>
              <DynamicSelect
                options={categories}
                value={categories.find(data => data.value === formData.product_category_no)}
                onChange={(selected) => handleCategorySelectChange("product_category_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateProductGroup(inputValue) }}
                placeholder={props.t("Select product category")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="product_no">{props.t("Product")}</Label>
              {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_08")} props={props} />
              {hasViewPermission(permissions, "DE_08") ? */}
              <DynamicSelect
                options={products}
                value={products.find(data => data.value === formData.product_no)}
                onChange={(selected) => handleSelectProductChange("product_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateProduct(inputValue.toLocaleUpperCase("tr")) }}
                placeholder={props.t("Select product")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue.toLocaleUpperCase("tr")}`} // Yeni seçenek ekleme mesajı
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return clientNoMatch || companyNameMatch;
                }}
              />
              {/* :
              <NoAuthUpdateInput data={formData.product_name} />
              } */}
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="extern_no">{props.t("Extern No")}</Label>
              <DynamicInput
                type="text"
                name="extern_no"
                id="extern_no"
                value={formData.extern_no}
                onChange={handleChange}
                placeholder={props.t("Enter extern no")}
              />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="origin_country">{props.t("Origin Country")}</Label>
              <DynamicCreatableSelect
                options={countries}
                value={countries.find(data => data.value == formData.country_id)}
                onChange={(selected) => handleSelectCountryChange("country_id", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                placeholder={props.t("Enter country")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const clientNo = option.data.label || "";
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                  return companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="production_date">{props.t("Production Date")}</Label>
              <DynamicInput
                type="date"
                id="production_date"
                name="production_date"
                value={formatDateForInput(formData.production_date)}
                onChange={handleChange}
                placeholderText={props.t("Enter production date")}
                onClick={(e) => e.target.showPicker()}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="unit_id">{props.t("Unit")}</Label>
              <DynamicCreatableSelect
                options={units}
                value={units.find(data => data.value === formData.unit_id)}
                onChange={(selected) => handleSelectChange("unit_id", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateUnit(inputValue.toLocaleUpperCase("tr")) }}
                placeholder={props.t("Select unit")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue.toLocaleUpperCase("tr")}`} // Yeni seçenek ekleme mesajı
                filterOption={(option, inputValue) => {
                  const nameMatch = option?.label?.toLowerCase().includes(inputValue.toLowerCase());

                  return nameMatch;
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="price_id">{props.t("Price")}</Label>
              <DynamicCreatableSelect
                options={prices}
                value={prices.find(data => data.value === formData.price_id)}
                onChange={(selected) => handleSelectChange("price_id", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreatePrice(inputValue.toLocaleUpperCase("tr")) }}
                placeholder={props.t("Select price")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputData) => {
                  // Sadece rakamlar ve '.' karakteri kalsın
                  let filteredValue = inputData?.replace(/[^0-9.]/g, "");

                  // Eğer ilk karakter '.' ise '0.' yap
                  if (filteredValue.startsWith(".")) {
                    filteredValue = "0.";
                  }

                  // Birden fazla '.' varsa yalnızca ilkini tut
                  const dotIndex = filteredValue.indexOf(".");
                  if (dotIndex !== -1) {
                    filteredValue =
                      filteredValue.substring(0, dotIndex + 1) + // İlk '.' ve öncesini al
                      filteredValue
                        .substring(dotIndex + 1)
                        .replace(/\./g, ""); // Sonraki tüm '.' karakterlerini kaldır
                  }

                  // .dan sonra maksimum 2 karakter sınırını uygula
                  if (dotIndex !== -1 && filteredValue.length > dotIndex + 3) {
                    filteredValue = filteredValue.substring(0, dotIndex + 3);
                  }

                  // Maksimum 12 karakter sınırını uygula
                  if (filteredValue.length > 12) {
                    filteredValue = filteredValue.slice(0, 12);
                  }

                  return filteredValue;
                }}

                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue.toLocaleUpperCase("tr")}`} // Yeni seçenek ekleme mesajı
                filterOption={(option, inputValue) => {
                  const nameMatch = option?.label?.toLowerCase().includes(inputValue.toLowerCase());

                  return nameMatch;
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

UpdateClientProductForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateClientProductForm)