import {
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAILURE,
    UPDATE_COMPANY_SUCCESS,
    DELETE_COMPANY_SUCCESS,
    SET_COMPANY,
} from './actionTypes';

// Action to set fetched companies
export const setCompanies = (data) => {
    return {
      type: SET_COMPANY,
      payload: data,
    };
  };

// Action for fetch error
export const fetchCompaniesFailure = (error) => {
    return {
        type: FETCH_COMPANIES_FAILURE,
        payload: error,
    };
};

// Action to update a company
export const updateCompanyAction = (id, companyData) => {
    return {
        type: UPDATE_COMPANY_SUCCESS,
        payload: { id, companyData },
    };
};

// Action to delete a company
export const deleteCompanyAction = (id) => {
    return {
        type: DELETE_COMPANY_SUCCESS,
        payload: id,
    };
};
