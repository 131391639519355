// src/store/selectedCountry/selectedCountryActions.js
import { SET_SELECTED_PRODUCT, CLEAR_SELECTED_PRODUCT } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setSelectedProduct = (city) => ({
  type: SET_SELECTED_PRODUCT,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedProduct = () => ({
  type: CLEAR_SELECTED_PRODUCT,
});
