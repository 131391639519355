import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Label, Row, Col, Input, Form } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setRoles } from '../../../store/settings/roles/action';
import DynamicInput from '../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { closeModal, openModal } from 'store/modal/actions';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import customStyles from 'styles/customDatatableStyle';
import { createNewRole, getModulsAndPermission, getRoles } from 'service/settings/rolesService';
import { setFilteredRoles } from 'store/settings/filteredRoles/action';
import { customValidationError } from 'components/Common/customValidationError';

const RolesForm = props => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [formSearchText, setFormSearchText] = useState('');
  const [permissionsForm, setPermissionsForm] = useState();
  const [filteredPermissionsForm, setFilteredPermissionsForm] = useState([]);
  const [data, setData] = useState([]);


  const handleChange = (e) => {
    const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    setFormData({
      ...formData,
      [e.target.name]: inputData,
    });
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const permissions = await getModulsAndPermission(); // Fetch countries from API
        //dispatch(setRoles(rolesList));  // Dispatch to Redux store
        setPermissionsForm(permissions);
        setFilteredPermissionsForm(permissions);
        console.log("sadasdasdsadasdadasd");
        console.log(permissions);
      } catch (error) {
        console.error('Error fetching countries:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch, props]);

  // Arama işlevi için useEffect
  useEffect(() => {
    if (formSearchText.trim() === "") {
      // Eğer arama kutusu boşsa tüm izinleri göster
      setFilteredPermissionsForm((prev) => ({ ...prev, allPermission: permissionsForm?.allPermission || [] }));
    } else {
      // Arama metnine göre allPermission'ı filtrele
      const filteredData = permissionsForm?.allPermission?.filter((item) =>
        item.name?.toLocaleUpperCase("tr").includes(formSearchText.toLocaleUpperCase("tr"))
      );
      setFilteredPermissionsForm((prev) => ({ ...prev, allPermission: filteredData }));
    }
  }, [formSearchText, permissionsForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  const handleConfirm = async () => {
    console.log("handleConfgirm");
    console.log(formData);
    if (formData.name === '' || !formData.name) {
      toast.error(props.t("Role can not be an empty"), { position: "top-right" });
    } else {
      if (data.length === 0) {
        toast.error(props.t("Please select minimum one permission"), { position: "top-right" });
      }
      else {
        dispatch(
          openModal({
            title: props.t("modal-save-title"),
            body: props.t("modal-save-body"),
            onConfirm: async () => {
              dispatch(closeModal()); // Close the modal after successful update
              try {
                const mydata = { ...formData, permissions: data };
                console.log("YYYYYYYYYYYYYYYYYYY");
                console.log(mydata);
                await createNewRole(mydata)
                  .then(async () => {
                    toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                    setFormData({});
                    setData([]);
                    const rolesList = await getRoles(); // Fetch countries from API
                    dispatch(setRoles(rolesList));
                    dispatch(setFilteredRoles(rolesList));

                    /* const countries = await getCountries(); // Ürünleri güncelle
                    dispatch(setCountries(countries)); // Redux state güncelleme
                    setFormData({}); */
                  })

              } catch (error) {
                console.error("Ürün grubu eklenemedi:", error);
                //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                customValidationError(error,props);
              }
            }
          })
        );
      }
    }

  };


  const handleCheckboxChange = (event, row, activity) => {
    // `updatedData`yı başlatıyoruz. Tüm veri setini içerecek şekilde klonluyoruz.
    let updatedData = [...data];

    if (event.target.checked === true) {
      console.log('İşaretlendi');

      const gveri = {
        module_no: row, // row'un module_no'sunu alıyoruz
        activity_no: activity, // Aktivitenin activity_no'sunu alıyoruz
        permission: true
      };
      const existingIndex = updatedData.findIndex(item => item.module_no == row && item.activity_no == activity);
      if (existingIndex !== -1) {
        console.log('AAA VARMIŞ');
        updatedData[existingIndex] = gveri;
      } else {
        console.log('AAA YOKMUŞ');
        updatedData.push(gveri);
      }
    } else {
      updatedData = updatedData.filter(item => !(item.module_no == row && item.activity_no == activity));

    }

    console.log('Updated Data:', updatedData);

    // Yeni güncellenmiş veriyi state'e kaydediyoruz
    setData(updatedData);
    console.log(data);
  };

  useEffect(() => {
    console.log("data değeri güncellendi");
    console.log(data);
  }, [data]);

  const columName = (activities) => {

    const columnArray = [];

    // Checkbox sütununu en sola ekliyoruz
    let selectAllColumn = {
      name: "",
      cell: (row) => (
        <input
          type="checkbox"
          onChange={(e) => {
            const isChecked = e.target.checked;
            const updatedData = row.activityies.map((item) => ({
              module_no: row.module_no,
              activity_no: item.activity_no,
              permission: isChecked
            }));

            // Checkbox'ı işaretlendiğinde tüm satırı seçili hale getir
            const newData = isChecked
              ? [...data, ...updatedData.filter(newItem =>
                !data.some(existingItem =>
                  existingItem.module_no === newItem.module_no &&
                  existingItem.activity_no === newItem.activity_no))]
              : data.filter(existingItem =>
                existingItem.module_no !== row.module_no);

            setData(newData);
          }}
          checked={row.activityies.every(
            (item) => data.some(
              (existingItem) =>
                existingItem.module_no === row.module_no &&
                existingItem.activity_no === item.activity_no &&
                existingItem.permission
            )
          )}
        />
      ),
      sortable: false,
      width: "50px",
      wrap: true,
    };

    columnArray.push(selectAllColumn); // Checkbox sütununu en başa ekliyoruz

    let column = {
      selector: (row) => props.t(row.name),
      sortable: true,
      wrap: true,
      cell: (row) => (
        <span style={{ fontWeight: 'bold', color: '#94505F' }}> {/* Renk ve kalınlık ayarlandı */}
          {row.name}
        </span>
      ),
    };

    columnArray.push(column);
    activities?.forEach(element => {
      let sutun = {
        name: element.name,
        selector: (row) => (
          row.activityies.map((item, index) => (
            (item.activity_no === element.activity_no) ?
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                <label className='mb-0'>
                  {props.t(item.activity_no)}
                </label>
                <input
                  className='mb-0 ms-2'
                  type="checkbox"
                  checked={data.find(data => (data.activity_no === item.activity_no && data.module_no === row.module_no))?.permission || false}
                  onChange={(e) => handleCheckboxChange(e, row.module_no, item.activity_no)}
                />
              </div> : null
          ))
        ),
        sortable: true,
        wrap: true,
      }
      columnArray.push(sutun);
    });
    return columnArray;
  }


  const columns = columName(filteredPermissionsForm.allActivities);

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} >
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Add Role")}</h5>
          <Button className='text-white' color="success" type='submit'>
            {props.t("Save")}
          </Button>
        </div>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="name">{props.t("New Role")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter role")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>
          <DataTable
            columns={columns}
            data={filteredPermissionsForm.allPermission}
            customStyles={customStyles}
            noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
            paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
            pagination
            highlightOnHover
            subHeader
            subHeaderComponent={
              <div className='d-flex justify-content-end w-50'>
                <Input
                  type="text"
                  placeholder={props.t("Search")}
                  value={formSearchText}
                  onChange={(e) => setFormSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                  style={{ width: '250px' }} // Smaller input field
                  maxLength={32}
                />
              </div>
            }
          />
        </Row>
      </Form>
    </div>
  );
};

RolesForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(RolesForm)