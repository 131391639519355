import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Card, Input } from 'reactstrap';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { approveInslug, getAllQrs, getInslug } from 'service/depot/inslugService';
import customStyles from 'styles/customDatatableStyle';
import { useNavigate, useParams } from 'react-router-dom';
import qr from "assets/icons/qr-colored.png";
import { QRCodeCanvas } from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import 'styles/custom/custom-print-style.css'
import DetailsButton from 'components/Common/Button/detailsButton';
import logoSrc from "assets/images/act-logo.webp";
import { closeUpdateModal, openUpdateModal } from 'store/updateModal/actions';
import UpdateModal from 'components/Common/Modals/updateModal';
import { toast } from 'react-toastify';
import { closeModal, openModal } from 'store/modal/actions';
import "styles/custom/custom-blink-button.css";
import AcceptModal from 'components/Common/Modals/acceptModal';
import { hasUpdatePermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import DynamicButton from 'components/Common/Button/dynamicButton';
import SplitDataTable from 'components/Common/SplitDataTable/splitDataTable';
import logo from "assets/images/act-logo-mini.png";
import { QRCode } from 'react-qrcode-logo';
import DynamicQr from 'components/Common/Qr/dynamicQr';
import DynamicPrint from 'components/Common/Print/dynamicPrint';

const today = () => {
  const today = new Date();
  const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
  return formattedDate;
}

const ViewInslug = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [inslugData, setInslugData] = useState([]);
  const [inslugObject, setInslugObject] = useState([]);
  const [allQrList, setAllQrList] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [inslugSearch, setInslugSearch] = useState('');
  const [itemSearch, setItemSearch] = useState('');
  const [filteredItemData, setFilteredItemData] = useState([]);
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const contentRef = useRef(null);
  const contentRefItem = useRef(null);
  const contentRefItemNotPrinted = useRef(null);

  // Tarih olup olmadığını kontrol eden ve uygun formatta döndüren fonksiyon
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString; // Geçerli bir tarih değilse olduğu gibi döndür
    }

    // Gün ay yıl saat dakika formatında döndür
    return date.toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const fetchInslugData = async () => {
    try {
      const inslugInfo = await getInslug(id);
      console.log("inslugInfooooss");
      console.log(inslugInfo);
      const { inslug, inslugItems } = inslugInfo;

      const inslugArray = [
        { key: "Company Name", value: inslug.company_name },
        { key: "Transport Company", value: inslug.transport_company_name },
        { key: "Supplier Company", value: inslug.supplier_company_name },
        { key: "Document No", value: inslug.document_no },
        { key: "Ext Document No", value: inslug.extern_document_no },
        { key: "Agency Reference", value: inslug.agency_reference },
        { key: "Description", value: inslug.description },
        { key: "Booking Date", value: inslug.booking_date ? formatDate(inslug.booking_date.toString()) : "" },
        { key: "ETA", value: inslug.eta ? formatDate(inslug.eta.toString()) : "" },
        { key: "Closing Out", value: inslug.closing_out ? formatDate(inslug.closing_out.toString()) : "" },
        { key: "Closing In", value: inslug.closing_in ? formatDate(inslug.closing_in.toString()) : "" },
        { key: "Customs Status", value: inslug.customs_status_name },
        { key: "Carrier Type", value: inslug.carrier_type_name },
        { key: "Loading Status", value: inslug.loading_status_name },
        {
          key: "Process Status", value: inslug.process_status_id !== undefined && (
            <span
              className="d-flex align-items-center justify-content-center text-center"
              style={{
                fontSize: "9px",
                height: "16px",
                marginTop: "2px",
                marginLeft: "8px",
                padding: "2px 12px 0px 12px", // Daha az padding
                borderRadius: "12px", // Oval kenarlar
                backgroundColor:
                  inslug.process_status_id % 4 === 0
                    ? "#dc3545" // danger (kırmızı)
                    : inslug.process_status_id % 4 === 1
                      ? "#ffc107" // warning (sarı)
                      : inslug.process_status_id % 4 === 2
                        ? "#28a745" // success (yeşil)
                        : "#17a2b8", // info (mavi)
                textAlign: "center",
                color: "white"
              }}
            >
              {inslug.process_status_name}
            </span>

          )
        }

      ];
      setInslugObject(inslug);
      setInslugData(inslugArray);
      setItemData(inslugItems);
      setFilteredItemData(inslugItems);

      const allQrs = await getAllQrs(id);
      console.log("allQRS");
      console.log(allQrs);
      setAllQrList(allQrs);
    } catch (error) {
      console.error("Error fetching inslug data:", error);
    }
  };

  useEffect(() => {
    console.log("111");
    try {
      fetchInslugData();
    } catch (error) {
      console.log("hata");
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("333");
    setFilteredItemData(
      itemData.filter(row =>
        row.client_product_id?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.container_no?.toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.description?.toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.leverage_charge?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.count?.toString().toLowerCase().includes(itemSearch.toLowerCase())
      )
    );
  }, [itemSearch, itemData]);



  const inslugColumns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];

  const itemColumns = [
    {
      name: props.t("Product Name"),
      selector: row => row.product_name,
      finder: "product_name",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Extern Party No"),
      selector: row => row.extern_party_no,
      sortable: true,
      finder: "extern_party_no",

      wrap: true,
    },
    {
      name: props.t("Container No"),
      selector: row => row.container_no,
      sortable: true,
      finder: "container_no",

      wrap: true,
    },
    {
      name: props.t("Description"),
      selector: row => row.description,
      sortable: true,
      finder: "description",

      wrap: true,
    },
    {
      name: props.t("Leverage Charge"),
      selector: row => row.leverage_charge,
      sortable: true,
      finder: "leverage_charge",

      wrap: true,
    },
    {
      name: props.t("Quantity"),
      selector: row => row.count,
      sortable: true,
      finder: "count",
      wrap: true,
    },
    {
      name: props.t("Actions"),
      selector: row => <DetailsButton disabled={false} onClick={() => handleViewItemDetails(row.id)} />,
      sortable: true,
      finder: "product_name",
      wrap: true,
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };



  const handlePrint = useReactToPrint({ contentRef });
  const handlePrintItem = useReactToPrint({ contentRef: contentRefItem });
  const handlePrintItemNotPrinted = useReactToPrint({ contentRef: contentRefItemNotPrinted });


  const handlePrintInslugInfo = () => {
    if (inslugObject.process_status_id === 13) {
      toast.error(props.t("Please approve inslug first"));
      return;
    }

    handlePrint();
  }

  const handlePrintType = () => {
    if (filteredItemData.length < 1) {
      toast.error(props.t("Please add an item first"));
      return;
    }
    if (inslugObject.process_status_id === 13) {
      toast.error(props.t("Please approve inslug first"));
      return;
    }
    dispatch(
      openUpdateModal({
        body: (
          <div className="d-flex justify-content-center align-items-center">
            <div className='m-3'>
              <Button color='success' onClick={() => {
                dispatch(closeUpdateModal());
                handlePrintItem();
              }}
              >{props.t("All")}</Button>
            </div>
            <Button color='warning' onClick={() => {
              dispatch(closeUpdateModal());
              handlePrintItemNotPrinted();
            }}>
              {props.t("Not Scanned")}</Button>
          </div>
        ),
        onConfirm: async () => {
        },
      })
    );
  }


  const handleViewItemDetails = (id) => {
    if (inslugObject.process_status_id === 13) {
      toast.error(props.t("Please approve inslug first"));
      return;
    }
    navigate(`/inslug/item-qr/${id}`);
  };

  const handleApprove = () => {
    if (filteredItemData.length < 1) {
      toast.error(props.t("Please add an item first"));
      return;
    }
    dispatch(
      openModal({
        title: props.t("modal-approve-title"),
        body: props.t("modal-approve-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            await approveInslug(id); // Delete company
            toast.success(props.t("toast-success-approve-data"), { position: "top-right" });
            fetchInslugData();
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-approve-data"), { position: "top-right" });
          }
        }
      })
    );
  }

  const navigateUpdateScreen = () => {
    console.log("çalıştı mı");
    navigate(`/inslug/update/${id}`);
  }

  return (
    <div>
      <Card className="p-4 rounded-lg mb-4">
        { inslugObject.process_status_id === 13 ? <h6 className="glowing-text">{props.t("Not Approved")}</h6> : null}
        <div className="d-flex justify-content-between mb-2">
          <div className='d-flex'>
            <h5 className="text-primary">{props.t("Inslug Information")}</h5>
            {inslugObject.process_status_id !== undefined && (
              <span
                className="d-flex align-items-center justify-content-center text-center"
                style={{
                  fontSize: "9px",
                  height: "16px",
                  marginTop: "2px",
                  marginLeft: "8px",
                  padding: "2px 12px 0px 12px", // Daha az padding
                  borderRadius: "12px", // Oval kenarlar
                  backgroundColor:
                    inslugObject.process_status_id % 4 === 0
                      ? "#dc3545" // danger (kırmızı)
                      : inslugObject.process_status_id % 4 === 1
                        ? "#ffc107" // warning (sarı)
                        : inslugObject.process_status_id % 4 === 2
                          ? "#28a745" // success (yeşil)
                          : "#17a2b8", // info (mavi)
                  textAlign: "center",
                  color: "white"
                }}
              >
                {inslugObject.process_status_name}
              </span>

            )}
          </div>
          <div className='d-flex'>
            <Button className="m-0 mx-2" color="red" onClick={handlePrintInslugInfo}>
              <img src={qr} width={'30'} alt="" />
            </Button>
            {inslugObject.process_status_id === 13 && hasUpdatePermission(permissions, "DE_03") ? <Button className="glowing-button mx-2" color="primary" onClick={handleApprove}>{props.t("Approve")}</Button> : null}
            {(inslugObject.process_status_id === 13 || inslugObject.process_status_id === 14) && hasUpdatePermission(permissions, "DE_03") ?
              <Button color="warning" onClick={navigateUpdateScreen}>{props.t("Update")}</Button> : null}
          </div>
        </div>

        <SplitDataTable
          data={inslugData}
          columns={inslugColumns}
          customStyles={customStyles}
          t={props.t}
        />
      </Card>

      <Card className="p-4 rounded-lg">
        <h5 className="text-primary">{props.t("Product Information")}</h5>
        <DataTable
          columns={itemColumns}
          data={filteredItemData}
          customStyles={customStyles}
          highlightOnHover
          pagination
          noDataComponent={props.t("table-empty-message")}
          paginationComponentOptions={customPaginationOptions}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          subHeader
          subHeaderComponent={
            <div className='d-flex justify-content-end w-50'>
              <Button className="m-0 p-0 px-3" color="red" onClick={handlePrintType}>
                <img src={qr} width={'30'} alt="" />
              </Button>
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={itemSearch}
                onChange={(e) => setItemSearch(e.target.value.toLocaleUpperCase("tr"))}
                className="form-control w-50"
                maxLength={32}
              />
            </div>
          }
        />
      </Card>

      <DynamicPrint
        t={props.t}
        contentRef={contentRef}
        datas={inslugData}
        dataColumns={inslugColumns}
        dataObject={inslugObject}
        itemDatas={itemData}
        itemColumns={itemColumns}
        actionTitle='Inslug'
      />

      <div className="print-only">
        <div style={{ margin: "40px", fontFamily: "Arial, sans-serif" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: "32px",
            }}
          >
            <div>
              <img
                src={logoSrc}
                alt="Company Logo"
                style={{ width: "200px", height: "auto", marginBottom: "10px" }}
              />
              <div style={{ paddingLeft: "12px", fontSize: "14px", fontWeight: "bold", marginBottom: "12px" }}>
                Antwerp Container Transport
              </div>
              <div style={{ paddingLeft: "12px", fontSize: "12px" }}>{today()}</div>

            </div>
            <div className="text-end">
              <div className='text-end'>
                {/* <QRCodeCanvas className='mb-2' value={inslugObject.qr} size={80} /> */}
                <DynamicQr />
                <div>{inslugObject.qr}</div>
              </div>
            </div>
          </div>
          <h5 className='py-2 bg-primary text-center text-white font-weight-bold'>
            Inslug
          </h5>
          <div
            style={{
              gap: "20px",
              marginBottom: "48px"
            }}
          >
            <SplitDataTable
              data={inslugData}
              columns={inslugColumns}
              customStyles={customStyles}
              t={props.t}
            />
          </div>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              fontFamily: "Arial, sans-serif",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#FF9C73" }}>
                <th style={{ border: "1px solid #ddd", textAlign: "left" }}>
                  {props.t("Product Name")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Extern Party No")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Container No")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Description")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Leverage Charge")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Quantity")}
                </th>
              </tr>
            </thead>
            <tbody>
              {itemData.map((row, index) => (
                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.product_name}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.extern_party_no}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.container_no}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.description}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.leverage_charge}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.count}</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>



      <div ref={contentRefItem} className="print-only">
        <div className="container">
          <div className="row">
            {allQrList?.map((data, index) => (
              <div
                key={index}
                className="col-6 mb-4 mt-5" // Her satıra 3 sütun için col-4 kullanıyoruz
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "250px",
                    height: "200px",
                    border: "1px solid #ddd",
                    padding: "10px",
                    boxSizing: "border-box",
                  }}
                >
                  <p className="mb-1">
                    <strong>{inslugObject.company_name}</strong>
                  </p>
                  <div className="d-flex justify-content-between">
                    <div style={{ paddingTop: 20, flex: 1, fontSize: "9px" }}>
                      <p className="mb-1">
                        <strong>{data.product_name}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.container_no}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.extern_party_no}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.leverage_charge}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.count}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.qr}</strong>
                      </p>
                    </div>
                    <div style={{ flexShrink: 0 }}>
                      {/* <QRCodeCanvas value={data.qr} size={120} /> */}
                      <DynamicQr size={120} value={data.qr || "N/A"} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div ref={contentRefItemNotPrinted} className="print-only">
        <div className="container">
          <div className="row">
            {allQrList?.filter(item => item.is_printed_inslug === false).map((data, index) => (
              <div
                key={index}
                className="col-6 mb-4 mt-5" // Her satıra 3 sütun için col-4 kullanıyoruz
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "250px",
                    height: "200px",
                    border: "1px solid #ddd",
                    padding: "10px",
                    boxSizing: "border-box",
                  }}
                >
                  <p className="mb-1">
                    <strong>{inslugObject.company_name}</strong>
                  </p>
                  <div className="d-flex justify-content-between">
                    <div style={{ paddingTop: 20, flex: 1, fontSize: "9px" }}>
                      <p className="mb-1">
                        <strong>{data.product_name}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.container_no}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.extern_party_no}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.leverage_charge}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.count}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>{data.qr}</strong>
                      </p>
                    </div>
                    <div style={{ flexShrink: 0 }}>
                      {/* <QRCodeCanvas value={data.qr} size={120} /> */}
                      <DynamicQr size={120} value={data.qr || "N/A"} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <UpdateModal />
      <AcceptModal />
    </div>
  );
};

ViewInslug.propTypes = {
  match: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(ViewInslug);
