import React, { useState, useEffect } from 'react';
import { Input, Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setProductGroups } from 'store/depot/productGroup/action'; // Redux actions
import DynamicInput from 'components/Common/Form/DynamicInput';
import { createProductCategory, getProductCategories } from 'service/depot/productCategoryService';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { closeModal, openModal } from 'store/modal/actions';
import { customValidationError } from 'components/Common/customValidationError';

const ProductGroupForm = props => {
  const dispatch = useDispatch();

  // Form state, JSON yapısına göre düzenlendi
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    setFormData({
      ...formData,
      [e.target.name]: inputData,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  const handleConfirm = async () => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          try {
            await createProductCategory(formData)
              .then(async () => {
                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                const updatedProducts = await getProductCategories(); // Ürünleri güncelle
                dispatch(setProductGroups(updatedProducts)); // Redux state güncelleme
                setFormData({});
              })
            dispatch(closeModal()); // Close the modal after successful update

          } catch (error) {
            console.error("Ürün grubu eklenemedi:", error);
            //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            customValidationError(error,props);
          }
        }
      })
    );

  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
      <div className='d-flex justify-content-between mb-4'>
        <h5 className="text-primary">{props.t("Add Product Category")}</h5>
        <Button className='text-white' color="success" type='submit'>
          {props.t("Save")}
        </Button>
      </div>
        <Row className='mb-4' form>
          <Col md={6}>
            <FormGroup>
              <Label for="name">{props.t("Product Category Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter product category name")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>
        </Row>
        </Form>
    </div>
  );
};

ProductGroupForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ProductGroupForm)