// src/store/selectedCountry/selectedCountryActions.js
import { SET_SELECTED_COMPANY, CLEAR_SELECTED_COMPANY } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setSelectedCompany = (city) => ({
  type: SET_SELECTED_COMPANY,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedCompany = () => ({
  type: CLEAR_SELECTED_COMPANY,
});
