import axios from 'axios';
// API URLs
import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const SUPPORT = '/support';
const CREATE = '/create';
const GET = '/get';
const DELETE = '/delete';

/**
 * Yeni Destek oluşturma fonksiyonu
 * @param {Object} data - Destek oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createSupport = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${SUPPORT}${CREATE}`,data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Support eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Destek listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getSupports = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${SUPPORT}${GET}`);
    return response.data;
  } catch (error) {
    console.error('Support listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen destek ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteSupport = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${SUPPORT}${DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Support silinirken bir hata oluştu:', error);
    throw error;
  }
};