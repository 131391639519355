import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Container } from "reactstrap";

// Ana bileşen
const PrintPage = () => {
    const contentRef = useRef();
    const reactToPrintFn = useReactToPrint({ contentRef });

    return (
        <div>
            <button onClick={reactToPrintFn}>Print</button>
            <div ref={contentRef}>Content to print</div>
        </div>
    );
};

export default PrintPage;
