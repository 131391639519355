import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next";
import { setTimer } from 'store/timer/actions';
import { closeFastCreateModal } from 'store/fastCreateModal/actions';

const FastCreateModal = props => {
  const dispatch = useDispatch();

  // Get the modal content from Redux state
  const fastCreateModalState = useSelector((state) => state.fastCreateModal);
  const timer = useSelector((state) => state.timerOpen.timer);

  const { isOpen, content } = fastCreateModalState;

  useEffect(() => {
    console.log("timer");
    console.log(timer);
    console.log("---------");
  }, [timer])


  // Handle modal close
  const handleClose = () => {
    dispatch(closeFastCreateModal());
  };

  // Handle form submit
  const handleSubmit = (e) => {
    content.onConfirm();  // Onay fonksiyonunu çalıştır
  };

  // Enter tuşuna basıldığında formu submit et
  const handleKeyDown = (e) => {
    try {
      console.log(timer);
      if (e.key === 'Enter') {
        if (timer === true) {
          dispatch(setTimer(false));
          handleSubmit(e); // Formu submit et
          const interval = setInterval(() => {
            dispatch(setTimer(true));
          }, 1000);
        }
      }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <ModalHeader toggle={handleClose}>
          <div className='text-success'>
            {props.t("Fast Save Action")}
          </div>
        </ModalHeader>
        <ModalBody>
            <div className='mb-3'>
              <span className='mb-2'>{props.t("Data")}</span> <span className='mx-2'>:</span>
              <strong className='text-warning '>{props.t(content.title)}</strong>
            </div>
            {props.t("modal-save-body")}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit}>
            {content.confirmLabel || props.t("Confirm")}
          </Button>
          <Button type="submit" color="danger" onClick={handleClose}>
            {content.cancelLabel || props.t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </form>
  );
};

FastCreateModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(FastCreateModal);
