import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../../store/actions";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'; 
import InlsugQrList from "./inslugQrList";
import { Card } from "reactstrap";

const InslugQrListPage = props => {
    const { t } = useTranslation();
    document.title = t("Inslug List");
    const breadcrumbItems = [

    ];
    useEffect(() => {
        props.setBreadcrumbItems("", breadcrumbItems);
    }, []);

    return (
        <React.Fragment>
            <Card>
                <InlsugQrList />
            </Card>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(InslugQrListPage);
