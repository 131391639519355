import { SET_INCOME_FORM_DATA, CLEAR_INCOME_FORM_DATA } from './actionTypes';

export const setIncomeFormData = (formData) => ({
  type: SET_INCOME_FORM_DATA,
  payload: formData,
});

export const clearIncomeFormData = () => ({
  type: CLEAR_INCOME_FORM_DATA,
});
