// src/store/selectedCountry/selectedCountryActions.js
import { SET_SELECTED_PRODUCT_GROUP, CLEAR_SELECTED_PRODUCT_GROUP } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setSelectedProductGroup = (city) => ({
  type: SET_SELECTED_PRODUCT_GROUP,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedProductGroup = () => ({
  type: CLEAR_SELECTED_PRODUCT_GROUP,
});
