// inslug/reducer.js

import { SET_UPDATE_INSLUG_DATA, SUBMIT_UPDATE_INSLUG_FORM, FETCH_UPDATE_INSLUG_SUCCESS, RESET_UPDATE_INSLUG_FORM } from './actionTypes';

// Define initial state
const initialState = {};

// Reducer to handle actions
const updateInslugReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UPDATE_INSLUG_DATA:
      return {
        ...state,
        ...action.payload, // Update specific fields from form
      };

    case SUBMIT_UPDATE_INSLUG_FORM:
      return {
        ...state,
        updateInslugList: [...state.updateInslugList, action.payload], // Add the submitted form to the list
      };

    case FETCH_UPDATE_INSLUG_SUCCESS:
      return {
        ...state,
        ...action.payload, // Pre-fill form if needed
      };

    case RESET_UPDATE_INSLUG_FORM:
      return initialState; // Reset state to initialState

    default:
      return state;
  }
};

export default updateInslugReducer;

