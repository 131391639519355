import React from "react"

import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../store/actions";
import FormUploadItem from "./FormUploadItem";

const FormUpload = (props) => {
  return (
    <React.Fragment>
        <FormUploadItem />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(FormUpload);