import axios from 'axios';
// API URLs
import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const CON_CIT = '/muhasebe-city';
const COUNTRY = '/country';

const CREATE = '/create';
const GET = '/get';
const GET_DELETED = '/get-deleted';
const UPDATE = '/update';
const DELETE = '/delete';

/**
 * Yeni firma oluşturma fonksiyonu
 * @param {Object} data - Firma oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */

export const createMuhasebe = async (data) => {
  console.log("servis iç");
  console.log(data);
  try {
    const response = await postWithToken(`${API_BASE_URL}${CON_CIT}${COUNTRY}${CREATE}`,data); // url/city/country/create
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Ülke eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getMuhasebe = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${CON_CIT}${COUNTRY}${GET}`);
    console.log("responseee");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Ülkeler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedCountries = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${CON_CIT}${COUNTRY}${GET_DELETED}`);
    console.log("responseee");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Ülkeler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateMuhasebe = async (data) => {
  try {
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${CON_CIT}${COUNTRY}${UPDATE}`,data);
    return response.data;
  } catch (error) {
    console.error('Ülke güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteCountry = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${CON_CIT}${COUNTRY}${DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};