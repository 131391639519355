import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { hasViewPermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';

const DepotDashboardActions = props => {
    const permissions = useSelector((state) => state.permissions?.permissions);

    const newInslug =
        <Col lg={4}>
            <Link to="/inslugs">
                <div className="mx-3">
                    <Button color="primary" className="py-3 w-100">
                        {props.t("Inslug")}
                    </Button>
                </div>
            </Link>
        </Col>;

    const newUitslug =
        <Col lg={4}>
            <Link to="/uitslugs">
                <div className="mx-3">
                    <Button color="warning" className="py-3 w-100">
                        {props.t("Uitslug")}
                    </Button>
                </div>
            </Link>
        </Col>;

    const newHeleven =
        <Col lg={4}>
            <Link to="/helevens">
                <div className="mx-3">
                    <Button color="success" className="py-3 w-100">
                        {props.t("Heleven")}
                    </Button>
                </div>
            </Link>
        </Col>;

    return (
        <div className="d-flex mb-4 w-100">
            {hasViewPermission(permissions, "DE_03") ? newInslug : null}
            {hasViewPermission(permissions, "DE_04") ? newUitslug : null}
            {hasViewPermission(permissions, "DE_05") ? newHeleven : null}
        </div>
    );
};

DepotDashboardActions.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(DepotDashboardActions)