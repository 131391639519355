// src/store/selectedCountry/selectedCountryActions.js
import { SET_SELECTED_DEPOT_CITY, CLEAR_SELECTED_DEPOT_CITY } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setSelectedDepotCity = (city) => ({
  type: SET_SELECTED_DEPOT_CITY,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearSelectedDepotCity = () => ({
  type: CLEAR_SELECTED_DEPOT_CITY,
});
