import { SET_FORM_DATA, CLEAR_FORM_DATA } from './actionTypes';

export const setAddCompanyFormData = (formData) => ({
  type: SET_FORM_DATA,
  payload: formData,
});

export const clearAddCompanyFormData = () => ({
  type: CLEAR_FORM_DATA,
});
