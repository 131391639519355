// actions.js
import { SET_TIMER } from './actionTypes';

// Action to open modal with dynamic content
export const setTimer = (content) => {
  return {
    type: SET_TIMER,
    payload: content, // Dynamic content for the modal
  };
};

