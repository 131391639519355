import { SET_EXTERN_COMPANY_FORM_DATA, CLEAR_EXTERN_COMPANY_FORM_DATA } from './actionTypes';

export const setAddExternCompanyFormData = (formData) => ({
  type: SET_EXTERN_COMPANY_FORM_DATA,
  payload: formData,
});

export const clearAddExternCompanyFormData = () => ({
  type: CLEAR_EXTERN_COMPANY_FORM_DATA,
});
