const ACCOUNTING = {
    COUNTRY: 1,
    TAX_TYPE: 2,
    DISCOUNT_ID: 3,
    INCOME_ITEM: 4,
    OUTCOME_ITEM: 5,
    PAYMENT_METHOD: 6,
    CURRENCY: 7,
    PAYMENT_STATUS: 8, 
    INCOME_OUTCOME: 9,
    INCOME: 10,
    OUTCOME: 11,
    QUANTITY: 99,
};

export default ACCOUNTING;