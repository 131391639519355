import React, { useEffect } from 'react';
import { Button, Table, FormGroup, Card } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { addInslugItemRow, setInslugItemData, removeInslugItemRow, resetInslugItems } from 'store/depot/inslugItem/actions';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { inslugItemInfo } from 'service/depot/inslugItemService';
import { resetSelectedProducts, setSelectedProducts } from 'store/depot/selectedProducts/actions';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { setSelectedClientProducts } from 'store/depot/selectedClientProducts/actions';
import { closeModal, openModal } from 'store/modal/actions';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import { toast } from 'react-toastify';
import { createInslug } from 'service/depot/inslugService';
import { resetInslugForm } from 'store/depot/inslug/actions';
import { useNavigate } from 'react-router-dom';

const AddInslugItem = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const newInslug = useSelector((state) => state.inslug);
  const inslugItems = useSelector((state) => state.inslugItems?.inslugItems || []);
  const selectedProducts = useSelector((state) => state.selectedProducts?.selectedProducts || []);
  //const [clientProducts, setClientProducts] = useState([]);

  const clientProducts = useSelector((state) => state.selectedClientProducts?.selectedClientProducts || []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientProductData = await inslugItemInfo()
        console.log("clientProductInfo");
        console.log(clientProductData);
        const formattedClientProducts = clientProductData.map(product => ({
          clientNo: product.client_no,
          value: product.client_product_id,
          productNo: product.product_no,
          productName: product.product_name,
          label: customLabel(product)
        }));

        dispatch(setSelectedClientProducts(formattedClientProducts));
      } catch (error) {
        console.log(error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }

    }
    fetchData()
  }, [dispatch]);

  const handleSelectChange = (index, field, selectedOption) => {
    console.log("handleSelectChange");
    console.log(selectedOption);
    let updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts[index] = selectedOption;  // Seçilen ürünü ilgili index'te güncelle
    dispatch(setSelectedProducts(updatedSelectedProducts));

    dispatch(setInslugItemData(index, field, selectedOption ? selectedOption.value : null));
    //dispatch(setInslugItemData(index, "client_product_id", selectedOption ? selectedOption.client_product_id : null));
  };

  useEffect(() => {
    console.log("dataaaa");
    console.log(newInslug);
  }, [newInslug])

  useEffect(() => {
    console.log("itemsss");
    console.log(inslugItems);
  }, [inslugItems])

  const handleCreateOption = (inputValue, index) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          //handleCreateOption(inputValue);
          dispatch(closeModal());
        }
      }));
  };

  const handleInputChange = (index, e) => {
    dispatch(setInslugItemData(index, e.target.name, e.target.value.toLocaleUpperCase("tr")));
  };

  /*   // Handle input changes and dispatch actions to update Redux state
    const handleCountChange = (index, e) => {
      if (/^\d*$/.test(e.target.value.toString())) {
        const value = e.target.value.replace(/[^\d]/g, "");
        const name = e.target.name;
        dispatch(setInslugItemData(index, name, value));
      }
    }; */

  const handleCountChange = (index, e) => {
    console.log("eeeee");
    console.log(e);
    dispatch(setInslugItemData(index, "count", e?.value));
  }

  const addNewRow = () => {
    console.log("selecteeeeeeedProdduuuuccttsss");
    console.log(selectedProducts);
    dispatch(addInslugItemRow());
    dispatch(setSelectedProducts([...selectedProducts, null]));  // Yeni satır için başlangıçta null seçili
  };

  /*   const removeLastRow = () => {
      if (inslugItems.length > 0) {
        dispatch(removeLastInslugItemRow());  // Redux'tan son satırı sil
        let updatedSelectedProducts = [...selectedProducts];  // Mevcut listeyi kopyalayın
        updatedSelectedProducts = updatedSelectedProducts.slice(0, -1);  // Son öğeyi sil
        dispatch(setSelectedProducts(updatedSelectedProducts));  // Redux state'i güncelleyin
      }
    }; */

  const removeIndexRow = (index) => {
    if (inslugItems.length > 0) {
      dispatch(
        openModal({
          title: props.t("modal-delete-title"),
          body: <span>{props.t("modal-delete-body")}</span>,
          onConfirm: async () => {
            dispatch(closeModal());
            dispatch(removeInslugItemRow(index));
          }
        })
      );
    }
  };

  const customLabel = (product) => {
    return <div className="d-flex align-items-center">
      {/* <span className='shadow rounded bg-info px-1 text-white mx-2' color="info" pill>{`${product.client_no}`}</span>
      <span className=''>{product.company_name}</span>
      <span className='mx-2'>|</span> */}
      {/* <span className='rounded bg-warning px-1 text-white mx-2' color="info" pill>{`${product.product_no}`}</span> */}
      <span>{product.product_name}</span>
    </div>;
  }

  const createInslugJson = (data) => {
    const inslugData = {
      client_no: data.client_no,
      transport_company_no: data.transport_company_no,
      booking_date: data.booking_date,
      document_no: data.document_no,
      extern_document_no: data.extern_document_no,
      agency_reference: data.agency_reference,
      description: data.description,
      eta: data.eta,
      closing_out: data.closing_out,
      closing_in: data.closing_in,
      customs_status_id: data.customs_status_id,
      supplier_company_no: data.supplier_company_no,
      carrier_type_id: data.carrier_type_id,
      loading_status_id: data.loading_status_id,
      process_status_id: data.process_status_id,
    };
    return inslugData;
  };

  function extractSelectedFields(data) {
    console.log("extract json");
    console.log(data);
    // Yeni JSON arrayini oluştur
    const result = data.map(item => {
      return {
        client_product_id: item.client_product_id || null,
        container_no: item.container_no || null,
        leverage_charge: item.leverage_charge || null,
        extern_party_no: item.extern_party_no || null,
        description: item.description || null,
        count: parseInt(item.count, 10) || null
      };
    });

    return result;
  }

  const validation = () => {
    if (!newInslug.client_no) {
      toast.error(props.t("error-company-empty"), { position: "top-right" });
      return false;
    }
    if (!newInslug.document_no) {
      toast.error(props.t("error-document-empty"), { position: "top-right" });
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = validation();
    if (validationResult === false) {
      return;
    }
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          try {
            // Eğer client_no null değilse, işlem yapılır
            const inslugData = createInslugJson(newInslug);
            const inslugItemData = extractSelectedFields(inslugItems);
            const data = { inslug: inslugData, inslugItem: inslugItemData };
            console.log(data);
            await createInslug(data)
              .then(() => {
                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                dispatch(resetInslugForm()); // Formu sıfırla
                dispatch(resetInslugItems());
                dispatch(resetSelectedProducts([]));
                navigate('/inslugs');
              })
            dispatch(closeModal()); // Close the modal after successful update

          } catch (error) {
            console.error("Hata:", error);
            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  return (
    <Card className="p-4 rounded-lg">
      <div className="d-flex mb-3 justify-content-between">
        <h5 className='text-primary'>{props.t("Product Information")}</h5>
        <div>
          <Button className="btn btn-md mx-2" color="success" onClick={addNewRow}>+</Button>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th>
              {props.t("Company Product")}
              {/*  <NoAuthText hasPermission={hasViewPermission(permissions, "DE_09")} props={props} /> */}
            </th>
            <th>{props.t("Container No")}</th>
            <th>{props.t("Leverage Charge")}</th>
            <th>{props.t("Extern Party No")}</th>
            <th>{props.t("Description")}</th>
            <th>{props.t("Product Count")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {inslugItems.map((item, index) => (
            <tr key={index}>
              <td className='px-0 m-0'>
                <DynamicCreatableSelect
                  name="client_product_id"
                  value={clientProducts.find(option => option.value === item.client_product_id)}
                  options={clientProducts.filter(product => product.clientNo === newInslug.client_no)} // Filtreleme işlemi
                  //noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selectedOption) => handleSelectChange(index, 'client_product_id', selectedOption)}
                  onCreateOption={(inputValue) => handleCreateOption(inputValue, index)}  // Yeni ürün ekleme
                  placeholder={props.t("Select company product")}
                  formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  noOptionsMessage={() => props.t("no-option-message")}
                  filterOption={(option, inputValue) => {
                    console.log("addInslugItem option");
                    console.log(option);
                    console.log("addInslugItem inputValue");
                    console.log(inputValue);
                    const productNo = option.data.productNo || "";
                    const productName = option.data.productName || "";
                    const productNoMatch = productNo.toLowerCase().includes(inputValue.toLowerCase());
                    const productNameMatch = productName.toLowerCase().includes(inputValue.toLowerCase());

                    return productNoMatch || productNameMatch;
                  }}
                />
              </td>
              <td className='px-1 m-0'>
                <DynamicInput
                  type="text"
                  name="container_no"
                  value={item.container_no}
                  onChange={(e) => handleInputChange(index, e)}
                  placeholder={props.t("Enter container no")}
                  required
                  maxLength={32}
                />
              </td>
              <td className='px-1 m-0'>
                <DynamicInput
                  type="text"
                  name="leverage_charge"
                  value={item.leverage_charge}
                  placeholder={props.t("Enter leverage charge information")}
                  onChange={(e) => handleInputChange(index, e)}
                  maxLength={32}
                />
              </td>
              <td className='px-1 m-0'>
                <DynamicInput
                  type="text"
                  name="extern_party_no"
                  value={item.extern_party_no}
                  placeholder={props.t("Enter extern party no")}
                  onChange={(e) => handleInputChange(index, e)}
                  maxLength={32}

                />
              </td>
              <td className='px-1 m-0'>
                <DynamicInput
                  type="text"
                  name="description"
                  value={item.description}
                  placeholder={props.t("Enter description")}
                  onChange={(e) => handleInputChange(index, e)}
                  maxLength={255}
                />
              </td>
              <td className='px-1 m-0'>
                <DynamicSelect
                  id="count"
                  name="count"
                  value={item.count ? { value: item.count, label: item.count } : null}
                  placeholder={props.t("Quantity")}
                  noOptionsMessage={() => props.t("no-option-message")}
                  options={Array.from({ length: 100 }, (_, i) => ({
                    value: i + 1,
                    label: i + 1,
                  }))}
                  isSearchable={true} // Arama işlevini aktif eder
                  filterOption={(option, inputValue) =>
                    option.label.toString().includes(inputValue)
                  }
                  onChange={(e) => handleCountChange(index, e)}
                />
              </td>
              <td className='px-0 m-0'>
                <div className='d-flex align-items-center justify-content-center my-2'>
                  <Button className='btn btn-md' color="danger" onClick={() => removeIndexRow(index)}>-</Button> {/* Son satırı sil */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className='text-end'>
        <Button onClick={handleSubmit} className='btn btn-md' color="success">
          {props.t("Save")}
        </Button>
      </div>
    </Card>
  );
};

AddInslugItem.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(AddInslugItem)