// DetailView.js
import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import 'styles/custom/detail-view.css'; // Stil dosyasını ekleyin

// Tarih olup olmadığını kontrol eden ve uygun formatta döndüren fonksiyon
const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) {
    return dateString; // Geçerli bir tarih değilse olduğu gibi döndür
  }
  
  // Gün ay yıl saat dakika formatında döndür
  return date.toLocaleDateString("tr-TR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const DetailView = ({ val: val, t }) => {
  const {createdAt, updatedAt, is_deleted, ...rest} = val;
  console.log(val);
  console.log(t);

  if (!val) return null;

  return (
    <div className="detail-view-container">
      <Table bordered>
        <tbody>
          {Object.keys(rest).map((key, index) => (
            <tr key={index}>
              <td><strong>{t(key.replace(/_/g, " "))}</strong></td>
              <td>{rest[key] ? formatDate(rest[key].toString()) : t("missing")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

DetailView.propTypes = {
  data: PropTypes.object, // Artık "inslug" yerine genel "data" prop kullanıyoruz
  t: PropTypes.any,
};

export default withTranslation()(DetailView);
