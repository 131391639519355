import { SET_CREATE_UNIT_FORM_DATA, CLEAR_CREATE_UNIT_FORM_DATA } from './actionTypes';

export const setCreateUnitFormData = (formData) => ({
  type: SET_CREATE_UNIT_FORM_DATA,
  payload: formData,
});

export const clearCreateUnitFormData = () => ({
  type: CLEAR_CREATE_UNIT_FORM_DATA,
});
