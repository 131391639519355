// API URLs
import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const DEPOT = '/depot';
const DEFINITIONS = '/definitions';
const GET_RECURSIVE = '/get-recursive';
const GET_DEFINITIONS = '/get-definitions';

const GET_MODULE_INFO = '/menu';

const GET_ALL_DEFINITIONS = '/get-all-definitions';
const GET_MULTI = '/get-multi';
const TYPE = '/type';
const ITEM = '/def';
const CREATE = '/create';
const GET = '/get';
const GET_DELETED = '/get-deleted';
const UPDATE = '/update';
const DELETE = '/passive';

/**
 * Yeni firma oluşturma fonksiyonu
 * @param {Object} data - Firma oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createDefinition = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${CREATE}`, data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Ülke eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getDefinitionsWithDeleted = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${GET_DEFINITIONS}`, { id });
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getDefinitions = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${GET_RECURSIVE}`, { id });
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getModuleInfo = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${GET_MODULE_INFO}`, { id });
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getAllDefinitions = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${GET_ALL_DEFINITIONS}`, { id });
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getMultiDefinitions = async (arr) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${GET_MULTI}`, {ids: arr});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedDefinitions = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${GET_DELETED}`);
    console.log("responseee");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Ülkeler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateDefinitionType = async (data) => {
  try {
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${TYPE}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Ülke güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateDefinitionItemData = async (data) => {
  try {
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Ülke güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteDefinition = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${DEFINITIONS}${DELETE}`, { id });
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};