    import React, { useState, useEffect } from 'react';
    import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
    import { toast } from 'react-toastify';
    import 'react-toastify/dist/ReactToastify.css';
    import { useDispatch, useSelector } from 'react-redux';
    import { openModal, closeModal } from 'store/modal/actions';
    import DynamicInput from 'components/Common/Form/DynamicInput';
    import PropTypes from "prop-types";
    import { withTranslation } from "react-i18next";
    import FormUpload from 'pages/Forms/FormUpload'; // Import FormUpload
    import { updateLanguage, getLanguages } from 'service/depot/languageService';
    import { setLanguages } from 'store/languages/action';
    import { clearSelectedImage } from 'store/depot/selectedImage/action';
    import { closeUpdateModal } from 'store/updateModal/actions';
    import AcceptModal from 'components/Common/Modals/acceptModal';

    const UpdateLanguage = props => {
        const dispatch = useDispatch();
        const selectedImage = useSelector((state) => state.selectedImage?.selectedImage || []); // Use Redux state for selected image
        const [languageFormData, setLanguageFormData] = useState({});

        useEffect(() => {
            // Seçilen dili bileşene aktar ve bayrak resmini ayarla
            if (props.language) {
                setLanguageFormData({
                    ...props.language,
                    isocode: props.language.isocode || "",
                    name: props.language.name || ""
                });
                
                // Mevcut bayrak resmini Redux'a aktar
              /*   if (props.language.flag) {
                    dispatch(setSelectedImage([{
                        preview: props.language.flag,
                    }]));
                } */
            }
            
            return () => {
                // Bileşen unmount olduğunda seçimi temizle
                dispatch(clearSelectedImage());
            };
        }, [props.language, dispatch]);

        const handleChange = (e) => {
            const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
            setLanguageFormData({
                ...languageFormData,
                [e.target.name]: inputData,
            });
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            handleConfirm();
        };

        const handleConfirm = async () => {
            console.log(languageFormData);
            dispatch(
                openModal({
                    title: props.t("modal-update-title"),
                    body: props.t("modal-update-body"),
                    onConfirm: async () => {
                        dispatch(closeModal()); // Modalı kapatır
                        const { createdAt, updatedAt, is_passive, ...rest } = languageFormData;
                        let finalData = rest;

                        // Bayrak resmi değiştirildiyse güncelle
                        if (selectedImage.length > 0) {
                            console.log("selectedImage geldi");
                            console.log(selectedImage[0]);
                            const flag = await convertFileToBase64(selectedImage[0]);
                            finalData = { ...finalData, flag };
                        }

                        await updateLanguage(finalData)
                            .then(async () => {
                                toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                                const updatedLanguages = await getLanguages();
                                dispatch(setLanguages(updatedLanguages));
                                dispatch(closeUpdateModal());
                                window.location.reload();
                            })
                            .catch((error) => {
                                console.error("Dil güncellenemedi:", error);
                                toast.error(props.t("toast-error-update-data"), { position: "top-right" });
                            });
                    }
                })
            );
        };

        function convertFileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        }

        return (
            <div>

                    <div className='d-flex justify-content-between mb-4'>
                        <h5 className="text-primary">{props.t("Update Language")}</h5>
                        <Button color="warning" onClick={handleSubmit}>
                            {props.t("Update")}
                        </Button>
                    </div>
                    <Row className='mb-4' form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="name">{props.t("Language")}</Label>
                                <DynamicInput
                                    type="text"
                                    name="name"
                                    id="name"
                                    required
                                    value={languageFormData.name}
                                    onChange={handleChange}
                                    placeholder={props.t("Enter language")}
                                    minLength={3}
                                    maxLength={32}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="isocode">{props.t("ISO Code")}</Label>
                                <DynamicInput
                                    type="text"
                                    name="isocode"
                                    id="isocode"
                                    required
                                    value={languageFormData.isocode}
                                    onChange={handleChange}
                                    placeholder={props.t("Enter ISO code")}
                                    minLength={3}
                                    maxLength={3}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormUpload /> {/* Mevcut resim Redux state ile gösterilecek */}

                <AcceptModal />
            </div>
        );
    };

    UpdateLanguage.propTypes = {
        language: PropTypes.object,
        t: PropTypes.any,
    }

    export default withTranslation()(UpdateLanguage);
