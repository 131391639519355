// src/store/selectedCountry/selectedCountryActions.js
import { SET_CURRENTS, CLEAR_CURRENTS, UPDATE_UITSLUG_ITEM } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setCurrents = (city) => ({
  type: SET_CURRENTS,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearCurrents = () => ({
  type: CLEAR_CURRENTS,
});


