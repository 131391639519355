// actions.js
import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SAGA,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  SET_IS_LOADING
} from "./actionTypes";

// Şifre sıfırlama actionları
export const userForgetPassword = (user, history, errorMessage, successMessage) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history, errorMessage, successMessage },
  };
};

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  };
};

// Şifre değiştirme actionları
export const changePasswordRequest = ({ newPassword, token, navigate }) => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: { newPassword, token, navigate },
  };
};

// Şifre değiştirme actionları
export const changePasswordSaga = ({ newPassword, token, navigate }) => {
  return {
    type: CHANGE_PASSWORD_SAGA,
    payload: { newPassword, token, navigate },
  };
};

export const changePasswordSuccess = message => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const changePasswordError = message => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: message,
  };
};

export const setIsLoading = message => {
  return {
    type: SET_IS_LOADING,
    payload: message,
  };
};
