import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "store/actions";
import LanguageForm from './languages'

const LanguagePage = (props) => {

    const { t } = useTranslation();
    document.title = t("Languages");
    const breadcrumbItems = [];
    useEffect(() => {
        props.setBreadcrumbItems('', breadcrumbItems)
    },)

    return (
        <div>
            <LanguageForm />
        </div>
    );
};

export default connect(null, { setBreadcrumbItems })(LanguagePage);
