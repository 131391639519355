// src/store/selectedCountry/selectedCountryReducer.js
import { SET_HELEVEN_ITEM_LIST, CLEAR_HELEVEN_ITEM_LIST } from './actionTypes';

const initialState = {
  list: [], // Başlangıçta seçilen ülke boş
};

const helevenItemListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HELEVEN_ITEM_LIST:
      return {
        ...state,
        list: action.payload,
      };

    case CLEAR_HELEVEN_ITEM_LIST:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
};

export default helevenItemListReducer;
