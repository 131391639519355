import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { createProduct, getProductCategory, getProducts } from 'service/depot/productService'; // Product ve Kategori servisi
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setProducts } from 'store/depot/product/action'; // Redux actions
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import DynamicInput from 'components/Common/Form/DynamicInput';
import AcceptModal from 'components/Common/Modals/acceptModal';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { createProductCategory, getProductCategories } from 'service/depot/productCategoryService';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { hasCreatePermission, hasViewPermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import { createCompany, getCompanies } from 'service/depot/companyService';
import { createClientProduct } from 'service/depot/clientProductService';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import { customValidationError } from 'components/Common/customValidationError';
import fastCreateModal from 'components/Common/Modals/fastCreateModal';

const ProductForm = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);

  // Form state, JSON yapısına göre düzenlendi
  const [formData, setFormData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [productGroups, setProductGroups] = useState([]);

  const [selectedProductGroup, setSelectedProductGroup] = useState(null); // Seçilen kategoriyi tutacak state
  const md = 6;
  const xl = 4;

  // Kategori verilerini çekmek için useEffect
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const productGroupList = await getProductCategory(); // Kategorileri çekiyoruz
        const companies = await getCompanies(); // Kategorileri çekiyoruz

        const formattedCompanies = companies.map((company) => ({
          value: company.client_no,
          label: company.name,
        }));
        setCompanies(formattedCompanies);
        setProductGroups(productGroupList); // Kategorileri state'e set ediyoruz
      } catch (error) {
        //toast.error("Kategoriler getirilirken bir hata oluştu.", { position: "top-right" });
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    setFormData({
      ...formData,
      [e.target.name]: inputData,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(openModal(
      {
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          handleConfirm();
        }
      }
    )); // Modalı açıyoruz
  };

  const handleSelectChange = (field, selected) => {
    setSelectedProductGroup(selected)
    if (selected) {
      setFormData({ ...formData, [field]: selected.value });
    }
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  useEffect(() => {
    console.log("selectedCategory güncellendi");
    console.log(selectedProductGroup);
  }, [selectedProductGroup])

  const handleConfirm = async () => {
    dispatch(closeModal());
    const { client_no, ...rest } = formData;
    await createProduct(rest)
      .then(async (addedProduct) => {
        console.log("added Product");
        console.log(addedProduct);

        const updatedProducts = await getProducts();
        dispatch(setProducts(updatedProducts));
        setSelectedProductGroup(null);
        setFormData({});
        if (client_no && client_no != "") {
          await createClientProduct({ client_no: client_no, product_no: addedProduct.product_no }).then(() => {
          });
        }
        toast.success(props.t("toast-success-save-data"), { position: "top-right" });
      })
      .catch((error) => {
        console.error("Ürün eklenemedi:", error);
        //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
        customValidationError(error,props);
      });
     // Modalı kapatır
  };

  const handleCreateProductGroup = async (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_07")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }

    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          //const newProductCategoryNo = Math.random().toString(36).substr(2, 8).toUpperCase()
          const data = { name: inputValue };
          await createProductCategory(data)
            .then(async (addedProductGroup) => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
              const updatedProducts = await getProductCategories();
              setProductGroups(updatedProducts);
              const newData = { value: addedProductGroup.product_category_no, label: addedProductGroup.name };
              handleSelectChange("product_category_no", newData);
            })
            .catch((error) => {
              console.error("Ürün grubu eklenemedi:", error);
              //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
              customValidationError(error,props);
            });
        }
      }));// Modalı açıyoruz
  };

  const handleSelectCompanyChange = (field, selected) => {
    setFormData({ ...formData, [field]: selected?.value });
  };

  const handleCreateCompany = (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_01")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openFastCreateModal({
        title: inputValue,
        onConfirm: async () => {
          dispatch(closeFastCreateModal());
          // Yeni bir şirket oluşturmak için:
          const newCompany = {
            //client_no: Math.random().toString(36).substr(2, 8).toUpperCase(), // Rastgele 8 karakterli müşteri numarası
            name: inputValue,  // Kullanıcının girdiği değer
          };


          await createCompany(newCompany)
            .then(async (addedCompany) => {
              const newCompanyList = await getCompanies();// Yeni şirketleri yeniden getir
              const formattedCompanies = newCompanyList.map(company => ({
                value: company.client_no,
                label: company.name
              }));
              setCompanies(formattedCompanies);
              setFormData({ ...formData, ["client_no"]: addedCompany.client_no });
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); 
        }
      }));// Modalı açıyoruz
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Add Product")}</h5>
          <button className='btn btn-md bg-success text-white' color="primary" type="submit">
            {props.t("Save")}
          </button>
        </div>
        <Row className='mb-4' form>
          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="product_category_no">{props.t("Product Category")}</Label>
              {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_07")} props={props} />
              {hasViewPermission(permissions, "DE_07") ? */}
              <DynamicCreatableSelect
                options={productGroups.map((category) => ({
                  value: category.product_category_no,  // value'da client_no kullanıyoruz
                  label: category.name,  // label'da company.name kullanıyoruz
                }))}
                value={selectedProductGroup ? selectedProductGroup : null}
                onChange={(selected) => handleSelectChange("product_category_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateProductGroup(inputValue) }}
                placeholder={props.t("Select product category")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return clientNoMatch || companyNameMatch;
                }}
              />
              {/* :
                <NoAuthInput placeholder={props.t("Select product group")} />
              } */}
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="name">{props.t("Product Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter product name")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="description">{props.t("Description")}</Label>
              <DynamicInput
                type="text"
                name="description"
                id="description"
                value={formData.description}
                onChange={handleChange}
                placeholder={props.t("Enter product description")}
                minLength={3}
                maxLength={255}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="gtip_code">{props.t("GTIP Code")}</Label>
              <DynamicInput
                type="text"
                name="gtip_code"
                id="gtip_code"
                value={formData.gtip_code}
                onChange={handleChange}
                placeholder={props.t("Enter GTIP code")}
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="document_attribute_id">{props.t("Document Reference ID")}</Label>
              <DynamicInput
                type="text"
                name="document_attribute_id"
                id="document_attribute_id"
                value={formData.document_attribute_id}
                onChange={handleChange}
                placeholder={props.t("Enter document reference no")}
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="additional_attribute">{props.t("Additional Attribute")}</Label>
              <DynamicInput
                type="text"
                name="additional_attribute"
                id="additional_attribute"
                value={formData.additional_attribute}
                onChange={handleChange}
                placeholder={props.t("Enter additional attribute")}
                minLength={3}
                maxLength={255}
              />
            </FormGroup>
          </Col>

          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="client_no">{props.t("Company")}</Label>
              <DynamicCreatableSelect
                options={companies}
                value={companies.find(data => data.value === formData.client_no)}
                onChange={(selected) => handleSelectCompanyChange("client_no", selected)}
                onCreateOption={async (inputValue) => { handleCreateCompany(inputValue) }}
                placeholder={props.t("Select a company")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                  return companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>

      <AcceptModal />
      <FastCreateModal />
    </div>
  );
};

ProductForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ProductForm)