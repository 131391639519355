// /src/services/inslugService.js
import axios from 'axios';
import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const DEPOT = '/depot';
const STOCK = '/stock';
const INFO = '/info';
const UPDATE_INFO = '/update-info';
const UITSLUG_GET_ONE = '/one-uitslug-with-items';
const GET = '/get';
const CREATE = '/create';
const UPDATE = '/update';
const DELETE = '/delete';
const GET_DELETED = '/get-deleted';
const RESTORE = '/restore';
const UITSLUG_BY_COMPANY = '/info';
const STOCK_VIEW = '/stock-view';

/**
 * Inslug ekleme fonksiyonu
 * @param {Object} data - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const createStock = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${CREATE}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug ekleme fonksiyonu
 * @param {Object} id - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const getStock = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${UITSLUG_GET_ONE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mevcut tüm Inslug'ları getirme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getStocks = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${GET}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mevcut tüm Inslug'ları getirme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedStock = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${GET_DELETED}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug silme fonksiyonu
 * @param {string} id - Şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getFilteredStocks = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${UITSLUG_BY_COMPANY}`, data);
    console.log("responsedata");
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug eklemek için gerekli olan verileri çeken fonskiyon
 * @returns {Promise} - API cevabı
 */
export const stockInfo = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${INFO}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug eklemek için gerekli olan verileri çeken fonskiyon
 * @returns {Promise} - API cevabı
 */
export const getStockAllData = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${UPDATE_INFO}`, {id});
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug eklemek için gerekli olan verileri çeken fonskiyon
 * @returns {Promise} - API cevabı
 */
export const getStockItemDataWithQr = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${STOCK_VIEW}`, {id});
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Inslug item getirilirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Belirli bir inslug'ı ID'ye göre güncelleme fonksiyonu
 * @param {Object} data - Güncellenmiş Inslug verileri
 * @returns {Promise} - API cevabı
 */
export const updateStock = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteStock = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${DELETE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug geri getirme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreStock = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${DEPOT}${STOCK}${RESTORE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};


