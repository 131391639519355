// actions.js
import { OPEN_FAST_CREATE_MODAL, CLOSE_FAST_CREATE_MODAL } from './actionTypes';

// Action to open modal with dynamic content
export const openFastCreateModal = (content) => {
  return {
    type: OPEN_FAST_CREATE_MODAL,
    payload: content, // Dynamic content for the modal
  };
};

// Action to close modal
export const closeFastCreateModal = () => {
  return {
    type: CLOSE_FAST_CREATE_MODAL,
  };
};
