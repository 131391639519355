// actions.js
import { OPEN_MODAL, CLOSE_MODAL } from './actionTypes';

// Action to open modal with dynamic content
export const openModal = (content) => {
  return {
    type: OPEN_MODAL,
    payload: content, // Dynamic content for the modal
  };
};

// Action to close modal
export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
  };
};
