import { SET_UITSLUG_UPDATE_FORM_DATA, CLEAR_UITSLUG_UPDATE_FORM_DATA } from './actionTypes';

export const setUpdateUitslugFormData = (formData) => ({
  type: SET_UITSLUG_UPDATE_FORM_DATA,
  payload: formData,
});

export const clearUpdateUitslugFormData = () => ({
  type: CLEAR_UITSLUG_UPDATE_FORM_DATA,
});
