export const hasPermission = (permissonData, module_no, activity_no) => {
    const module = permissonData.find(item => (item.module_no === module_no && item.activity_no === activity_no))
    if(module) {
      if (module.permission === true) {
        return true;
      }
      return false;
    }
}

/* export const hasSidebarPermission = async (permissonData, module_no) => {
    const module = await permissonData.find(item => (item.module_no === module_no && item.activity_no === "VIEW"))
    if(module) {
      if (module.side_bar === true) {
        return true;
      }
    }
    return false;
} */

export const hasSidebarPermission = async (permissionData, module_no) => {
  // İlgili `module_no` değerine sahip ve "VIEW" veya "CREATE" activity_no değerine sahip modülleri filtreler
  const modules = await permissionData.filter(item => 
      item.module_no === module_no && (item.activity_no === "VIEW" || item.activity_no === "CREATE")
  );

  // Eğer modüllerden herhangi birinin `side_bar` değeri `true` ise `true` döndürür
  const hasPermission = modules.some(module => module.permission === true);
/*   console.log("hasSidebarPermission");
  console.log(`${module_no} ->  ${hasPermission}`); */
  return hasPermission;
}


export const hasViewPermission = (permissonData, module_no) => {
  const module = permissonData.find(item => (item.module_no === module_no && item.activity_no === "VIEW"))
  if(module) {
    if (module.permission === true) {
      return true;
    }
  }
  return false;
}

export const hasCreatePermission = (permissonData, module_no) => {
  const module = permissonData.find(item => (item.module_no === module_no && item.activity_no === "CREATE"))
  if(module) {
    if (module.permission === true) {
      return true;
    }
  }
  return false;
}

export const hasDeletePermission = (permissonData, module_no) => {
  const module = permissonData.find(item => (item.module_no === module_no && item.activity_no === "DELETE"))
  if(module) {
    if (module.permission === true) {
      return true;
    }
  }
  return false;
}

export const hasRestorePermission = (permissonData, module_no) => {
  const module = permissonData.find(item => (item.module_no === module_no && item.activity_no === "RESTORE"))
  if(module) {
    if (module.permission === true) {
      return true;
    }
  }
  return false;
}

export const hasUpdatePermission = (permissonData, module_no) => {
  const module = permissonData.find(item => (item.module_no === module_no && item.activity_no === "UPDATE"))
  if(module) {
    if (module.permission === true) {
      return true;
    }
  }
  return false;
}

export const hasExcelPermission = (permissonData, module_no) => {
  /* const module = permissonData.find(item => (item.module_no === module_no && item.activity_no === "EXCEL"))
  if(module) {
    if (module.permission === true) {
      return true;
    }
  } */
  return true;
}