import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import { Button, CardBody, Input } from 'reactstrap';
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { hasCreatePermission, hasDeletePermission, hasExcelPermission, hasRestorePermission, hasUpdatePermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { closeModal, openModal } from 'store/modal/actions';
import { useDispatch } from 'react-redux';
import DetailsButton from 'components/Common/Button/detailsButton';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { getAllDefinitions, } from 'service/Definitions/definitionsService';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import RestoreButton from 'components/Common/Button/restoreButton';
import { approveHeleven, deleteHeleven, getHelevens, restoreHeleven } from 'service/depot/helevenService';

const Helevens = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [helevenDatas, setHelevenDatas] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [processStatuses, setProcessStatuses] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);

  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProcessStatuses = async () => {
      try {
        const processStatusList = await getAllDefinitions(12);
        console.log("processStatusList");
        console.log(processStatusList);
        setStatusFilter(processStatusList[0].id);
        setProcessStatuses(processStatusList);
      } catch (error) {
        console.log(`hata: ${error}`);
        //toast.error("process status list", { position: "top-right" });
      }
    };

    const fetchInslugData = async () => {
      try {
        const inslugList = await getHelevens();
        console.log("uitslugListtttt");
        console.log(inslugList);
        setHelevenDatas(inslugList);
        setFilteredData(inslugList);
      } catch (error) {
        //toast.error("toast-error-fetch-data", { position: "top-right" });
      }
    };
    fetchInslugData();
    fetchProcessStatuses();
  }, []);

  useEffect(() => {
    console.log("statusFilter");
    console.log(statusFilter);

  }, [statusFilter]);

  useEffect(() => {
    if (statusFilter) {
      const fileredByProcessStatus = helevenDatas.filter(data => data.process_status_id == statusFilter);
      console.log("filtereeeeeeeeddddddd");
      console.log(fileredByProcessStatus);
      console.log(helevenDatas);
      console.log("statusFilteerrr");
      console.log(statusFilter);
      const filtered = fileredByProcessStatus.filter((item) => {
        const matchesSearchText = (
          (item.client_no && item.client_no.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.document_no && item.document_no.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.company_name && item.company_name.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.booking_date && new Date(item.booking_date).toLocaleDateString().includes(searchText))
        );

        return matchesSearchText;
      });
      setFilteredData(filtered);
    } else {
      const filtered = helevenDatas.filter((item) => {
        const matchesSearchText = (
          (item.client_no && item.client_no.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.document_no && item.document_no.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.company_name && item.company_name.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.booking_date && new Date(item.booking_date).toLocaleDateString().includes(searchText))
        );
        return matchesSearchText;
      });
      setFilteredData(filtered);
    }
  }, [searchText, statusFilter, helevenDatas]);


  const createJson = (data) => {
    const jsonObject = {
      [props.t("Company No")]: data.client_no,
      [props.t("Company Name")]: data.comapny_name,
      [props.t("Transport Company No")]: data.transport_company_no,
      [props.t("Supplier Company No")]: data.supplier_company_no,
      [props.t("Agency Reference")]: data.agency_reference,
      [props.t("Booking Date")]: data.booking_date,
      [props.t("Document No")]: data.document_no,
      [props.t("Ext Document No")]: data.ext_document_no,
      [props.t("Description")]: data.descriptipn,
      [props.t("Customs Status")]: data.customs_status_id,
      [props.t("Carrier Type")]: data.carrier_type_id,
      [props.t("Loading Status")]: data.loading_status_id,
      [props.t("Process Status")]: data.process_status_id,
    };
    return jsonObject;
  };

  const handleEdit = async (row) => {
    navigate(`/uitslug/update/${row.client_no}/${row.id}`);
  };

  const handleViewDetails = (id) => {
    navigate(`/uitslug/details/${id}`);
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          dispatch(closeModal());
          try {
            await deleteHeleven(id);
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const uitslugList = await getHelevens();
            console.log("uitsssss");
            console.log(uitslugList)
            setHelevenDatas(uitslugList);
            setFilteredData(uitslugList);
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          dispatch(closeModal());
          try {
            await restoreHeleven(id);
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });
            const uitslugList = await getHelevens();
            console.log("uitsssss");
            console.log(uitslugList)
            setHelevenDatas(uitslugList);
            setFilteredData(uitslugList);
          } catch (error) {
            console.error('Veri silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-restore-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  const handleChangeStatus = (e) => {
    setStatusFilter(e.target.value);
  }

  const handleAccept = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-update-title"),
        body: props.t("modal-update-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            await approveHeleven(id); // Delete company
            toast.success(props.t("toast-success-update-data"), { position: "top-right" });

            const inslugList = await getHelevens();
            setHelevenDatas(inslugList);
            setFilteredData(inslugList);
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
          }
        }
      })
    );
  }

  const columns = [
    { name: props.t("Company Name"), selector: row => row.company_name || props.t("-"), sortable: true },
    { name: props.t("Booking Date"), selector: row => row.booking_date ? new Date(row.booking_date).toLocaleDateString() : props.t("-"), sortable: true },
    {
      name: props.t("Status"),
      selector: row => row.process_status_id || props.t("-"), // Sıralanabilir sütun
      sortable: true,
      cell: row => {
        const mod = row.process_status_id % 4;
        let badgeColor;
        switch (mod) {
          case 0:
            badgeColor = "danger";
            break;
          case 1:
            badgeColor = "warning";
            break;
          case 2:
            badgeColor = "success";
            break;
          case 3:
            badgeColor = "info";
            break;
          default:
            badgeColor = "secondary";
        }

        return (
          <small className={`badge bg-${badgeColor}`} style={{ padding: "5px 10px" }}>
            {props.t(`${processStatuses?.find(data => data.id === row.process_status_id)?.name}`)} {/* İçeriği siz belirleyebilirsiniz */}
          </small>
        );
      }
    },
    {
      name: props.t("Actions"),
      cell: row => (
        <>
          <DetailsButton disabled={false} onClick={() => handleViewDetails(row.id)} />
          {row.process_status_id === 13 || row.process_status_id === 14 ? hasUpdatePermission(permissions, "DE_04") ? <EditButton disabled={false} onClick={() => handleEdit(row)} /> : <EditButton disabled={true} /> : null}
          {row.process_status_id === 13 || row.process_status_id === 14 ? hasDeletePermission(permissions, "DE_04") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} /> : null}
          {row.process_status_id === 16 ? hasRestorePermission(permissions, "DE_04") ? <RestoreButton onClick={() => handleRestore(row.id)} /> : <RestoreButton disabled={true} /> : null}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
    },
  ];

  const exportToExcel = () => {
    const columnNames = {
      client_no: props.t("Company No"),
      company_name: props.t("Company Name"),
      booking_date: props.t("Booking Date"),
    };

    const selectedColumns = helevenDatas.map(item => ({
      [columnNames.client_no]: item.client_no,
      [columnNames.company_name]: item.company_name,
      [columnNames.booking_date]: item.booking_date ? new Date(item.booking_date).toLocaleDateString() : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Uitslug List"));
    XLSX.writeFile(workbook, props.t("Uitslug List") + ".xlsx");
  };

  const excelIcon = (
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>
  );

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  return (
    <CardBody>
      <div>
        <div className='d-flex align-items-center justify-content-between mb-3 p-2'>
          <h5 className="text-primary">{props.t("Uitslug List")}</h5>
          {hasCreatePermission(permissions, "DE_04") ?
            <Link to={"/heleven/create"}>
              <Button className="btn btn-md mx-2" color="success">{props.t("New Heleven")}</Button>
            </Link>
            : <Button className="btn btn-md mx-2" disabled color="secondary">{props.t("New Uitslug")}</Button>}
        </div>
        <DataTable
          subHeader
          customStyles={customStyles}
          noDataComponent={props.t("table-empty-message")}
          paginationComponentOptions={customPaginationOptions}
          subHeaderComponent={
            <div className='d-flex justify-content-end w-75'>
              {hasExcelPermission(permissions, "DE_04") && filteredData.length > 0 ? excelIcon : null}
              <Input
                type="select"
                value={statusFilter}
                onChange={(e) => handleChangeStatus(e)}
                style={{ minWidth: "100px", maxWidth: '250px', marginRight: '10px' }}
              >
                {processStatuses?.map((status) => (
                  <option key={status.id} value={status.id}>
                    {props.t(status.name)}
                  </option>
                ))}
              </Input>
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))}
                className="form-control w-50"
                maxLength={32}
              />
            </div>
          }
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          columns={columns}
          data={filteredData}
          pagination
          className='p-3'
          highlightOnHover
        />
      </div>
      <AcceptModal />
    </CardBody>
  );
};

Helevens.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(Helevens);
