// reducer.js
import {
  SET_DEFINITIONS,
  CLEAR_DEFINITIONS
} from './actionTypes';

const initialState = {
  definitions: [],
};

const definitionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFINITIONS:
      return {
        ...state,
        definitions: action.payload,
      };

    case CLEAR_DEFINITIONS:
      return {
        ...state,
        definitions: initialState.processStatus,
      };
    default:
      return state;
  }
};

export default definitionsReducer;
