import { SET_UPDATE_DEPOT_FORM_DATA, CLEAR_UPDATE_DEPOT_FORM_DATA } from './actionTypes';

export const setUpdateDepotFormData = (formData) => ({
  type: SET_UPDATE_DEPOT_FORM_DATA,
  payload: formData,
});

export const clearUpdateDepotFormData = () => ({
  type: CLEAR_UPDATE_DEPOT_FORM_DATA,
});
