// src/store/selectedCountry/selectedCountryReducer.js
import { SET_UITSLUG_ITEM_LIST, CLEAR_UITSLUG_ITEM_LIST, UPDATE_UITSLUG_ITEM } from './actionTypes';

const initialState = {
  uitslugItemList: [], // Başlangıçta seçilen ülke boş
};

const uitslugItemListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UITSLUG_ITEM_LIST:
      return {
        ...state,
        uitslugItemList: action.payload,
      };

    case CLEAR_UITSLUG_ITEM_LIST:
      return {
        ...state,
        uitslugItemList: [],
      };
    case UPDATE_UITSLUG_ITEM:
      return {
        ...state,
        uitslugItemList: state.uitslugItemList.map((item) =>
          item.id === action.payload.id
            ? { ...item, output_count: action.payload.outputCount }
            : item
        ),
      };
    default:
      return state;
  }
};

export default uitslugItemListReducer;
