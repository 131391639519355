import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Row, Col, Form } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import AcceptModal from 'components/Common/Modals/acceptModal';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import { createCountry, getCountries } from 'service/Definitions/countryService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { getCities } from 'service/Definitions/cityService';
import UpdateCityForm from 'pages/Depot/Definitions/City/cityUpdateForm';
import { openUpdateModal } from 'store/updateModal/actions';
import { clearCityFormData, setCityFormData } from 'store/updates/cityForm/action';
import { setIsCreate } from 'store/isUpdate/action';
import { setCountries } from 'store/depot/Definitions/country/action';
import { hasCreatePermission } from 'service/common/permissionService';
import DynamicButton from 'components/Common/Button/dynamicButton';
import { createDepot, getDepots } from 'service/settings/depotService';
import { clearCreateDepotFormData, setCreateDepotFormData } from 'store/settings/createDepotForm/action';
import { setDepots } from 'store/settings/depots/actions';
import { clearSelectedDepotCity, setSelectedDepotCity } from 'store/depot/selectedDepotCity/action';
import { setCities } from 'store/depot/Definitions/city/action';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';
import FastCreateModal from 'components/Common/Modals/fastCreateModal';
import { customValidationError } from 'components/Common/customValidationError';

const WarehouseForm = props => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const formData = useSelector((state) => state.createDepotFormData?.formData || null);
    const countries = useSelector((state) => state.countries?.countries || []);
    const cities = useSelector((state) => state.cities?.cities || []);

    const [isHovered, setIsHovered] = useState(false);
    const md = 6;
    const lg = 6;
    const xl = 6;

    const getCompaniesAndCities = async () => {
        try {
            const countryList = await getCountries();
            const cityList = await getCities();
            console.log(countryList);
            console.log(cities);
            const formattedCities = cityList.map(city => ({
                value: city.id,
                label: city.name,
                country_id: city.country_id
            }));

            const formattedCountries = countryList.map(country => ({
                value: country.id,
                label: country.name
            }));
            console.log("**********");
            console.log(formattedCities);
            console.log(formattedCountries);
            dispatch(setCountries(formattedCountries));
            dispatch(setCities(formattedCities));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getCompaniesAndCities();
    }, [dispatch]);

    const handleChange = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        dispatch(setCreateDepotFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    useEffect(() => {
        console.log("formdata");
        console.log(formData);
    }, [formData]);

    const handleChangeLowerCase = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value;
        dispatch(setCreateDepotFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    const handlePhone = (value) => {
        console.log("value");
        console.log(value);
        dispatch(setCreateDepotFormData({
            ...formData,
            ["phone"]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm()
    };

    const handleSelectCountryChange = async (field, selected) => {
        dispatch(clearSelectedDepotCity());
        dispatch(setCreateDepotFormData({ ...formData, country_id: selected?.value, city_id: null }));
        await getCompaniesAndCities();
    };

    const handleSelectCityChange = (field, selected) => {
        dispatch(setSelectedDepotCity(selected));
        dispatch(setCreateDepotFormData({ ...formData, [field]: selected?.value }));
    };

    const handleCreateCountry = (inputValue) => {
        if (!hasCreatePermission(permissions, "DE_10")) {
            toast.error(props.t("No authority"), { position: "top-right" });
            return;
        }
        dispatch(
            openFastCreateModal({
                title: inputValue,
                onConfirm: async () => {
                    dispatch(closeFastCreateModal());
                    // Yeni bir şirket oluşturmak için:
                    const newCountry = {
                        name: inputValue,  // Kullanıcının girdiği değer
                    };

                    await createCountry(newCountry)
                        .then(async (addedCountry) => {
                            const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
                            const newSelected = newCountryList.find(data => data.id === addedCountry.id);
                            const formattedCompanies = newCountryList.map(country => ({
                                value: country.id,
                                label: country.name
                            }));
                            dispatch(setCountries(formattedCompanies));
                            dispatch(setCreateDepotFormData({ ...formData, ["country_id"]: newSelected.id }));
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                        })
                        .catch((error) => {
                            //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                            customValidationError(error,props);
                        });
                }
            }));// Modalı açıyoruz
    }

    const handleCreateCity = (inputValue) => {
        if (!hasCreatePermission(permissions, "DE_10")) {
            toast.error(props.t("No authority"), { position: "top-right" });
            return;
        }
        handleEdit(inputValue);
        dispatch(
            openUpdateModal({
                body: <UpdateCityForm page={"depot"} />,
                onConfirm: async () => { }
            })
        );
    }

    // Handle editing row and open modal
    const handleEdit = async (inputValue) => {
        dispatch(setIsCreate(true));
        dispatch(setCityFormData({ country_id: formData.country_id, name: inputValue }));
    };

    const handleConfirm = async () => {
        console.log("formdataaaasdasdasdasd");
        console.log(formData);
        if (!(formData.name) || formData.name === '') {
            toast.error(props.t("Please enter depot name"));
            return;
        }
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    console.log("formdataaaa");
                    console.log(formData);
                    dispatch(closeModal()); // Close the modal after failure
                    await createDepot(formData)
                        .then(async () => {
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                            console.log(formData);
                            const list = await getDepots(); // Fetch updated companies
                            const { info, data } = list;
                            dispatch(setDepots(data || []));
                            dispatch(clearCityFormData());
                            dispatch(clearSelectedDepotCity());
                            dispatch(clearSelectedDepotCity());
                            dispatch(clearCreateDepotFormData());
                        })
                        .catch((error) => {
                            //toast.error(props.t("toast-error-save-data"));
                            customValidationError(error,props);
                        });
                }
            })
        );


    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Add Depot")}</h5>
                    <DynamicButton
                        text={props.t("Save")}
                        type="submit"
                        buttonType={0}
                    />
                </div>
                <Row className='mb-4' form>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="name">{props.t("Depot Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter depot name")}
                                required
                                maxLength={32}
                                minLength={1}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="phone">{props.t("Phone")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder={props.t("Enter phone number")}
                                value={formData.phone}
                                minLength={6}
                                maxLength={24}
                                onMouseEnter={() => setIsHovered(true)}  // Hover durumu aktif
                                onMouseLeave={() => setIsHovered(false)} // Hover durumu pasif
                                onChange={value => handlePhone(value)} />
                        </FormGroup>
                    </Col>
                    {/* <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="address">{props.t("Address")}</Label>
                            <DynamicInput
                                type="text"
                                name="address"
                                id="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder={props.t("Enter address")}
                                maxLength={255}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col> */}

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="country">{props.t("Country")}</Label>
                            <DynamicCreatableSelect
                                options={countries}
                                value={countries.find(data => data.value === formData.country_id || null)}
                                onChange={(selected) => handleSelectCountryChange("country_id", selected)} // Kategori seçimi
                                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                                placeholder={props.t("Select country")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    const clientNo = option.data.label || "";
                                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="city">{props.t("City")}</Label>
                            {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_12")} props={props} />
                            {hasViewPermission(permissions, "DE_12") ? */}
                            <DynamicCreatableSelect
                                options={cities.filter(data => data.country_id === formData.country_id || null)} // Filtreleme işlemi
                                value={cities.find(data => data.value === formData.city_id)}
                                onChange={(selected) => handleSelectCityChange("city_id", selected)} // Kategori seçimi
                                onCreateOption={async (inputValue) => { handleCreateCity(inputValue) }}
                                placeholder={props.t("Select city")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                                    const clientNo = option.data.label || "";
                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                                    return companyNameMatch;
                                }}
                            />
                            {/* :
                                <NoAuthInput placeholder={props.t("Enter city")} />
                            } */}
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <AcceptModal />
            <FastCreateModal />
        </div>
    );
};

WarehouseForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(WarehouseForm)