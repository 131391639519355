import React, { useEffect, useState } from 'react';
import { Card, Badge } from 'reactstrap';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DataTable from 'react-data-table-component';

const ProfileItem = (props) => {
    const [user, setUser] = useState({});

    useEffect(() => {
        const fetchUserInfo = () => {
            const user = localStorage.getItem("authUser");
            const userData = JSON.parse(user);
            setUser(userData);
        };

        fetchUserInfo();
    }, []);

    // DataTable için dikey düzenle sütunları oluşturuyoruz
    const data = [
        /* { key: 'ID', value: user.id }, */
        { key: props.t('Name'), value: user.name },
        { key: props.t('Surname'), value: user.surname },
        { key: props.t('Email'), value: user.email },
        { key: props.t('Phone'), value: user.phone },
        { key: props.t('Role'), value: user.role_name },
       /*  {
            key: 'Account Status',
            value: (
                <Badge color={user.is_passive ? "danger" : "success"}>
                    {user.is_passive ? "Inactive" : "Active"}
                </Badge>
            ),
        }, */
        /* { key: 'Created At', value: new Date(user.createdAt).toLocaleString() },
        { key: 'Updated At', value: new Date(user.updatedAt).toLocaleString() }, */
    ];

    const columns = [
        {
            name: '',
            selector: row => row.key,
            sortable: true,
            width: '50%', // Sütun genişliği ayarı
            style: {
                fontWeight: 'bold',
            },
        },
        {
            name: '',
            selector: row => row.value,
            sortable: true,
            width: '50%', // Sütun genişliği ayarı
        }
    ];

    return (
        <div className="profile-page">
                <DataTable
                    title="User Profile Data"
                    columns={columns}
                    data={data}
                    noHeader
                    highlightOnHover
                    responsive
                />
        </div>
    );
};

ProfileItem.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ProfileItem);
