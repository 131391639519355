// reducer.js
import {
  SET_DEFINITION_ITEMS,
  CLEAR_DEFINITION_ITEMS
} from './actionTypes';

const initialState = {
  definitionItems: [],
};

const definitionItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFINITION_ITEMS:
      return {
        ...state,
        definitionItems: action.payload,
      };

    case CLEAR_DEFINITION_ITEMS:
      return {
        ...state,
        definitionItems: [],
      };
    default:
      return state;
  }
};

export default definitionItemsReducer;
