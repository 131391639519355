// src/store/selectedCountry/selectedCountryReducer.js
import { SET_SELECTED_EXTERN_CITY, CLEAR_SELECTED_EXTERN_CITY } from './actionTypes';

const initialState = {
  selectedExternCity: null, // Başlangıçta seçilen ülke boş
};

const selectedExternCityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_EXTERN_CITY:
      return {
        ...state,
        selectedExternCity: action.payload,
      };

    case CLEAR_SELECTED_EXTERN_CITY:
      return {
        ...state,
        selectedExternCity: null,
      };

    default:
      return state;
  }
};

export default selectedExternCityReducer;
