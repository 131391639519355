import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasExcelPermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import 'styles/custom/datatable.css'
import { useParams } from 'react-router-dom';
import { getClientProductDetails } from 'service/depot/clientProductService';

const ClientProductDetails = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchTextInfo, setSearchTextInfo] = useState(''); // State for search text
  const [searchTextData, setSearchTextData] = useState(''); // State for search text
  const [searchText2Data, setSearchText2Data] = useState(''); // State for search text

  const [infoList, setInfoList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [data2List, setData2List] = useState([]);

  const [filteredDataList, setFilteredDataList] = useState([]); // State for form data
  const [filteredData2List, setFilteredData2List] = useState([]); // State for form data
  const [filteredInfoList, setFilteredInfoList] = useState([]); // State for form data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { info, details, details2 } = await getClientProductDetails(id);
        console.log("verilerr");
        console.log(info);
        console.log(details);
        console.log(details2);
        
        const infoArray = [
          { key: "Company Name", value: info.company_name },
          { key: "Product Name", value: info.product_name },
          { key: "Extern No", value: info.extern_no },
          { key: "Country", value: info.country_name },
          { key: "Production Date", value: info.production_date ? new Date(info.production_date).toLocaleDateString() : "" },
          { key: "Unit", value: info.unit_name },
          { key: "Price", value: info.price_name },
        ];

        const detailsArray = [
          { key: "Company No", value: details.client_no },
          { key: "Company Name", value: details.name },
          { key: "Mail", value: details.mail },
          { key: "Phone", value: details.phone },
          { key: "Country", value: details.country_name },
          { key: "City", value: details.city_name },
          { key: "Address", value: details.address },
          { key: "IBAN", value: details.iban },
          { key: "Tax Number", value: details.tax_no },
          { key: "Tax Office", value: details.tax_office },
          { key: "Created At", value: details.createdAt ? new Date(details.createdAt).toLocaleDateString() : "" },
        ];

        const details2Array = [
          { key: "Product No", value: details2.product_no },
          { key: "Product Name", value: details2.name },
          { key: "Category Name", value: details2.product_category_name },
          { key: "Stock Status", value: details2.count },
          { key: "GTIP Code", value: details2.gtip_code },
          { key: "Document Reference No", value: details2.document_reference_no },
          { key: "Additional Attribute", value: details2.additional_attribute },
        ];

        setInfoList(infoArray);
        setDataList(detailsArray);
        setData2List(details2Array);


      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredDataList(dataList);
  }, [dataList]);

  useEffect(() => {
    setFilteredData2List(dataList);
  }, [data2List]);

  useEffect(() => {
    setFilteredInfoList(infoList);
  }, [infoList]);

  useEffect(() => {
    setFilteredInfoList(
      infoList.filter(row =>
        row.value?.toString().toLowerCase().includes(searchTextInfo.toLowerCase())
      )
    );
  }, [searchTextInfo, infoList]);

  useEffect(() => {
    setFilteredDataList(
      dataList?.filter(row =>
        row.value?.toString().toLowerCase().includes(searchTextData.toLowerCase())
      )
    );
  }, [searchTextData, dataList]);

  useEffect(() => {
    setFilteredData2List(
      data2List?.filter(row =>
        row.value?.toString().toLowerCase().includes(searchTextData.toLowerCase())
      )
    );
  }, [searchText2Data, dataList]);

  const exportToExcel = () => {
    /* const column1Name = props.t("Company No");
    const column2Name = props.t("Company Name");
    const column3Name = props.t("Mail");
    const column4Name = props.t("Phone");
    const column5Name = props.t("Address");
    const column6Name = props.t("Country");
    const column7Name = props.t("City");
    const column8Name = props.t("IBAN");
    const column9Name = props.t("Tax No");
    const column10Name = props.t("Tax Office");
    const column11Name = props.t("Created At");


    const selectedColumns = filteredDataList.map(itemCompanies => ({
      [column1Name]: itemCompanies.client_no,
      [column2Name]: itemCompanies.name,
      [column3Name]: itemCompanies.email,
      [column4Name]: itemCompanies.phone,
      [column5Name]: itemCompanies.address,
      [column6Name]: itemCompanies.country_name,
      [column7Name]: itemCompanies.city_name,
      [column8Name]: itemCompanies.iban,
      [column9Name]: itemCompanies.tax_no,
      [column10Name]: itemCompanies.tax_office,
      [column11Name]: standartDateTime(itemCompanies.createdAt),
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Companies"));
    XLSX.writeFile(workbook, props.t("Companies") + ".xlsx"); */
  };

  const columns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];

  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div  >
      <Card>
        <CardBody>
          <h5 className="text-primary">{props.t("Company Product Informations")}</h5>

          <DataTable
            style={{
              border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
            }}
            className='data-table px-3'
            columns={columns}
            data={filteredInfoList}
            customStyles={customStyles}
            noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
            paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
            highlightOnHover            
          />
        </CardBody>
      </Card>

      {
        dataList.length > 0 ?
          <Card>
            <CardBody>
            <h5 className="text-primary">{props.t("Company Informations")}</h5>

              <DataTable
                style={{
                  border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
                }}
                className='data-table p-3'
                columns={columns}
                data={filteredDataList}
                customStyles={customStyles}
                noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
                paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
                highlightOnHover
              />
            </CardBody>
          </Card>
          : null
      }

      {
        dataList.length > 0 ?
          <Card>
            <CardBody>
            <h5 className="text-primary">{props.t("Product Informations")}</h5>

              <DataTable
                style={{
                  border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
                }}
                className='data-table p-3'
                columns={columns}
                data={filteredData2List}
                customStyles={customStyles}
                noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
                paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
                highlightOnHover
              />
            </CardBody>
          </Card>
          : null
      }

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

ClientProductDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ClientProductDetails)