// src/store/selectedCountry/selectedCountryActions.js
import { SET_IS_CREATE, CLEAR_IS_CREATE } from './actionTypes';

// Seçilen ülkeyi set etmek
export const setIsCreate = (city) => ({
  type: SET_IS_CREATE,
  payload: city,
});

// Seçilen ülkeyi temizlemek
export const clearIsCreate = () => ({
  type: CLEAR_IS_CREATE,
});
