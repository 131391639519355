// reducer.js
import {
  SET_PARTS,
  CLEAR_PARTS
} from './actionTypes';

const initialState = {
  parts: [],
};

const partssReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTS:
      return {
        ...state,
        parts: action.payload,  // Payload doğrudan companies array'i olacak
      };

    case CLEAR_PARTS:
      return {
        ...state,
        parts: [],  // Payload doğrudan companies array'i olacak
      };

    default:
      return state;
  }
};

export default partssReducer;
