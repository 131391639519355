import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import { Button, Card, CardBody, Input } from 'reactstrap';
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { useSelector, useDispatch } from 'react-redux';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { hasExcelPermission } from 'service/common/permissionService';
import { clearUitslugItemList, setUitslugItemList } from 'store/depot/uitslugStore/uitslugList/action';
import DynamicButton from 'components/Common/Button/dynamicButton';
import { useParams } from 'react-router-dom';

const UitslugItemList = props => {
  const dispatch = useDispatch();
  const { client_no } = useParams();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const uitslugItemList = useSelector((state) => state.uitslugItemList?.uitslugItemList || []);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');

  // Search işlemi
  useEffect(() => {
    const filtered = uitslugItemList.filter((item) => {
      const matchesSearchText = (
        (item.extern_document_no && item.extern_document_no.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.document_no && item.document_no.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.extern_party_no && item.extern_party_no.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.product_name && item.product_name.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.product_no && item.product_no.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.createdAt?.toString() && item.createdAt.toString().includes(searchText))
      );
      return matchesSearchText;
    });
    setFilteredData(filtered);
  }, [searchText, uitslugItemList]);

  // Input değişimlerini işleme
  const handleInputChange = (e, rowId, maxCount) => {
    const inputValue = e.target.value;

    // Sadece rakamları kabul et
    if (/^\d*$/.test(inputValue)) {
      const numericValue = parseInt(inputValue, 10);

      // maxCount'tan büyük değilse güncelle
      if (numericValue <= maxCount || isNaN(numericValue)) {
        const updatedList = uitslugItemList.map((item) =>
          item.id === rowId ? { ...item, output_count: inputValue } : item
        );
        dispatch(setUitslugItemList(updatedList)); // Redux state'ini güncelle
      }
    }
  };

  // Excel'e veri aktarma
  const exportToExcel = () => {
    const columnNames = {
      client_no: props.t("Company No"),
      company_name: props.t("Company Name"),
      booking_date: props.t("Booking Date"),
    };

    const selectedColumns = uitslugItemList.map((item) => ({
      [columnNames.client_no]: item.client_no,
      [columnNames.company_name]: item.company_name,
      [columnNames.booking_date]: item.booking_date ? new Date(item.booking_date).toLocaleDateString() : "-",
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Uitslug List"));
    XLSX.writeFile(workbook, props.t("Uitslug List") + ".xlsx");
  };

  // DataTable sütunları
  const columns = [
    { name: props.t("Product Name"), selector: (row) => row.product_name || props.t("-"), sortable: true },
    { name: props.t("Document No"), selector: (row) => row.document_no || props.t("-"), sortable: true },
    { name: props.t("Extern Party No"), selector: (row) => row.extern_party_no || props.t("-"), sortable: true },
    { name: props.t("Container No"), selector: (row) => row.container_no || props.t("-"), sortable: true },
    { name: props.t("Extern Document No"), selector: (row) => row.extern_document_no || props.t("-"), sortable: true },
    { name: props.t("Stock Status"), selector: (row) => row.count || props.t("-"), sortable: true },
    {
      name: props.t("Outbound Quantity"),
      cell: (row) => (
        <Input
          value={row.output_count || ""}
          onChange={(e) => handleInputChange(e, row.id, row.count)}
          placeholder={`Max: ${row.count}`}
          inputProps={{
            inputMode: "numeric", // Mobilde sadece rakam klavyesini gösterir
            pattern: "[0-9]*", // HTML5 doğrulama için sadece rakam
          }}
          style={{
            width: "100px",
            textAlign: "center",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "4px",
          }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
    },
  ];

  const excelIcon = (
    <Button className="me-2 p-0" color="success p-2" onClick={exportToExcel}>
      <img src={excel} width="24" alt="" />
    </Button>
  );

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: "/",
  };

  return (
    <Card>
      <CardBody>
        <div>
          <div className="d-flex align-items-center justify-content-between mb-3 p-2">
            <h5 className="text-primary">{props.t("Items")}</h5>
          </div>
          <DataTable
            subHeader
            customStyles={customStyles}
            noDataComponent={props.t("table-empty-message")}
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50]}
            customPaginationOptions={customPaginationOptions}
            subHeaderComponent={
              <div className="d-flex justify-content-end w-75">
                {hasExcelPermission(permissions, "DE_03") && filteredData.length > 0 ? excelIcon : null}
                <Input
                  type="text"
                  placeholder={props.t("Search")}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))}
                  className="form-control w-50"
                  maxLength={32}
                />
              </div>
            }
          />
        </div>
        <AcceptModal />
        <div className='text-end'>
          {client_no ?
            <DynamicButton
              text={props.t("Update")}
              type="submit"
              buttonType={1}
            />
            :
            <DynamicButton
              text={props.t("Save")}
              type="submit"
              buttonType={0}
            />
          }
        </div>
      </CardBody>

    </Card>
  );
};

UitslugItemList.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(UitslugItemList);
