import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Card, Input } from 'reactstrap';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import customStyles from 'styles/customDatatableStyle';
import { useNavigate, useParams } from 'react-router-dom';
import qr from "assets/icons/qr-colored.png";
import { QRCodeCanvas } from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import 'styles/custom/custom-print-style.css'
import DetailsButton from 'components/Common/Button/detailsButton';
import logoSrc from "assets/images/act-logo.webp";
import { approveUitslug, getUitslug } from 'service/depot/uitslugService';
import { openUpdateModal } from 'store/updateModal/actions';
import { closeModal, openModal } from 'store/modal/actions';
import { toast } from 'react-toastify';
import { hasUpdatePermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import AcceptModal from 'components/Common/Modals/acceptModal';
import SplitDataTable from 'components/Common/SplitDataTable/splitDataTable';
import DynamicPrint from 'components/Common/Print/dynamicPrint';

const today = () => {
  const today = new Date();
  const formattedDate = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
  return formattedDate;
}

const UitslugDetails = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const { id } = useParams();
  const [uitslugData, setUitslugData] = useState([]);
  const [uitslugObject, setUitslugObject] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [uitslugSearch, setUitslugSearch] = useState('');
  const [itemSearch, setItemSearch] = useState('');
  const [filteredUitslugData, setFilteredUitslugData] = useState([]);
  const [filteredItemData, setFilteredItemData] = useState([]);

  const contentRef = useRef(null);


  // Tarih olup olmadığını kontrol eden ve uygun formatta döndüren fonksiyon
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString; // Geçerli bir tarih değilse olduğu gibi döndür
    }

    // Gün ay yıl saat dakika formatında döndür
    return date.toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const fetchUitslugData = async () => {
    try {
      const uitslugInfo = await getUitslug(id);
      console.log("inslugInfooooss");
      console.log(uitslugInfo);
      const { uitslug, items } = uitslugInfo;

      const inslugArray = [
        { key: "Company Name", value: uitslug.company_name },
        { key: "Delivered Company", value: uitslug.delivered_company_name },
        { key: "Transport Company", value: uitslug.transport_company_name },
        { key: "Carrier Type", value: uitslug.carrier_type_name },
        { key: "Licence Plate", value: uitslug.license_plate },
        { key: "Driver Name", value: uitslug.driver_name },
        { key: "Booking Date", value: uitslug.booking_date ? formatDate(uitslug.booking_date.toString()) : "" },
        { key: "Description", value: uitslug.description },
        { key: "Customs Status", value: uitslug.customs_status_name },
        { key: "Loading Status", value: uitslug.loading_status_name },
        {
          key: "Process Status", value: uitslug.process_status_id !== undefined && (
            <span
              className="d-flex align-items-center justify-content-center text-center"
              style={{
                fontSize: "9px",
                height: "16px",
                marginTop: "2px",
                marginLeft: "8px",
                padding: "2px 12px 0px 12px", // Daha az padding
                borderRadius: "12px", // Oval kenarlar
                backgroundColor:
                uitslug.process_status_id % 4 === 0
                    ? "#dc3545" // danger (kırmızı)
                    : uitslug.process_status_id % 4 === 1
                      ? "#ffc107" // warning (sarı)
                      : uitslug.process_status_id % 4 === 2
                        ? "#28a745" // success (yeşil)
                        : "#17a2b8", // info (mavi)
                textAlign: "center",
                color: "white"
              }}
            >
              {uitslug.process_status_name}
            </span>)
        }
      ];

      setUitslugObject(uitslug);
      setUitslugData(inslugArray);
      setItemData(items);
      setFilteredUitslugData(inslugArray);
      setFilteredItemData(items);
    } catch (error) {
      console.error("Error fetching inslug data:", error);
    }
  };

  useEffect(() => {
    fetchUitslugData();
  }, [dispatch]);

  useEffect(() => {
    setFilteredUitslugData(
      uitslugData.filter(row =>
        row.key.toLowerCase().includes(uitslugSearch.toLowerCase()) ||
        row.value?.toString().toLowerCase().includes(uitslugSearch.toLowerCase())
      )
    );
  }, [uitslugSearch, uitslugData]);

  useEffect(() => {
    setFilteredItemData(
      itemData.filter(row =>
        row.product_name?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.extern_party_no?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.container_no?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.output_count?.toString().toLowerCase().includes(itemSearch.toLowerCase())
      )
    );
  }, [itemSearch, itemData]);

  const uitslugColumns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold',
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];

  const itemColumns = [
    {
      name: props.t("Product Name"),
      selector: row => row.product_name,
      finder: "product_name",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Extern Party No"),
      selector: row => row.extern_party_no,
      finder: "extern_party_no",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Container No"),
      selector: row => row.container_no,
      finder: "container_no",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Outbound Quantity"),
      selector: row => row.output_count,
      finder: "output_count",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      selector: row => <DetailsButton disabled={false} onClick={() => handleViewItemDetails(row.id)} />,
      finder: "Actions",
      sortable: true,
      wrap: true,
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  const handleViewItemDetails = (id) => {
    if (uitslugObject.process_status_id === 13) {
      toast.error(props.t("Approve uitslug first"));
      return;
    }
    navigate(`/uitslug/item-qr/${id}`);
  };

  const handleApprove = () => {
    if (filteredItemData.length < 1) {
      toast.error(props.t("Add an item first"));
      return;
    }
    dispatch(
      openModal({
        title: props.t("modal-approve-title"),
        body: props.t("modal-approve-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            await approveUitslug(id); // Delete company
            toast.success(props.t("toast-success-approve-data"), { position: "top-right" });
            fetchUitslugData();
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-approve-data"), { position: "top-right" });
          }
        }
      })
    );
  }

  const handlePrint = useReactToPrint({ contentRef });

  const handlePrintInslugInfo = () => {
    if (uitslugObject.process_status_id === 13) {
      toast.error(props.t("Approve uitslug first"));
      return;
    }

    handlePrint();
  }

  const navigateUpdateScreen = () => {
    navigate(`/uitslug/update/${uitslugObject.client_no}/${uitslugObject.id}`);
  }

  return (
    <div>
      <Card className="p-4 rounded-lg mb-4">
      { uitslugObject.process_status_id === 13 ? <h6 className="glowing-text">{props.t("Not Approved")}</h6> : null}
        <div className="d-flex justify-content-between mb-2">
          <div className='d-flex'>
            <h5 className="text-primary">{props.t("Uitslug Information")}</h5>
            {uitslugObject.process_status_id !== undefined && (
              <span
                className="d-flex align-items-center justify-content-center text-center"
                style={{
                  fontSize: "9px",
                  height: "16px",
                  marginTop: "2px",
                  marginLeft: "8px",
                  padding: "2px 12px 0px 12px", // Daha az padding
                  borderRadius: "12px", // Oval kenarlar
                  backgroundColor:
                    uitslugObject.process_status_id % 4 === 0
                      ? "#dc3545" // danger (kırmızı)
                      : uitslugObject.process_status_id % 4 === 1
                        ? "#ffc107" // warning (sarı)
                        : uitslugObject.process_status_id % 4 === 2
                          ? "#28a745" // success (yeşil)
                          : "#17a2b8", // info (mavi)
                  textAlign: "center",
                  color: "white"
                }}
              >
                {uitslugObject.process_status_name}
              </span>
            )}
          </div>
          <div className='d-flex'>
            <Button className="m-0 p-0 px-2" color="red" onClick={handlePrintInslugInfo}>
              <img src={qr} width={'30'} alt="" />
            </Button>
            {uitslugObject.process_status_id === 13 && hasUpdatePermission(permissions, "DE_04") ? <Button className="mx-3 glowing-button" color="primary" onClick={handleApprove}>{props.t("Approve")}</Button> : null}
            {(uitslugObject.process_status_id === 13 || uitslugObject.process_status_id === 14) && hasUpdatePermission(permissions, "DE_04") ?
              <Button color="warning" onClick={navigateUpdateScreen}>{props.t("Update")}</Button> : null}
          </div>
        </div>
        <SplitDataTable
          data={uitslugData}
          columns={uitslugColumns}
          t={props.t} />

      </Card>

      <Card className="p-4 rounded-lg">
        <h5 className="text-primary">{props.t("Product Information")}</h5>
        <DataTable
          columns={itemColumns}
          data={filteredItemData}
          customStyles={customStyles}
          highlightOnHover
          pagination
          noDataComponent={props.t("table-empty-message")}
          paginationComponentOptions={customPaginationOptions}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          subHeader
          subHeaderComponent={
            <div className='d-flex justify-content-end w-50'>
              {/* <Button className="m-0 p-0 px-3" color="red" onClick={() => { handlePrintType(id) }}>
                <img src={qr} width={'30'} alt="" />
              </Button> */}
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={itemSearch}
                onChange={(e) => setItemSearch(e.target.value.toLocaleUpperCase("tr"))}
                className="form-control w-50"
                maxLength={32}
              />
            </div>
          }
        />
      </Card>


      <DynamicPrint
        t={props.t}
        contentRef={contentRef}
        datas={uitslugData}
        dataColumns={uitslugColumns}
        dataObject={uitslugObject}
        itemDatas={itemData}
        itemColumns={itemColumns}
        actionTitle={props.t('Uitslug')}
        actionTitleBgColor="#f5b225"
      />

      {/* <div className="print-only">
        <div ref={contentRef} style={{ margin: "40px", fontFamily: "Arial, sans-serif" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: "32px",
            }}
          >
            <div>
              <img
                src={logoSrc}
                alt="Company Logo"
                style={{ width: "200px", height: "auto", marginBottom: "10px" }}
              />
              <div style={{ paddingLeft: "12px", fontSize: "14px", fontWeight: "bold" }}>
                Antwerp Container Transport
              </div>
            </div>
              <div className='text-end'>
                <div className='mb-2' style={{ fontSize: "12px" }}>{today()}</div>
                <QRCodeCanvas className='mb-2' value={uitslugObject.qr} size={80} />
                <div>{uitslugObject.qr}</div>
              </div>
          </div>
          <h5 className='py-2 bg-warning text-center text-white font-weight-bold'>
            Uitslug
          </h5>
          <div
            style={{
              width: "100%",
              marginBottom: "48px",
            }}
          >
            <SplitDataTable
              data={uitslugData}
              columns={uitslugColumns}
              t={props.t} />
          </div>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              fontFamily: "Arial, sans-serif",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#FF8A8A" }}>
                <th style={{ border: "1px solid #ddd", textAlign: "left" }}>
                  {props.t("Product Name")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Extern Party No")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Container No")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Description")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Leverage Charge")}
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  {props.t("Outbound Quantitiy")}
                </th>
              </tr>
            </thead>
            <tbody>
              {itemData.map((row, index) => (
                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.product_name}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.extern_party_no}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.container_no}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.description}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.leverage_charge}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.output_count}</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}



      <AcceptModal />
    </div>
  );
};

UitslugDetails.propTypes = {
  match: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(UitslugDetails);
