import React, { useState, useEffect, useRef } from 'react';
import { Badge, Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateModal from 'components/Common/Modals/updateModal';
import { getCountries } from 'service/Definitions/countryService';
import { getCities } from 'service/Definitions/cityService';
import { hasDeletePermission, hasExcelPermission, hasRestorePermission, hasUpdatePermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import { clearSelectedUpdateCountry, setSelectedUpdateCountry } from 'store/depot/selectedUpdateCountry/action';
import { clearSelectedUpdateCity, setSelectedUpdateCity } from 'store/depot/selectedUpdateCity/action';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import { deleteDepot, getDepots } from 'service/settings/depotService';
import { setDepots } from 'store/settings/depots/actions';
import { setUpdateDepotFormData } from 'store/settings/updateDepotForm/action';
import WarehouseUpdateForm from './warehouseUpdateForm';
import DetailsButton from 'components/Common/Button/detailsButton';
import { useNavigate } from 'react-router-dom';
import RestoreButton from 'components/Common/Button/restoreButton';
import { QRCodeCanvas } from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import qr from "assets/icons/qr-colored.png";

const WarehouseList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contentRef = useRef(null);

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState(''); // State for search text
  const depots = useSelector((state) => state.depots?.depots || []);
  const [filteredDepots, setFilteredDepots] = useState([]); // State for form data
  const [info, setInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await getDepots();
        const { info, data } = list;
        setInfo(info);
        dispatch(setDepots(data || []));
      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, []);

  // Seçime göre filteredCompanies'i güncelle
  useEffect(() => {
    console.log("depots");
    console.log(depots);
    setFilteredDepots(depots);
  }, [depots]);

  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    const dataToFilter = depots;
    const filteredData = dataToFilter.filter((depot) =>
      depot.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      depot.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      depot.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
      /* company.address?.toLowerCase().includes(searchText.toLowerCase()) || */
      /* company.country?.toLowerCase().includes(searchText.toLowerCase()) || */
      /* company.city?.toLowerCase().includes(searchText.toLowerCase()) || */
      depot.iban?.toLowerCase().includes(searchText.toLowerCase()) ||
      depot.tax_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      depot.tax_office?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredDepots(filteredData);
  }, [searchText, depots]);

  // Handle editing row and open modal
  const handleEdit = async (id) => {
    const selectedData = filteredDepots.find((data) => data.id === id);
    console.log("filtered data - selected Company");
    console.log(selectedData);
    dispatch(setUpdateDepotFormData(selectedData));
    dispatch(clearSelectedUpdateCountry());
    dispatch(clearSelectedUpdateCity());

    try {
      console.log("selecteeeddddattaaaaa");
      console.log(selectedData);
      const country = await getCountryById(selectedData.country_id);
      const city = await getCityById(selectedData.city_id);
      console.log("countryy");
      console.log(country);
      console.log("city");
      console.log(city);

      if (country) {
        dispatch(setSelectedUpdateCountry({ value: country.id, label: country.name }));
      }
      if (city) {
        console.log("city çalıştıııııııııııııııııı");
        dispatch(setSelectedUpdateCity({ value: city.id, label: city.name, country_id: city.country_id }));
      }
    } catch (error) {
      console.log("error");
    }

    // Open modal for editing
    dispatch(
      openUpdateModal({
        body: (
          <WarehouseUpdateForm />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const getCountryById = async (id) => {
    const countries = await getCountries();
    const selectedCountry = countries.find(data => data.id === id);
    return selectedCountry;
  }

  const getCityById = async (id) => {
    const cities = await getCities();
    const selectedCity = cities.find(data => data.id === id);
    return selectedCity;
  }

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after failure
          try {
            await deleteDepot(id); // Delete company
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const list = await getDepots();
            const { info, data } = list;
            setInfo(data || []);
            dispatch(setDepots(data || []));
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after failure
          try {
            await deleteDepot(id); // Delete company
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });

            const list = await getDepots(); // Fetch updated companies
            const { info, data } = list;
            dispatch(setDepots(data || [])); // Update Redux state
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  const handleViewDetails = (id) => {
    navigate(`/depots/${id}`);
  };

  const handlePrint = useReactToPrint({ contentRef });


  const exportToExcel = () => {
    const column2Name = props.t("Depot Name");
    const column3Name = props.t("Mail");
    const column4Name = props.t("Phone");
    const column5Name = props.t("Address");
    const column6Name = props.t("Country");
    const column7Name = props.t("City");

    const selectedColumns = filteredDepots.map(itemCompanies => ({
      [column2Name]: itemCompanies.name,
      [column3Name]: itemCompanies.email,
      [column4Name]: itemCompanies.phone,
      [column5Name]: itemCompanies.address,
      [column6Name]: itemCompanies.country_name,
      [column7Name]: itemCompanies.city_name,
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Depots"));
    XLSX.writeFile(workbook, props.t("Depots") + ".xlsx");
  };

  const columns = [
    /*     {
          name: props.t("QR Code"),
          selector: (row, rowIndex) => <QRCodeCanvas value={row.qr} size={20} />,
          sortable: true,
          wrap: true,
        }, */
    {
      name: props.t("#"),
      selector: (row, rowIndex) =>
        rowIndex + 1,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Depot Name"),
      selector: (row, rowIndex) =>
        row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Phone"),
      selector: (row, rowIndex) =>
        row.phone,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Country"),
      selector: (row, rowIndex) =>
        row.country_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("City"),
      selector: (row, rowIndex) =>
        row.city_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Status"),
      selector: (row) => row.is_passive, // Sıralama için ham veriyi kullanır
      sortable: true, // Sıralamayı aktif hale getirir  
      cell: (row) =>
        row.is_passive === true ?
          <Badge>{props.t("Passive")}</Badge> :
          <Badge color='success'>{props.t("Active")}</Badge>,
      sortFunction: (rowA, rowB) => {
        // "is_passive" değerine göre sıralama yapar: true (Pasif) veya false (Aktif)
        return rowA.is_passive === rowB.is_passive ? 0 : rowA.is_passive ? 1 : -1;
      },
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        row.is_passive ?
          hasRestorePermission(permissions, "DE_11") ? <RestoreButton onClick={() => handleRestore(row.id)} /> : <RestoreButton disabled={true} />
          :
          <>
            <DetailsButton disabled={false} onClick={() => handleViewDetails(row.id)} />
            {hasUpdatePermission(permissions, "DE_11") ? <EditButton disabled={false} onClick={() => handleEdit(row.id)} /> : <EditButton disabled={true} />}
            {hasDeletePermission(permissions, "DE_11") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} />}
          </>


    },
  ];

  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div  >
      <h5 className="text-primary mb-4">{props.t("Depot List")}</h5>

      <DataTable
        style={{
          border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
        }}
        className='p-3'
        columns={columns}
        data={filteredDepots}
        customStyles={customStyles}
        pagination
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        highlightOnHover
        paginationLa
        subHeader
        subHeaderComponent={
          <div className='d-flex align-items-right mb-2'>
            <div className='d-flex'>
              {/* <Button className="m-0 p-0 px-3" color="red" onClick={handlePrint}>
                <img src={qr} width={'30'} alt="" />
              </Button> */}
              {hasExcelPermission(permissions, "DE_11") && filteredDepots.length > 0 ? excelButton : null}
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                style={{ width: '250px' }} // Smaller input field
                maxLength={32}
              />
            </div>
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />

      <div ref={contentRef} className="print-only">
        <div className="container mt-5">
          <div className="row">
            {depots?.map((depot, index) => (
              <div key={index} className="col-6 text-center mb-4">
                <QRCodeCanvas value={depot.qr} size={120} />
                <p><strong>{depot.name}</strong></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

WarehouseList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(WarehouseList)