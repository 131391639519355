import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { approveInslug, deleteInslug, getInslugs, restoreInslug } from 'service/depot/inslugService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import { Button, CardBody, Input } from 'reactstrap';
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { hasCreatePermission, hasDeletePermission, hasExcelPermission, hasRestorePermission, hasUpdatePermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import { closeModal, openModal } from 'store/modal/actions';
import { useDispatch } from 'react-redux';
import DetailsButton from 'components/Common/Button/detailsButton';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { getAllDefinitions } from 'service/Definitions/definitionsService';
import CheckButton from 'components/Common/Button/checkButton';
import RestoreButton from 'components/Common/Button/restoreButton';

const Inslugs = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [inslugData, setInslugData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [processStatuses, setProcessStatuses] = useState([]);
  const [statusFilter, setStatusFilter] = useState();

  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProcessStatuses = async () => {
      try {
        const processStatusList = await getAllDefinitions(12);
        console.log("processStatusList");
        console.log(processStatusList);
        setStatusFilter(processStatusList[0].id);
        setProcessStatuses(processStatusList);
      } catch (error) {
        console.log(`hata: ${error}`);
        //toast.error("process status list", { position: "top-right" });
      }
    };

    const fetchInslugData = async () => {
      try {
        const inslugList = await getInslugs();
        console.log("inslugsss");
        console.log(inslugList);
        setInslugData(inslugList);
        setFilteredData(inslugList);
      } catch (error) {
        //toast.error("toast-error-fetch-data", { position: "top-right" });
      }
    };
    fetchInslugData();
    fetchProcessStatuses();
  }, []);

  useEffect(() => {
    console.log("statusFilter");
    console.log(statusFilter);

  }, [statusFilter]);

  useEffect(() => {
    if (statusFilter) {
      const fileredByProcessStatus = inslugData.filter(data => data.process_status_id == statusFilter);
      console.log("filtereeeeeeeeddddddd");
      console.log(fileredByProcessStatus);
      console.log(inslugData);
      console.log("statusFilteerrr");
      console.log(statusFilter);
      const filtered = fileredByProcessStatus.filter((item) => {
        const matchesSearchText = (
          (item.client_no && item.client_no.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.document_no && item.document_no.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.company_name && item.company_name.toLowerCase().includes(searchText.toLowerCase())) ||
          /* (item.booking_date && new Date(item.booking_date).toLocaleDateString().includes(searchText)) || */
          (item.eta && new Date(item.eta).toLocaleDateString().includes(searchText)) ||
          (item.closing_out && new Date(item.closing_out).toLocaleDateString().includes(searchText)) ||
          (item.closing_in && new Date(item.closing_in).toLocaleDateString().includes(searchText))
        );

        return matchesSearchText;
      });
      setFilteredData(filtered);
    } else {
      const filtered = inslugData.filter((item) => {
        const matchesSearchText = (
          (item.client_no && item.client_no.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.document_no && item.document_no.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.company_name && item.company_name.toLowerCase().includes(searchText.toLowerCase())) ||
          /* (item.booking_date && new Date(item.booking_date).toLocaleDateString().includes(searchText)) || */
          (item.eta && new Date(item.eta).toLocaleDateString().includes(searchText)) ||
          (item.closing_out && new Date(item.closing_out).toLocaleDateString().includes(searchText)) ||
          (item.closing_in && new Date(item.closing_in).toLocaleDateString().includes(searchText))
        );
        return matchesSearchText;
      });
      setFilteredData(filtered);
    }
  }, [searchText, statusFilter, inslugData]);


  const createJson = (data) => {
    const jsonObject = {
      [props.t("Company No")]: data.client_no,
      [props.t("Company Name")]: data.comapny_name,
      [props.t("Transport Company No")]: data.transport_company_no,
      [props.t("Supplier Company No")]: data.supplier_company_no,
      [props.t("Agency Reference")]: data.agency_reference,
      [props.t("Booking Date")]: data.booking_date,
      [props.t("ETA")]: data.client_no,
      [props.t("Closing Out")]: data.closing_out,
      [props.t("Closing In")]: data.closing_in,
      [props.t("Document No")]: data.document_no,
      [props.t("Ext Document No")]: data.ext_document_no,
      [props.t("Description")]: data.descriptipn,
      [props.t("Customs Status")]: data.customs_status_id,
      [props.t("Carrier Type")]: data.carrier_type_id,
      [props.t("Loading Status")]: data.loading_status_id,
      [props.t("Process Status")]: data.process_status_id,
    };
    return jsonObject;
  };

  const handleEdit = (id) => {
    console.log("inslugUpdatePageSend id");
    console.log(id);
    navigate(`/inslug/update/${id}`);

  };

  const handleViewDetails = (id) => {
    navigate(`/inslug/details/${id}`);
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          dispatch(closeModal());
          try {
            await deleteInslug(id); // Delete company
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const inslugList = await getInslugs();
            setInslugData(inslugList);
            setFilteredData(inslugList);
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal());
          }
        }
      })
    );
  };

  const handleChangeStatus = (e) => {
    setStatusFilter(e.target.value);
  }

  const handleApprove = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-update-title"),
        body: props.t("modal-update-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            await approveInslug(id); // Delete company
            toast.success(props.t("toast-success-update-data"), { position: "top-right" });

            const inslugList = await getInslugs();
            setInslugData(inslugList);
            setFilteredData(inslugList);
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-update-data"), { position: "top-right" });
          }
        }
      })
    );
  }

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          dispatch(closeModal());
          try {
            await restoreInslug(id); // Delete company
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });

            const inslugList = await getInslugs();
            setInslugData(inslugList);
            setFilteredData(inslugList);
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-restore-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  const columns = [
    { name: props.t("Document No"), selector: row => row.document_no || props.t(""), sortable: true },
    { name: props.t("Company Name"), selector: row => row.company_name || props.t(""), sortable: true },
    { name: props.t("ETA"), selector: row => row.eta ? new Date(row.eta).toLocaleDateString() : props.t(""), sortable: true },
    // { name: props.t("Closing Out"), selector: row => row.closing_out ? new Date(row.closing_out).toLocaleDateString() : props.t("-"), sortable: true },
    // { name: props.t("Closing In"), selector: row => row.closing_in ? new Date(row.closing_in).toLocaleDateString() : props.t("-"), sortable: true },
    {
      name: props.t("Status"),
      selector: row => row.process_status_id || props.t("-"), // Sıralanabilir sütun
      sortable: true,
      cell: row => {
        const mod = row.process_status_id % 4;
        let badgeColor;
        switch (mod) {
          case 0:
            badgeColor = "danger";
            break;
          case 1:
            badgeColor = "warning";
            break;
          case 2:
            badgeColor = "success";
            break;
          case 3:
            badgeColor = "info";
            break;
          default:
            badgeColor = "secondary";
        }

        return (
          <small className={`badge bg-${badgeColor}`} style={{ padding: "5px 10px" }}>
            {props.t(`${processStatuses?.find(data => data.id === row.process_status_id)?.name}`)} {/* İçeriği siz belirleyebilirsiniz */}
          </small>
        );
      }
    },
    {
      name: props.t("Actions"),
      cell: row => (
        <>
          <DetailsButton disabled={false} onClick={() => handleViewDetails(row.id)} />
          {row.process_status_id === 13 || row.process_status_id === 14 ? hasUpdatePermission(permissions, "DE_03") ? <EditButton disabled={false} onClick={() => handleEdit(row.id)} /> : <EditButton disabled={true} /> : null}
          {row.process_status_id === 13 || row.process_status_id === 14 ? hasDeletePermission(permissions, "DE_03") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} /> : null}
          {row.process_status_id === 16 ? hasRestorePermission(permissions, "DE_03") ? <RestoreButton onClick={() => handleRestore(row.id)} /> : <RestoreButton disabled={true} /> : null}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
    },
  ];

  const exportToExcel = () => {
    const columnNames = {
      client_no: props.t("Company No"),
      company_name: props.t("Company Name"),
      booking_date: props.t("Booking Date"),
      eta: props.t("ETA"),
      closing_in: props.t("Closing In"),
      closing_out: props.t("Closing Out"),
    };

    const selectedColumns = filteredData.map(item => ({
      [columnNames.client_no]: item.client_no,
      [columnNames.company_name]: item.company_name,
      [columnNames.booking_date]: new Date(item.booking_date).toLocaleDateString(),
      [columnNames.eta]: new Date(item.eta).toLocaleDateString(),
      [columnNames.closing_in]: new Date(item.closing_in).toLocaleDateString(),
      [columnNames.closing_out]: new Date(item.closing_out).toLocaleDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Inslug List"));
    XLSX.writeFile(workbook, props.t("Inslug List") + ".xlsx");
  };

  const excelIcon = (
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>
  );

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  return (
    <CardBody>
      <div>
        <div className='d-flex align-items-center justify-content-between mb-3 p-2'>
          <h5 className="text-primary">{props.t("Inslug List")}</h5>
          {hasCreatePermission(permissions, "DE_03") ?
            <Link to={"/inslug/create"}>
              <Button className="btn btn-md mx-2" color="success">{props.t("New Inslug")}</Button>
            </Link>
            : <Button className="btn btn-md mx-2" disabled color="secondary">{props.t("New Inslug")}</Button>}
        </div>
        <DataTable
          subHeader
          customStyles={customStyles}
          noDataComponent={props.t("table-empty-message")}
          paginationComponentOptions={customPaginationOptions}
          subHeaderComponent={
            <div className='d-flex justify-content-end w-75'>
              {hasExcelPermission(permissions, "DE_03") && filteredData.length > 0 ? excelIcon : null}
              <Input
                type="select"
                value={statusFilter}
                onChange={(e) => handleChangeStatus(e)}
                style={{ minWidth: "100px", maxWidth: '250px', marginRight: '10px' }}
              >
                {processStatuses?.map((status) => (
                  <option key={status.id} value={status.id}>
                    {props.t(status.name)}
                  </option>
                ))}
              </Input>
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))}
                className="form-control w-50"
                maxLength={32}
              />
            </div>
          }
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          columns={columns}
          data={filteredData}
          pagination
          className='p-3'
          highlightOnHover
        />
      </div>
      <AcceptModal />
    </CardBody>
  );
};

Inslugs.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(Inslugs);
