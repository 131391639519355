import React, { useState } from 'react';
import { FormGroup, Label, Row, Col, Form } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { openModal, closeModal } from 'store/modal/actions'; 
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import AcceptModal from 'components/Common/Modals/acceptModal';
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import DynamicButton from 'components/Common/Button/dynamicButton';
import { setUnits } from 'store/settings/units/actions';
import { useParams } from 'react-router-dom';
import { createCell, getPartCells } from 'service/settings/cellService';
import { setCells } from 'store/settings/cells/actions';
import { customValidationError } from 'components/Common/customValidationError';

const CellForm = props => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [formData, setFormData] = useState({});
    const md = 6;
    const lg = 6;   
    const xl = 4;

    const handleChange = (e) => {
        const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        setFormData({
            ...formData,
            [e.target.name]: inputData,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm()
    };

    const handleConfirm = async () => {
        console.log(formData);
        if (!(formData.name) || formData.name === '') {
            toast.error(props.t("Please enter cell name"));
            return;
        }
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after failure
                    await createCell({...formData, id: id})
                        .then(async () => {
                            dispatch(closeModal()); // Close the modal after failure
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                            console.log(formData);
                            const list = await getPartCells(id);
                            const {info, data} = list;
                            dispatch(setCells(data || []));
                            setFormData({});
                        })
                        .catch((error) => {
                            console.error("Şirket ekelme işlemi başarısız oldu:", error);
                            //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                            customValidationError(error,props);
                        });
                }
            })
        );


    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Add Cell")}</h5>
                    <DynamicButton
                        text={props.t("Save")}
                        type="submit"
                        buttonType={0}
                    />
                </div>
                <Row className='mb-4' form>
                    <Col md={md} lg={lg} xl={xl}>
                        <FormGroup>
                            <Label for="name">{props.t("Cell Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter cell name")}
                                required
                                maxLength={64}
                                minLength={1}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <AcceptModal />
        </div>
    );
};

CellForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(CellForm)