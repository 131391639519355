import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import 'styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasCreatePermission, hasExcelPermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import 'styles/custom/datatable.css'
import { useNavigate, useParams } from 'react-router-dom';
import { getProductCategoryDetails } from 'service/depot/productCategoryService';

const ProductCategoryDetails = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchTextInfo, setSearchTextInfo] = useState(''); // State for search text
  const [searchTextData, setSearchTextData] = useState(''); // State for search text
  const [dataList, setDataList] = useState([]);
  const [infoList, setInfoList] = useState([]);
  const [filteredDataList, setFilteredDataList] = useState([]); // State for form datap
  const [filteredInfoList, setFilteredInfoList] = useState([]); // State for form data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { info, details } = await getProductCategoryDetails(id);
        console.log(info);
        console.log("detailss");
        console.log(details);
        const infoArray = [
          { key: "Category No", value: info.product_category_no },
          { key: "Category Name", value: info.name },
          { key: "Product Count", value: info.count },
        ];

        setDataList(details);
        setInfoList(infoArray);

      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredDataList(dataList);
  }, [dataList]);


  useEffect(() => {
    setFilteredInfoList(infoList);
  }, [infoList]);

  useEffect(() => {
    setFilteredDataList(
      dataList.filter(row =>
        row.product_no?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.name?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.gtip_code?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.additional_attribute?.toString().toLowerCase().includes(searchTextData.toLowerCase()) ||
        row.description?.toString().toLowerCase().includes(searchTextData.toLowerCase())
      )
    );
  }, [searchTextData, dataList]);

  useEffect(() => {
    setFilteredInfoList(
      infoList.filter(row =>
        row.key.toLowerCase().includes(searchTextInfo.toLowerCase()) ||
        row.value?.toString().toLowerCase().includes(searchTextInfo.toLowerCase())
      )
    );
  }, [searchTextInfo, infoList]);

  const exportToExcel = () => {
    const column1Name = props.t("Product No");
    const column2Name = props.t("Product Name");
    const column3Name = props.t("Stock Status");
    const column4Name = props.t("GTIP Code");


    const selectedColumns = filteredDataList.map(itemCompanies => ({
      [column1Name]: itemCompanies.product_no,
      [column2Name]: itemCompanies.name,
      [column3Name]: itemCompanies.count,
      [column4Name]: itemCompanies.gtip_code,
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, infoList[1].value);
    XLSX.writeFile(workbook, props.t(infoList[1].value) + ".xlsx");
  };

  const columns = [
    {
      selector: row => props.t(row.key),
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      selector: row => row.value,
      sortable: false,
      wrap: true,
    },
  ];

  const columnDetails = [
    {
      name: props.t("Product No"),
      selector: (row, rowIndex) =>
        row.product_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Name"),
      selector: (row, rowIndex) =>
        row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Stock Status"),
      selector: (row, rowIndex) =>
        row.count || 0,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("GTIP Code"),
      selector: (row, rowIndex) =>
        row.gtip_code,
      sortable: true,
      wrap: true,
    },
  ];

  const excelButton =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  const handleAddProduct = () => {
    navigate('/product');
  }

  return (
    <div  >
      <Card>
        <CardBody>
          <h5 className="text-primary">{props.t("Product Category Informations")}</h5>
          <DataTable
            style={{
              border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
            }}
            className='data-table px-3'
            columns={columns}
            data={filteredInfoList}
            customStyles={customStyles}
            noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
            highlightOnHover
          />
        </CardBody>
      </Card>

      {
        dataList.length > 0 ?
          <Card>
            <CardBody>
              <div className='d-flex justify-content-between mb-2'>
                <h5 className="text-primary mb-4">{props.t("Products")}</h5>
                <div>
                  {hasCreatePermission(permissions, "DE_08") ?
                    <Button className="btn btn-md mx-2" color="success" onClick={handleAddProduct}>{props.t("Add Product")}</Button>
                    : <Button className="btn btn-md mx-2" disabled color="secondary">{props.t("Add Product")}</Button>}
                </div>
              </div>
              <DataTable
                style={{
                  border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
                }}
                className='data-table p-3'
                columns={columnDetails}
                data={filteredDataList}
                customStyles={customStyles}
                pagination
                noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
                paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
                highlightOnHover
                paginationLa
                subHeader
                subHeaderComponent={
                  <div className='d-flex align-items-right mb-2'>
                    <div className='d-flex'>
                      {hasExcelPermission(permissions, "DE_01") && filteredDataList.length > 0 ? excelButton : null}
                      <Input
                        type="text"
                        placeholder={props.t("Search")}
                        value={searchTextData}
                        onChange={(e) => setSearchTextData(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                        style={{ width: '250px' }} // Smaller input field
                      />
                    </div>
                  </div>
                }
              />
            </CardBody>
          </Card>
          : null
      }
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

ProductCategoryDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ProductCategoryDetails)