import { SET_STOCK_FILTER_FORM_DATA, CLEAR_STOCK_FILTER_FORM_DATA } from './actionTypes';

export const setStockFilterFormData = (formData) => ({
  type: SET_STOCK_FILTER_FORM_DATA,
  payload: formData,
});

export const clearStockFilterFormData = () => ({
  type: CLEAR_STOCK_FILTER_FORM_DATA,
});
