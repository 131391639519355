import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Row, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import { createCountry, getCountries } from 'service/Definitions/countryService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { getCities } from 'service/Definitions/cityService';
import { useSelector } from 'react-redux';
import { closeUpdateModal } from 'store/updateModal/actions';
import { hasCreatePermission } from 'service/common/permissionService';
import { clearSelectedUpdateCountry, setSelectedUpdateCountry } from 'store/depot/selectedUpdateCountry/action';
import { clearSelectedUpdateCity, setSelectedUpdateCity } from 'store/depot/selectedUpdateCity/action';
import { clearUpdateDepotFormData, setUpdateDepotFormData } from 'store/settings/updateDepotForm/action';
import { getDepots, updateDepot } from 'service/settings/depotService';
import { setDepots } from 'store/settings/depots/actions';
import { customValidationError } from 'components/Common/customValidationError';
import { closeFastCreateModal, openFastCreateModal } from 'store/fastCreateModal/actions';

const WarehouseUpdateForm = props => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const updateDepotFormData = useSelector((state) => state.updateDepotFormData?.formData || {});
    const selectedUpdateCountry = useSelector((state) => state.selectedUpdateCountry?.selectedUpdateCountry || null);
    const selectedUpdateCity = useSelector((state) => state.selectedUpdateCity?.selectedUpdateCity || null);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [isHovered, setIsHovered] = useState(false); // Hover durumu için state

    useEffect(() => {
        console.log("updateDepotFormDataaaaaa");
        console.log(updateDepotFormData);
        const fetchData = async () => {
            try {
                const countryList = await getCountries();
                const cityList = await getCities();
                console.log(countryList);
                console.log(cities);
                const formattedCities = cityList.map(city => ({
                    value: city.id,
                    label: city.name,
                    country_id: city.country_id
                }));

                const formattedCountries = countryList.map(country => ({
                    value: country.id,
                    label: country.name
                }));
                
                console.log("**********");
                console.log(formattedCities);
                console.log(formattedCountries);
                setCountries(formattedCountries);
                setCities(formattedCities);
            } catch (error) {
                console.log(`error ${error}`);
            }
        }

        fetchData();
    }, [dispatch]);

    const handleChange = (e) => {
        const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        dispatch(setUpdateDepotFormData({
            ...updateDepotFormData,
            [e.target.name]: inputData,
        }));
    };

    useEffect(() => {
        console.log("updateDepotFormData formData güncellendi");
        console.log(updateDepotFormData);
    }, [updateDepotFormData]);

    const handleChangeLowerCase = (e) => {
        const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value;
        dispatch(setUpdateDepotFormData({
            ...updateDepotFormData,
            [e.target.name]: inputData,
        }));
    };

    const handlePhone = (value) => {
        console.log("value");
        console.log(value);
        dispatch(setUpdateDepotFormData({
            ...updateDepotFormData,
            ["phone"]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm()
    };

    const handleSelectCountryChange = (field, selected) => {
        console.log("country change çalıştııı");
        dispatch(clearSelectedUpdateCity());
        if (selected) {
            dispatch(setSelectedUpdateCountry(selected));
            dispatch(setUpdateDepotFormData({ ...updateDepotFormData, [field]: selected.value, ["city_id"]: null }));

        } else {
            dispatch(clearSelectedUpdateCountry());
            dispatch(setUpdateDepotFormData({ ...updateDepotFormData, [field]: null, ["city_id"]: null }));
        }
    };

    const handleSelectCityChange = (field, selected) => {
        dispatch(setSelectedUpdateCity(selected));
        dispatch(setUpdateDepotFormData({ ...updateDepotFormData, [field]: selected ? selected.value : null }));
    };

    const handleCreateCountry = (inputValue) => {
        if (!hasCreatePermission(permissions, "DE_10")) {
            toast.error(props.t("No authority"), { position: "top-right" });
            return;
        }
        dispatch(
            openFastCreateModal({
                title: inputValue,
                onConfirm: async () => {
                    dispatch(closeFastCreateModal());
                    // Yeni bir şirket oluşturmak için:
                    const newCountry = {
                        name: inputValue,  // Kullanıcının girdiği değer
                    };
                    await createCountry(newCountry)
                        .then(async (id) => {
                            const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
                            const newSelected = newCountryList.find(data => data.id === id);
                            const formattedCountries = newCountryList.map(country => ({
                                value: country.id,
                                label: country.name
                            }));
                            setCountries(formattedCountries);
                            dispatch(setSelectedUpdateCountry({ value: newSelected.id, label: newSelected.name }));
                            dispatch(setUpdateDepotFormData({ ...updateDepotFormData, ["country_id"]: newSelected.id, ["city_id"]: null }));
                            dispatch(clearSelectedUpdateCity());

                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                        })
                        .catch((error) => {
                            //toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                            customValidationError(error,props);
                        }); 
                }
            }));// Modalı açıyoruz
    }

    const handleConfirm = async () => {
        console.log("company add Form Data handle Confirm");
        console.log(updateDepotFormData);
        dispatch(
            openModal({
                title: props.t("modal-update-title"),
                body: props.t("modal-update-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after successful update
                    const { createdAt, updatedAt, depot_no, country_name, city_name, is_passive, qr, client_no, ...rest } = updateDepotFormData;
                    console.log("update confirm formdata");
                    console.log(updateDepotFormData);

                    console.log("reessstttt");
                    console.log(rest);
                    await updateDepot(rest)
                        .then(async () => {
                            toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                            console.log(updateDepotFormData);
                            const list = await getDepots(); // Fetch updated companies
                            const { info, data } = list;
                            dispatch(setDepots(data || []));
                            dispatch(clearSelectedUpdateCity());
                            dispatch(clearSelectedUpdateCountry());
                            dispatch(clearUpdateDepotFormData());
                            dispatch(closeUpdateModal());

                        })
                        .catch((error) => {
                            console.error("Şirket ekelme işlemi başarısız oldu:", error);
                            //toast.error(props.t("toast-error-update-data"), { position: "top-right" });
                            customValidationError(error,props);
                        });
                }
            })
        );
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Update Depot")}</h5>
                    <Button className='text-white' color="warning" type="submit">
                        {props.t("Update")}
                    </Button>
                </div>
                <Row className='mb-4' form>

                    <Col md={6}>
                        <FormGroup>
                            <Label for="name">{props.t("Depot Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={updateDepotFormData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter depot name")}
                                maxLength={64}
                                minLength={1}
                                required
                            />
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <Label for="phone">{props.t("Phone")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder={props.t("Enter phone number")}
                                value={updateDepotFormData.phone}
                                minLength={6}
                                maxLength={24}
                                onMouseEnter={() => setIsHovered(true)}  // Hover durumu aktif
                                onMouseLeave={() => setIsHovered(false)} // Hover durumu pasif
                                onChange={value => handlePhone(value)} />
                        </FormGroup>
                    </Col>

                    {/* <Col md={4}>
                        <FormGroup>
                            <Label for="address">{props.t("Address")}</Label>
                            <DynamicInput
                                type="text"
                                name="address"
                                id="address"
                                value={updateDepotFormData.address}
                                onChange={handleChange}
                                placeholder={props.t("Enter address")}
                                maxLength={255}
                                minLength={3}
                            />
                        </FormGroup>
                    </Col> */}

                    <Col md={6}>
                        <FormGroup>
                            <Label for="country">{props.t("Country")}</Label>
                            {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_11")} props={props} />
                            {hasViewPermission(permissions, "DE_11") ? */}
                                <DynamicSelect
                                    options={countries}
                                    value={selectedUpdateCountry}
                                    onChange={(selected) => handleSelectCountryChange("country_id", selected)} // Kategori seçimi
                                    onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                                    placeholder={props.t("Enter country")}
                                    isClearable
                                    noOptionsMessage={() => props.t("no-option-message")}
                                    formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                                    onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                    filterOption={(option, inputValue) => {
                                        const clientNo = option.data.label || "";
                                        // Hem client_no hem de company.name üzerinden arama yapabilmek için
                                        const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                                        return companyNameMatch;
                                    }}
                                />
                                {/* :
                                <NoAuthUpdateInput data={formData.country_name} />
                            } */}
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <Label for="city">{props.t("City")}</Label>
                            {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_12")} props={props} />
                            {hasViewPermission(permissions, "DE_12") ? */}
                                <DynamicSelect
                                    options={cities.filter(data => selectedUpdateCountry ? data.country_id === selectedUpdateCountry.value : null)} // Filtreleme işlemi
                                    value={selectedUpdateCity}
                                    onChange={(selected) => handleSelectCityChange("city_id", selected)} // Kategori seçimi
                                    placeholder={props.t("Enter city")}
                                    isClearable
                                    noOptionsMessage={() => props.t("no-option-message")}
                                    onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                    filterOption={(option, inputValue) => {
                                        // Hem client_no hem de company.name üzerinden arama yapabilmek için
                                        const clientNo = option.data.label || "";

                                        const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                                        return companyNameMatch;
                                    }}
                                />
                                {/* :
                                <NoAuthUpdateInput data={formData.city_name} />
                            } */}
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

WarehouseUpdateForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(WarehouseUpdateForm)