// reducer.js
import {
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_FAILURE,
    UPDATE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_SUCCESS,
    SET_PRODUCT,
  } from './actionTypes';
  
  const initialState = {
    products: [],
    error: null,
  };
  
  const productReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_PRODUCT_SUCCESS:
        const updatedProducts = state.products.map((product) =>
        product.id === action.payload.id ? action.payload.productData : product
        );
        return {
          ...state,
          products: updatedProducts,
        };
  
      case DELETE_PRODUCT_SUCCESS:
        const filteredProducts = state.products.filter((product) => product.id !== action.payload);
        return {
          ...state,
          products: filteredProducts,
        };
        
        case SET_PRODUCT:
          return {
            ...state,
            products: action.payload,  // Payload doğrudan companies array'i olacak
          };
  
      default:
        return state;
    }
  };
  
  export default productReducer;
  