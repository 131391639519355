import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { FaEdit } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EditButton = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  const [editTooltipOpen, setEditTooltipOpen] = useState(false);

  const toggleEditTooltip = () => setEditTooltipOpen(!editTooltipOpen);

  return (
    <div id="editButtonTooltipWrapper">
      <Button color="link" onClick={onClick} disabled={disabled} className='py-0'>
        <FaEdit color={disabled ? "gray" : "green"} size={18} />
      </Button>
      
      <Tooltip
        isOpen={editTooltipOpen}
        target="editButtonTooltipWrapper"
        toggle={toggleEditTooltip}
        placement="top"
      >
        {disabled ? t("No authority") : t("Update")}
      </Tooltip>
    </div>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

EditButton.defaultProps = {
  disabled: false
};

export default EditButton;
