// src/store/selectedCountry/selectedCountryReducer.js
import { SET_SELECTED_UPDATE_COUNTRY, CLEAR_SELECTED_UPDATE_COUNTRY } from './actionTypes';

const initialState = {
  selectedUpdateCountry: null, // Başlangıçta seçilen ülke boş
};

const selectedUpdateCountryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_UPDATE_COUNTRY:
      return {
        ...state,
        selectedUpdateCountry: action.payload,
      };

    case CLEAR_SELECTED_UPDATE_COUNTRY:
      return {
        ...state,
        selectedUpdateCountry: null,
      };

    default:
      return state;
  }
};

export default selectedUpdateCountryReducer;
