// /src/services/inslugService.js
import axios from 'axios';
import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const API_DEPOT = '/depot';
const API_INSLUG = '/inslug';
const API_INSLUG_INFO = '/info';
const API_INSLUG_GET_ONE = '/one-inslug-with-items';
const API_INSLUG_GET = '/get';
const API_INSLUG_CREATE = '/create';
const API_INSLUG_UPDATE = '/update';
const API_INSLUG_DELETE = '/delete';
const API_INSLUG_APPROVE = '/approve';
const API_INSLUG_GET_DELETED = '/get-deleted';
const API_INSLUG_RESTORE = '/restore';
const API_INSLUG_QR_LIST = '/qr-list';
const API_INSLUG_APPROVED  ='/approved';
const API_ALL_QR = '/all-qr';
/**
 * Inslug ekleme fonksiyonu
 * @param {Object} inslugData - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const createInslug = async (inslugData) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_CREATE}`, inslugData);
    return response.data;
  } catch (error) {
    console.error('Inslug eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Inslug ekleme fonksiyonu
 * @param {Object} id - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const getInslug = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_GET_ONE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug ekleme fonksiyonu
 * @param {Object} id - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const getAllQrs = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_ALL_QR}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mevcut tüm Inslug'ları getirme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getInslugs = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_GET}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


/**
 * Mevcut tüm Inslug'ları getirme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getApproved = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_APPROVED}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug geri getirme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getInslugQrs = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_QR_LIST}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};


/**
 * Mevcut tüm Inslug'ları getirme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedInslugs = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_GET_DELETED}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


/**
 * Inslug eklemek için gerekli olan verileri çeken fonskiyon
 * @returns {Promise} - API cevabı
 */
export const inslugInfo = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_INFO}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Belirli bir inslug'ı ID'ye göre güncelleme fonksiyonu
 * @param {string} id - Güncellenecek Inslug'un ID'si
 * @param {Object} inslugData - Güncellenmiş Inslug verileri
 * @returns {Promise} - API cevabı
 */
export const updateInslug = async (inslugData) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_UPDATE}`, inslugData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteInslug = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_DELETE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const approveInslug = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_APPROVE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Inslug geri getirme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreInslug = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_RESTORE}`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};
