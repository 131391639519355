import { API_BASE_URL } from "config";
import { getWithToken, postWithToken } from 'service/common/tokenService';
const API_DEPOT = '/depot';
const API_UNIT = '/unit';
const CREATE = '/create';
const GET = '/get';
const GET_DEPOT_UNITS = '/get-depot-units';
const GET_DELETED = '/get-deleted';
const UPDATE = '/update';
const DELETE = '/passive';
const RESTORE = '/restore';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} data - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createUnit = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_UNIT}${CREATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getUnits = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_UNIT}${GET}`);
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};


/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const getDepotUnits = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_UNIT}${GET_DEPOT_UNITS}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedUnits = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_UNIT}${GET_DELETED}`);
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateUnit = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_UNIT}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteUnit = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_UNIT}${DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreUnit= async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_UNIT}${RESTORE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};
