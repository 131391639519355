// reducer.js
import {
  UPDATE_CUSTOMS_STATUS_SUCCESS,
  DELETE_CUSTOMS_STATUS_SUCCESS,
  SET_CUSTOMS_STATUS,
} from './actionTypes';

const initialState = {
  customsStatus: [],
  error: null,
};

const customsStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMS_STATUS_SUCCESS:
      const updatedCustomsStatus = state.customsStatus.map((country) =>
      country.id === action.payload.id ? action.payload.data : country
      );
      return {
        ...state,
        customsStatus: updatedCustomsStatus,
      };

    case DELETE_CUSTOMS_STATUS_SUCCESS:
      const filteredCustomsStatus = state.customsStatus.filter((country) => country.id !== action.payload);
      return {
        ...state,
        customsStatus: filteredCustomsStatus,
      };

    case SET_CUSTOMS_STATUS:
      return {
        ...state,
        customsStatus: action.payload, 
      };

    default:
      return state;
  }
};

export default customsStatusReducer;
