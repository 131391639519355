import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setCities } from '../../../../store/depot/Definitions/city/action'; // Redux actions
import { openModal, closeModal } from '../../../../store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import { deleteCity, getCities, updateCity } from 'service/Definitions/cityService';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import { setCityFormData } from 'store/updates/cityForm/action';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateCityForm from './cityUpdateForm';
import UpdateModal from 'components/Common/Modals/updateModal';
import { setSelectedCountry } from 'store/depot/selectedCountry/action';
import { getCountries } from 'service/Definitions/countryService';
import { setIsCreate } from 'store/isUpdate/action';
import { hasDeletePermission, hasExcelPermission, hasUpdatePermission } from 'service/common/permissionService';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';


const CityList = props => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [editingRow, setEditingRow] = useState(null); // Row being edited
  // const [formData, setFormData] = useState({}); // State for form data
  const formData = useSelector((state) => state.cityFormData?.formData || {});

  const [searchText, setSearchText] = useState(''); // State for search text
  const [filteredCities, setFilteredCities] = useState([]);

  const cities = useSelector((state) => state.cities?.cities || []);
  const [countries, setCountries] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const cityList = await getCities(); // Fetch companies from API
        console.log(cityList);
        dispatch(setCities(cityList));  // Dispatch to Redux store
        setFilteredCities(cityList);
        console.log("******");
        console.log(cityList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        toast.error("toast-error-fetch-data", { position: "top-right" });
      }
    };

    const fetchCountryData = async () => {
      try {
        const countryList = await getCountries();
        console.log(countries)
        setCountries(countryList)
      } catch (error) {
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
    fetchCountryData();
  }, [dispatch]);


  useEffect(() => {
    const filteredData = cities.filter((city) =>
      city.name?.includes(searchText) ||
      city.country_name?.includes(searchText)
    );
    setFilteredCities(filteredData);
  }, [searchText, cities]);

  /*   const handleEdit = (id, rowIndex) => {
      const selectedData = filteredCities.find(data => data.id === id);
      setEditingRow(rowIndex); // Set the row for editing
      setFormData(selectedData); // Set the form data for the current row
    }; */

  // Handle editing row and open modal
  const handleEdit = (id) => {
    dispatch(setIsCreate(false));
    const selectedData = filteredCities.find(data => data.id === id);
    console.log("selectedDAta");
    console.log(selectedData);
    console.log("countries");
    console.log(countries);
    const selectedCountryData = countries.find(data => data.id === selectedData.country_id);
    console.log("selectedCountryData");
    console.log(selectedCountryData);
    //dispatch(setFormData(selectedData)); // Set the form data for the current row
    dispatch(setCityFormData(selectedData));
    dispatch(setSelectedCountry({ value: selectedCountryData.id, label: selectedCountryData.name }));
    // Open modal for editing
    dispatch(
      openUpdateModal({
        body: (
          <UpdateCityForm />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleSave = (rowIndex) => {
    dispatch(
      openModal({
        title: props.t("modal-update-title"),
        body: props.t("modal-update-body"),
        onConfirm: async () => {
          try {
            await updateCity(formData); // Call API to update company
            toast.success(props.t("toast-success-update-data"), { position: "top-right" });

            const countries = await getCities(); // Fetch updated companies
            dispatch(setCities(countries)); // Update Redux state
            setFilteredCities(countries); // Update filtered companies
            setEditingRow(null); // Exit edit mode
            dispatch(closeModal()); // Close the modal after successful update

          } catch (error) {
            console.error('Error updating company:', error);
            toast.error(props.t("toast-error-update-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteCity(id); // Delete company
            toast.success(props.t("toast-error-delete-data"), { position: "top-right" });

            const updatedCompanies = await getCities(); // Fetch updated companies
            dispatch(setCities(updatedCompanies)); // Update Redux state
            setFilteredCities(updatedCompanies); // Update filtered companies
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('There was a problem deleting the product group.', error);
            toast.error(props.t("toast-success-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setCityFormData({
      ...formData,
      [name]: value.toLocaleUpperCase("tr"),
    }));
  };

  const exportToExcel = () => {

    const column1Name = props.t("id");
    const column2Name = props.t("Country");
    const column3Name = props.t("City");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredCities.map(country => ({
      [column1Name]: country.id, // 1. sütun verisi
      [column2Name]: country.country_name, // 2. sütun verisi
      [column3Name]: country.name, // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("City List"));
    XLSX.writeFile(workbook, props.t("City List") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const columns = [
    {
      name: props.t("#"),
      selector: (row, rowIndex) => row.id,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Country"),
      selector: (row, rowIndex) => row.country_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("City"),
      selector: (row, rowIndex) =>
        row.name,
      sortable: true,
      wrap: true,

    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        <>
          {hasUpdatePermission(permissions, "DE_12") ? <EditButton disabled={false} onClick={() => handleEdit(row.id)} /> : <EditButton disabled={true} />}
          {hasDeletePermission(permissions, "DE_12") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} />}
        </>
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary">{props.t("City List")}</h5>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredCities}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_12") && filteredCities.length > 0 ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
              maxLength={32}
            />
          </div>
        }
      />

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

CityList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(CityList)