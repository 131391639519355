import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next";
import { setTimer } from 'store/timer/actions';
import { closeUpdateModal } from 'store/updateModal/actions';

const UpdateModal = props => {
  const dispatch = useDispatch();

  // Get the modal content from Redux state
  const updateModalState = useSelector((state) => state.updateModal);
  const timer = useSelector((state) => state.timerOpen.timer);

  const { isOpen, content } = updateModalState;

  useEffect(()=>{
    console.log("timer");
    console.log(timer);
    console.log("---------");
  },[timer])


  // Handle modal close
  const handleClose = () => {
    dispatch(closeUpdateModal());
  };

  // Handle form submit
  const handleSubmit = (e) => {
    content.onConfirm();  // Onay fonksiyonunu çalıştır
  };

  // Enter tuşuna basıldığında formu submit et
  const handleKeyDown = (e) => {
    try {
      console.log(timer);
    if (e.key === 'Enter') {
      if (timer === true) {
        dispatch(setTimer(false));
        handleSubmit(e); // Formu submit et
        const interval = setInterval(() => {
          dispatch(setTimer(true));
        }, 1000);
      }
    }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <Modal size='xl' isOpen={isOpen} toggle={handleClose} style={{width: "1200px"}}>
        <ModalHeader toggle={handleClose}>{content.title}</ModalHeader>
        <ModalBody>
          {content.body}
        </ModalBody>
      </Modal>
    </form>
  );
};

UpdateModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateModal);
