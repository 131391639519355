// src/store/selectedCountry/selectedCountryReducer.js
import { SET_SELECTED_PRODUCT_GROUP, CLEAR_SELECTED_PRODUCT_GROUP } from './actionTypes';

const initialState = {
  selectedProductGroup: null, // Başlangıçta seçilen ülke boş
};

const selectedProductGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT_GROUP:
      return {
        ...state,
        selectedProductGroup: action.payload,
      };

    case CLEAR_SELECTED_PRODUCT_GROUP:
      return {
        ...state,
        selectedProductGroup: null,
      };

    default:
      return state;
  }
};

export default selectedProductGroupReducer;
